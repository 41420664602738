import {
  Field,
  FieldArray,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import styles from "./styles.module.scss";
import Input from "../../../custom/input/input";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createNextOfKin, getNextOfKin } from "../../../requests";
import { useAtomValue } from "jotai";
import { isUpdateProfile, userData } from "../../../state";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import AddNextOfKinModal from "./addNextOfKinModal";
import { errorMessage } from "../../../utils/errorMessage";

const NextOfKin = () => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const isEditProfile = useAtomValue(isUpdateProfile);
  const [open, setOpen] = useState(false);
  const [currentField, setCurrentField] = useState<NextOfKin>({} as NextOfKin);

  const updateNextOfKinMutation = useMutation({ mutationFn: createNextOfKin });
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["next-of-kin"],
    queryFn: () => getNextOfKin(user?.MemberId!),
  });

  const nextOfKinData = data?.Data as NextOfKin[];

  const updateNextOfKinHandler = async (
    values: FormikValues,
    resetForm: () => void
  ) => {
    const curr = (values.nextKin as NextOfKin[])?.find(
      (work) => work.Id === currentField.Id
    ) as NextOfKin;

    const payload: Partial<NextOfKin> = {
      Id: currentField?.Id,
      FullName: curr?.FullName,
      Email: curr?.Email,
      PhoneNumber: curr?.PhoneNumber,
      Relationship: curr?.Relationship,
      Address: curr?.Address,
      Designation: curr?.Designation,
      MembershipNo: user?.MemberId!,
    };

    try {
      await updateNextOfKinMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          refetch();
          resetForm();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      // fullName: "",
      // email: "",
      // phoneNumber: "",
      // relationship: "",
      // contactAddressLine1: "",
      nextKin: nextOfKinData,
    },
    onSubmit: (values, { resetForm }) =>
      updateNextOfKinHandler(values, resetForm),
    enableReinitialize: true,
  });

  if (isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <FormikProvider value={formik}>
        <Button
          className={classNames(styles.lightBtn, styles.end)}
          onClick={() => setOpen(true)}
          text="Add Next of Kin"
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <FieldArray name="nextKin">
            {() => (
              <>
                {nextOfKinData?.map((nextOfKin, index) => (
                  <section className={styles.details} key={nextOfKin?.Id}>
                    <h2 className={styles.heading}>
                      NEXT OF KIN - {index + 1}
                    </h2>
                    <hr className={styles.hr} />
                    <section className={styles.fields}>
                      <Field
                        as={Input}
                        type="text"
                        name={`nextKin[${index}].FullName`}
                        label="Full Name"
                        placeholder={nextOfKin?.FullName}
                        className={styles.input}
                        disabled={!isEditProfile}
                      />
                      <Field
                        as={Input}
                        type="email"
                        name={`nextKin[${index}].Email`}
                        label="Email Address"
                        placeholder={nextOfKin?.Email}
                        className={styles.input}
                        disabled={!isEditProfile}
                      />
                    </section>
                    <section className={styles.fields}>
                      <Field
                        as={Input}
                        type="text"
                        name={`nextKin[${index}].PhoneNumber`}
                        label="Phone Number"
                        placeholder={nextOfKin?.PhoneNumber}
                        className={styles.input}
                        disabled={!isEditProfile}
                      />
                      <Field
                        as={Input}
                        type="text"
                        name={`nextKin[${index}].Relationship`}
                        label="Relationship"
                        placeholder={nextOfKin?.Relationship}
                        className={styles.input}
                        disabled={!isEditProfile}
                      />
                      <Field
                        as={Input}
                        type="text"
                        name={`nextKin[${index}].Designation`}
                        label="Occupation"
                        placeholder={nextOfKin?.Designation}
                        className={styles.input}
                        disabled={!isEditProfile}
                      />
                      <Field
                        as={Input}
                        type="text"
                        name={`nextKin[${index}].Address`}
                        label="Contact Address"
                        placeholder={nextOfKin?.Address}
                        className={styles.input}
                        disabled={!isEditProfile}
                      />
                    </section>
                    <br />
                    {isEditProfile && (
                      <section className={styles.btnWrapper}>
                        {/* <Button text='Cancel' className={styles.cancelBtn} /> */}
                        <Button
                          text="Save Changes"
                          type="button"
                          onClick={() => {
                            setCurrentField(nextOfKin);
                            formik.handleSubmit();
                          }}
                          isLoading={updateNextOfKinMutation.isPending}
                          disabled={updateNextOfKinMutation?.isPending}
                        />
                      </section>
                    )}
                  </section>
                ))}
              </>
            )}
          </FieldArray>
        </form>
      </FormikProvider>
      <AddNextOfKinModal open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

export default NextOfKin;
