import styles from "./styles.module.scss";
import Logo from "../../assets/success.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MessageReceived = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, [navigate]);

  return (
    <section className={styles.section}>
      <img className={styles.icon} src={Logo} alt="successful" loading="lazy" />
      <h2 className={styles.heading}>Message Received!</h2>
      <p className={styles.para}>
        We received your message. Our <br /> team will send a response within
        the <br />
        next 24 hours.
      </p>
    </section>
  );
};

export default MessageReceived;
