import classNames from "classnames";
import styles from "./styles.module.scss";
import Button from "../../custom/button/button";
import { App, Modal, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { userProfile } from "../../state";
import { endorseMember, getEndorsementRequests } from "../../requests";
import { AxiosError } from "axios";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import { useState } from "react";
import { errorMessage } from "../../utils/errorMessage";
import * as Yup from "yup";

const EndorsementRequests = () => {
  const profile = useAtomValue(userProfile);
  const { notification } = App.useApp();
  const [open, setOpen] = useState(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [request, setRequest] = useState<EndorsementRequest | null>(null);

  const columns: ColumnsType<EndorsementRequest> = [
    {
      title: "S/N",
      key: "index",
      render(_value, _record, index) {
        return index + 1;
      },
    },
    {
      title: "Member ID",
      dataIndex: "ReuesterMembershipNo",
      key: "index",
      render: (_, { ReuesterMembershipNo }) => ReuesterMembershipNo || "N/A",
    },
    {
      title: "Member Name",
      dataIndex: "RequesterName",
      key: "index",
      render: (_, { RequesterName }) => RequesterName || "N/A",
    },
    {
      title: "Endorsement Type",
      dataIndex: "EndorsementType",
      key: "index",
      render: (_, { EndorsementType }) => EndorsementType || "N/A",
    },
    {
      title: "Status",
      dataIndex: "IsEndorsed",
      key: "index",
      render: (_, { IsEndorsed, HasResponded }) => {
        if (IsEndorsed) return <span className={styles.payment}>Endorsed</span>;
        else {
          if (HasResponded)
            return <span className={styles.declined}>Rejected</span>;
          else
            return <span className={styles.waiting}>Pending Endorsement</span>;
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, item) => (
        <button
          className={styles.extLink}
          onClick={() => {
            setRequest(item);
            setOpen(true);
          }}
        >
          View
        </button>
      ),
    },
  ];

  const endorseMemberMutation = useMutation({
    mutationKey: ["endorseMember"],
    mutationFn: endorseMember,
  });
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["get-endorsement-requests", profile.BranchId],
    queryFn: () => getEndorsementRequests(profile?.BranchId),
    enabled: !!profile?.BranchId,
  });

  const activityData = data?.Data as EndorsementRequest[];

  const endorseMemberHandler = async (
    values: FormikValues,
    isEndorsed: boolean,
    resetForm: () => void
  ) => {
    const payload: EndorseMemberPayload = {
      endorsementRequestModel: {
        MembershipNo: request?.ReuesterMembershipNo!,
        BranchId: profile?.BranchId!,
        ChapterId: profile?.ChapterId!,
        EndorsementType: request?.EndorsementType! || "Branch",
        EndorsementId: request?.EndorsementId!,
        ElectionId: request?.ElectionId!,
        RequesterComment: request?.RequesterComment!,
      },
      ChairmanMembershipNo: profile?.MembershipNo!,
      Comment: values?.comment || "N/A",
      IsEndorsed: isEndorsed,
    };

    try {
      await endorseMemberMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          setOpen(false);
          refetch();
          resetForm();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string(),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      comment: "",
    },
    onSubmit: (values, { resetForm }) => {
      endorseMemberHandler(values, isApproved, resetForm);
    },
    validationSchema,
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("spaceBetween")}>
        <h3>Endorsement Requests</h3>
      </section>
      <section>
        <Table
          columns={columns}
          dataSource={activityData ?? []}
          scroll={{ x: true }}
          pagination={false}
          locale={{ emptyText: "No requests yet" }}
          rootClassName={classNames("table")}
        />
      </section>
      <Modal
        title={
          request?.HasResponded
            ? "Endorsement Request Details"
            : "Endorse Member"
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        styles={{
          body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
        }}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <div className={styles.fieldCol}>
                <label>
                  <b>Member ID:</b> {request?.ReuesterMembershipNo || "N/A"}
                </label>
                <label>
                  <b>Endorsement Type:</b> {request?.EndorsementType || "N/A"}
                </label>
                <label>
                  <b>Comment from member:</b>{" "}
                  {request?.RequesterComment || "N/A"}
                </label>

                {request?.HasResponded ? null : (
                  <>
                    <Field
                      as={Input}
                      name="comment"
                      label="Leave a comment"
                      placeholder="Comment"
                    />
                    <section className={styles.cta}>
                      <Button
                        type="button"
                        text="Reject"
                        onClick={() => {
                          setIsApproved(false);
                          formik.submitForm();
                        }}
                        className={styles.btnReject}
                        isLoading={endorseMemberMutation.isPending}
                        disabled={endorseMemberMutation.isPending}
                      />
                      <Button
                        type="button"
                        text="Approve"
                        onClick={() => {
                          setIsApproved(true);
                          formik.submitForm();
                        }}
                        isLoading={endorseMemberMutation.isPending}
                        disabled={endorseMemberMutation.isPending}
                      />
                    </section>
                  </>
                )}
              </div>
            </form>
          </FormikProvider>
        </div>
      </Modal>
    </div>
  );
};

export default EndorsementRequests;
