import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  children?: React.ReactNode;
  title: string;
  className?: string;
  isOpen?: boolean;
}

const Details = ({ children, title, className, isOpen, ...rest }: Props) => {
  return (
    <details
      open={isOpen}
      className={classNames(className, styles.section)}
      {...rest}
    >
      <summary className={styles.headingWrapper}>
        <h2 className={styles.heading}>{title}</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className={styles.icon}
        >
          <g clip-path="url(#clip0_19014_16250)">
            <path
              d="M18.7416 14.9348C18.5775 14.935 18.4149 14.9027 18.2633 14.8398C18.1117 14.7769 17.974 14.6845 17.8583 14.5681L11.4641 8.17481C11.2707 7.98129 11.041 7.82779 10.7882 7.72305C10.5354 7.61832 10.2644 7.56442 9.99079 7.56442C9.71715 7.56442 9.4462 7.61832 9.1934 7.72305C8.94061 7.82779 8.71092 7.98129 8.51745 8.17481L2.13329 14.5598C2.01798 14.6792 1.88005 14.7744 1.72754 14.8399C1.57504 14.9054 1.41101 14.9399 1.24504 14.9414C1.07907 14.9428 0.914465 14.9112 0.760845 14.8483C0.607224 14.7855 0.467661 14.6927 0.350294 14.5753C0.232929 14.4579 0.140112 14.3184 0.077261 14.1647C0.01441 14.0111 -0.0172176 13.8465 -0.0157757 13.6805C-0.0143337 13.5146 0.0201492 13.3505 0.0856609 13.198C0.151173 13.0455 0.246399 12.9076 0.365788 12.7923L6.74995 6.40731C7.61003 5.54883 8.77559 5.06668 9.99079 5.06668C11.206 5.06668 12.3715 5.54883 13.2316 6.40731L19.6258 12.8006C19.8007 12.9754 19.9199 13.1982 19.9681 13.4408C20.0164 13.6833 19.9917 13.9348 19.897 14.1632C19.8023 14.3917 19.642 14.587 19.4364 14.7243C19.2307 14.8617 18.9889 14.9349 18.7416 14.9348Z"
              fill="#374957"
            />
          </g>
          <defs>
            <clipPath id="clip0_19014_16250">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="matrix(-1 0 0 -1 20 20)"
              />
            </clipPath>
          </defs>
        </svg>
      </summary>
      <p className={styles.children}>{children}</p>
    </details>
  );
};

export default Details;
