import classNames from "classnames";
import Button from "../../custom/button/button";
import styles from "./main.module.scss";
import { App } from "antd";
import { useAtomValue } from "jotai";
import { otherPayment, userData } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { baseUrl, processPayment } from "../../requests";
import { formatCurrency } from "../../utils/formatCurrency";

const PayForOtherEvent = ({
  setIsEventScheduled,
  hasUserRegistered,
  amount,
  dataId,
}: {
  setIsEventScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  hasUserRegistered?: boolean;
  amount: number;
  dataId: number;
}) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const otherPaymentDetails = useAtomValue(otherPayment);

  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });

  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: amount || otherPaymentDetails?.Amount,
      Description: "Other event Payment",
      ReturnURL: `${baseUrl}/event-application/other-events`,
      PaymentForm: "Other",
      DataId: dataId ? `${dataId}` : otherPaymentDetails?.DataId!,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          setIsEventScheduled(true);
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.message ||
          error?.response?.data?.title ||
          error?.message,
      });
    }
  };

  return (
    <section className={classNames("card")}>
      <h2>Pay for Event</h2>
      <div className={styles.line}></div>
      {hasUserRegistered ? (
        <p>
          Looks like you've registered for this event but have not completed
          payment. Proceed to pay{" "}
          <b>
            {amount
              ? formatCurrency(amount)
              : formatCurrency(otherPaymentDetails?.Amount)}
          </b>{" "}
          before the event date.
        </p>
      ) : (
        <p>
          You are one step away to complete your event registration. Proceed to
          pay{" "}
          <b>
            {amount
              ? formatCurrency(amount)
              : formatCurrency(otherPaymentDetails?.Amount)}
          </b>{" "}
          before the event date.
        </p>
      )}
      <br />
      <Button
        type="button"
        isLoading={processPaymentMutation.isPending}
        disabled={processPaymentMutation.isPending}
        onClick={handlePayment}
        text="Make Payment"
      />
    </section>
  );
};

export default PayForOtherEvent;
