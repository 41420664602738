import { App, Modal, Spin } from "antd";
import Button from "../../custom/button/button";
import styles from "./main.module.scss";
import warning from "../../assets/warning.png";
import success from "../../assets/success.png";
import points from "../../assets/points.png";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { formatDate } from "../../utils/formatDate";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  baseUrl,
  getAssessments,
  getCutOffScores,
  processPayment,
  queryPayment,
  requestLicense,
  TransactionStatus,
} from "../../requests";
import { AxiosError } from "axios";
import { errorMessage } from "../../utils/errorMessage";
import ProfileCard from "../profileCard/profileCard";

const columns: ColumnsType<Assessment> = [
  {
    title: "Date Registered",
    dataIndex: "DateRegistered",
    key: "ExamRegistrationId",
    render: (_, { DateRegistered }) => formatDate(DateRegistered),
  },
  {
    title: "Assessment Name",
    dataIndex: "ExamName",
    key: "ExamRegistrationId",
  },
  {
    title: "Score",
    dataIndex: "Score",
    key: "ExamRegistrationId",
  },
  {
    title: "Status",
    key: "ExamRegistrationId",
    dataIndex: "tags",
    render: (_, { HasAttended }) => {
      let color = HasAttended ? styles.successful : styles.pending;
      return <p className={color}>{HasAttended ? "Attended" : "Pending"}</p>;
    },
  },
  {
    title: "Action",
    key: "action",
    render: (_, item) => (
      <a
        className={styles.extLink}
        href={`/competency-assessment/details?examRegistrationId=${item.ExamRegistrationId}`}
      >
        View
      </a>
    ),
  },
];

const Main = () => {
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const navigate = useNavigate();
  const ref = params.get("ref");
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentData, setPaymentData] = useState<ProcessPaymentPayload>({
    RouteId: 0,
    MembershipNo: user?.MemberId!,
    Amount: 0,
    Description: "",
    ReturnURL: "",
    PaymentForm: "",
    DataId: 0,
  });

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const handleClosePayment = () => setOpenPayment(false);
  const handleOpenPayment = () => setOpenPayment(true);
  const openSuccessModal = () => setPaymentSuccess(true);
  const closeSuccessModal = () => setPaymentSuccess(false);

  const requestLicenseMutation = useMutation({
    mutationKey: ["request-license"],
    mutationFn: requestLicense,
  });
  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });
  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(ref!),
    enabled: !!ref,
  });
  const cutOffScores = useQuery({
    queryKey: ["get-cut-off-scores"],
    queryFn: getCutOffScores,
  });
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-assessments"],
    queryFn: () => getAssessments(user?.MemberId!),
  });
  const assessments = data?.Data as Assessment[];
  const licenseCutOffScore = cutOffScores.data?.Data?.LicenseScore as number;

  useEffect(() => {
    if (
      !!ref &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    )
      openSuccessModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, queryTransaction.isSuccess]);

  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: paymentData?.RouteId || user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: paymentData?.Amount,
      Description: paymentData?.Description,
      ReturnURL: `${baseUrl}/competency-assessment`,
      PaymentForm: paymentData?.PaymentForm,
      DataId: paymentData?.DataId,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handleRequestLicense = async (memberId: string) => {
    try {
      await requestLicenseMutation.mutateAsync(memberId, {
        onSuccess: (data) => {
          setPaymentData({ ...data.Data });
          handleOpenPayment();
        },
      });
    } catch (error: any) {
      if (error?.response?.data?.Message?.includes("outstanding")) {
        openModal();
      } else {
        notification.error({
          message: "Error",
          description: errorMessage(error),
        });
      }
    }
  };

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={styles.spaceBetween}>
        <h3>Competency Assessment</h3>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.lightBtn}
            onClick={() => navigate("/competency-assessment/apply")}
            text="Apply for Assessment"
          />
          <Button
            onClick={() => handleRequestLicense(user?.MemberId!)}
            isLoading={requestLicenseMutation.isPending}
            disabled={requestLicenseMutation.isPending}
            text="Apply for HRPL"
          />
        </div>
      </section>

      <section className={styles.tableContainer}>
        <ProfileCard addAboutLink showLicenseScore />
        <br />
        <div className={styles.tableHeading}>
          <img src={points} alt="points" />
          <div>
            <div className={styles.amount}>{licenseCutOffScore}</div>
            <label>POINTS NEEDED</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={assessments}
          pagination={false}
          scroll={{ x: true }}
          rowKey={(record) => record.ExamId}
          className={classNames("table")}
          locale={{ emptyText: "No registered assessments yet" }}
        />
      </section>
      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={warning} alt="warning" />
          <h2>Outstanding Payment</h2>
          <p>
            You have an outstanding subscription payment. Kindly make this
            Payment before license renewal payment.
          </p>
          <div className={styles.buttonGroup}>
            <Button className={classNames("outlineBtn")} text="Cancel" />
            <Button onClick={() => navigate("/pay-dues")} text="Make Payment" />
          </div>
        </div>
      </Modal>
      <Modal
        open={openPayment}
        centered
        footer={null}
        onCancel={handleClosePayment}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Congratulations!!!</h2>
          <p>
            You are eligible to get your license renewal. Now, please proceed to
            pay the fee of <b>N{paymentData?.Amount}</b>
          </p>
          <div className={styles.buttonGroup}>
            <Button
              className={classNames("outlineBtn")}
              onClick={handleClosePayment}
              text="Cancel"
            />
            <Button
              onClick={handlePayment}
              isLoading={processPaymentMutation.isPending}
              disabled={processPaymentMutation.isPending}
              text="Make Payment"
            />
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={paymentSuccess}
        onCancel={closeSuccessModal}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={success} alt="success" />
          <h2>Congratulations!!!</h2>
          <p>
            Your exam fees have been paid successfully. This is a great step
            that will boost your overall score. Best of luck with your exams!
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
