import styles from "../styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import {
  getConferenceSessions,
  getEventById,
  getMaterials,
  getRegisteredEvents,
  getStateByCountryId,
} from "../../../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import classNames from "classnames";
import { ReactComponent as BackArrow } from "../../../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../../../assets/clock.svg";
import { ReactComponent as CyberPay } from "../../../../assets/cyberpay-logo.svg";
import { ReactComponent as Info } from "../../../../assets/info.svg";
import Points from "../../../../assets/points.png";
import Button from "../../../../custom/button/button";
import { getDateWithoutTime } from "../../../../utils/formatDate";
import Select from "../../../../custom/select/select";
import { useAtomValue } from "jotai";
import { userData } from "../../../../state";
import { useState } from "react";

const ScheduleConference = ({
  isEventScheduled,
  setIsEventScheduled,
  isScheduling,
}: {
  isEventScheduled: boolean;
  setIsEventScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduling: boolean;
}) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const navigate = useNavigate();
  const { values, setFieldValue, submitForm } =
    useFormikContext<FormikValues>();
  const learningEventId: number = parseInt(values.learningEventId);
  const [morningSession, setMorningSession] = useState<string[]>([]);
  const [afternoonSession, setAfternoonSession] = useState<string[]>([]);

  const conferenceInfo = useQuery({
    queryKey: ["get-conference-details"],
    queryFn: () => getEventById(learningEventId!),
    enabled: !!learningEventId,
  });
  const materials = useQuery({
    queryKey: ["get-conference-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const sessions = useQuery({
    queryKey: ["get-conference-sessions"],
    queryFn: () => getConferenceSessions(learningEventId!),
  });
  const states = useQuery({
    queryKey: ["get-states"],
    queryFn: () => getStateByCountryId(1),
  });
  const registeredEvents = useQuery({
    queryKey: ["get-user-event-info"],
    queryFn: () => getRegisteredEvents(user?.MemberId!, "Conference"),
  });

  const materialData = materials?.data?.Data as Material[];
  const conferenceInfoData = conferenceInfo?.data?.Data as EventById;
  const sessionData = sessions?.data?.Data as Session[];
  const stateData = states?.data as State[];
  const registeredEventsData = registeredEvents?.data
    ?.Data as RegisteredEvent[];

  const userRegister = registeredEventsData?.find(
    (event) => event.EventId === conferenceInfoData?.EventNameId
  ) as RegisteredEvent;
  const hasUserRegistered = !!userRegister;
  const hasUserPaid = hasUserRegistered && userRegister?.HasPaid;

  const stateOptions = stateData?.map((state) => (
    <option key={state.StateId} value={state.StateId}>
      {state.StateName}
    </option>
  ));

  const hasMorningSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "A"
  );
  const hasAfternoonSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "B"
  );

  const hasBothSessions = hasMorningSessions && hasAfternoonSessions;

  const handleSubmit = () => {
    if (hasBothSessions) {
      if (values?.session?.length !== 2) {
        notification.warning({
          message: "Please select both session groups",
        });
      } else {
        submitForm();
      }
    } else {
      submitForm();
    }
  };

  if (conferenceInfo.isLoading) return <Spin />;
  if (conferenceInfo.isError)
    return <div>{(conferenceInfo.error as AxiosError)?.message}</div>;

  return (
    <section className={styles.detailsContainer}>
      <div className={styles.detailsContent}>
        <h2>
          {conferenceInfoData?.EventName}
          <span>
            <img src={Points} alt="points" />
          </span>
        </h2>
        <br />
        <div className={styles.line}></div>
        <br />
        {hasUserPaid && (
          <>
            <h3>Venue</h3>
            <p>{conferenceInfoData?.Venue}</p>
            <br />
          </>
        )}
        <h3>Business Case</h3>
        <p>
          {conferenceInfoData?.BusinessCase ||
            conferenceInfoData?.DiscussionPoint}
        </p>
        <br />
        <h3>Who to Participate</h3>
        <ul>
          <li>{conferenceInfoData?.TargetMember}</li>
        </ul>
        <br />
        <h3>Core conference Objectives</h3>
        <p>{conferenceInfoData?.CoreObjective}</p>
        <br />
        <h3>Talking Key Points</h3>
        <p>{conferenceInfoData?.DiscussionPoint}</p>
        <br />
        {materialData && materialData?.length > 0 ? (
          <h3>Conference Schedule Material</h3>
        ) : null}
        <br />
        <div className={styles.list}>
          {materialData?.map((item) => (
            <div key={item.MaterialId} className={styles.files}>
              <div>
                <File />
                <label>{item.MaterialName}</label>
              </div>

              <Link
                to={item.MaterialUrl}
                target="_blank"
                className={classNames("linkAsBtn")}
              >
                Download
              </Link>
            </div>
          ))}
        </div>
        <br />
        <h4>Plenary Session Details</h4>
        <label>
          <Info /> Attendance is compulsory
        </label>
        {sessionData
          ?.filter((x) => x.IsTecnicalSession === false)
          .map((item) => {
            const startDate = getDateWithoutTime(item.StartDate);
            return (
              <section key={item.Id} className={styles.eventSchedule}>
                <div className={styles.smallGap}>
                  <Clock />
                  <div className={styles.sessionDetails}>
                    <small>{item.SessionType?.toUpperCase()}</small>
                    <h4>{item.SessionName}</h4>
                    <div className={styles.sessionDescription}>
                      Synopsis: {item.Synopsis || "N/A"}
                    </div>
                    <p>Speaker: {item.Speaker}</p>
                    <div>
                      {startDate} &#8226; {item.SessionStartTime} -{" "}
                      {item.SessionEndTime}
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
      </div>

      <section className={styles.list}>
        {isEventScheduled || (hasUserRegistered && !hasUserPaid) ? (
          <>
            {conferenceInfoData?.IsPaymentRequired ? (
              <section className={styles.resource}>
                <div>
                  <h3>Get Payment Breakdown</h3>
                  <p>Cost details of your preferred event listed here.</p>
                  <br />
                  <Button
                    className={styles.mainBtn}
                    onClick={() =>
                      navigate(
                        `/event-application/conferences/invoice?eventDetailId=${conferenceInfoData?.LearningEventId}&uniqueId=${userRegister?.UniqueId}`
                      )
                    }
                    text="Generate Invoice"
                    iconAfter={<BackArrow />}
                  />
                </div>
                <CyberPay />
              </section>
            ) : null}
          </>
        ) : (
          <div className={styles.scheduleContainer}>
            {hasMorningSessions && <h3>Select technical session: Group 1</h3>}
            {sessionData
              ?.filter(
                (x) =>
                  x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "A"
              )
              ?.map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                return (
                  <section key={item.Id} className={styles.eventSchedule}>
                    <div className={styles.smallGap}>
                      <Clock />
                      <div className={styles.sessionDetails}>
                        <small>{item.SessionType?.toUpperCase()}</small>
                        <h4>{item.SessionName}</h4>
                        <p className={styles.sessionDescription}>
                          Synopsis: {item.Synopsis || "N/A"}
                        </p>
                        <p>Speaker: {item.Speaker}</p>
                        <div>
                          {startDate} &#8226; {item.SessionStartTime} -{" "}
                          {item.SessionEndTime}
                        </div>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="session1"
                      onChange={() => {
                        setMorningSession((prev) => [...prev, `${item.Id}`]);
                        setFieldValue("session", [
                          `${item.Id}`,
                          ...afternoonSession,
                        ]);
                      }}
                    />
                  </section>
                );
              })}
            <br />
            {hasAfternoonSessions && <h3>Select technical session: Group 2</h3>}
            {sessionData
              ?.filter(
                (x) =>
                  x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "B"
              )
              ?.map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                return (
                  <section key={item.Id} className={styles.eventSchedule}>
                    <div className={styles.smallGap}>
                      <Clock />
                      <div className={styles.sessionDetails}>
                        <small>{item.SessionType?.toUpperCase()}</small>
                        <h4>{item.SessionName}</h4>
                        <p className={styles.sessionDescription}>
                          Synopsis: {item.Synopsis || "N/A"}
                        </p>
                        <p>Speaker: {item.Speaker}</p>
                        <div>
                          {startDate} &#8226; {item.SessionStartTime} -{" "}
                          {item.SessionEndTime}
                        </div>
                      </div>
                    </div>
                    <input
                      type="radio"
                      name="session2"
                      onChange={() => {
                        setAfternoonSession((prev) => [...prev, `${item.Id}`]);
                        setFieldValue("session", [
                          ...morningSession,
                          `${item.Id}`,
                        ]);
                      }}
                    />
                  </section>
                );
              })}
            <ErrorMessage name="session">
              {(msg) => <div className={styles.error}>{msg}</div>}
            </ErrorMessage>
            <br />
            <div className={styles.fields}>
              <Field
                name="state"
                as={Select}
                label="Location of head office"
                placeholder="Location of head office"
                options={stateOptions}
              />
            </div>
            <br />
            <Button
              isLoading={isScheduling}
              disabled={isScheduling}
              type="button"
              onClick={handleSubmit}
              text="Proceed to Payment"
            />
          </div>
        )}
      </section>
    </section>
  );
};

export default ScheduleConference;
