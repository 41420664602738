import { App, Modal } from "antd";
import styles from "./main.module.scss";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAllBranches,
  getChairmanByBranch,
  getChaptersByBranch,
  requestEndorsement,
} from "../../requests";
import { errorMessage } from "../../utils/errorMessage";
import Input from "../../custom/input/input";
import Select from "../../custom/select/select";
import Button from "../../custom/button/button";
import classNames from "classnames";

interface EndorsementModalProps {
  open: boolean;
  closeModal: () => void;
}

const EndorsementModal = ({ open, closeModal }: EndorsementModalProps) => {
  const queryClient = useQueryClient();
  const user = useAtomValue(userData);
  const { notification } = App.useApp();
  const [branch, setBranch] = useState("");

  const requestEndorsementMutation = useMutation({
    mutationKey: ["request-endorsement"],
    mutationFn: requestEndorsement,
  });
  const { data: branchesData } = useQuery({
    queryKey: ["get-branches"],
    queryFn: getAllBranches,
  });
  const { data: chaptersData } = useQuery({
    queryKey: ["get-chapters-by-branch", branch],
    queryFn: () => getChaptersByBranch(branch),
    enabled: !!branch,
  });
  const getBranchChairman = useQuery({
    queryKey: ["get-chairman-by-branch", branch],
    queryFn: () => getChairmanByBranch(branch),
    enabled: !!branch,
  });

  const chairman = getBranchChairman?.data?.Data as Chairman;
  const branchOptions = branchesData?.Data?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));
  const chapterOptions = chaptersData?.Data?.map((chapter) => (
    <option key={chapter.ChapterId} value={chapter.ChapterId}>
      {chapter.ChapterName}
    </option>
  ));

  const validationSchema = Yup.object().shape({
    branch: Yup.string().required("required"),
    chapter: Yup.number().required("required"),
    branchChairman: Yup.string().required("required"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      branch: branch || "",
      chapter: 0,
      branchChairman: chairman?.Name,
    },
    onSubmit: (values) => {
      requestEndorsementHandler(values);
    },
    validationSchema,
    enableReinitialize: true,
  });

  const requestEndorsementHandler = async (values: FormikValues) => {
    const payload: RequestEndorsementPayload = {
      MembershipNo: user?.MemberId!,
      BranchId: parseInt(values?.branch),
      ChapterId: parseInt(values?.chapter),
      EndorsementType: "Upgrade",
      MembershipTypeId: user?.MembershipTypeId!,
    };
    try {
      await requestEndorsementMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: data?.Message || "Request sent successfully",
          });
          closeModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const cancelEndorsement = () => {
    queryClient.cancelQueries({ queryKey: ["request-endorsement"] });
    formik.resetForm();
    closeModal();
  };

  return (
    <Modal
      centered
      open={open}
      title="Request Endorsement"
      onCancel={closeModal}
      footer={null}
      styles={{
        body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
      }}
    >
      <div className={styles.modalContent}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.fieldCol}>
              <Field
                as={Select}
                name="branch"
                label="Branch"
                placeholder="Select branch"
                options={branchOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  formik.setFieldValue("branch", e.target.value);
                  setBranch(e.target.value);
                }}
              />
              <Field
                as={Select}
                name="chapter"
                label="Chapter"
                placeholder="Select chapter"
                options={chapterOptions}
              />
              <Field
                as={Input}
                name="branchChairman"
                label="Branch Chairman"
                placeholder="Branch Chairman"
                disabled
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button
                type="button"
                onClick={cancelEndorsement}
                text="Cancel"
                className={classNames("cancelBtn")}
              />
              <Button
                isLoading={requestEndorsementMutation.isPending}
                disabled={requestEndorsementMutation.isPending}
                type="submit"
                text="Submit"
              />
            </div>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default EndorsementModal;
