import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Arrow } from "../../assets/arrow-back.svg";
import { ReactComponent as CyberPay } from "../../assets/cyberpay-logo.svg";
import { ReactComponent as Info } from "../../assets/info.svg";
import styles from "./main.module.scss";
import Points from "../../assets/points.png";
import Button from "../../custom/button/button";
import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import PayForConference from "./payForConference";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import {
  getAllBranches,
  getConferenceSessions,
  getEventById,
  getMaterials,
  getRegisteredEvents,
} from "../../requests";
import { getDateWithoutTime } from "../../utils/formatDate";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { conferencePayment, conferenceStep, userData } from "../../state";
import { Fragment, useEffect, useState } from "react";
import Select from "../../custom/select/select";
import { App } from "antd";

const ConferenceDetails = ({
  isEventScheduled,
  setIsEventScheduled,
  isScheduling,
}: {
  isEventScheduled: boolean;
  setIsEventScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduling: boolean;
}) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const [params] = useSearchParams();
  const setStep = useSetAtom(conferenceStep);
  const { values, resetForm, setFieldValue, submitForm } =
    useFormikContext<FormikValues>();
  const learningEventId: number = parseInt(values.learningEventId);
  const conferenceDetail = useAtomValue(conferencePayment);
  const navigate = useNavigate();
  const ref = params.get("ref");
  const [morningSession, setMorningSession] = useState<string[]>([]);
  const [afternoonSession, setAfternoonSession] = useState<string[]>([]);

  useEffect(() => {
    if (!!ref && conferenceDetail?.IsCorporateSponsor)
      setIsEventScheduled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const [conferenceDetails, materials, sessions, branches, registeredEvents] =
    useQueries({
      queries: [
        {
          queryKey: ["get-conference-details"],
          queryFn: () => getEventById(learningEventId!),
          enabled: !!learningEventId,
        },
        {
          queryKey: ["get-conference-materials"],
          queryFn: () => getMaterials(learningEventId!),
        },
        {
          queryKey: ["get-conference-sessions"],
          queryFn: () => getConferenceSessions(learningEventId!),
        },
        {
          queryKey: ["get-branches"],
          queryFn: getAllBranches,
        },
        {
          queryKey: ["get-user-event-info"],
          queryFn: () => getRegisteredEvents(user?.MemberId!, "Conference"),
        },
      ],
    });
  const conferenceDetailsData = conferenceDetails.data?.Data as EventById;
  const conferenceMaterialsData = materials.data?.Data as Material[];
  const sessionData = sessions?.data?.Data as Session[];
  const branchData = branches?.data?.Data;
  const registeredEventsData = registeredEvents?.data
    ?.Data as RegisteredEvent[];

  const userRegister = registeredEventsData?.find(
    (event) => event.EventId === conferenceDetailsData?.EventNameId
  ) as RegisteredEvent;
  const hasUserRegistered = !!userRegister;
  const hasUserPaid = hasUserRegistered && userRegister?.HasPaid;
  const branchOptions = branchData?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));

  const hasMorningSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "A"
  );
  const hasAfternoonSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "B"
  );

  const hasBothSessions = hasMorningSessions && hasAfternoonSessions;

  const handleSubmit = () => {
    if (hasBothSessions) {
      if (values?.session?.length !== 2) {
        notification.warning({
          message: "Please select both session groups",
        });
      } else {
        submitForm();
      }
    } else {
      submitForm();
    }
  };

  return (
    <div>
      <section className={classNames("headingWithBackArrow")}>
        <button
          onClick={() => {
            resetForm({ values: { conferenceName: "", conferenceType: "" } });
            setStep((prev) => prev - 1);
          }}
        >
          <BackArrow />
        </button>
        <h3>Conference Details</h3>
      </section>
      <section className={styles.detailsContainer}>
        <div className={styles.detailsContent}>
          <h2>
            {conferenceDetailsData?.EventName}
            <span>
              <img src={Points} alt="points" />
            </span>
          </h2>
          <br />
          <div className={styles.line}></div>
          <br />
          {hasUserPaid && (
            <>
              <h3>Venue</h3>
              <p>{conferenceDetailsData?.Venue || "N/A"}</p>
              <br />
            </>
          )}
          <br />
          <h3>Business Case</h3>
          <p>
            {conferenceDetailsData?.BusinessCase ||
              conferenceDetailsData?.DiscussionPoint}
          </p>
          <br />
          <h3>Who to Participate</h3>
          <ul>
            <li>{conferenceDetailsData?.TargetMember}</li>
          </ul>
          <br />
          <h3>Core Training Objectives</h3>
          <p>{conferenceDetailsData?.CoreObjective}</p>
          <br />
          <h3>Talking Key Points</h3>
          <p>{conferenceDetailsData?.DiscussionPoint}</p>
          <br />
          {conferenceMaterialsData?.length > 0 ? (
            <h3>Conference Schedule Material</h3>
          ) : null}
          <br />
          <div className={styles.list}>
            {conferenceMaterialsData &&
              conferenceMaterialsData?.map((item) => (
                <div className={styles.materialFiles} key={item.EventId}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
          </div>
          <br />
          <h4>Plenary Session Details</h4>
          <label>
            <Info /> Attendance is compulsory
          </label>
          {sessionData
            ?.filter((x) => x.IsTecnicalSession === false)
            .map((item) => {
              const startDate = getDateWithoutTime(item.StartDate);
              return (
                <section className={styles.eventSchedule} key={item.Id}>
                  <div className={styles.smallGap}>
                    <Clock />
                    <div className={styles.sessionDetails}>
                      <small>{item.SessionType?.toUpperCase()}</small>
                      <h4>{item.SessionName}</h4>
                      <div className={styles.sessionDescription}>
                        Synopsis: {item.Synopsis || "N/A"}
                      </div>
                      <p>Speaker: {item.Speaker}</p>
                      <div>
                        {startDate} &#8226; {item.SessionStartTime} -{" "}
                        {item.SessionEndTime}
                      </div>
                    </div>
                  </div>
                </section>
              );
            })}
        </div>
        <section className={styles.list}>
          {isEventScheduled || (hasUserRegistered && !hasUserPaid) ? (
            <>
              {values?.memberType === "corporate" &&
              conferenceDetailsData?.IsPaymentRequired ? (
                <section className={styles.resource}>
                  <div>
                    <h3>Get Payment Breakdown</h3>
                    <p>Cost details of your preferred event listed here.</p>
                    <br />
                    <Button
                      onClick={() =>
                        navigate(
                          `/event-application/conferences/invoice?id=${
                            userRegister?.Id || conferenceDetail?.DataId
                          }&eventDetailId=${
                            conferenceDetailsData?.LearningEventId
                          }&uniqueId=${userRegister?.UniqueId}`
                        )
                      }
                      text="Generate Invoice"
                      iconAfter={<Arrow />}
                    />
                  </div>
                  <CyberPay />
                </section>
              ) : conferenceDetailsData?.IsPaymentRequired ? (
                <PayForConference
                  hasUserRegistered={hasUserRegistered}
                  dataId={userRegister?.Id}
                  amount={userRegister?.PayingAmount}
                />
              ) : null}
            </>
          ) : (
            <div className={styles.scheduleContainer}>
              {hasMorningSessions && <h3>Select technical session: Group 1</h3>}
              {sessionData
                ?.filter(
                  (x) =>
                    x.IsTecnicalSession &&
                    x.TechnicalGroup?.toUpperCase() === "A"
                )
                ?.map((item) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  return (
                    <section key={item.Id} className={styles.eventSchedule}>
                      <div className={styles.smallGap}>
                        <Clock />
                        <div className={styles.sessionDetails}>
                          <small>{item.SessionType?.toUpperCase()}</small>
                          <h4>{item.SessionName}</h4>
                          <p className={styles.sessionDescription}>
                            Synopsis: {item.Synopsis || "N/A"}
                          </p>
                          <p>Speaker: {item.Speaker}</p>
                          <div>
                            {startDate} &#8226; {item.SessionStartTime} -{" "}
                            {item.SessionEndTime}
                          </div>
                        </div>
                      </div>
                      <input
                        type="radio"
                        name="session1"
                        onChange={() => {
                          setMorningSession((prev) => [...prev, `${item.Id}`]);
                          setFieldValue("session", [
                            `${item.Id}`,
                            ...afternoonSession,
                          ]);
                        }}
                      />
                    </section>
                  );
                })}
              <br />
              {hasAfternoonSessions && (
                <h3>Select technical session: Group 2</h3>
              )}
              {sessionData
                ?.filter(
                  (x) =>
                    x.IsTecnicalSession &&
                    x.TechnicalGroup?.toUpperCase() === "B"
                )
                ?.map((item) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  return (
                    <section key={item.Id} className={styles.eventSchedule}>
                      <div className={styles.smallGap}>
                        <Clock />
                        <div className={styles.sessionDetails}>
                          <small>{item.SessionType?.toUpperCase()}</small>
                          <h4>{item.SessionName}</h4>
                          <p className={styles.sessionDescription}>
                            Synopsis: {item.Synopsis || "N/A"}
                          </p>
                          <p>Speaker: {item.Speaker}</p>
                          <div>
                            {startDate} &#8226; {item.SessionStartTime} -{" "}
                            {item.SessionEndTime}
                          </div>
                        </div>
                      </div>
                      <input
                        type="radio"
                        name="session2"
                        onChange={() => {
                          setAfternoonSession((prev) => [
                            ...prev,
                            `${item.Id}`,
                          ]);
                          setFieldValue("session", [
                            ...morningSession,
                            `${item.Id}`,
                          ]);
                        }}
                      />
                    </section>
                  );
                })}
              <ErrorMessage name="session">
                {(msg) => <div className={styles.error}>{msg}</div>}
              </ErrorMessage>
              <br />
              <div className={styles.fields}>
                <Field
                  name="branch"
                  as={Select}
                  label="Location"
                  placeholder="Location"
                  options={branchOptions}
                />
              </div>
              <Button
                isLoading={isScheduling}
                disabled={isScheduling}
                type="button"
                onClick={handleSubmit}
                text="Proceed to Payment"
              />
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

export default ConferenceDetails;
