import success from "../../../assets/success.png";
import styles from "../styles.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  TransactionStatus,
  getEvents,
  queryPayment,
  removeBrackets,
} from "../../../requests";
import { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../../utils/formatDate";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import EmptyState from "../../../custom/emptyState/emptyState";
import { ReactComponent as Clock } from "../../../assets/clock.svg";
import { formatCurrency } from "../../../utils/formatCurrency";

enum EventType {
  Training = 1,
  Conference = 2,
  Other = 3,
}

const NonMemberConference = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [pageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const PAGE_SIZE = 20;
  const queryParams = `${pageNumber}${PAGE_SIZE}`;
  const reference = params.get("ref");

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const { data, isLoading, error, isError } = useQuery({
    queryKey: ["get-conference", queryParams],
    queryFn: () =>
      getEvents({
        PageNumber: pageNumber,
        PageSize: PAGE_SIZE,
      }),
  });
  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(reference!),
    enabled: !!reference,
  });

  useEffect(() => {
    if (
      !!reference &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    ) {
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, queryTransaction.isSuccess]);

  const conferenceData = data?.Data?.filter(
    (x) => x.EventTypeId === EventType.Conference
  );

  if (isLoading || queryTransaction.isLoading) {
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  }

  if (isError) {
    return <div>{(error as AxiosError)?.message}</div>;
  }

  return (
    <div>
      <section className={styles.list}>
        {conferenceData && conferenceData.length > 0 ? (
          conferenceData?.map((item) => {
            const startDate = getDateWithoutTime(item?.StartDate);
            const endDate = getDateWithoutTime(item?.EndDate);
            const isEqual = item?.StartDate === item?.EndDate;
            return (
              <div className={styles.event} key={item.LearningEventId}>
                <div className={styles.smallGap}>
                  <div className={styles.columnGap}>
                    <h3>{removeBrackets(item.EventName)}</h3>
                    <small>THEME: {item.EventCategoryName}</small>
                    <div className={styles.smallGap}>
                      <Clock />
                      <p className={styles.dates}>
                        {isEqual ? startDate : `${startDate} - ${endDate}`}{" "}
                        {item.VenueType ? `| ${item.VenueType}` : null}
                      </p>
                    </div>
                    <label className={styles.priceBadge}>
                      {formatCurrency(parseFloat(item.NonMemeberAmount))}
                    </label>
                  </div>
                </div>
                <div className={styles.columnGap}>
                  <Button
                    onClick={() =>
                      navigate(
                        `/non-member-events/conference/apply?learningEventId=${item.LearningEventId}`
                      )
                    }
                    className={classNames("nonMemberBtn")}
                    text="Individual Registration"
                  />
                  <Button
                    onClick={() =>
                      navigate(
                        `/non-member-events/conference/apply-as-org?learningEventId=${item.LearningEventId}`
                      )
                    }
                    className={classNames("nonMemberBtn")}
                    text="Corporate Registration"
                  />
                </div>
              </div>
            );
          })
        ) : (
          <EmptyState text="No conference events available" />
        )}
      </section>
      <br />
      <br />

      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Payment Successful!</h2>
          <p>
            You have successfully paid for your conference. Please check your
            email for instructions to access your badge.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default NonMemberConference;
