import styles from "./main.module.scss";
import success from "../../assets/success.png";
import avatar from "../../assets/user.png";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import { App, Modal } from "antd";
import { useState } from "react";
import Textarea from "../../custom/textarea/textarea";
import { useMutation, useQuery } from "@tanstack/react-query";
import { endorseMember, getMemberByMembershipNo } from "../../requests";
import { useAtomValue } from "jotai";
import { getUserGrade, userData, userProfile } from "../../state";
import * as Yup from "yup";
import { errorMessage } from "../../utils/errorMessage";

const Main = () => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const profile = useAtomValue(userProfile);
  const grade = getUserGrade(user?.MembershipTypeId!);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [endorsedMember, setEndorsedMember] = useState<string>("");

  const openSuccessModal = () => setOpenSuccess(true);
  const closeSuccessModal = () => setOpenSuccess(false);
  const openRejectModal = () => setOpenReject(true);
  const closeRejectModal = () => setOpenReject(false);

  const getMember = useQuery({
    queryKey: ["getMember", endorsedMember],
    queryFn: () => getMemberByMembershipNo(endorsedMember),
    enabled: !!endorsedMember,
  });

  const endorseMemberMutation = useMutation({
    mutationKey: ["endorseMember"],
    mutationFn: endorseMember,
  });
  const endorseMemberHandler = async (
    values: FormikValues,
    isEndorsed: boolean = true
  ) => {
    const payload: EndorseMemberPayload = {
      endorsementRequestModel: {
        MembershipNo: formik.values.membershipNo,
        BranchId: profile?.BranchId!,
        ChapterId: profile?.ChapterId!,
        EndorsementType: "Voting",
        EndorsementId: 24,
        ElectionId: 18,
      },
      ChairmanMembershipNo: profile?.MembershipNo!,
      Comment: values?.comment || "N/A",
      IsEndorsed: isEndorsed,
    };

    try {
      await endorseMemberMutation.mutateAsync(payload, {
        onSuccess: () => {
          closeRejectModal();
          openSuccessModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const validationSchema1 = Yup.object().shape({
    membershipNo: Yup.string().required("Membership Number is required"),
  });
  const validationSchema2 = Yup.object().shape({
    comment: Yup.string().required("Comment is required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      membershipNo: "",
    },
    onSubmit: (values) => {
      setEndorsedMember(values.membershipNo);
      endorseMemberHandler(values);
    },
    validationSchema: validationSchema1,
  });
  const formik2 = useFormik<FormikValues>({
    initialValues: {
      comment: "",
    },
    onSubmit: (values) => {
      setEndorsedMember(formik.values.membershipNo);
      endorseMemberHandler(values, false);
    },
    validationSchema: validationSchema2,
  });

  return (
    <div>
      <h1 className={styles.formTitle}>Endorse Member</h1>
      <section className={styles.endorsementForm}>
        <div className={styles.profile}>
          <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>
              <b>{grade} Grade Member</b> at <b>CIPM Nigeria</b>
            </label>
          </div>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.field}>
              <Field
                as={Input}
                name="membershipNo"
                label="Membership Number"
                placeholder="Eg 123456789"
              />
            </div>
            <div className={styles.ctaGroup}>
              <Button
                type="button"
                onClick={() => {
                  if (formik.values.membershipNo === "") {
                    notification.warning({
                      message: "Warning",
                      description:
                        "Please enter a membership number to proceed.",
                    });
                  } else openRejectModal();
                }}
                text="No, Reject"
                className={styles.btnReject}
              />
              <Button
                type="submit"
                text="Yes, Endorse"
                isLoading={endorseMemberMutation.isPending}
                disabled={endorseMemberMutation.isPending}
              />
            </div>
          </form>
        </FormikProvider>
      </section>
      <Modal
        centered
        open={openSuccess}
        onCancel={closeSuccessModal}
        footer={null}
      >
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Endorsement Sent!</h2>
          {formik2.values.comment ? (
            <p>
              Your reason for not endorsing{" "}
              <b>{getMember?.data?.Data?.Name || "this member"} </b>has been
              submitted.
            </p>
          ) : (
            <p>
              You have successfully endorsed{" "}
              <b>{getMember?.data?.Data?.Name || "this member"} </b>as a CIPM
              member.
            </p>
          )}
        </div>
      </Modal>
      <Modal
        title="State reason for rejection"
        centered
        open={openReject}
        onCancel={closeRejectModal}
        footer={null}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik2}>
            <form onSubmit={formik2.handleSubmit}>
              <Field
                name="comment"
                as={Textarea}
                label="Comment"
                placeholder="State reason here..."
              />
              <div className={styles.ctaGroup}>
                <Button
                  className={styles.btnCancel}
                  text="Cancel"
                  onClick={closeRejectModal}
                />
                <Button
                  text="Save"
                  type="submit"
                  isLoading={endorseMemberMutation.isPending}
                  disabled={endorseMemberMutation.isPending}
                />
              </div>
            </form>
          </FormikProvider>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
