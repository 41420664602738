import Button from "../../custom/button/button";
import success from "../../assets/success.png";
import warning from "../../assets/warning.png";
import styles from "./main.module.scss";
import { ReactComponent as Info } from "../../assets/info.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  MemberType,
  TransactionStatus,
  deleteRegisteredEvent,
  getEvents,
  getRegisteredEvents,
  queryPayment,
} from "../../requests";
import { useEffect, useState } from "react";
import { App, Modal, Spin } from "antd";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../utils/formatDate";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import RegisteredCorporateEvents from "../../screens/corporateMembers/eventApplication/registeredCorporateEvents";
import EmptyState from "../../custom/emptyState/emptyState";
import { formatCurrency } from "../../utils/formatCurrency";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { errorMessage } from "../../utils/errorMessage";
import classNames from "classnames";

enum EventType {
  Training = 1,
  Conference = 2,
  Other = 3,
}

const Main = () => {
  const { notification } = App.useApp();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [event, setEvent] = useState<RegisteredEvent>({} as RegisteredEvent);
  const [params] = useSearchParams();
  const user = useAtomValue(userData);
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;
  const navigate = useNavigate();
  const [pageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const PAGE_SIZE = 20;
  const queryParams = `${pageNumber}${PAGE_SIZE}`;
  const reference = params.get("ref");

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const deleteEventMutation = useMutation({
    mutationKey: ["delete-event"],
    mutationFn: deleteRegisteredEvent,
  });
  const { data, isLoading, error, isError } = useQuery({
    queryKey: ["get-training", queryParams],
    queryFn: () =>
      getEvents({
        PageNumber: pageNumber,
        PageSize: PAGE_SIZE,
      }),
  });
  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(reference!),
    enabled: !!reference,
  });

  useEffect(() => {
    if (
      !!reference &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    ) {
      registeredEvents.refetch();
      openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, queryTransaction.isSuccess]);

  const registeredEvents = useQuery({
    queryKey: ["get-registered-events", user?.MemberId!],
    queryFn: () => getRegisteredEvents(user?.MemberId!, "Training"),
    enabled: !isCorporate && !!user?.MemberId,
  });

  const trainingData = data?.Data?.filter(
    (x) => x.EventTypeId === EventType.Training
  );
  const registeredEventsData = registeredEvents?.data
    ?.Data as RegisteredEvent[];

  const handleDeleteEvent = async (id: number) => {
    try {
      await deleteEventMutation.mutateAsync(id, {
        onSuccess: (data) => {
          registeredEvents.refetch();
          setOpenConfirm(false);
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  if (isLoading) {
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  }

  if (isError) {
    return <div>{(error as AxiosError)?.message}</div>;
  }

  return (
    <div>
      <section className={styles.heading}>
        <h3>Event Recommendations</h3>
        {trainingData && trainingData?.length > 0 ? (
          <Button
            onClick={() => navigate("/event-application/training/apply")}
            text="Apply for Training"
          />
        ) : null}
      </section>

      {trainingData && trainingData.length > 0 ? (
        trainingData?.map((item) => {
          const startDate = getDateWithoutTime(item?.StartDate);
          const endDate = getDateWithoutTime(item?.EndDate);
          const isEqual = item?.StartDate === item?.EndDate;
          return (
            <section className={styles.events} key={item.LearningEventId}>
              <div className={styles.event}>
                <div className={styles.eventDetails}>
                  <div className={styles.eventName}>{item.EventName}</div>
                  <small>THEME: {item.EventCategoryName}</small>
                  <div className={styles.smallGap}>
                    <Clock />
                    <p className={styles.dates}>
                      {isEqual ? startDate : `${startDate} - ${endDate}`}{" "}
                      {item.VenueType ? `| ${item.VenueType}` : null}
                    </p>
                  </div>
                  <div className={styles.prices}>
                    <span className={styles.priceBadge}>
                      Financial:
                      {formatCurrency(parseFloat(item.FinAmount))}
                    </span>
                    <span className={styles.priceBadge}>
                      Non-Financial:{" "}
                      {formatCurrency(parseFloat(item.NonFinanceAmount))}
                    </span>
                    <span className={styles.priceBadge}>
                      Non-Member:{" "}
                      {formatCurrency(parseFloat(item.NonMemeberAmount))}
                    </span>
                    <span className={styles.priceBadge}>
                      Senior Citizen:{" "}
                      {formatCurrency(parseFloat(item.SeniorAmount))}
                    </span>
                    <span className={styles.priceBadge}>
                      Student: {formatCurrency(parseFloat(item.StudentAmount))}
                    </span>
                  </div>
                </div>
              </div>
            </section>
          );
        })
      ) : (
        <EmptyState text="No training events available" />
      )}
      <br />
      <br />

      {isCorporate ? (
        <RegisteredCorporateEvents eventType="Training" />
      ) : (
        <div>
          {registeredEventsData && registeredEventsData.length > 0 ? (
            <h3>Registered Trainings</h3>
          ) : null}
          {registeredEventsData &&
            registeredEventsData.length > 0 &&
            registeredEventsData?.map((item) => {
              const dateWithoutTime = getDateWithoutTime(item?.StartDate);
              const endDate = getDateWithoutTime(item?.EndDate);
              return (
                <section className={styles.events} key={item.Id}>
                  <div className={styles.event}>
                    <section className={styles.smallGap}>
                      <div className={styles.center}>
                        <label className={styles.month}>
                          {dateWithoutTime?.split(" ")?.slice(1)?.join(" ")}
                        </label>
                        <h4 className={styles.date}>
                          {dateWithoutTime?.split(" ")?.slice(0, 1)?.join(" ")}
                        </h4>
                      </div>
                      <span>
                        <b>-</b>
                      </span>
                      <div className={styles.center}>
                        <label className={styles.month}>
                          {endDate?.split(" ")?.slice(1)?.join(" ")}
                        </label>
                        <h4 className={styles.date}>
                          {endDate?.split(" ")?.slice(0, 1)?.join(" ")}
                        </h4>
                      </div>
                    </section>
                    <hr />
                    <section className={styles.spaceBetween}>
                      <div className={styles.eventDetails}>
                        <h4 className={styles.eventName}>{item.EventName}</h4>
                        {!item.HasPaid && (
                          <p>
                            <Info />
                            <b>
                              You are to pay {formatCurrency(item.PayingAmount)}
                            </b>
                          </p>
                        )}
                      </div>
                      <div className={styles.smallGap}>
                        <Button
                          className={styles.greyBtn}
                          onClick={() =>
                            navigate(
                              `/event-application/registered-trainings/${item.Id}/${item.EventId}`
                            )
                          }
                          text="View"
                        />
                        {item.RequirePayment && !item.HasPaid ? (
                          <Button
                            className={styles.deleteBtn}
                            onClick={() => {
                              setEvent(item);
                              setOpenConfirm(true);
                            }}
                            text="Delete"
                          />
                        ) : null}
                      </div>
                    </section>
                  </div>
                </section>
              );
            })}
        </div>
      )}
      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Payment Successful!</h2>
          <p>You have successfully paid for your training.</p>
        </div>
      </Modal>
      <Modal
        centered
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Confirm Deletion</h2>
          <p>
            Are you sure you want to delete this event:{" "}
            <b>{event?.EventName}</b>?
          </p>
          <div className="buttonGroup">
            <Button
              className={styles.greyBtn}
              onClick={() => setOpenConfirm(false)}
              text="Cancel"
            />
            <Button
              className={styles.deleteBtn}
              onClick={() => handleDeleteEvent(event?.Id)}
              text="Delete"
              isLoading={deleteEventMutation.isPending}
              disabled={deleteEventMutation.isPending}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
