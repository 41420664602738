import { NavLink } from "react-router-dom";
import smalllogo from "../../assets/logo-small.svg";
import { ReactComponent as Home } from "../../assets/home.svg";
import { ReactComponent as User } from "../../assets/user.svg";
import { ReactComponent as AddressBook } from "../../assets/address-book.svg";
import { ReactComponent as Diploma } from "../../assets/diploma.svg";
import { ReactComponent as Elearning } from "../../assets/e-learning.svg";
import { ReactComponent as BoxAlt } from "../../assets/box-alt.svg";
import { ReactComponent as Database } from "../../assets/database.svg";
import { ReactComponent as Time } from "../../assets/time-fast.svg";
import { ReactComponent as Notebook } from "../../assets/notebook.svg";
import { ReactComponent as Line } from "../../assets/line.svg";
import { ReactComponent as Voting } from "../../assets/voting.svg";
import styles from "./styles.module.scss";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { MemberType } from "../../requests";

const Sidebar = () => {
  const user = useAtomValue(userData);
  const E_VOTING_LINK = "https://evoting.cipmnigeria.org/";
  const isNotCorporate = user?.MembershipTypeId !== MemberType.Corporate;
  const isFellowOrFullMember =
    user?.MembershipTypeId === MemberType.Fellow ||
    user?.MembershipTypeId === MemberType.Full;

  return (
    <>
      <div className={styles.logo}>
        <img src={smalllogo} alt="logo" />
      </div>
      <nav className={styles.sidebarNav}>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/home"}
        >
          <Home />
          Home
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/profile"}
        >
          <User />
          Profile
        </NavLink>
        {user?.MembershipTypeId === MemberType.Corporate ? null : (
          <NavLink
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
            to={"/membership-upgrade"}
          >
            <AddressBook />
            Membership Upgrade
          </NavLink>
        )}
        {isFellowOrFullMember ? (
          <NavLink
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
            to={"/referral"}
          >
            <Line />
            Referrals
          </NavLink>
        ) : null}
        {user?.IsChairman ? (
          <>
            <hr className={styles.divider} />
            <label>
              <Time />
              Chairman Activities
            </label>
            <NavLink
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
              to={"/endorsement-requests"}
            >
              <Line />
              Endorsement Requests
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
              to={"/external-activities"}
            >
              <Line />
              External Activities
            </NavLink>
          </>
        ) : null}
        {user?.MembershipTypeId === MemberType.Corporate ? null : (
          <>
            <hr className={styles.divider} />
            <label>
              <Diploma />
              HRPL - License Renewal
            </label>
            <NavLink
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
              to={"/obtain-license"}
            >
              <Line />
              Obtain License
            </NavLink>
            <label>
              <Line />
              Apply for HRPL
            </label>
            <NavLink
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
              to={"/hr-work-activity"}
            >
              <Line className={styles.noDisplay} />
              HR Work Activity
            </NavLink>
            <NavLink
              className={({ isActive }) => (isActive ? styles.activeLink : "")}
              to={"/competency-assessment"}
            >
              <Line className={styles.noDisplay} />
              Competency Assessment
            </NavLink>
          </>
        )}
        <hr className={styles.divider} />
        <label>
          <Elearning />
          Event Application
        </label>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"event-application/training"}
        >
          <Line />
          Training
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/event-application/conferences"}
        >
          <Line />
          Conferences
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/event-application/other-events"}
        >
          <Line />
          Other Events
        </NavLink>
        <hr className={styles.divider} />
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/pay-dues"}
        >
          <BoxAlt />
          Pay Dues
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/payment-history"}
        >
          <Database />
          Payment History
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/query-transaction"}
        >
          <Database />
          Query Transaction
        </NavLink>
        <hr className={styles.divider} />
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/my-documents"}
        >
          <Notebook />
          My Documents
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? styles.activeLink : "")}
          to={"/activity-history"}
        >
          <Time />
          Activity History
        </NavLink>
        {isNotCorporate ? (
          <a href={E_VOTING_LINK} target="_blank" rel="noreferrer">
            <Voting />
            Voting
          </a>
        ) : null}
      </nav>
    </>
  );
};

export default Sidebar;
