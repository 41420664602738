import EmptyActivity from "../../../assets/emptyActivity.png";
import { Table, Spin } from "antd";
import type { ColumnsType } from "antd/es/table";
import styles from "./styles.module.scss";
import { useQuery } from "@tanstack/react-query";
import { getExternalActivities } from "../../../requests";
import { AxiosError } from "axios";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";
import Pagination from "../../../custom/pagination/pagination";
import { useState } from "react";
import { format, parseISO } from "date-fns";

const Activity = () => {
  const user = useAtomValue(userData);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 10;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-external-activities", user?.MemberId!],
    queryFn: () => getExternalActivities(user?.MemberId!),
  });

  const activityData = data?.Data as ExternalActivity[];

  const columns: ColumnsType<ExternalActivity> = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Id",
    },
    {
      title: "Position",
      dataIndex: "Position",
      key: "Id",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "Id",
      render: (_, record) => format(parseISO(record?.StartDate), "do MMM yyyy"),
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "Id",
      render: (_, record) => format(parseISO(record?.EndDate), "do MMM yyyy"),
    },
  ];

  if (isLoading) return <Spin />;
  if (isError) return <p className="error">{(error as AxiosError)?.message}</p>;

  return (
    <section className={styles.section}>
      <Table
        columns={columns}
        dataSource={activityData ?? []}
        scroll={{ x: true }}
        style={{ overflowY: "auto" }}
        pagination={false}
        locale={{
          emptyText: () => (
            <section className={styles.emptySection}>
              <img
                src={EmptyActivity}
                className={styles.icon}
                alt="empty table"
              />
              <p className={styles.para}>You have no added activity</p>
            </section>
          ),
        }}
        rootClassName={styles.table}
      />
      {/* <Pagination
          pageNumber={pageNumber || 0}
          itemsPerPage={PAGE_SIZE}
          totalSize={data?. || 0}
          setPageNumber={handlePagination}
        /> */}
    </section>
  );
};

export default Activity;
