import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import styles from "./main.module.scss";
import Select from "../../custom/select/select";
import Button from "../../custom/button/button";
import { Filters } from "./main";
import Input from "../../custom/input/input";

interface FilterProps {
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  closeDrawer: () => void;
  setActiveFilters: React.Dispatch<React.SetStateAction<number>>;
}

const PaymentHistoryFilters: React.FC<FilterProps> = ({
  setFilters,
  closeDrawer,
  setActiveFilters,
}) => {
  const reverseFormat = (date: string) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  };
  const formik = useFormik<FormikValues>({
    initialValues: {
      from: "",
      to: "",
      //purpose: "",
      channel: "",
      status: "",
    },
    onSubmit: (values) => {
      const numberOfActiveFilters = Object.values(values).filter(
        (val) => val !== ""
      )?.length;
      setFilters({
        from: reverseFormat(values?.from) ?? "",
        to: reverseFormat(values?.to) ?? "",
        //purpose: values?.purpose,
        channel: values?.channel,
        status: values?.status,
      });
      setActiveFilters(numberOfActiveFilters);
      closeDrawer();
    },
  });

  const channelOptions = (
    <>
      <option>CyberPay</option>
      <option>Deposit</option>
      <option>Other</option>
    </>
  );
  const statusOptions = (
    <>
      <option>Successful</option>
      <option>Failed</option>
      <option>Unknown</option>
    </>
  );
  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <h3>Filters</h3>
        <section className={styles.filters}>
          <Field name="from" as={Input} type="date" label="From" />
          <Field name="to" as={Input} type="date" label="To" />
          {/* <Field
            name="purpose"
            as={Select}
            label="Purpose"
            placeholder="License Renewal"
          /> */}
          <Field
            name="channel"
            as={Select}
            label="Channel"
            placeholder="Select"
            options={channelOptions}
          />
          <Field
            name="status"
            as={Select}
            label="Status"
            placeholder="Select"
            options={statusOptions}
          />
        </section>
        <section className={styles.buttons}>
          <Button type="submit" className={styles.apply} text="Apply Filters" />
          <Button
            className={styles.reset}
            text="Reset Filters"
            onClick={() => formik.resetForm()}
          />
        </section>
      </form>
    </FormikProvider>
  );
};

export default PaymentHistoryFilters;
