import classNames from "classnames";
import Button from "../../custom/button/button";
import styles from "./referral.module.scss";
import Table, { ColumnsType } from "antd/es/table";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { userProfile } from "../../state";
import { approveReferenceRequest, getreferenceRequest } from "../../requests";
import { App, Modal, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import * as Yup from "yup";
import { errorMessage } from "../../utils/errorMessage";

const Referral = () => {
  const { notification } = App.useApp();
  const profile = useAtomValue(userProfile);
  const [open, setOpen] = useState(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [request, setRequest] = useState<ReferenceRequest | null>(null);

  const columns: ColumnsType<ReferenceRequest> = [
    {
      title: "S/N",
      key: "index",
      render(_value, _record, index) {
        return index + 1;
      },
    },
    {
      title: "Applicant Id",
      dataIndex: "ApplicantId",
      key: "index",
    },
    {
      title: "Applicant Name",
      dataIndex: "ApplicantName",
      key: "index",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "index",
      render: (_, { Status }) => {
        switch (Status) {
          case "Approved":
            return <span className={styles.payment}>{Status}</span>;
          case "Pending":
            return <span className={styles.waiting}>{Status}</span>;
          default:
            return <span className={styles.declined}>{Status}</span>;
        }
      },
      // Status ? (
      //   <span className={styles.payment}>Approved</span>
      // ) : (
      //   <span className={styles.waiting}>Pending Approval</span>
      // ),
    },
    {
      title: "Comment",
      dataIndex: "Comment",
      key: "index",
      render: (value) => value || "N/A",
    },
    {
      title: "Action",
      key: "action",
      render: (_, item) => (
        <button
          className={styles.extLink}
          onClick={() => {
            setRequest(item);
            setOpen(true);
          }}
        >
          View
        </button>
      ),
    },
  ];

  const approveReferenceRequestMutation = useMutation({
    mutationKey: ["approveReferenceRequest"],
    mutationFn: approveReferenceRequest,
  });

  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["get-referral", profile?.MembershipNo],
    queryFn: () => getreferenceRequest(profile?.MembershipNo),
    enabled: !!profile?.MembershipNo,
  });

  const activityData = data?.Data as ReferenceRequest[];

  const approveReferenceRequestHandler = async (
    values: FormikValues,
    isApproved: boolean,
    resetForm: () => void
  ) => {
    const payload: ApproveReferenceRequestPayload = {
      Id: request?.Id!,
      ApplicantId: request?.ApplicantId!,
      RefereeMembershipNo: request?.RefereeMembershipNo!,
      ApplicantName: request?.ApplicantName!,
      Status: isApproved ? "Approved" : "Rejected",
      IsApproved: isApproved,
      Comment: values?.comment || "N/A",
    };

    try {
      await approveReferenceRequestMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          setOpen(false);
          refetch();
          resetForm();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string(),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      comment: "",
    },
    onSubmit: (values, { resetForm }) => {
      approveReferenceRequestHandler(values, isApproved, resetForm);
    },
    validationSchema,
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("spaceBetween")}>
        <h3>Applicant Details</h3>
      </section>

      <section>
        <Table
          columns={columns}
          dataSource={activityData ?? []}
          scroll={{ x: true }}
          pagination={false}
          rowKey={(record) => record.Id}
          locale={{ emptyText: "No referral yet" }}
          rootClassName={classNames("table")}
        />
      </section>
      <Modal
        title="Refer Applicant"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        styles={{
          body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
        }}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <div className={styles.fieldCol}>
                <Field as={Input} name="comment" placeholder="Comment" />
                <section className={styles.cta}>
                  <Button
                    type="button"
                    text="Reject"
                    onClick={() => {
                      setIsApproved(false);
                      formik.submitForm();
                    }}
                    className={styles.btnReject}
                    isLoading={approveReferenceRequestMutation.isPending}
                    disabled={approveReferenceRequestMutation.isPending}
                  />
                  <Button
                    type="button"
                    text="Approve"
                    onClick={() => {
                      setIsApproved(true);
                      formik.submitForm();
                    }}
                    isLoading={approveReferenceRequestMutation.isPending}
                    disabled={approveReferenceRequestMutation.isPending}
                  />
                </section>
              </div>
            </form>
          </FormikProvider>
        </div>
      </Modal>
    </div>
  );
};

export default Referral;
