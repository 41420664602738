import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import Button from "../../custom/button/button";

const SingleLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <main className={styles.main}>
      <nav className={styles.nav}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
          <p className={styles.logoText}>
            Chartered Institute of Personnel Management of Nigeria
          </p>
        </div>
      </nav>
      <section className={styles.sectionAreaWrapper}>
        <Outlet />
      </section>
      {location.pathname === "/message-received" ? (
        <Button
          onClick={() => navigate("/")}
          className={styles.btn}
          text="Cancel"
        />
      ) : (
        <button onClick={() => navigate("/need-help")} className={styles.btn}>
          Need Help from ICT?
        </button>
      )}
    </main>
  );
};

export default SingleLayout;
