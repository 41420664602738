import styles from "./styles.module.scss";
import warning from "../../../assets/warning.png";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";
import { ReactComponent as Info } from "../../../assets/info.svg";
import {
  EventType,
  MemberType,
  deleteRegisteredEvent,
  getRegisteredCorporateEvents,
} from "../../../requests";
import Button from "../../../custom/button/button";
import { getDateWithoutTime } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { App, Modal, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { errorMessage } from "../../../utils/errorMessage";
import classNames from "classnames";
import { formatCurrency } from "../../../utils/formatCurrency";

interface Props {
  eventType: EventType;
}

const RegisteredCorporateEvents = ({ eventType }: Props) => {
  const queryClient = new QueryClient();
  const { notification } = App.useApp();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [event, setEvent] = useState<RegisteredCorporateEvent>(
    {} as RegisteredCorporateEvent
  );
  const user = useAtomValue(userData);
  const navigate = useNavigate();
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;
  const currentEvent =
    eventType === "Training"
      ? "trainings"
      : eventType === "Conference"
      ? "conferences"
      : "others";

  const deleteEventMutation = useMutation({
    mutationKey: ["delete-event"],
    mutationFn: deleteRegisteredEvent,
  });
  const corporateEvents = useQuery({
    queryKey: ["get-registered-corporate-events", user?.MemberId!],
    queryFn: () => getRegisteredCorporateEvents(user?.MemberId!, eventType),
    enabled: isCorporate && !!user?.MemberId,
  });

  const handleDeleteEvent = async (id: number) => {
    try {
      await deleteEventMutation.mutateAsync(id, {
        onSuccess: (data) => {
          corporateEvents.refetch();
          queryClient.refetchQueries({ queryKey: ["get-registered-events"] });
          setOpenConfirm(false);
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  if (corporateEvents.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (corporateEvents.isError)
    return <div>{(corporateEvents.error as AxiosError)?.message}</div>;

  return (
    <div>
      {corporateEvents?.data && corporateEvents?.data?.Data?.length > 0 && (
        <h3>Registered {eventType === "Other" ? "Events" : `${eventType}s`}</h3>
      )}
      {corporateEvents?.data && corporateEvents?.data?.Data?.length > 0
        ? corporateEvents?.data?.Data?.map((item) => {
            const dateWithoutTime = getDateWithoutTime(item?.StartDate);
            const endDate = getDateWithoutTime(item?.EndDate);
            return (
              <section className={styles.events} key={item.Id}>
                <div className={styles.event}>
                  <section className={styles.smallGap}>
                    <div className={styles.center}>
                      <label className={styles.month}>
                        {dateWithoutTime?.split(" ")?.slice(1)?.join(" ")}
                      </label>
                      <h4 className={styles.date}>
                        {dateWithoutTime?.split(" ")?.slice(0, 1)?.join(" ")}
                      </h4>
                    </div>
                    <span>
                      <b>-</b>
                    </span>
                    <div className={styles.center}>
                      <label className={styles.month}>
                        {endDate?.split(" ")?.slice(1)?.join(" ")}
                      </label>
                      <h4 className={styles.date}>
                        {endDate?.split(" ")?.slice(0, 1)?.join(" ")}
                      </h4>
                    </div>
                  </section>
                  <hr />
                  <section className={styles.spaceBetween}>
                    <div className={styles.eventDetails}>
                      <h4 className={styles.eventName}>{item.EventName}</h4>
                      {!item.HasPaid && (
                        <p>
                          <Info />
                          <b>
                            You are to pay {formatCurrency(item.PayingAmount)}
                          </b>
                        </p>
                      )}
                    </div>
                    <div className={styles.smallGap}>
                      <Button
                        className={styles.greyBtn}
                        onClick={() =>
                          navigate(
                            `/event-application/registered-${currentEvent}/${item.Id}/${item.EventId}`
                          )
                        }
                        text="View"
                      />
                      {!!item.PayingAmount && !item.HasPaid ? (
                        <Button
                          className={styles.deleteBtn}
                          onClick={() => {
                            setEvent(item);
                            setOpenConfirm(true);
                          }}
                          text="Delete"
                        />
                      ) : null}
                    </div>
                  </section>
                </div>
              </section>
            );
          })
        : null}
      <Modal
        centered
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Confirm Deletion</h2>
          <p>
            Are you sure you want to delete this event:{" "}
            <b>{event?.EventName}</b>?
          </p>
          <div className="buttonGroup">
            <Button
              className={styles.greyBtn}
              onClick={() => setOpenConfirm(false)}
              text="Cancel"
            />
            <Button
              className={styles.deleteBtn}
              onClick={() => handleDeleteEvent(event?.Id)}
              text="Delete"
              isLoading={deleteEventMutation.isPending}
              disabled={deleteEventMutation.isPending}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RegisteredCorporateEvents;
