import { Outlet, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "../../assets/logo.svg";

const Layout = () => {
  const navigate = useNavigate();

  return (
    <main className={styles.main}>
      <nav className={styles.nav}>
        <div className={styles.logoWrapper}>
          <Logo className={styles.logo} />
          <p className={styles.logoText}>
            Chartered Institute of Personnel Management of Nigeria
          </p>
        </div>
      </nav>
      <section className={styles.sectionAreaWrapper}>
        <Outlet />
        <section className={styles.bg}></section>
      </section>
      <button onClick={() => navigate("/need-help")} className={styles.btn}>
        Need Help from ICT?
      </button>
    </main>
  );
};

export default Layout;
