import classNames from "classnames";
import styles from "./main.module.scss";
import line from "../../assets/badgeLine.png";
import avatar from "../../assets/user.png";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { ReactComponent as Line } from "../../assets/line.svg";
import { ReactComponent as Logo } from "../../assets/ice-logo.svg";
import Button from "../../custom/button/button";
import { useAtomValue } from "jotai";
import { getUserGrade, userData } from "../../state";
import { QRCode, Spin, Image } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  MemberType,
  getAllBadges,
  getRegisteredEventById,
} from "../../requests";
import { AxiosError } from "axios";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { format, isEqual, parseISO } from "date-fns";

const GateEntry = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const user = useAtomValue(userData);
  const grade = getUserGrade(user?.MembershipTypeId!);
  const id = params.get("id");
  const eventDetailId = params.get("eventDetailId");
  const eventId = params.get("eventId");
  const eventType = params.get("eventType");
  const eventName = params.get("eventName");
  const badgeRef = useRef(null);
  const isCorporateMember = user?.MembershipTypeId === MemberType.Corporate;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-badges"],
    queryFn: () =>
      getAllBadges({
        MembershipNo: user?.MemberId!,
        EvenType: eventType!,
        IsCorporate: isCorporateMember,
        EventDetailsId: parseInt(eventDetailId!),
        EventId: parseInt(eventId!),
      }),
    enabled: !!eventDetailId && !!eventId && !!eventType,
  });

  const event = useQuery({
    queryKey: ["get-event", id],
    queryFn: () => getRegisteredEventById(id!),
    enabled: !!id,
  });

  const downloadBadge = useReactToPrint({
    content: () => badgeRef.current,
    documentTitle: "Gate Entry Badge",
  });

  const badge = data?.Data?.filter(
    (x) => x.EventName === eventName || x.EventName?.includes(eventName!)
  ) as Badge[];

  const eventDetails = event?.data?.Data as RegisteredEvent;

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  const startDate = eventDetails?.StartDate
    ? parseISO(eventDetails.StartDate)
    : null;
  const endDate = eventDetails?.EndDate ? parseISO(eventDetails.EndDate) : null;

  const parsedStartDate = startDate ? format(startDate, "do MMMM yyyy") : "";
  const parsedEndDate = endDate ? format(endDate, "do MMMM yyyy") : "";
  const areDatesEqual = isEqual(
    new Date(eventDetails?.StartDate),
    new Date(eventDetails?.EndDate)
  );

  const [startDay, startMonth, startYear] = parsedStartDate?.split(" ");
  const [endDay, endMonth, endYear] = parsedEndDate?.split(" ");

  return (
    <div>
      <section className={styles.heading}>
        <div className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Gate Entry Badge</h3>
        </div>
        <Button onClick={downloadBadge} text="Download" />
      </section>
      <br />
      <br />
      <div className={styles.list}>
        {badge &&
          badge.length > 0 &&
          badge?.map((item) => (
            <div
              ref={badgeRef}
              key={item.GuidQrcode}
              className={styles.entryBadge}
            >
              <img src={line} alt="line" />
              <div className={styles.badgeContent}>
                <section className={styles.badgeHeader}>
                  <h1>{item?.EventName}</h1>
                  <Logo className={styles.logo} />
                </section>
                <p className={styles.details}>
                  <span>Name of Delegate: </span>
                  {item?.Name}
                </p>
                <p className={styles.details}>
                  <span>Grade of Membership: </span>
                  {grade}
                </p>
                <section className="spaceBetween">
                  <QRCode value={item?.GuidQrcode} />
                  <Image
                    width={200}
                    height={200}
                    src={item?.PictureUrl}
                    fallback={avatar}
                  />
                </section>
                <section className={styles.badgeFooter}>
                  <div className={styles.smallGap}>
                    {areDatesEqual ? (
                      <>
                        <h2>
                          {startDay} - {endDay}
                        </h2>
                        <h4>
                          {startMonth} {startYear}
                        </h4>
                      </>
                    ) : (
                      <>
                        <h2>
                          {startDay} {startMonth} {startYear}
                        </h2>
                        <span>
                          <Line />
                        </span>
                        <h2>
                          {endDay} {endMonth} {endYear}
                        </h2>
                      </>
                    )}
                  </div>
                  {item?.TechnicalSessions?.length > 0 && (
                    <div className={styles.divider}></div>
                  )}
                  <div>
                    {item?.TechnicalSessions?.map((x, index) => (
                      <h4 key={x}>
                        Technical session {index + 1}: {x}
                      </h4>
                    ))}
                  </div>
                </section>
                <p className={styles.contact}>
                  Phone lines: 07001237555 & 018880408 (Mon - Fri between 8am to
                  5pm)
                </p>
              </div>
              <img src={line} className={styles.secondLine} alt="line" />
            </div>
          ))}
      </div>
    </div>
  );
};

export default GateEntry;
