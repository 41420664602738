import {
  Field,
  FieldArray,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import styles from "./styles.module.scss";
import Input from "../../../custom/input/input";
import DownloadFile from "../../../custom/downloadFile/downloadFile";
import { useAtomValue } from "jotai";
import { isUpdateProfile, userData } from "../../../state";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createProfessionalBody, getProfessionalBody } from "../../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { format } from "date-fns";
import AddProfessionalBodyModal from "./addProfessionalBodyModal";
import { useState } from "react";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import { errorMessage } from "../../../utils/errorMessage";

const ProfessionalBody = () => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const [open, setOpen] = useState(false);
  const isEditProfile = useAtomValue(isUpdateProfile);
  const [currentField, setCurrentField] = useState<ProfessionalBody>(
    {} as ProfessionalBody
  );
  const [file, setFile] = useState<File | null>();

  const updateProfessionalBodyMutation = useMutation({
    mutationFn: createProfessionalBody,
  });
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["professional-body"],
    queryFn: () => getProfessionalBody(user?.MemberId!),
  });

  const professionalBodyData = data?.Data as ProfessionalBody[];

  const updateProfessionalBodyHandler = async (
    values: FormikValues,
    resetForm: () => void
  ) => {
    const curr = (values.bodies as ProfessionalBody[]).find(
      (item) => item.Id === currentField.Id
    ) as ProfessionalBody;

    const payload = new FormData();
    payload.append("Id", `${currentField?.Id}`);
    payload.append("MembershipNo", `${user?.MemberId}`);
    payload.append(
      "ApplicantId",
      currentField?.ApplicantId ? `${currentField?.ApplicantId}` : "N/A"
    );
    payload.append("BodyName", curr?.BodyName);
    payload.append("MembershipGrade", curr?.MembershipGrade);
    payload.append("ElectionDate", curr?.ElectionDate);

    if (file) {
      payload.append("DataFile", file);
    } else {
      payload.append("FileUrl", currentField?.FileUrl);
    }

    try {
      await updateProfessionalBodyMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          refetch();
          resetForm();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const updatedData = professionalBodyData?.map((x) => ({
    ...x,
    ElectionDate: x.ElectionDate
      ? format(new Date(x.ElectionDate ?? null), "yyyy-MM-dd")
      : "",
  }));

  const formik = useFormik<FormikValues>({
    initialValues: {
      // professionalBody: "",
      // membershipGrade: "",
      // electionDate: "",
      bodies: updatedData,
    },
    onSubmit: (values, { resetForm }) =>
      updateProfessionalBodyHandler(values, resetForm),
    enableReinitialize: true,
  });

  if (isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  const professionalDocument = professionalBodyData?.map((item, index) => (
    <section className={styles.details} key={item.Id}>
      <h2>PROFESSIONAL BODY - {index + 1}</h2>
      <hr className={styles.hr} />
      <section className={styles.field}>
        <Field
          as={Input}
          type="text"
          name={`bodies[${index}].BodyName`}
          label="Professional Body"
          placeholder={item?.BodyName}
          className={styles.input}
          disabled={!isEditProfile}
        />
      </section>
      <section className={styles.field}>
        <Field
          as={Input}
          type="text"
          name={`bodies[${index}].MembershipGrade`}
          label="Membership Grade (Optional)"
          placeholder={item?.MembershipGrade}
          className={styles.input}
          disabled={!isEditProfile}
        />
      </section>
      <section className={styles.field}>
        <Field
          as={Input}
          type="date"
          name={`bodies[${index}].ElectionDate`}
          label="Election Date (Optional)"
          placeholder={
            item?.ElectionDate
              ? format(new Date(item?.ElectionDate ?? null), "yyyy-MM-dd")
              : null
          }
          className={styles.input}
          disabled={!isEditProfile}
        />
      </section>
      <section className={styles.field}>
        <DownloadFile
          className={styles.input}
          title="Certificate"
          documentName={file?.name || item?.BodyName}
          //documentSize={10023}
          documentUrl={item?.FileUrl}
          otherAction={
            isEditProfile ? (
              <label className={styles.changeFile}>
                Change
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    const file = e.target.files;
                    if (file) {
                      setFile(file[0]);
                    }
                  }}
                />
              </label>
            ) : null
          }
        />
      </section>
      {isEditProfile && (
        <section className={styles.btnWrapper}>
          {/* <Button text='Cancel' className={styles.cancelBtn} /> */}
          <Button
            text="Save Changes"
            type="button"
            onClick={() => {
              setCurrentField(item);
              formik.handleSubmit();
            }}
            isLoading={updateProfessionalBodyMutation.isPending}
            disabled={updateProfessionalBodyMutation.isPending}
          />
        </section>
      )}
    </section>
  ));

  return (
    <div>
      <FormikProvider value={formik}>
        <Button
          className={classNames(styles.lightBtn, styles.end)}
          onClick={() => setOpen(true)}
          text="Add Professional Body"
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <FieldArray name="bodies">
            {() => <>{professionalDocument}</>}
          </FieldArray>
        </form>
      </FormikProvider>
      <AddProfessionalBodyModal
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  );
};

export default ProfessionalBody;
