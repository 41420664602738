import Button from "../../custom/button/button";
import success from "../../assets/success.png";
import styles from "./main.module.scss";
import { ReactComponent as Arrow } from "../../assets/arrow-back.svg";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import { App, Modal, Spin } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from "../../custom/select/select";
import classNames from "classnames";
import { useEffect, useState } from "react";
import ProfileCard from "../profileCard/profileCard";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  TransactionStatus,
  applyForExam,
  baseUrl,
  getExams,
  processPayment,
  queryPayment,
} from "../../requests";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { AxiosError } from "axios";
import Input from "../../custom/input/input";
import { getDateWithoutTime } from "../../utils/formatDate";
import { errorMessage } from "../../utils/errorMessage";
import * as Yup from "yup";
import EmptyState from "../../custom/emptyState/emptyState";

interface PaymentPayload {
  RouteId: number;
  Amount: number;
  Description: string;
  PaymentForm: string;
  DataId: number | string;
}

const Main = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const user = useAtomValue(userData);
  const { notification } = App.useApp();
  const ref = params.get("ref");
  const [examDetails, setExamDetails] = useState({
    examId: 0,
    examScheduleId: 0,
    date: "",
    time: "",
    amount: 0,
    score: 0,
  });
  const [open, setOpen] = useState(false);
  const fromTomorrow = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const minDate = tomorrow.toISOString().slice(0, 10);
    return minDate;
  };

  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(ref!),
    enabled: !!ref,
  });

  useEffect(() => {
    if (
      !!ref &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    )
      openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, queryTransaction.isSuccess]);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const applyForExamMutation = useMutation({
    mutationKey: ["apply-for-exam"],
    mutationFn: applyForExam,
  });
  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });
  const allExams = useQuery({
    queryKey: ["get-all-exams"],
    queryFn: () => getExams(user?.MemberId!),
  });
  const allExamData = allExams.data?.Data as Exam[];

  const examOptions = allExamData?.map((item) => (
    <option key={item.ExamId} value={`${item.ExamId}`}>
      {item.ExamName}
    </option>
  ));

  const applyForExamHandler = async (values: FormikValues) => {
    const payload: ApplyForExamPayload = {
      ExamId: examDetails?.examId,
      MembershipNo: user?.MemberId!,
      ExamScheduleId: examDetails?.examScheduleId,
      ExamDate: values?.date,
      ExamTime: values?.time,
    };
    try {
      await applyForExamMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const paymentDetails = {
            RouteId: data?.Data?.RouteId || user?.MembershipTypeId!,
            Amount: examDetails?.amount,
            Description: data?.Data?.Description,
            PaymentForm: data?.Data?.PaymentForm,
            DataId: `${data?.Data?.DataId!}`,
          };
          handlePayment(paymentDetails);
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
      if (error?.response?.data?.Data) {
        const res = error?.response?.data?.Data;
        const paymentDetails = {
          RouteId: res?.RouteId,
          Amount: res?.Amount,
          Description: res?.Description,
          PaymentForm: res?.PaymentForm,
          DataId: res?.DataId,
        };
        handlePayment(paymentDetails);
      }
    }
  };

  const handlePayment = async (paymentDetails: PaymentPayload) => {
    const payload: ProcessPaymentPayload = {
      RouteId: paymentDetails?.RouteId,
      MembershipNo: user?.MemberId!,
      Amount: paymentDetails?.Amount,
      Description: paymentDetails?.Description,
      ReturnURL: `${baseUrl}/competency-assessment/apply`,
      PaymentForm: paymentDetails?.PaymentForm,
      DataId: paymentDetails?.DataId,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    exam: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    time: Yup.string().required("Required"),
    amount: Yup.number().required("Required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      exam: examDetails?.examId ? `${examDetails?.examId}` : "",
      date: "",
      time: "",
      amount: examDetails?.amount,
    },
    onSubmit: (values) => {
      applyForExamHandler(values);
    },
    enableReinitialize: true,
    validationSchema,
  });

  if (allExams.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (allExams.isError)
    return <div>{(allExams.error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("headingWithBackArrow")}>
        <button onClick={() => navigate("/competency-assessment")}>
          <Arrow />
        </button>
        <h3>Apply for Assessment</h3>
      </section>
      {allExamData && allExamData?.length > 0 ? (
        <section className={styles.tableContainer}>
          <ProfileCard addAboutLink showLicenseScore />
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <section className={styles.fields}>
                <div>
                  <Field
                    name="exam"
                    as={Select}
                    label="Exam Name"
                    placeholder="Select"
                    options={examOptions}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      formik.setFieldValue("exam", e.target.value);
                      const selectedExam = allExamData?.find(
                        (item) => item.ExamId === parseInt(e.target.value)
                      ) as Exam;
                      if (selectedExam) {
                        setExamDetails({
                          examId: selectedExam.ExamId,
                          examScheduleId: selectedExam.ExamScheduleId,
                          date:
                            selectedExam.Date === null
                              ? "N/A"
                              : getDateWithoutTime(selectedExam.Date),
                          time:
                            selectedExam.Time === null
                              ? "N/A"
                              : selectedExam.Time,
                          amount: selectedExam.Amount,
                          score: selectedExam.Score,
                        });
                      }
                    }}
                  />
                  {examDetails?.score ? (
                    <div>Score attainable: {examDetails?.score || "N/A"}</div>
                  ) : null}
                </div>
                <Field
                  name="date"
                  type="date"
                  as={Input}
                  label="Date"
                  min={fromTomorrow()}
                />
                <Field name="time" type="time" as={Input} label="Time" />
                <Field
                  name="amount"
                  as={Input}
                  label="Amount"
                  placeholder="N200,000"
                  disabled
                />
              </section>
              <br />
              <br />
              <section className={classNames("spaceBetween")}>
                <Link to="https://www.cipmnigeria.org/">
                  Learn more about HRPL process
                </Link>
                <div className={styles.buttonGroup}>
                  <Button className={classNames("cancelBtn")} text="Cancel" />
                  <Button
                    type="submit"
                    isLoading={applyForExamMutation.isPending}
                    disabled={applyForExamMutation.isPending}
                    text={
                      `Pay ` +
                      (examDetails?.amount ? `N${examDetails?.amount}` : "")
                    }
                  />
                </div>
              </section>
            </form>
          </FormikProvider>
        </section>
      ) : (
        <EmptyState text="No exams available yet" />
      )}

      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Congratulations!!!</h2>
          <p>
            Your exam fees have been paid successfully. This is a great step
            that will boost your overall score. Best of luck with your exams!
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
