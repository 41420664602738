import classNames from "classnames";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { baseUrl, forgotPasswordNonMember } from "../../requests";
import { App } from "antd";
import { errorMessage } from "../../utils/errorMessage";

const NonMemberForgotPassword = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const forgotPasswordMutation = useMutation({
    mutationKey: ["non-member-forgot-password"],
    mutationFn: forgotPasswordNonMember,
  });
  const forgotPasswordHandler = async (values: FormikValues) => {
    const payload = {
      Email: values.organizationEmail,
      Url: `${baseUrl}/non-member-events`,
    };

    try {
      await forgotPasswordMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      organizationEmail: "",
    },
    onSubmit: (values) => {
      forgotPasswordHandler(values);
    },
  });

  return (
    <div className={styles.formContainer}>
      <h3 className={styles.greenTitle}>Forgot Password</h3>
      <br />
      <section>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Field
              name="organizationEmail"
              as={Input}
              label="Organization Email"
              placeholder="Organization Email"
            />
            <br />
            <div className={styles.buttonGroup}>
              <Button
                text="Cancel"
                type="button"
                onClick={() => navigate("/non-member-events")}
                className={classNames("cancelBtn")}
              />
              <Button
                text="Submit"
                type="submit"
                isLoading={forgotPasswordMutation.isPending}
                disabled={forgotPasswordMutation.isPending}
              />
            </div>
          </form>
        </FormikProvider>
      </section>
    </div>
  );
};

export default NonMemberForgotPassword;
