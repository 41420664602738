import { todaysDate } from "../../utils/todaysDate";
import styles from "./styles.module.scss";
import avatar from "../../assets/user.png";
import { ReactComponent as Bell } from "../../assets/bell.svg";
import { ReactComponent as Menu } from "../../assets/menu.svg";
import { ReactComponent as ArrowDown } from "../../assets/arrow.svg";
import { ReactComponent as Logout } from "../../assets/sign-out.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomDropdown from "../../custom/dropdown/dropdown";
import { logout } from "../../utils/logout";
import Button from "../../custom/button/button";
import { useQuery } from "@tanstack/react-query";
import { getNotifications, getProfile } from "../../requests";
import { useAtom, useAtomValue } from "jotai";
import { userData, userProfile } from "../../state";
import { timeAgo } from "../../utils/timeAgo";
import classNames from "classnames";
import { Empty, Spin } from "antd";
import { AxiosError } from "axios";
import { useEffect } from "react";

const Header = ({ handleOpenSidebar }: { handleOpenSidebar: () => void }) => {
  const navigate = useNavigate();
  const user = useAtomValue(userData);
  const [profile, setProfile] = useAtom(userProfile);
  const location = useLocation();
  const today = todaysDate();
  const routeName = () => {
    if (
      location.pathname.includes("license") ||
      location.pathname.includes("hr") ||
      location.pathname.includes("competency")
    )
      return "Apply for HRPL";
    return location.pathname
      .split("/")[1]
      ?.split("-")
      .map((x) => {
        if (x === "hr") {
          return x.toUpperCase();
        }
        return x.charAt(0).toUpperCase() + x.slice(1);
      })
      .join(" ");
  };

  const {
    data: notificationData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["get-notifications"],
    queryFn: () => getNotifications(user?.MemberId!),
  });

  const profileData = useQuery({
    queryKey: ["get-profile"],
    queryFn: () => getProfile(user?.MemberId!),
  });

  useEffect(() => {
    if (profileData.isSuccess) {
      setProfile(profileData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData?.data, profileData.isSuccess]);

  const sortedNotifications = notificationData?.Data?.sort((a, b) => {
    const date1 = new Date(a.CreatedDate);
    const date2 = new Date(b.CreatedDate);

    return date2.getTime() - date1.getTime();
  }) as ActivityHistory[];
  const notificationsAlert =
    sortedNotifications?.length >= 9 ? "9+" : `${sortedNotifications?.length}`;

  return (
    <header className={styles.header}>
      <section className={styles.headerMenuGroup}>
        <button onClick={handleOpenSidebar} className={styles.menuBtn}>
          <Menu />
        </button>
        <h1 className={styles.title}>{routeName()}</h1>
      </section>
      <section className={styles.headerGroup}>
        <label>{today}</label>
        <CustomDropdown
          dropdownButton={
            <button className={styles.notifications}>
              <Bell />
              {parseInt(notificationsAlert) > 0 ? (
                <span className={styles.notifyCount}>{notificationsAlert}</span>
              ) : null}
            </button>
          }
          dropdownContent={
            <div>
              {isLoading ? (
                <Spin />
              ) : isError ? (
                <div>{(error as AxiosError)?.message}</div>
              ) : (
                <>
                  <h2>Notifications</h2>
                  <hr className={styles.divider} />
                  {sortedNotifications?.slice(0, 3)?.map((item) => (
                    <section className={styles.alerts} key={item.ActivityId}>
                      <button className={styles.notifications}>
                        <Bell className={styles.smallBell} />
                        {item.IsActive ? (
                          <span className={styles.notifyCount}></span>
                        ) : (
                          ""
                        )}
                      </button>
                      <div className={styles.alertContent}>
                        <b>{item.ActivityDescription}</b>
                        <label className={styles.alertDetails}>
                          <span>{timeAgo(item.CreatedDate)}</span>
                          {/* <div className={styles.bulletSeparator}></div>
                        <span>{item.}</span> */}
                        </label>
                      </div>
                    </section>
                  ))}
                  {sortedNotifications?.length > 0 ? (
                    <div className={styles.alertActions}>
                      <NavLink to="/notifications">
                        View all notifications
                      </NavLink>
                      {/* <label>Mark all as read</label> */}
                    </div>
                  ) : (
                    <Empty
                      description="No notifications"
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  )}
                </>
              )}
            </div>
          }
          placement="bottomLeft"
        />
        <CustomDropdown
          dropdownButton={
            <button className={styles.account}>
              <img src={profile?.UploadPictureUrl || avatar} alt="woman" />
              <ArrowDown />
            </button>
          }
          dropdownContent={
            <div>
              <section className={styles.profile}>
                <img src={profile?.UploadPictureUrl || avatar} alt="woman" />
                <div>
                  <h4>{profile?.Name}</h4>
                  <button
                    onClick={() => navigate("/profile")}
                    className={classNames("transparentBtn")}
                  >
                    View profile
                  </button>
                </div>
              </section>
              <hr className={styles.divider} />
              <Button
                className={styles.transparentButton}
                text="Logout"
                iconBefore={<Logout />}
                onClick={logout}
              />
            </div>
          }
        />
      </section>
    </header>
  );
};

export default Header;
