import styles from "./styles.module.scss";
import Logo from "../../assets/emailSent.svg";
import { ReactComponent as CloseButton } from "../../assets/closeButton.svg";
import { useQuery } from "@tanstack/react-query";
import { verifyEmail } from "../../requests";
import { Spin } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../custom/button/button";
import { logout } from "../../utils/logout";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const token = params.get("token");
  const email = params.get("email");

  const { isLoading, isError, error } = useQuery({
    queryKey: ["verify-alt-email"],
    queryFn: () => verifyEmail(token!, email!),
    enabled: !!token && !!email,
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError)
    return (
      <div className={styles.para}>
        <p className="error">{(error as any)?.response?.data?.Message}</p>
        <br />
        <Button onClick={logout} text="Back to login" />
      </div>
    );

  return (
    <section className={styles.section}>
      <CloseButton
        onClick={() => navigate("/home")}
        className={styles.closeBtn}
      />
      <img className={styles.icon} src={Logo} alt="email" loading="lazy" />
      <h2 className={styles.heading}>Email Verified</h2>
      <p className={styles.para}>
        Your new email has now been verified.
        <Button onClick={logout} text="Back to login" />
      </p>
    </section>
  );
};

export default VerifyEmail;
