import { ReactComponent as BackArrow } from "../../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../../assets/clock.svg";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import styles from "../styles.module.scss";
import Points from "../../../assets/points.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAllBranches,
  getEventById,
  getEventScheduleByEventId,
  getMaterials,
  registerNonMemberEvent,
} from "../../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import PayForTraining from "./payment";
import { getDateWithoutTime } from "../../../utils/formatDate";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import { useAtom } from "jotai";
import { trainingPayment } from "../../../state";
import Input from "../../../custom/input/input";
import Upload from "../../../custom/upload/upload";
import * as Yup from "yup";
import Select from "../../../custom/select/select";

const ApplyForNonMemberTraining = () => {
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const [trainingPaymentDetails, setTrainingPaymentDetails] =
    useAtom(trainingPayment);
  const navigate = useNavigate();
  const learningEventId = params.get("learningEventId");

  const registerEventMutation = useMutation({
    mutationKey: ["register-event"],
    mutationFn: registerNonMemberEvent,
  });
  const trainingInfo = useQuery({
    queryKey: ["get-training-details"],
    queryFn: () => getEventById(parseInt(learningEventId!)),
    enabled: !!learningEventId,
  });
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-event-schedule"],
    queryFn: () => getEventScheduleByEventId(parseInt(learningEventId!)),
  });
  const materials = useQuery({
    queryKey: ["get-training-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const branches = useQuery({
    queryKey: ["get-branches"],
    queryFn: getAllBranches,
  });

  const validationSchema = Yup.object().shape({
    schedule: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    branch: Yup.number().min(1).required("Required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      schedule: "",
      name: "",
      phoneNumber: "",
      email: "",
      branch: 0,
    },
    onSubmit: (values) => {
      if (!profilePic) {
        notification.error({
          message: "Please upload a profile picture",
        });
        return;
      } else registerEventHandler(values);
    },
    validationSchema: validationSchema,
  });

  const materialData = materials?.data?.Data as Material[];
  const trainingInfoData = trainingInfo?.data?.Data as EventById;
  const trainingSchedule = data?.Data as EventSchedule[];
  const branchData = branches?.data?.Data;

  const branchOptions = branchData?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));

  if (isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  const registerEventHandler = async (values: FormikValues) => {
    const payload = new FormData();
    payload.append("ParticipantName", values.name);
    payload.append("ParticipantPhoneNo", values.phoneNumber);
    payload.append("ParticipantEmail", values.email);
    payload.append("IsAMember", "false");
    payload.append("RequirePayment", `${trainingInfoData?.IsPaymentRequired}`);
    payload.append("HasPaid", "false");
    payload.append("HasAttended", "false");
    payload.append("EventId", trainingInfoData?.EventNameId.toString());
    payload.append("TrainingscheduleId", values.schedule);
    payload.append("IsCorporateSponsor", "false");
    payload.append("EvenType", "Training");
    payload.append(
      "EventCategoryId",
      trainingInfoData?.EventCategoryId.toString()
    );
    payload.append(
      "EventDetailId",
      trainingInfoData?.LearningEventId.toString()
    );
    payload.append("BranchId", `${parseInt(values.branch)}`);
    if (profilePic) {
      payload.append("Picture", profilePic);
    }

    try {
      await registerEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const dataAsObj = data?.Data as ProcessPaymentPayload;
          setTrainingPaymentDetails({
            RouteId: dataAsObj?.RouteId,
            MembershipNo: dataAsObj?.MembershipNo,
            Amount: dataAsObj?.Amount,
            Description: dataAsObj?.Description,
            ReturnURL: dataAsObj?.ReturnURL,
            PaymentForm: dataAsObj?.PaymentForm,
            DataId: dataAsObj?.DataId,
            IsCorporateSponsor: formik.values.memberType === "corporate",
          });
          setIsEventScheduled(true);
          if (dataAsObj?.Amount === 0) {
            navigate("/non-member-events/training");
            notification.success({
              message: "Success",
              description: "Event registered successfully.",
            });
          }
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.message ||
          error?.message,
      });
      if (
        error?.response?.data?.Message?.includes(
          "The member has registered for the event"
        )
      ) {
        const dataAsObj = error?.response?.data?.Data as ProcessPaymentPayload;
        const dataAsNumber = error?.response?.data?.Data as number;
        setTrainingPaymentDetails({
          RouteId: dataAsObj?.RouteId,
          MembershipNo: dataAsObj?.MembershipNo,
          Amount: dataAsObj?.Amount,
          Description: dataAsObj?.Description,
          ReturnURL: dataAsObj?.ReturnURL,
          PaymentForm: dataAsObj?.PaymentForm,
          DataId:
            formik.values.memberType === "corporate"
              ? dataAsNumber
              : dataAsObj?.DataId,
          IsCorporateSponsor: formik.values.memberType === "corporate",
        });
        //setIsEventScheduled(true);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setProfilePic(files[0]);
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <section className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Training Details</h3>
        </section>
        <section className={styles.formContainer}>
          <Upload
            label="Upload Profile Picture"
            accept="img"
            allowedFormats={["img"]}
            onChange={handleFileChange}
            fileName={profilePic?.name}
            fileSize={profilePic?.size}
          />
          <Field as={Input} name="name" label="Name" placeholder="Name" />
          <div className={styles.fields}>
            <Field
              as={Input}
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone number"
            />
            <Field
              as={Input}
              name="email"
              label="Email Address"
              placeholder="Email address"
            />
          </div>
        </section>
        <section className={styles.detailsContainer}>
          <div className={styles.detailsContent}>
            <h2>
              {trainingInfoData?.EventName}
              <span>
                <img src={Points} alt="points" />
              </span>
            </h2>
            <br />
            <div className={styles.line}></div>
            <br />
            {/* <h3>Venue</h3>
            <p>{trainingInfoData?.Venue}</p>
            <br /> */}
            <h3>Business Case</h3>
            <p>{trainingInfoData?.BusinessCase || "N/A"}</p>
            <br />
            <h3>Who to Participate</h3>
            <ul>
              <li>{trainingInfoData?.TargetMember || "N/A"}</li>
            </ul>
            <br />
            <h3>Core Training Objectives</h3>
            <p>{trainingInfoData?.CoreObjective || "N/A"}</p>
            <br />
            <h3>Talking Key Points</h3>
            <p>{trainingInfoData?.DiscussionPoint || "N/A"}</p>
            <br />
            {materialData && materialData?.length > 0 ? (
              <h3>Training Schedule Material</h3>
            ) : null}
            <br />
            <div className={styles.list}>
              {materialData?.map((item, index) => (
                <div key={index} className={styles.files}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {isEventScheduled ? (
            <div className={styles.list}>
              {trainingInfoData?.IsPaymentRequired && (
                <PayForTraining
                  amount={trainingPaymentDetails?.Amount}
                  email={formik.values.email}
                />
              )}
            </div>
          ) : (
            <div className={styles.scheduleContainer}>
              <h3>Select preferred schedule</h3>
              {trainingSchedule?.map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                const endDate = getDateWithoutTime(item.EndDate);
                return (
                  <section
                    key={item.LearningEventId}
                    className={styles.eventSchedule}
                  >
                    <Clock />
                    <div>
                      <h4>{item?.Venue}</h4>
                      <label>
                        {startDate} - {endDate}
                      </label>
                      <label>{item.Time}</label>
                    </div>
                    <Field
                      type="radio"
                      name="schedule"
                      value={`${item?.TrainingScheduleId}`}
                    />
                  </section>
                );
              })}
              <ErrorMessage name="schedule">
                {(msg) => <div className={styles.error}>{msg}</div>}
              </ErrorMessage>
              <br />
              <div className={styles.list}>
                {/* <Field
                  name="state"
                  as={Select}
                  label="State"
                  placeholder="Select state"
                  options={stateOptions}
                /> */}
                <Field
                  name="branch"
                  as={Select}
                  label="Location"
                  placeholder="Select location"
                  options={branchOptions}
                />
              </div>
              <br />
              <Button
                isLoading={registerEventMutation.isPending}
                disabled={registerEventMutation.isPending}
                type="submit"
                text={
                  trainingInfoData?.IsPaymentRequired
                    ? "Proceed to Payment"
                    : "Register"
                }
              />
            </div>
          )}
        </section>
      </form>
    </FormikProvider>
  );
};

export default ApplyForNonMemberTraining;
