import Button from "../../custom/button/button";
import laptop from "../../assets/laptop.png";
import success from "../../assets/success.png";
import warning from "../../assets/warning.png";
import points from "../../assets/points.png";
import styles from "./main.module.scss";
import { ReactComponent as Calendar } from "../../assets/calendar2.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Marker } from "../../assets/marker.svg";
import { App, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import ProfileCard from "../profileCard/profileCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  MemberType,
  TransactionStatus,
  getCutOffScores,
  getHRWorkActivity,
  getMembershipUpgradeStatus,
  queryPayment,
  requestMembershipUpgrade,
} from "../../requests";
import { useAtomValue } from "jotai";
import { userData, userProfile } from "../../state";
import { errorMessage } from "../../utils/errorMessage";
import { AxiosError } from "axios";
import { formatDate, getDateWithoutTime } from "../../utils/formatDate";
import Table, { ColumnsType } from "antd/es/table";
import EndorsementModal from "./endorsementModal";
import AddActivity from "./addActivity";
import Pagination from "../../custom/pagination/pagination";

const columns: ColumnsType<HRWorkActivity> = [
  {
    title: "Date Added",
    dataIndex: "CreatedDate",
    key: "Id",
    render: (_, { CreatedDate }) => formatDate(CreatedDate),
  },
  {
    title: "Activity Name",
    dataIndex: "NameOfTraining",
    key: "Id",
  },
  {
    title: "Score",
    key: "Id",
    dataIndex: "Score",
  },
];

const Main = () => {
  const queryClient = useQueryClient();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const user = useAtomValue(userData);
  const profile = useAtomValue(userProfile);
  const [open, setOpen] = useState(false);
  const [openRequirements, setOpenRequirements] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAwaitingApproval, setOpenAwaitingApproval] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openEndorsementModal, setOpenEndorsementModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 10;
  const handlePagination = (pageNumber: number) => setPageNumber(pageNumber);
  const queryParams = `${pageNumber}${PAGE_SIZE}`;
  const { notification } = App.useApp();
  const reference = params.get("ref");

  const requestUpgradeMutation = useMutation({
    mutationKey: ["request-upgrade"],
    mutationFn: requestMembershipUpgrade,
  });

  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(reference!),
    enabled: !!reference,
  });

  const checkStatus = useQuery({
    queryKey: ["check-status"],
    queryFn: () => getMembershipUpgradeStatus(profile?.MembershipNo),
  });

  const activities = useQuery({
    queryKey: ["get-hr-work-activity", queryParams],
    queryFn: () => getHRWorkActivity(user?.MemberId!, pageNumber, PAGE_SIZE),
  });

  const cutOffScores = useQuery({
    queryKey: ["get-cut-off-scores"],
    queryFn: getCutOffScores,
  });

  useEffect(() => {
    if (
      !!reference &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    ) {
      checkStatus.refetch();
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, queryTransaction.isSuccess]);

  const upgradeCutOffScore = cutOffScores.data?.Data?.UpgradeScore as number;
  const activityData = activities?.data?.Data as HRWorkActivity[];
  const status = checkStatus?.data?.Data as MembershipUpgradeStatus;
  const isAwaitingApproval =
    !status?.HasInvited &&
    !status?.HasPaid &&
    status?.UpgradeHistories?.some((x) => x.IsApproved);
  const isDeclined = status?.UpgradeHistories?.some(
    (x) => x.IsApproved === false
  );
  const timeForInterview = status?.HasInvited && !status?.RequiredPayment;
  const timeForPayment =
    status?.HasInvited && status?.RequiredPayment && !status?.HasPaid;
  const waitingForCertificate = status?.HasPaid && !status?.IsReady;
  const isCertificateReady = status?.IsReady;

  const notifyMessage = () => {
    if (isAwaitingApproval)
      return <label className={styles.waiting}>Awaiting Approval</label>;
    else if (isDeclined)
      return <label className={styles.declined}>Request Declined</label>;
    else if (timeForInterview)
      return (
        <div className={styles.interview}>
          <label>Time for Interview</label>
          <div className={styles.textWithIcon}>
            <Calendar />
            {status?.InterviewDetail?.InterviewDate
              ? getDateWithoutTime(status?.InterviewDetail?.InterviewDate, true)
              : "N/A"}
          </div>
          <div className={styles.textWithIcon}>
            <Clock />
            {status?.InterviewDetail?.Time}
          </div>
          <div className={styles.textWithIcon}>
            <Marker />
            {status?.InterviewDetail?.InterviewVenue}
          </div>
        </div>
      );
    else if (timeForPayment)
      return <label className={styles.payment}>Make Payment</label>;
    else if (waitingForCertificate) {
      return <label className={styles.payment}>Certificate Processing</label>;
    } else if (isCertificateReady)
      return <label className={styles.payment}>Certificate Ready</label>;
    return <label className={styles.waiting}>Request Initiated</label>;
  };

  const requestUpgrade = async () => {
    try {
      await requestUpgradeMutation.mutateAsync(profile?.MembershipNo, {
        onSuccess: () => {
          setOpenAwaitingApproval(true);
          checkStatus.refetch();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  if (checkStatus.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (
    checkStatus.isError &&
    (checkStatus.error as AxiosError as any)?.response?.data?.Message !==
      "There is no ongoing request"
  )
    return <div>{(checkStatus.error as AxiosError)?.message}</div>;

  return (
    <div>
      <div className={styles.heading}>
        <h3>Profile Details</h3>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.lightBtn}
            onClick={() => setOpenEndorsementModal(true)}
            text="Request Endorsement"
          />
          <Button
            className={styles.lightBtn}
            onClick={() => setOpenActivityModal(true)}
            text="Add Activity"
          />
          <Button
            onClick={() => setOpenRequirements(true)}
            text="Request for Upgrade"
            className={styles.btn}
            isLoading={requestUpgradeMutation.isPending}
            disabled={requestUpgradeMutation.isPending}
          />
        </div>
      </div>
      {checkStatus.isSuccess && (
        <div className={styles.status}>
          <div className={styles.stat}>
            <img src={laptop} alt="laptop" />
            <div>
              <h2>{profile?.Name}</h2>
              {notifyMessage()}
            </div>
          </div>
          {status?.UpgradeHistories?.length > 0 && (
            <Button
              className={classNames("outlineBtn")}
              text="View Details"
              onClick={() => {
                queryClient.refetchQueries({ queryKey: ["get-profile"] });
                navigate("/membership-upgrade/details");
              }}
            />
          )}
        </div>
      )}
      <section className={styles.profileContainer}>
        <ProfileCard showUpgradeScore />
        <br />
        <div className={styles.tableHeading}>
          <img src={points} alt="points" />
          <div>
            <div className={styles.amount}>{upgradeCutOffScore}</div>
            <label>POINTS NEEDED</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={activityData}
          pagination={false}
          scroll={{ x: true }}
          className={classNames("table")}
          locale={{ emptyText: "No work activities yet" }}
        />
        <Pagination
          pageNumber={pageNumber || 0}
          itemsPerPage={PAGE_SIZE}
          totalSize={activities.data?.TotalSize || 0}
          setPageNumber={handlePagination}
        />
      </section>
      <EndorsementModal
        open={openEndorsementModal}
        closeModal={() => setOpenEndorsementModal(false)}
      />
      <AddActivity
        open={openActivityModal}
        closeModal={() => setOpenActivityModal(false)}
      />
      <Modal centered open={open} onCancel={() => setOpen(false)} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Congratulations!!!</h2>
          <p>
            You have successfully paid for membership upgrade. Your certificate
            is now processing; you will be notified when it's ready!
          </p>
          <div className={styles.buttonGroup}>
            <Button
              onClick={() => setOpen(false)}
              text="Cancel"
              className={classNames("outlineBtn")}
            />
            <Button onClick={() => setOpen(false)} text="Okay" />
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={openAwaitingApproval}
        onCancel={() => setOpenAwaitingApproval(false)}
        footer={null}
      >
        <div className={styles.modalContent}>
          <img src={warning} alt="warning" />
          <h2>Congratulations!!!</h2>
          <p>
            Your request for membership upgrade is sent successfully. Kindly
            await admin approval.
          </p>
          <div className={styles.buttonGroup}>
            <Button
              onClick={() => setOpenAwaitingApproval(false)}
              text="Cancel"
              className={classNames("outlineBtn")}
            />
            <Button onClick={() => setOpenAwaitingApproval(false)} text="Ok" />
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={openRequirements}
        onCancel={() => setOpenRequirements(false)}
        footer={null}
      >
        <div className={styles.modalContent}>
          <img src={warning} alt="warning" />
          <h2>Upgrade Requirements</h2>
          <p>
            To apply for membership upgrade, you need to meet the following
            requirements:
          </p>
          <ul className={styles.list}>
            <li>
              Eligibility once every{" "}
              {user?.MembershipTypeId === MemberType.Full ? "7" : "6"} years
            </li>
            <li>An active license</li>
            <li>No outstanding subscription dues</li>
            <li>At least {upgradeCutOffScore} points</li>
          </ul>
          <div className={styles.buttonGroup}>
            <Button
              onClick={() => setOpenRequirements(false)}
              text="Cancel"
              className={classNames("outlineBtn")}
            />
            <Button
              onClick={() => {
                setOpenRequirements(false);
                setOpenConfirm(true);
              }}
              text="Proceed"
            />
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={openConfirm}
        onCancel={() => setOpenConfirm(false)}
        footer={null}
      >
        <div className={styles.modalContent}>
          <img src={warning} alt="warning" />
          <h2>Confirm Personal Details</h2>
          <p>
            Please confirm if your personal details are correct before applying
            for upgrade:
          </p>
          <ul className={styles.list}>
            <li>Name</li>
            <li>Email address</li>
            <li>Designation</li>
            <li>Phone number</li>
            <li>Profile picture</li>
            <li>Organization name</li>
            <li>Sector</li>
          </ul>
          <div className={styles.buttonGroup}>
            <Button
              onClick={() => {
                navigate("/profile");
              }}
              text="Go to Profile"
              className={classNames("outlineBtn")}
            />
            <Button
              onClick={() => {
                setOpenConfirm(false);
                requestUpgrade();
              }}
              text="Proceed to Apply"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
