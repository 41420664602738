import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { needHelp } from "../../requests";
import { useNavigate } from "react-router-dom";
import Textarea from "../../custom/textarea/textarea";
import { App } from "antd";
import classNames from "classnames";

const NeedHelp = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const needHelpMutation = useMutation({
    mutationKey: ["needHelp"],
    mutationFn: needHelp,
  });

  const needHelpHandler = async (values: FormikValues) => {
    const payload: NeedHelpPayload = {
      FullName: values.fullName,
      Email: values.email,
      Massage: values.message,
    };
    try {
      await needHelpMutation.mutateAsync(payload, {
        onSuccess: () => {
          navigate("/message-received");
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message || error?.message || error?.response?.data?.message,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("required"),
    email: Yup.string().email("Invalid email").required("required"),
    message: Yup.string().required("required"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      fullName: "",
      email: "",
      message: "",
    },
    onSubmit: (values) => {
      needHelpHandler(values);
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <section className={styles.section}>
        <h2 className={styles.heading}>How can we help?</h2>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <section className={styles.fields}>
            <Field
              as={Input}
              type="text"
              name="fullName"
              label="Full Name"
              placeholder="Eg John Doe"
              className={styles.input}
            />
            <Field
              type="email"
              as={Input}
              name="email"
              label="Email"
              placeholder="Enter email"
              className={styles.input}
            />
            <Field
              as={Textarea}
              name="message"
              label="Message"
              placeholder="Input your message"
              className={styles.textarea}
              rows={5}
              count={250}
            />
          </section>
          <div className={classNames("buttonGroup")}>
            <Button
              text={"Cancel"}
              onClick={() => navigate("/")}
              className={classNames("cancelBtn")}
            />
            <Button
              type="submit"
              disabled={needHelpMutation.isPending}
              text={"Send Message"}
              isLoading={needHelpMutation.isPending}
              className={styles.sendMessage}
            />
          </div>
        </form>
      </section>
    </FormikProvider>
  );
};

export default NeedHelp;
