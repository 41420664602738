import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../../custom/input/input";
import { App } from "antd";
import { useAtomValue } from "jotai";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  updateEmail,
  updatePersonalDetails,
  getCountries,
  getStateByCountryId,
  getLgaStateId,
  getZoneStateId,
  getBranches,
  getChaptersByBranch,
} from "../../../requests";
import { isUpdateProfile } from "../../../state";
import VerifyButton from "./verifyButton/verifyButton";
import ChangeOfNameModal from "./changeOfNameModal/changeOfNameModal";
import ChangeOfEmailModal from "./changeOfEmailModal/changeOfEmailModal";
import ChangeOfMaritalStatusModal from "./changeOfMaritalStatusModal/changeOfMaritalStatusModal";
import { useMutation } from "@tanstack/react-query";
import Button from "../../../custom/button/button";
import { format } from "date-fns";
import Select from "../../../custom/select/select";
import { errorMessage } from "../../../utils/errorMessage";
import VerifyModal from "./verifyModal/verifyModal";

const gender = ["Male", "Female"];

interface Props {
  personalDetails?: Profile;
}

const PersonalDetails = ({ personalDetails }: Props) => {
  const { notification } = App.useApp();
  const isChangeOfEmail = personalDetails?.IsNameConfirmed;
  const isEmailConfirmed = personalDetails?.IsEmailConfirmed;
  const isMaritalStatusConfirmed = personalDetails?.IsMaritalStatusConfirmed;
  //const isAlternateEmailConfirmed = personalDetails?.IsAlternateEmailConfirmed;
  const membershipId = personalDetails?.MembershipNo;
  const applicantId = personalDetails?.ApplicantId;
  const isEditProfile = useAtomValue(isUpdateProfile);
  const [isChangeOfNameModal, setIsChangeOfNameModal] = useState(false);
  const [isChangeOfMaritalStatusModal, setIsChangeOfMaritalStatusModal] =
    useState(false);
  const [isChangeOfEmailModal, setIsChangeOfEmailModal] = useState(false);
  const [isNoChange, setIsNoChange] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const queryClient = useQueryClient();
  const fullName = personalDetails?.Name as string;
  const emailAddress = personalDetails?.Email as string;
  const maritalStatus = personalDetails?.MaritalStatusId as string;
  //const alternativeEmail = personalDetails?.AlternateEmail;

  const isNameTheSame = (
    previousFullName: string,
    fullName: string
  ): boolean => {
    return (
      previousFullName?.toLocaleLowerCase()?.trim() ===
      fullName?.toLocaleLowerCase()?.trim()
    );
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      fullName: personalDetails?.Name,
      maritalStatus: personalDetails?.MaritalStatusId,
      emailAddress: personalDetails?.Email,
      alternativeEmail: personalDetails?.AlternateEmail,
      phoneNumber: personalDetails?.PhoneNumber,
      whatsappNumber: personalDetails?.WhatsAppNumber,
      dob: format(new Date(personalDetails?.DateBirth! ?? 0), "yyyy-MM-dd"),
      designation: personalDetails?.Occupation,
      branch: personalDetails?.BranchId,
      branchChairman: "",
      chapter: personalDetails?.ChapterId,
      country: personalDetails?.CountryId,
      contactAddress: personalDetails?.ContactAddress,
      residentialAddress: personalDetails?.ResidentialAddress,
      stateOfOrigin: personalDetails?.StateOriginId,
      lga: personalDetails?.LGAId,
      stateOfEmployment: personalDetails?.StateId,
      zone: personalDetails?.ZoneId,
      gender: personalDetails?.Gender,
    },
    onSubmit: (values, { setFieldValue }) => {
      const hasNameChanged = !isNameTheSame(fullName, values.fullName);
      const hasEmailChanged = !isNameTheSame(emailAddress, values.emailAddress);
      const hasMaritalStatusChanged = maritalStatus !== values.maritalStatus;

      if (hasNameChanged || hasEmailChanged || hasMaritalStatusChanged) {
        setFieldValue("fullName", personalDetails?.Name);
        setFieldValue("emailAddress", personalDetails?.Email);
        setFieldValue("maritalStatus", personalDetails?.MaritalStatusId);
        setIsNoChange(true);
      } else {
        const personalDetail = {
          Name: personalDetails?.Name,
          MaritalStatusId: personalDetails?.MaritalStatusId || "",
          AlternateEmail:
            values.alternativeEmail || personalDetails?.AlternateEmail,
          Email: values.emailAddress || personalDetails?.Email,
          PhoneNumber: values.phoneNumber,
          WhatsAppNumber: values.whatsappNumber,
          DateBirth: values.dob,
          BranchId: values.branch,
          Chapter: values.chapter,
          ContactAddress: values.contactAddress,
          ResidentialAddress: values.residentialAddress,
          Occupation: values.designation,
          CountryId: values.country,
          StateOriginId: values.stateOfOrigin,
          LGAId: values.lga,
          StateId: values.stateOfEmployment,
          ZoneId: personalDetails?.ZoneId,
          Gender: values.gender || "",
          ApplicantId: applicantId,
          MembershipNo: membershipId,
          MembershipType: personalDetails?.MembershipType,
        };

        updateProfileMutation.mutate(personalDetail, {
          onSuccess: (response) => {
            notification.success({ message: response });
            queryClient.refetchQueries({ queryKey: ["get-profile"] });
          },

          onError: (error: any) => {
            notification.error({
              message: "Error",
              description: errorMessage(error),
            });
          },
        });
      }
    },
    enableReinitialize: true,
  });

  const countryId = formik.values.country;
  const stateId = formik.values.stateOfOrigin;
  const branchId = formik.values.branch;

  const {
    data: countries,
    isLoading: isCountryLoading,
    isError: isCountryError,
    error: countryError,
  } = useQuery({
    queryKey: ["get-countries"],
    queryFn: getCountries,
  });

  const {
    data: states,
    isLoading: isStateLoading,
    isError: isStateError,
    error: stateError,
  } = useQuery({
    queryKey: ["get-states", countryId],
    queryFn: () => getStateByCountryId(countryId as number),
    enabled: !!countryId,
  });

  const {
    data: lgas,
    isLoading: isLgasLoading,
    isError: isLgasError,
    error: lgasError,
  } = useQuery({
    queryKey: ["get-lgas", stateId],
    queryFn: () => getLgaStateId(stateId as number),
    enabled: !!stateId,
  });

  const {
    data: branches,
    isLoading: isBranchesLoading,
    isError: isBranchesError,
    error: branchesError,
  } = useQuery({
    queryKey: ["get-branches", stateId, countryId],
    queryFn: () => getBranches(),
  });

  const {
    data: chapters,
    isLoading: isChaptersLoading,
    isError: isChaptersError,
    error: chaptersError,
  } = useQuery({
    queryKey: ["get-chapters", branchId],
    queryFn: () => getChaptersByBranch(branchId as number),
    enabled: !!branchId,
  });

  useEffect(() => {
    const chairmanName = branches?.filter(
      ({ BranchId }) => BranchId === parseInt(formik.values?.branch ?? 0)
    )[0]?.ChairmanName;

    formik.setFieldValue("branchChairman", chairmanName);
    //eslint-disable-next-line
  }, [branchId]);

  const { data: zone } = useQuery({
    queryKey: ["get-zone", stateId, countryId],
    queryFn: () => getZoneStateId(stateId as number),
    enabled: !!stateId,
  });

  useEffect(() => {
    formik.setFieldValue("zone", zone);
    // eslint-disable-next-line
  }, [zone, countryId]);

  const changeEmailMutation = useMutation({
    mutationKey: ["profile-update-email"],
    mutationFn: (payload: VerifyEmailPayload) => updateEmail(payload),
  });

  // const changeAlternateEmailMutation = useMutation({
  //   mutationKey: ["profile-update-alternate-email"],
  //   mutationFn: (payload: VerifyEmailPayload) => verifyAlternateEmail(payload),
  // });

  const updateProfileMutation = useMutation({
    mutationKey: ["profile-update"],
    mutationFn: (payload: Partial<Profile>) => updatePersonalDetails(payload),
  });

  const handleVerifyEmail = () => {
    if (!hasAlternateEmail(personalDetails?.AlternateEmail)) {
      formik.setFieldValue("emailAddress", personalDetails?.Email);
      setIsChangeOfEmailModal(true);
      return;
    }

    const payload = {
      MembershipNo: membershipId as string,
      Email: formik?.values?.emailAddress as string,
    };

    changeEmailMutation.mutate(payload, {
      onSuccess: (response) => {
        notification.success({ message: response });
        setIsMailSent(true);
        setIsChangeOfEmailModal(true);
        formik.setFieldValue("emailAddress", emailAddress);
        queryClient.refetchQueries({ queryKey: ["get-profile"] });
      },
      onError: (error: any) => {
        const errorMessage = error?.response?.data?.Message
          ? error?.response?.data?.Message
          : error?.response?.data?.errors
          ? error?.response?.data?.errors["MembershipNo" || "Email"]?.[0]
          : error?.message;

        notification.error({ message: errorMessage });
      },
    });
  };

  // const handleVerifyAlternateEmail = () => {
  //   const payload = {
  //     MembershipNo: membershipId as string,
  //     Email: formik?.values?.alternativeEmail as string,
  //   };

  //   changeAlternateEmailMutation.mutate(payload, {
  //     onSuccess: (response) => {
  //       notification.success({ message: response });
  //       formik.setFieldValue("alternativeEmail", alternativeEmail);
  //       queryClient.refetchQueries({ queryKey: ["get-profile"] });
  //     },
  //     onError: (error: any) => {
  //       const errorMessage = error?.response?.data?.Message
  //         ? error?.response?.data?.Message
  //         : error?.response?.data?.errors
  //         ? error?.response?.data?.errors["Email"]?.[0]
  //         : error?.message;

  //       notification.error({ message: errorMessage });
  //     },
  //   });
  // };

  const hasAlternateEmail = (alternateEmail: string) => {
    return typeof alternateEmail === "string";
  };

  const handleChangeOfNameVerification = () => {
    if (isNameTheSame(fullName, formik.values.fullName)) {
      notification.warning({
        message:
          "Full name has not changed. Kindly edit the field before you request for verification",
      });
      return;
    }
    setIsChangeOfNameModal(true);
  };

  const handleChangeOfMaritalStatusVerification = () => {
    if (
      !!maritalStatus &&
      isNameTheSame(maritalStatus, formik.values.maritalStatus)
    ) {
      notification.warning({
        message:
          "Marital status has not changed. Kindly edit the field before you request for verification",
      });
      return;
    }
    setIsChangeOfMaritalStatusModal(true);
  };

  const chapterData = chapters?.Data as ChapterByBranch[];

  const countriesOption = countries?.map(({ CountryName, CountryId }) => (
    <option value={CountryId} key={CountryId}>
      {CountryName}
    </option>
  ));

  const statesOption = states?.map(({ StateName, StateId }) => (
    <option value={StateId} key={StateId}>
      {StateName}
    </option>
  ));

  const lgasOption = lgas?.map(({ LGAName, LGAId }) => (
    <option value={LGAId} key={LGAId}>
      {LGAName}
    </option>
  ));

  const genderOption = gender?.map((gender) => (
    <option value={gender} key={gender}>
      {gender}
    </option>
  ));

  const branchesOption = branches?.map(({ BranchName, BranchId }) => (
    <option value={BranchId} key={BranchId}>
      {BranchName}
    </option>
  ));

  const chaptersOption = chapterData?.map(({ ChapterName, ChapterId }) => (
    <option value={ChapterId} key={ChapterId}>
      {ChapterName}
    </option>
  ));

  const maritalStatusOption = (
    <>
      <option>Married</option>
      <option>Single</option>
      <option>Widowed</option>
      <option>Divorced</option>
    </>
  );

  return (
    <FormikProvider value={formik}>
      <ChangeOfNameModal
        open={isChangeOfNameModal}
        onClose={() => setIsChangeOfNameModal(false)}
        fullName={formik.values.fullName}
        previousFullName={fullName}
        setFieldValue={formik.setFieldValue}
      />
      <ChangeOfMaritalStatusModal
        open={isChangeOfMaritalStatusModal}
        onClose={() => setIsChangeOfMaritalStatusModal(false)}
        maritalStatus={formik.values.maritalStatus}
        setFieldValue={formik.setFieldValue}
      />
      <ChangeOfEmailModal
        open={isChangeOfEmailModal}
        isMailSent={isMailSent}
        onClose={() => setIsChangeOfEmailModal(false)}
      />
      <VerifyModal open={isNoChange} onClose={() => setIsNoChange(false)} />
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <section className={styles.fields}>
          <span className={styles.fieldWrapper}>
            <Field
              as={Input}
              name="fullName"
              label="Full Name"
              placeholder={formik.values.fullName}
              className={styles.input}
              disabled={!isEditProfile || !isChangeOfEmail}
            />
            {isEditProfile && (
              <VerifyButton
                text="Verification is needed for updates"
                isAwaiting={isChangeOfEmail!}
                handleVerify={handleChangeOfNameVerification}
              />
            )}
          </span>
          <span>
            <Field
              as={Select}
              name="maritalStatus"
              label="Marital Status"
              placeholder={formik.values.maritalStatus}
              options={maritalStatusOption}
              className={styles.input}
              disabled={!isEditProfile || !isMaritalStatusConfirmed}
            />
            {isEditProfile && (
              <VerifyButton
                text="Verification is needed for updates"
                isAwaiting={isMaritalStatusConfirmed ? true : false}
                handleVerify={handleChangeOfMaritalStatusVerification}
              />
            )}
          </span>
        </section>
        <section className={styles.fields}>
          <span>
            <Field
              as={Input}
              type="email"
              name="emailAddress"
              label="Email Address"
              placeholder={formik.values.emailAddress}
              className={styles.input}
              disabled={!isEditProfile || !isEmailConfirmed}
            />
            {isEditProfile && (
              <VerifyButton
                text="Verification is needed for updates"
                isAwaiting={isEmailConfirmed ? true : false}
                handleVerify={() => handleVerifyEmail()}
                isLoading={changeEmailMutation.isPending}
              />
            )}
          </span>
          <span>
            <Field
              as={Input}
              type="email"
              name="alternativeEmail"
              label="Alternative Email"
              placeholder={formik.values.alternativeEmail}
              className={styles.input}
              disabled={!isEditProfile}
              //disabled={!isEditProfile || !isAlternateEmailConfirmed}
            />
            {/* {isEditProfile && (
              <VerifyButton
                text="Verification is needed for updates"
                isAwaiting={isAlternateEmailConfirmed ? true : false}
                handleVerify={() => handleVerifyAlternateEmail()}
                isLoading={changeAlternateEmailMutation.isPending}
              />
            )} */}
          </span>
        </section>
        <section className={styles.fields}>
          <Field
            as={Input}
            name="phoneNumber"
            label="Phone Number"
            placeholder={formik.values.phoneNumber}
            className={styles.input}
            disabled={!isEditProfile}
          />
          <Field
            as={Input}
            name="whatsappNumber"
            label="WhatApp Number (Optional)"
            placeholder={formik.values.whatsappNumber}
            className={styles.input}
            disabled={!isEditProfile}
          />
        </section>
        <div className={styles.field}>
          <Field
            as={Input}
            name="designation"
            label="Designation"
            placeholder={formik.values.designation}
            className={styles.input}
            disabled={!isEditProfile}
          />
        </div>
        <section className={styles.fields}>
          <Field
            as={Select}
            name="branch"
            label="Branch"
            placeholder={formik.values.branch}
            className={styles.input}
            //disabled={isChairman || !isEditProfile}
            disabled={!isEditProfile}
            options={branchesOption}
            isLoading={isBranchesLoading}
            error={(branchesError as any)?.response?.data?.Message}
            isError={isBranchesError}
          />
          <Field
            as={Input}
            name="branchChairman"
            label="Branch Chairman"
            placeholder={
              branches?.filter(
                ({ BranchId }) =>
                  BranchId === parseInt(formik.values?.branch ?? 0)
              )[0]?.ChairmanName
            }
            className={styles.input}
            disabled={true}
          />
        </section>
        <section className={styles.fields}>
          <Field
            as={Select}
            name="chapter"
            label="Chapter (Optional)"
            placeholder={formik.values.chapter}
            className={styles.input}
            disabled={!isEditProfile}
            //disabled={isChairman || !isEditProfile}
            options={chaptersOption}
            isLoading={isChaptersLoading}
            error={(chaptersError as any)?.response?.data?.Message}
            isError={isChaptersError}
          />
          <Field
            as={Select}
            name="country"
            label="Country"
            placeholder={formik.values.country}
            className={styles.input}
            options={countriesOption}
            isLoading={isCountryLoading}
            error={(countryError as any)?.response?.data?.Message}
            isError={isCountryError}
            disabled={!isEditProfile}
            //disabled
          />
        </section>
        <section className={styles.field}>
          <Field
            as={Input}
            name="contactAddress"
            label="Contact Address"
            placeholder={formik.values.contactAddress}
            className={styles.input}
            disabled={!isEditProfile}
            //disabled
          />
        </section>
        <section className={styles.field}>
          <Field
            as={Input}
            name="residentialAddress"
            label="Residential Address (if different from contact address)"
            placeholder={formik.values.residentialAddress}
            className={styles.input}
            disabled={!isEditProfile}
          />
        </section>
        <section className={styles.field}>
          <Field
            as={Select}
            name="stateOfOrigin"
            label="State of Origin"
            placeholder={formik.values.stateOfOrigin}
            className={styles.input}
            options={statesOption}
            isLoading={isStateLoading}
            isError={isStateError}
            error={stateError}
            disabled={!isEditProfile}
            //disabled
          />
        </section>
        <section className={styles.fields}>
          <Field
            as={Select}
            name="lga"
            label="LGA"
            placeholder={formik.values.lga}
            className={styles.input}
            options={lgasOption}
            isLoading={isLgasLoading}
            isError={isLgasError}
            error={lgasError}
            disabled={!isEditProfile}
            //disabled
          />
          <Field
            as={Select}
            name="stateOfEmployment"
            label="State (Place of Employment)"
            placeholder={formik.values.stateOfEmployment}
            className={styles.input}
            options={statesOption}
            isLoading={isStateLoading}
            isError={isStateError}
            error={stateError}
            disabled={!isEditProfile}
          />
        </section>
        <section className={styles.fields}>
          <Field
            as={Input}
            name="zone"
            label="Zone"
            placeholder={formik.values.zone}
            className={styles.input}
            //disabled={!isEditProfile}
            disabled={true}
          />
          <Field
            as={Select}
            name="gender"
            label="Gender (Optional)"
            placeholder={formik.values.gender}
            className={styles.input}
            disabled={!isEditProfile}
            //disabled
            options={genderOption}
          />
        </section>
        <hr className={styles.hr} />
      </form>
      {isEditProfile && (
        <section className={styles.btnWrapper}>
          <Button
            text="Save Changes"
            type="button"
            isLoading={updateProfileMutation?.isPending}
            disabled={updateProfileMutation?.isPending}
            onClick={() => formik.handleSubmit()}
          />
        </section>
      )}
    </FormikProvider>
  );
};

export default PersonalDetails;
