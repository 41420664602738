import styles from './styles.module.scss';

interface Props {
	text: string;
	heading: string;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Card = ({ heading, text, icon: Icon }: Props) => {
	return (
		<article className={styles.card}>
			<span>
				<h4 className={styles.heading}>{heading}</h4>
				<p className={styles.para}>{text}</p>
			</span>
			<span className={styles.iconWrapper}>
				<Icon className={styles.icon} />
			</span>
		</article>
	);
};

export default Card;
