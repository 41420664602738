import styles from "./main.module.scss";
import { ReactComponent as CyberPay } from "../../assets/cyberpay-logo.svg";
import Button from "../../custom/button/button";
import success from "../../assets/success.png";
import Pagination from "../../custom/pagination/pagination";
import { useEffect, useState } from "react";
import MakePayment from "./makePayment";
import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";
import {
  TransactionStatus,
  getMemberDues,
  getOutstandingDue,
  queryPayment,
} from "../../requests";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { Modal, Spin, Table } from "antd";
import { AxiosError } from "axios";
import { formatCurrency } from "../../utils/formatCurrency";
import { ColumnsType } from "antd/es/table";
import { useSearchParams } from "react-router-dom";

const Main = () => {
  const [params] = useSearchParams();
  const user = useAtomValue(userData);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 20;
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [openSuccessPayment, setOpenSuccessPayment] = useState(false);
  const referenceParams = params.get("ref");

  const handleOpenSuccessPayment = () => setOpenSuccessPayment(true);
  const handleCloseSuccessPayment = () => setOpenSuccessPayment(false);
  const openPaymentForm = () => setShowPaymentForm(true);
  const handlePagination = (pageNumber: number) => setPageNumber(pageNumber);

  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(referenceParams!),
    enabled: !!referenceParams,
  });

  const { data, refetch, isLoading, isError, error } = useQuery({
    queryKey: ["get-outstanding-due"],
    queryFn: () => getOutstandingDue(user?.MemberId as string),
    enabled: !!user?.MemberId,
  });
  const dues = useQuery({
    queryKey: ["get-dues", user?.MemberId],
    queryFn: () => getMemberDues(user?.MemberId!),
    enabled: !!user?.MemberId,
  });

  useEffect(() => {
    if (
      !!referenceParams &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    ) {
      dues.refetch();
      refetch();
      handleOpenSuccessPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTransaction.isSuccess]);

  const outstandingData = data?.Data as Due;
  const duesData = dues?.data?.Data as Due[];

  const columns: ColumnsType<Due> = [
    {
      title: "Year",
      dataIndex: "DueYear",
      key: "id",
      // render: (_, { DueYear: year }) => {
      //   let isCurrentYear =
      //     duesData?.reduce(
      //       (acc, curr) => Math.max(acc, curr.DueYear),
      //       duesData[0]?.DueYear
      //     ) === year;
      //   return (
      //     <span className={classNames("yearCell")}>
      //       <b>{year}</b>
      //       {isCurrentYear && (
      //         <span className={classNames("currentBadge")}>Current</span>
      //       )}
      //     </span>
      //   );
      // },
    },
    {
      title: "Total amount",
      dataIndex: "totalAmount",
      key: "id",
      render: (_, { OutstandingAmount, Amount }) => OutstandingAmount + Amount,
    },
    {
      title: "Amount Paid",
      dataIndex: "Amount",
      key: "id",
    },
    {
      title: "Balance",
      key: "id",
      dataIndex: "OutstandingAmount",
    },
  ];

  if (isLoading || queryTransaction.isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={styles.heading}>
        <h3>Outstanding Payments</h3>
        {outstandingData?.OutstandingAmount > 0 ? (
          <Button onClick={openPaymentForm} text="Make Payment" />
        ) : null}
      </section>

      <section className={classNames("tableContainer")}>
        <div className={classNames("tableHeading")}>
          <CyberPay />
          <div>
            <div className={classNames("tableAmount")}>
              {/* <span>&#8358;</span> */}
              {formatCurrency(outstandingData?.OutstandingAmount)}
            </div>
            <label>Total Outstanding Payment</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={duesData}
          scroll={{ x: true }}
          pagination={false}
          rootClassName={classNames("table")}
        />
        <Pagination
          pageNumber={pageNumber}
          itemsPerPage={PAGE_SIZE}
          totalSize={duesData?.length || 0}
          setPageNumber={handlePagination}
        />
      </section>
      {showPaymentForm ? (
        <MakePayment outstandingAmount={outstandingData?.OutstandingAmount} />
      ) : null}
      <Modal
        centered
        open={openSuccessPayment}
        onCancel={handleCloseSuccessPayment}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={success} alt="success" />
          <h2>Congratulations!</h2>
          <p>We've received your payment for the annual subscription.</p>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
