import { App, Modal } from "antd";
import classNames from "classnames";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../../custom/input/input";
import styles from "./styles.module.scss";
import Button from "../../../custom/button/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createEducationalBackground } from "../../../requests";
import * as Yup from "yup";
import { errorMessage } from "../../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";
import Upload from "../../../custom/upload/upload";
import { useState } from "react";
import Select from "../../../custom/select/select";
import { qualificationDictionary } from "./educationalBackground";

interface Props {
  open: boolean;
  handleClose: () => void;
  qualificationOptions: UserQualification[];
}

const AddBackgroundModal = ({
  open,
  handleClose,
  qualificationOptions,
}: Props) => {
  const queryClient = useQueryClient();
  const { notification } = App.useApp();
  const [doc, setDoc] = useState<File | null>(null);
  const user = useAtomValue(userData);
  const addBackgroundMutation = useMutation({
    mutationKey: ["add-educational-background"],
    mutationFn: createEducationalBackground,
  });
  const options = qualificationOptions?.map((item) => (
    <option key={item.id} value={item.id}>
      {item.QualificationName}
    </option>
  ));
  const validationSchema = Yup.object().shape({
    institutionName: Yup.string().required("Institution name is required"),
    discipline: Yup.string(),
    qualification: Yup.number().required().min(1, "Qualification is required"),
    graduationDate: Yup.string().required("Graduation date is required"),
    file: Yup.mixed().required("Certificate is required"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      institutionName: "",
      discipline: "",
      qualification: 0,
      startDate: "",
      graduationDate: "",
      file: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = new FormData();
      payload.append("InstitutionName", values.institutionName);
      if (values.discipline) payload.append("Discipline", values.discipline);
      payload.append("QualificationId", values.qualification);
      payload.append(
        "Qualification",
        qualificationDictionary[values.qualification]
      );
      payload.append("StartDate", values.startDate);
      payload.append("GraduationDate", values.graduationDate);
      payload.append("MembershipNo", user?.MemberId!);
      payload.append("ApplicantId", user?.MemberId!);
      if (doc) payload.append("Certificate", doc);

      try {
        await addBackgroundMutation.mutateAsync(payload, {
          onSuccess: (data) => {
            notification.success({
              message: "Success",
              description:
                data?.Message || "Educational background added successfully",
            });
            queryClient.refetchQueries({
              queryKey: ["get-educational-background"],
            });
            handleClose();
            resetForm();
          },
        });
      } catch (error) {
        notification.error({
          message: "Error",
          description: errorMessage(error),
        });
      }
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      setDoc(file[0]);
      formik.setFieldValue("file", file[0]);
    }
  };

  return (
    <Modal
      open={open}
      centered
      footer={null}
      title="Add Educational Background"
      onCancel={handleClose}
      styles={{
        body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
      }}
    >
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className={classNames("modalContent")}
        >
          <Field
            as={Input}
            type="text"
            name="institutionName"
            label="Institution Name"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="text"
            name="discipline"
            label="Discipline"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Select}
            name="qualification"
            label="Qualification"
            className={classNames(styles.input, styles.fullWidth)}
            options={options}
          />
          <Field
            as={Input}
            type="date"
            name="startDate"
            label="Start Date"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="date"
            name="graduationDate"
            label="Graduation Date"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Upload
            label="Upload Certificate"
            allowedFormats={["pdf", "img"]}
            onChange={handleFileChange}
            fileName={doc?.name}
            fileSize={doc?.size}
          />
          {formik.touched.file && formik.errors.file ? (
            <div
              className={classNames("error")}
            >{`*${formik.errors.file.toString()}`}</div>
          ) : null}
          <br />
          <section className={classNames("buttonGroup")}>
            <Button
              text="Cancel"
              type="button"
              className={classNames("cancelBtn")}
              onClick={handleClose}
            />
            <Button
              text="Save"
              type="submit"
              isLoading={addBackgroundMutation?.isPending}
              disabled={addBackgroundMutation?.isPending}
            />
          </section>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default AddBackgroundModal;
