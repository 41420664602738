import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Arrow } from "../../assets/arrow-back.svg";
import { ReactComponent as CyberPay } from "../../assets/cyberpay-logo.svg";
import styles from "./main.module.scss";
import Points from "../../assets/points.png";
import Button from "../../custom/button/button";
import PayForTraining from "./payForTraining";
import { useQuery } from "@tanstack/react-query";
import {
  getAllBranches,
  getEventById,
  getEventScheduleByEventId,
  getMaterials,
  getRegisteredEvents,
} from "../../requests";
import { Spin } from "antd";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../utils/formatDate";
import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useAtomValue, useSetAtom } from "jotai";
import { trainingPayment, trainingStep, userData } from "../../state";
import { useEffect } from "react";
import Select from "../../custom/select/select";

const TrainingDetails = ({
  isEventScheduled,
  setIsEventScheduled,
  isScheduling,
}: {
  isEventScheduled: boolean;
  setIsEventScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduling: boolean;
}) => {
  const user = useAtomValue(userData);
  const [params] = useSearchParams();
  const setStep = useSetAtom(trainingStep);
  const navigate = useNavigate();
  const { values, resetForm } = useFormikContext<FormikValues>();
  const learningEventId: number = parseInt(values.learningEventId);
  const trainingDetails = useAtomValue(trainingPayment);
  const ref = params.get("ref");

  useEffect(() => {
    if (!!ref && trainingDetails?.IsCorporateSponsor) setIsEventScheduled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const trainingInfo = useQuery({
    queryKey: ["get-training-details"],
    queryFn: () => getEventById(learningEventId!),
    enabled: !!learningEventId,
  });
  const { data } = useQuery({
    queryKey: ["get-event-schedule"],
    queryFn: () => getEventScheduleByEventId(learningEventId!),
  });
  const materials = useQuery({
    queryKey: ["get-training-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const branches = useQuery({
    queryKey: ["get-branches"],
    queryFn: getAllBranches,
  });
  const registeredEvents = useQuery({
    queryKey: ["get-user-event-info"],
    queryFn: () => getRegisteredEvents(user?.MemberId!, "Training"),
  });

  const materialData = materials?.data?.Data as Material[];
  const trainingInfoData = trainingInfo?.data?.Data as EventById;
  const trainingSchedule = data?.Data as EventSchedule[];
  const branchData = branches?.data?.Data;
  const registeredEventsData = registeredEvents?.data
    ?.Data as RegisteredEvent[];

  const userRegister = registeredEventsData?.find(
    (event) => event.EventId === trainingInfoData?.EventNameId
  ) as RegisteredEvent;
  const hasUserRegistered = !!userRegister;
  const hasUserPaid = hasUserRegistered && userRegister?.HasPaid;

  const branchOptions = branchData?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));

  if (trainingInfo.isLoading) return <Spin />;
  if (trainingInfo.isError)
    return <div>{(trainingInfo.error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("headingWithBackArrow")}>
        <button
          onClick={() => {
            resetForm({ values: { traingName: "", trainingType: "" } });
            setStep((prev) => prev - 1);
          }}
        >
          <BackArrow />
        </button>
        <h3>Training Details</h3>
      </section>
      <section className={styles.detailsContainer}>
        <div className={styles.detailsContent}>
          <h2>
            {trainingInfoData?.EventName || "N/A"}
            <span>
              <img src={Points} alt="points" />
            </span>
          </h2>
          <br />
          <div className={styles.line}></div>
          <br />
          {hasUserPaid && (
            <>
              <h3>Venue</h3>
              <p>{trainingInfoData?.Venue}</p>
              <br />
            </>
          )}
          <h3>Business Case</h3>
          <p>{trainingInfoData?.BusinessCase || "N/A"}</p>
          <br />
          <h3>Who to Participate</h3>
          <ul>
            <li>{trainingInfoData?.TargetMember || "N/A"}</li>
          </ul>
          <br />
          <h3>Core Training Objectives</h3>
          <p>{trainingInfoData?.CoreObjective || "N/A"}</p>
          <br />
          <h3>Talking Key Points</h3>
          <p>{trainingInfoData?.DiscussionPoint || "N/A"}</p>
          <br />
          {materialData && materialData?.length > 0 ? (
            <h3>Training Schedule Material</h3>
          ) : null}
          <br />
          <div className={styles.list}>
            {materialData?.map((item, index) => (
              <div key={index} className={styles.files}>
                <div>
                  <File />
                  <label>{item.MaterialName}</label>
                </div>

                <Link
                  to={item.MaterialUrl}
                  target="_blank"
                  className={classNames("linkAsBtn")}
                >
                  Download
                </Link>
              </div>
            ))}
          </div>
        </div>

        <section className={styles.list}>
          {isEventScheduled || (hasUserRegistered && !hasUserPaid) ? (
            <>
              {values?.memberType === "corporate" &&
              trainingInfoData?.IsPaymentRequired ? (
                <section className={styles.resource}>
                  <div>
                    <h3>Get Payment Breakdown</h3>
                    <p>Cost details of your preferred event listed here.</p>
                    <br />
                    <Button
                      onClick={() =>
                        navigate(
                          `/event-application/training/invoice?id=${
                            userRegister?.Id || trainingDetails?.DataId
                          }&eventDetailId=${trainingInfoData?.LearningEventId}`
                        )
                      }
                      text="Generate Invoice"
                      iconAfter={<Arrow />}
                    />
                  </div>
                  <CyberPay />
                </section>
              ) : trainingInfoData?.IsPaymentRequired ? (
                <PayForTraining
                  hasUserRegistered={hasUserRegistered}
                  setIsEventScheduled={setIsEventScheduled}
                  amount={userRegister?.PayingAmount}
                  dataId={userRegister?.Id}
                />
              ) : null}
            </>
          ) : (
            <div className={styles.scheduleContainer}>
              <h3>Select preferred schedule</h3>
              {trainingSchedule?.map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                const endDate = getDateWithoutTime(item.EndDate);
                return (
                  <section className={styles.eventSchedule}>
                    <Clock />
                    <div>
                      <h4>{item?.Venue}</h4>
                      <label>
                        {startDate} - {endDate}
                      </label>
                      <label>{item.Time}</label>
                    </div>
                    <Field
                      type="radio"
                      name="schedule"
                      value={`${item?.TrainingScheduleId}`}
                    />
                  </section>
                );
              })}
              <ErrorMessage name="schedule">
                {(msg) => <div className={styles.error}>{msg}</div>}
              </ErrorMessage>
              <br />
              <div className={styles.list}>
                <Field
                  name="branch"
                  as={Select}
                  label="Location"
                  placeholder="Location"
                  options={branchOptions}
                />
              </div>
              <Button
                isLoading={isScheduling}
                disabled={isScheduling}
                type="submit"
                text="Proceed to Payment"
              />
            </div>
          )}
        </section>
      </section>
    </div>
  );
};

export default TrainingDetails;
