import styles from "./main.module.scss";
import { ReactComponent as Bell } from "../../assets/bell.svg";
import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "../../requests";
import { useAtom } from "jotai";
import { userData } from "../../state";
import { timeAgo } from "../../utils/timeAgo";
import { format, subDays } from "date-fns";

const Main = () => {
  const [user] = useAtom(userData);

  const { data: notificationData } = useQuery({
    queryKey: ["get-notifications"],
    queryFn: () => getNotifications(user?.MemberId!),
  });

  const sortedNotifications = notificationData?.Data?.sort((a, b) => {
    const date1 = new Date(a.CreatedDate);
    const date2 = new Date(b.CreatedDate);
    return date2.getTime() - date1.getTime();
  }) as ActivityHistory[];

  const today = new Date();
  const yesterday = format(subDays(today, 1), "yyyy-MM-dd");

  const todayNotifications = sortedNotifications?.filter((item) => {
    const dateObj = new Date(item.CreatedDate);
    return dateObj.getTime() >= today.getTime();
  });

  const yesterdayNotifications = sortedNotifications?.filter(
    (notification) =>
      format(new Date(notification.CreatedDate), "yyyy-MM-dd") === yesterday
  );

  const lastWeekNotifications = sortedNotifications?.filter(
    (notification) =>
      new Date(notification.CreatedDate) >= subDays(today, 7) &&
      new Date(notification.CreatedDate) < subDays(today, 1)
  );

  const lastMonthNotifications = sortedNotifications?.filter(
    (notification) =>
      new Date(notification.CreatedDate) >= subDays(today, 30) &&
      new Date(notification.CreatedDate) < subDays(today, 7)
  );

  const olderNotifications = sortedNotifications?.filter(
    (notification) => new Date(notification.CreatedDate) < subDays(today, 30)
  );

  // const yesterday = new Date(today);
  // yesterday.setDate(yesterday.getDate() - 1);
  // const lastWeek = new Date(today);
  // lastWeek.setDate(lastWeek.getDate() - 7);
  // const lastMonth = new Date(today);
  // lastMonth.setMonth(lastMonth.getMonth() - 1);

  // const todayNotifications = sortedNotifications?.filter((item) => {
  //   const dateObj = new Date(item.CreatedDate);
  //   return dateObj.getTime() >= today.getTime();
  // });

  // const yesterdayNotifications = sortedNotifications?.filter((item) => {
  //   const dateObj = new Date(item.CreatedDate);
  //   return dateObj >= yesterday && dateObj <= today;
  // });
  // const lastWeekNotifications = sortedNotifications?.filter((item) => {
  //   const dateObj = new Date(item.CreatedDate);
  //   return dateObj >= lastWeek && dateObj <= yesterday;
  // });
  // const lastMonthNotifications = sortedNotifications?.filter((item) => {
  //   const dateObj = new Date(item.CreatedDate);
  //   return dateObj >= lastMonth && dateObj <= lastWeek;
  // });
  // const olderNotifications = sortedNotifications?.filter((item) => {
  //   const dateObj = new Date(item.CreatedDate);
  //   return dateObj < lastMonth;
  // });

  return (
    <div className={styles.notifyContainer}>
      {todayNotifications && todayNotifications.length > 0 ? (
        <h3 className={styles.subheading}>Today</h3>
      ) : null}
      {todayNotifications?.map((item) => (
        <section className={styles.alerts} key={item.ActivityId}>
          <button className={styles.notifications}>
            <Bell className={styles.smallBell} />
            {item.IsActive ? <span></span> : ""}
          </button>
          <div className={styles.alertContent}>
            <b>{item?.ActivityDescription}</b>
            <label className={styles.alertDetails}>
              <span>{timeAgo(item?.CreatedDate)}</span>
              <div className={styles.bulletSeparator}></div>
              {/* <span>{item.category}</span> */}
            </label>
          </div>
        </section>
      ))}
      {yesterdayNotifications && yesterdayNotifications.length > 0 ? (
        <h3 className={styles.subheading}>Yesterday</h3>
      ) : null}
      {yesterdayNotifications?.map((item) => (
        <section className={styles.alerts} key={item.ActivityId}>
          <button className={styles.notifications}>
            <Bell className={styles.smallBell} />
            {item.IsActive ? <span></span> : ""}
          </button>
          <div className={styles.alertContent}>
            <b>{item?.ActivityDescription}</b>
            <label className={styles.alertDetails}>
              <span>{timeAgo(item?.CreatedDate)}</span>
              <div className={styles.bulletSeparator}></div>
              {/* <span>{item.category}</span> */}
            </label>
          </div>
        </section>
      ))}
      {lastWeekNotifications && lastWeekNotifications.length > 0 ? (
        <h3 className={styles.subheading}>Last week</h3>
      ) : null}
      {lastWeekNotifications?.map((item) => (
        <section className={styles.alerts} key={item.ActivityId}>
          <button className={styles.notifications}>
            <Bell className={styles.smallBell} />
            {item.IsActive ? <span></span> : ""}
          </button>
          <div className={styles.alertContent}>
            <b>{item?.ActivityDescription}</b>
            <label className={styles.alertDetails}>
              <span>{timeAgo(item?.CreatedDate)}</span>
              <div className={styles.bulletSeparator}></div>
              {/* <span>{item.category}</span> */}
            </label>
          </div>
        </section>
      ))}
      {lastMonthNotifications && lastMonthNotifications.length > 0 ? (
        <h3 className={styles.subheading}>Last month</h3>
      ) : null}
      {lastMonthNotifications?.map((item) => (
        <section className={styles.alerts} key={item.ActivityId}>
          <button className={styles.notifications}>
            <Bell className={styles.smallBell} />
            {item.IsActive ? <span></span> : ""}
          </button>
          <div className={styles.alertContent}>
            <b>{item?.ActivityDescription}</b>
            <label className={styles.alertDetails}>
              <span>{timeAgo(item?.CreatedDate)}</span>
              <div className={styles.bulletSeparator}></div>
              {/* <span>{item.category}</span> */}
            </label>
          </div>
        </section>
      ))}
      {olderNotifications && olderNotifications.length > 0 ? (
        <h3 className={styles.subheading}>Older</h3>
      ) : null}
      {olderNotifications?.map((item) => (
        <section className={styles.alerts} key={item.ActivityId}>
          <button className={styles.notifications}>
            <Bell className={styles.smallBell} />
            {item.IsActive ? <span></span> : ""}
          </button>
          <div className={styles.alertContent}>
            <b>{item?.ActivityDescription}</b>
            <label className={styles.alertDetails}>
              <span>{timeAgo(item?.CreatedDate)}</span>
              <div className={styles.bulletSeparator}></div>
              {/* <span>{item.category}</span> */}
            </label>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Main;
