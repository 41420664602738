import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  ApplyForTraining1,
  ApplyForTraining2,
} from "../components/training/form";
import {
  EventStep,
  trainingPayment,
  trainingStep,
  userProfile,
} from "../state";
import { FormikProvider, FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { registerEvent } from "../requests";
import { App } from "antd";
import TrainingDetails from "../components/training/trainingDetails";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const TrainingApplication = ({
  step,
  isEventScheduled,
  setIsEventScheduled,
  isScheduling,
}: {
  step: EventStep;
  isEventScheduled: boolean;
  setIsEventScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduling: boolean;
}) => {
  switch (step) {
    case 1:
      return <ApplyForTraining1 />;
    case 2:
      return <ApplyForTraining2 />;
    case 3:
      return (
        <TrainingDetails
          isEventScheduled={isEventScheduled}
          setIsEventScheduled={setIsEventScheduled}
          isScheduling={isScheduling}
        />
      );
    default:
      return <ApplyForTraining1 />;
  }
};

const ApplyForTraining = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const profile = useAtomValue(userProfile);
  const [step, setStep] = useAtom(trainingStep);
  const setTrainingPayment = useSetAtom(trainingPayment);
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const ref = params.get("ref");
  const isFinalStep = step === 3;

  useEffect(() => {
    ref ? setStep(3) : setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerEventMutation = useMutation({
    mutationKey: ["register-event"],
    mutationFn: registerEvent,
  });

  const registerEventHandler = async (values: FormikValues) => {
    let payload: RegisterEventPayload = {
      ParticipantName: profile?.Name,
      ParticipantPhoneNo: profile?.PhoneNumber!,
      ParticipantEmail: profile?.Email!,
      IsAMember: !!profile?.MembershipNo,
      MembershipNo: profile?.MembershipNo!,
      RequirePayment: true,
      HasPaid: false,
      HasAttended: false,
      EventId: parseInt(formik.values.trainingName),
      TrainingscheduleId: parseInt(values.schedule) || 1,
      IsCorporateSponsor: values.memberType === "corporate",
      EvenType: "Training",
      EventCategoryId: parseInt(values.trainingType),
      EventDetailId: parseInt(values.learningEventId),
      BranchId: parseInt(values.branch),
      //StateId: parseInt(values.state),
    };

    if (values.memberType === "corporate") {
      payload.OrganisationName = values.organizationName;
      payload.ContactPersonName = values.contactPersonName;
      payload.ContactEmail = values.email;
      payload.ContactPhoneNo = values.phoneNumber;
      payload.OrganisationAddress = values.organizationAddress;
    }

    try {
      await registerEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const dataAsNumber = data?.Data as number;
          const dataAsObj = data?.Data as ProcessPaymentPayload;
          setTrainingPayment({
            RouteId: dataAsObj?.RouteId,
            MembershipNo: dataAsObj?.MembershipNo,
            Amount: dataAsObj?.Amount,
            Description: dataAsObj?.Description,
            ReturnURL: dataAsObj?.ReturnURL,
            PaymentForm: dataAsObj?.PaymentForm,
            DataId:
              formik.values.memberType === "corporate"
                ? dataAsNumber
                : dataAsObj?.DataId,
            IsCorporateSponsor: formik.values.memberType === "corporate",
          });
          setIsEventScheduled(true);
          if (dataAsObj?.Amount === 0) {
            navigate("/event-application/training");
            notification.success({
              message: "Event registered successfully",
            });
          }
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.message ||
          error?.message,
      });
      if (
        error?.response?.data?.Message?.includes(
          "The member has registered for the event"
        )
      ) {
        const dataAsObj = error?.response?.data?.Data as ProcessPaymentPayload;
        const dataAsNumber = error?.response?.data?.Data as number;
        setTrainingPayment({
          RouteId: dataAsObj?.RouteId,
          MembershipNo: dataAsObj?.MembershipNo,
          Amount: dataAsObj?.Amount,
          Description: dataAsObj?.Description,
          ReturnURL: dataAsObj?.ReturnURL,
          PaymentForm: dataAsObj?.PaymentForm,
          DataId:
            formik.values.memberType === "corporate"
              ? dataAsNumber
              : dataAsObj?.DataId,
          IsCorporateSponsor: formik.values.memberType === "corporate",
        });
        //setIsEventScheduled(true);
      }
    }
  };

  const validationSchema = [
    Yup.object().shape({
      memberType: Yup.string().required("Required"),
      organizationName: Yup.string().when("memberType", {
        is: "corporate",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      contactPersonName: Yup.string().when("memberType", {
        is: "corporate",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      email: Yup.string()
        .email("Invalid email")
        .when("memberType", {
          is: "corporate",
          then: (schema) => schema.required("Required"),
          otherwise: (schema) => schema.notRequired(),
        }),
      phoneNumber: Yup.string().when("memberType", {
        is: "corporate",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      organizationAddress: Yup.string().when("memberType", {
        is: "corporate",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    Yup.object().shape({
      trainingType: Yup.string().required("Required"),
      trainingName: Yup.string().required("Required"),
      learningEventId: Yup.number(),
      amount: Yup.number(),
    }),
    Yup.object().shape({
      schedule: Yup.string().required("Required"),
      //state: Yup.string().required("Required"),
      branch: Yup.string().required("Required"),
    }),
  ];

  const formik = useFormik<FormikValues>({
    initialValues: {
      memberType: "",
      trainingType: "",
      trainingName: "",
      organizationName: "",
      contactPersonName: "",
      email: "",
      phoneNumber: "",
      organizationAddress: "",
      learningEventId: 0,
      amount: 0,
      schedule: "",
      //state: "",
      branch: "",
    },
    onSubmit: (values) => {
      if (isFinalStep) {
        registerEventHandler(values);
      } else {
        setStep((prev) => prev + 1);
      }
    },
    validationSchema: validationSchema[step - 1],
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TrainingApplication
          step={step}
          isEventScheduled={isEventScheduled}
          setIsEventScheduled={setIsEventScheduled}
          isScheduling={registerEventMutation.isPending}
        />
      </form>
    </FormikProvider>
  );
};

export default ApplyForTraining;
