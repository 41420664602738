import { ReactComponent as BackArrow } from "../../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../../assets/clock.svg";
import { ReactComponent as CyberPay } from "../../../assets/cyberpay-logo.svg";
import { ReactComponent as Info } from "../../../assets/info.svg";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import styles from "../styles.module.scss";
import Points from "../../../assets/points.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getConferenceSessions,
  getEventById,
  getMaterials,
  getProfile,
  getRegisteredNonMemberOrgEvents,
  getStateByCountryId,
  registerNonMemberOrgEvent,
} from "../../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { getDateWithoutTime } from "../../../utils/formatDate";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import { useAtom } from "jotai";
import {
  Nominee,
  corporateDataId,
  nonMemberData,
  tempNominees,
} from "../../../state";
import Input from "../../../custom/input/input";
import * as Yup from "yup";
import Select from "../../../custom/select/select";
import Table, { ColumnsType } from "antd/es/table";
import MessageBox from "../../corporateMembers/eventApplication/conference/messageBox";
import { errorMessage } from "../../../utils/errorMessage";
//import { joinStrings } from "../../../utils/joinStrings";

const ApplyForNonMemberConferenceAsOrg = () => {
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const [nonMemberId, setNonMemberId] = useAtom(nonMemberData);
  const [nominees, setNominees] = useAtom(tempNominees);
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const [dataId, setDataId] = useAtom(corporateDataId);
  const navigate = useNavigate();
  const learningEventId = params.get("learningEventId");
  // const [morningSession, setMorningSession] = useState<string[]>([]);
  // const [afternoonSession, setAfternoonSession] = useState<string[]>([]);

  useEffect(() => {
    setNominees([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<Nominee> = [
    {
      title: "Name",
      dataIndex: "ParticipantName",
      key: "ParticipantName",
    },
    {
      title: "Phone Number",
      dataIndex: "ParticipantPhoneNo",
      key: "ParticipantPhoneNo",
    },
    {
      title: "Email Address",
      dataIndex: "ParticipantEmail",
      key: "ParticipantEmail",
    },
    {
      title: "Action",
      render: (_, record) => (
        <button
          onClick={() => removeNominee(record)}
          className={styles.removeBtn}
        >
          Remove
        </button>
      ),
    },
  ];

  const removeNominee = (nominee: Nominee) => {
    setNominees((prev) =>
      prev.filter((x) => x.ParticipantEmail !== nominee.ParticipantEmail)
    );
  };

  const validationSchema = Yup.object().shape({
    nomineeType: Yup.string().required("required"),
    name: Yup.string().when("nomineeType", {
      is: "nonMember",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    phoneNumber: Yup.string().when("nomineeType", {
      is: "nonMember",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    email: Yup.string()
      .email("Invalid email")
      .when("nomineeType", {
        is: "nonMember",
        then: (schema) => schema.required("required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    membershipNo: Yup.string().when("nomineeType", {
      is: "member",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    //session: Yup.array().min(1).max(2).required("Session is required"),
    state: Yup.string().required("Required"),
    //branch: Yup.string().required("Required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      nomineeType: "",
      name: "",
      phoneNumber: "",
      email: "",
      membershipNo: "",
      //session: [],
      state: "",
      //branch: "",
    },
    onSubmit: (values) => {
      registerEventHandler(values);
    },
    validationSchema: validationSchema,
  });

  const registerEventMutation = useMutation({
    mutationKey: ["register-event"],
    mutationFn: registerNonMemberOrgEvent,
  });
  const verifyMembershipNo = useQuery({
    queryKey: ["verify-membership-no"],
    queryFn: () => getProfile(formik.values.membershipNo),
    enabled: false,
  });
  const registeredEvents = useQuery({
    queryKey: ["get-registered-events"],
    queryFn: () =>
      getRegisteredNonMemberOrgEvents(nonMemberId?.Email!, "Conference"),
  });
  const conferenceInfo = useQuery({
    queryKey: ["get-conference-details"],
    queryFn: () => getEventById(parseInt(learningEventId!)),
    enabled: !!learningEventId,
  });
  const materials = useQuery({
    queryKey: ["get-conference-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const sessions = useQuery({
    queryKey: ["get-conference-sessions"],
    queryFn: () => getConferenceSessions(learningEventId!),
  });
  const states = useQuery({
    queryKey: ["get-states"],
    queryFn: () => getStateByCountryId(1),
  });

  const unpaidEvents = registeredEvents?.data?.Data?.filter(
    (x) => x.HasPaid === false
  ) as RegisteredNonMemberEvent[];
  const materialData = materials?.data?.Data as Material[];
  const conferenceInfoData = conferenceInfo?.data?.Data as EventById;
  const sessionData = sessions?.data?.Data as Session[];
  const stateData = states?.data as State[];

  const stateOptions = stateData?.map((state) => (
    <option key={state.StateId} value={state.StateId}>
      {state.StateName}
    </option>
  ));

  // const hasMorningSessions = sessionData?.some(
  //   (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "A"
  // );
  // const hasAfternoonSessions = sessionData?.some(
  //   (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "B"
  // );

  // const hasBothSessions = hasMorningSessions && hasAfternoonSessions;

  // const handleSubmit = () => {
  //   if (hasBothSessions) {
  //     if (formik.values?.session?.length !== 2) {
  //       notification.warning({
  //         message: "Please select both session groups",
  //       });
  //     } else {
  //       formik.submitForm();
  //     }
  //   } else {
  //     formik.submitForm();
  //   }
  // };

  const isMembershipNoInvalid =
    (verifyMembershipNo?.error as AxiosError as any)?.response?.data
      ?.Message === "Invalid membership number";

  const handleAddNominee = async () => {
    const isFormValid =
      formik.values.email && formik.values.phoneNumber && formik.values.name;
    if (formik.values.nomineeType === "member") {
      await verifyMembershipNo.refetch().then((responses) => {
        const nomineeData = responses?.data as Profile;

        const row: Nominee = {
          ParticipantName: nomineeData?.Name,
          ParticipantPhoneNo: nomineeData?.PhoneNumber,
          ParticipantEmail: nomineeData?.Email,
          IsAMember: true,
          MembershipNo: nomineeData?.MembershipNo,
        };

        const hasNominee = nominees?.some(
          (x) => x.ParticipantEmail === row.ParticipantEmail
        );
        const isNumInvalid = (
          responses?.error as AxiosError as any
        )?.response?.data?.Message?.includes("Invalid");

        if (!isNumInvalid) {
          if (!hasNominee) {
            setNominees((prev) => [...prev, row]);
          } else {
            notification.warning({
              message: "Attendee already added",
            });
          }
        }
      });
    } else {
      const hasNominee = nominees?.some(
        (x) => x.ParticipantEmail === formik.values.email
      );
      if (hasNominee) {
        notification.warning({
          message: "Attendee already added",
        });
      } else if (!isFormValid) {
        notification.warning({
          message: "Please enter complete attendee details",
        });
      } else {
        setNominees((prev) => [
          ...prev,
          {
            ParticipantName: formik.values.name,
            ParticipantPhoneNo: formik.values.phoneNumber,
            ParticipantEmail: formik.values.email,
            IsAMember: false,
          },
        ]);
      }
    }
  };

  if (conferenceInfo.isLoading) return <Spin />;
  if (conferenceInfo.isError)
    return <div>{(conferenceInfo.error as AxiosError)?.message}</div>;

  const registerEventHandler = async (values: FormikValues) => {
    const payload: RegisterCorporateEventPayload = {
      HasPaid: false,
      HasAttended: false,
      EventId: conferenceInfoData?.EventNameId,
      EventCategoryId: conferenceInfoData?.EventCategoryId,
      EventDetailId: conferenceInfoData?.LearningEventId,
      //ConferenceSessionId: joinStrings(values.session),
      IsCorporateSponsor: true,
      EvenType: "Conference",
      Nomminees: [...nominees],
      CorporateMemberId: nonMemberId?.Id!,
      StateId: parseInt(values.state) || 0,
    };

    try {
      await registerEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          setDataId(data.Data);
          setIsEventScheduled(true);
          if (!conferenceInfoData?.IsPaymentRequired) {
            notification.success({
              message: "Success",
              description:
                "Event registered successfully. Please check your mail for your entry badge.",
            });
          }
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const signOutNonMember = () => {
    setNonMemberId(null);
    window.localStorage.removeItem("non-member-data");
    navigate("/non-member-events/conference");
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <p>
          You are registering as <b>{nonMemberId?.Name}</b>. Not this
          organization?{" "}
          <button onClick={signOutNonMember} className={styles.underline}>
            Log out
          </button>
        </p>
        <br />
        <br />
        {unpaidEvents &&
          unpaidEvents.length > 0 &&
          unpaidEvents?.map((event) => (
            <div key={event.Id} className={styles.infoContainer}>
              <div className={classNames("spaceBetween")}>
                <p>
                  <span>
                    <Info />
                  </span>
                  You are yet to complete payment for <b>{event.EventName}</b>
                </p>
                <Link
                  to={`/non-member-events/invoice?eventType=Conference&email=${formik.values.email}&id=${event.UniqueId}&eventDetailId=${event.EventDetailId}`}
                  className={styles.underline}
                >
                  View Invoice
                </Link>
              </div>
            </div>
          ))}
        <br />
        <section className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Conference Details</h3>
        </section>
        <section className={styles.formContainer}>
          {nominees.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={nominees}
                pagination={false}
                rowKey={(item) => item.ParticipantEmail}
                scroll={{ x: true }}
              />
              <br />
            </>
          ) : null}
          <h4>Add Attendee</h4>
          <div className={styles.radioWrapper}>
            <div className={styles.radioNormal}>
              <Field
                type="radio"
                id="member"
                name="nomineeType"
                value="member"
              />
              <label htmlFor="member">CIPM Member</label>
            </div>
            <div className={styles.radioNormal}>
              <Field
                type="radio"
                id="nonMember"
                name="nomineeType"
                value="nonMember"
              />
              <label htmlFor="nonMember">Non-CIPM Member</label>
            </div>
          </div>
          <ErrorMessage name="nomineeType">
            {(err) => <div className={styles.error}>*{err}</div>}
          </ErrorMessage>
          <br />
          {formik.values.nomineeType === "nonMember" && (
            <>
              <Field as={Input} name="name" label="Name" placeholder="Name" />
              <div className={styles.fields}>
                <Field
                  as={Input}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone number"
                />
                <Field
                  as={Input}
                  name="email"
                  label="Email Address"
                  placeholder="Email address"
                />
              </div>
            </>
          )}
          {formik.values.nomineeType === "member" && (
            <Field
              as={Input}
              name="membershipNo"
              label="Membership Number"
              placeholder="Membership number"
            />
          )}
          {isMembershipNoInvalid && (
            <MessageBox message="Invalid membership number" />
          )}
          <br />
          {formik.values.nomineeType && (
            <div className={styles.buttonGroup}>
              <Button
                type="button"
                onClick={handleAddNominee}
                className={styles.editButton}
                isLoading={verifyMembershipNo.isFetching}
                disabled={verifyMembershipNo.isFetching}
                text="Add Attendee"
              />
            </div>
          )}
        </section>
        <section className={styles.detailsContainer}>
          <div className={styles.detailsContent}>
            <h2>
              {conferenceInfoData?.EventName}
              <span>
                <img src={Points} alt="points" />
              </span>
            </h2>
            <br />
            <div className={styles.line}></div>
            <br />
            {/* <h3>Venue</h3>
            <p>{conferenceInfoData?.Venue}</p>
            <br /> */}
            <h3>Business Case</h3>
            <p>{conferenceInfoData?.BusinessCase || "N/A"}</p>
            <br />
            <h3>Who to Participate</h3>
            <ul>
              <li>{conferenceInfoData?.TargetMember || "N/A"}</li>
            </ul>
            <br />
            <h3>Core Training Objectives</h3>
            <p>{conferenceInfoData?.CoreObjective || "N/A"}</p>
            <br />
            <h3>Talking Key Points</h3>
            <p>{conferenceInfoData?.DiscussionPoint || "N/A"}</p>
            <br />
            {materialData && materialData?.length > 0 ? (
              <h3>Training Schedule Material</h3>
            ) : null}
            <br />
            <div className={styles.list}>
              {materialData?.map((item, index) => (
                <div key={index} className={styles.files}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
            </div>
            <br />
            <h4>Plenary Session Details</h4>
            <label>
              <Info /> Attendance is compulsory
            </label>
            {sessionData
              ?.filter((x) => x.IsTecnicalSession === false)
              .map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                return (
                  <section key={item.Id} className={styles.eventSchedule}>
                    <div className={styles.smallGap}>
                      <Clock />
                      <div className={styles.sessionDetails}>
                        <small>{item.SessionType?.toUpperCase()}</small>
                        <h4>{item.SessionName}</h4>
                        <div className={styles.sessionDescription}>
                          Synopsis: {item.Synopsis || "N/A"}
                        </div>
                        <p>Speaker: {item.Speaker}</p>
                        <div>
                          {startDate} &#8226; {item.SessionStartTime} -{" "}
                          {item.SessionEndTime}
                        </div>
                      </div>
                    </div>
                  </section>
                );
              })}
          </div>
          {isEventScheduled ? (
            <div className={styles.list}>
              {conferenceInfoData?.IsPaymentRequired && (
                <section className={styles.resource}>
                  <div>
                    <h3>Get Payment Breakdown</h3>
                    <p>Cost details of your preferred event listed here.</p>
                    <br />
                    <Button
                      onClick={() =>
                        navigate(
                          `/non-member-events/invoice?eventType=Conference&email=${formik.values.email}&id=${dataId}&eventDetailId=${conferenceInfoData?.LearningEventId}`
                        )
                      }
                      text="Generate Invoice"
                      iconAfter={<BackArrow />}
                    />
                  </div>
                  <CyberPay />
                </section>
              )}
            </div>
          ) : (
            <div className={styles.scheduleContainer}>
              {/* {hasMorningSessions && <h3>Select technical session: Group 1</h3>}
              {sessionData
                ?.filter(
                  (x) =>
                    x.IsTecnicalSession &&
                    x.TechnicalGroup?.toUpperCase() === "A"
                )
                ?.map((item) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  return (
                    <section key={item.Id} className={styles.eventSchedule}>
                      <div className={styles.smallGap}>
                        <Clock />
                        <div className={styles.sessionDetails}>
                          <small>{item.SessionType?.toUpperCase()}</small>
                          <h4>{item.SessionName}</h4>
                          <p className={styles.sessionDescription}>
                            {item.Synopsis || "N/A"}
                          </p>
                          <p>Speaker: {item.Speaker}</p>
                          <div>
                            {startDate} &#8226; {item.SessionStartTime} -{" "}
                            {item.SessionEndTime}
                          </div>
                        </div>
                      </div>
                      <input
                        type="radio"
                        name="session1"
                        onChange={() => {
                          setMorningSession((prev) => [...prev, `${item.Id}`]);
                          formik.setFieldValue("session", [
                            `${item.Id}`,
                            ...afternoonSession,
                          ]);
                        }}
                      />
                    </section>
                  );
                })}
              <br />
              {hasAfternoonSessions && (
                <h3>Select technical session: Group 2</h3>
              )}
              {sessionData
                ?.filter(
                  (x) =>
                    x.IsTecnicalSession &&
                    x.TechnicalGroup?.toUpperCase() === "B"
                )
                ?.map((item) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  return (
                    <section key={item.Id} className={styles.eventSchedule}>
                      <div className={styles.smallGap}>
                        <Clock />
                        <div className={styles.sessionDetails}>
                          <small>{item.SessionType?.toUpperCase()}</small>
                          <h4>{item.SessionName}</h4>
                          <p className={styles.sessionDescription}>
                            {item.Synopsis || "N/A"}
                          </p>
                          <p>Speaker: {item.Speaker}</p>
                          <div>
                            {startDate} &#8226; {item.SessionStartTime} -{" "}
                            {item.SessionEndTime}
                          </div>
                        </div>
                      </div>
                      <input
                        type="radio"
                        name="session2"
                        onChange={() => {
                          setAfternoonSession((prev) => [
                            ...prev,
                            `${item.Id}`,
                          ]);
                          formik.setFieldValue("session", [
                            ...morningSession,
                            `${item.Id}`,
                          ]);
                        }}
                      />
                    </section>
                  );
                })}
              <br /> */}
              <Field
                name="state"
                as={Select}
                label="Location of head office"
                placeholder="Select location"
                options={stateOptions}
              />
              <Button
                isLoading={registerEventMutation.isPending}
                disabled={registerEventMutation.isPending}
                type="submit"
                text={
                  conferenceInfoData?.IsPaymentRequired
                    ? "Proceed to Payment"
                    : "Register"
                }
              />
            </div>
          )}
        </section>
      </form>
    </FormikProvider>
  );
};

export default ApplyForNonMemberConferenceAsOrg;
