import { useState } from 'react';
import { Modal, App } from 'antd';
import styles from './styles.module.scss';
import { Field, FormikValues, useFormik, FormikProvider, FormikErrors } from 'formik';
import Select from '../../../../custom/select/select';
import Button from '../../../../custom/button/button';
import Upload from '../../../../custom/upload/upload';
import Input from '../../../../custom/input/input';
import * as yup from 'yup';
import { useAtomValue } from 'jotai';
import { userData } from '../../../../state';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { verifyName } from '../../../../requests/index';

interface Props {
	open: boolean;
	onClose: () => void;
	fullName: string;
	previousFullName: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

const Marriage = 'Marriage';
const Divorce = 'Divorce';
const Others = 'Others';

const options = [{ reason: Marriage }, { reason: Divorce }, { reason: Others }];

const ChangeOfNameModal = ({ open, onClose, fullName, previousFullName, setFieldValue }: Props) => {
	const { notification } = App.useApp();
	const user = useAtomValue(userData);
	const memberId = user?.MemberId as string;
	const [file, setFile] = useState<File | null>(null);
	const [changeOfNameDocumentFile, setChangeOfNameDocumentFile] = useState<File | null>(null);
	const queryClient = useQueryClient();

	const changeNameMutation = useMutation({
		mutationKey: ['update-profile-fullName'],
		mutationFn: (formData: FormData) => verifyName(formData),
	});

	const validationSchema = yup.object({
		reason: yup.string().required('reason is required'),
		documentName: yup.string().when('reason', {
			is: (value: string) => value === Others,
			then: schema => schema.required('document name is required'),
		}),
	});

	const formik = useFormik<FormikValues>({
		initialValues: {
			reason: '',
			documentName: '',
		},
		validationSchema,
		onSubmit: (values, { resetForm }) => {
			if (!isFileUploadedWhenReasonIsNotDivorce(values.reason, file, changeOfNameDocumentFile)) return;

			const formData = new FormData();

			formData.append('Name', fullName);
			formData.append('MembershipNo', memberId);
			formData.append('Reason', values?.reason);

			if (values.reason === Others) {
				formData.append('DocumentTitle', values?.documentName);
			}

			if (values.reason === Marriage) {
				formData.append('UploadNewspaper', changeOfNameDocumentFile!);
			}

			if (isAppendFile(values.reason)) {
				formData.append(getDocumentName(values.reason), file!);
			}

			changeNameMutation.mutate(formData, {
				onSuccess: response => {
					notification.success({ message: response });
					setFieldValue('fullName', previousFullName);
					queryClient.refetchQueries({ queryKey: ['get-profile'] });

					resetForm();
					setFile(null);
					setChangeOfNameDocumentFile(null);
					onClose();
				},
				onError: (error: any) => {
					const errorMessage = error?.response?.data?.Message
						? error?.response?.data?.Message
						: error?.response?.data?.errors
						? error?.response?.data?.errors['MembershipNo' || 'Name' || 'Reason' || 'DocumentTitle']?.[0]
						: error?.message;

					notification.error({ message: errorMessage });
				},
			});
		},
	});

	const reasonForChangeOfName = formik.values.reason;

	const isFileUploadedWhenReasonIsNotDivorce = (
		reasonForChangeOfName: string,
		file: File | null,
		documentForChangeOfName: File | null
	) => {
		if (reasonForChangeOfName === Marriage && file === null) return false;
		if (reasonForChangeOfName === Marriage && documentForChangeOfName === null) return false;
		if (reasonForChangeOfName === Others && file === null) return false;
		return true;
	};

	const isAppendFile = (reasonForChangeOfName: string) => {
		return [Marriage, Others].indexOf(reasonForChangeOfName) !== -1;
	};

	const getDocumentName = (reasonForChangeOfName: string) => {
		return reasonForChangeOfName === Marriage ? 'UploadChangeofName' : 'UploadAffidavit';
	};

	const changeOfNameOptions = options.map(option => (
		<option key={option.reason} value={option.reason}>
			{option.reason}
		</option>
	));

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) setFile(files[0]);
	};

	const handleChangeOfDocumentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) setChangeOfNameDocumentFile(files[0]);
	};

	return (
		<FormikProvider value={formik}>
			<Modal open={open} footer={null} title='Change of Name' onCancel={onClose}>
				<section className={styles.section}>
					<form onSubmit={formik.handleSubmit} className={styles.form}>
						<section className={styles.field}>
							<Field
								as={Select}
								name='reason'
								label='Reason '
								placeholder='Select'
								className={styles.input}
								options={changeOfNameOptions}
							/>
						</section>

						{reasonForChangeOfName === Others && (
							<section className={styles.field}>
								<Field
									as={Input}
									name='documentName'
									label='Specify Document to Upload'
									placeholder='Eg Document'
									className={styles.input}
								/>
							</section>
						)}

						{[Marriage, Others].indexOf(reasonForChangeOfName) !== -1 && (
							<section className={styles.field}>
								<Upload
									label='Upload Court Affidavit of Change of Name *'
									accept='img, pdf'
									allowedFormats={['img', 'pdf']}
									onChange={handleFileChange}
									fileName={file?.name}
									fileSize={file?.size}
								/>
								{!file && (
									<p className={styles.error}>
										<span>&#42;</span> affidavit is required
									</p>
								)}
							</section>
						)}
						{reasonForChangeOfName === Marriage && (
							<section className={styles.field}>
								<Upload
									label='Change of Name Document *'
									accept='img, pdf'
									allowedFormats={['img', 'pdf']}
									onChange={handleChangeOfDocumentFile}
									fileName={changeOfNameDocumentFile?.name}
									fileSize={changeOfNameDocumentFile?.size}
								/>
								{!changeOfNameDocumentFile && (
									<p className={styles.error}>
										<span>&#42;</span> changeofNameDocument is required
									</p>
								)}
							</section>
						)}
					</form>
					<section className={styles.btnWrapper}>
						<Button text='Cancel' className={styles.btnCancel} onClick={onClose} type='button' />
						<Button
							text={'Save'}
							onClick={() => formik.handleSubmit()}
							disabled={false}
							isLoading={changeNameMutation.isPending}
							type='submit'
						/>
					</section>
				</section>
			</Modal>
		</FormikProvider>
	);
};

export default ChangeOfNameModal;
