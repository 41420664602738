import styles from "./main.module.scss";
import points from "../../assets/points.png";
import Table, { ColumnsType } from "antd/es/table";
import Pagination from "../../custom/pagination/pagination";
import { Spin } from "antd";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getNotifications } from "../../requests";
import { useAtomValue } from "jotai";
import { userProfile } from "../../state";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../utils/formatDate";

const columns: ColumnsType<ActivityHistory> = [
  {
    title: "Date",
    dataIndex: "CreatedDate",
    key: "id",
    render: (_, { CreatedDate }) => getDateWithoutTime(CreatedDate!),
  },
  {
    title: "Purpose",
    dataIndex: "Purpose",
    key: "id",
    render: (_, { Purpose }) => Purpose || "N/A",
  },
  {
    title: "Reviewed Comment",
    dataIndex: "ActivityDescription",
    key: "id",
  },
];

const Main = () => {
  const profile = useAtomValue(userProfile);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 10;
  const handlePagination = (pageNumber: number) => setPageNumber(pageNumber);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-activity-history", pageNumber, PAGE_SIZE],
    queryFn: () =>
      getNotifications(profile?.MembershipNo, pageNumber, PAGE_SIZE),
  });

  const activity = data?.Data as ActivityHistory[];
  const sortedActivities = activity?.sort((a, b) => {
    const date1 = new Date(a.CreatedDate);
    const date2 = new Date(b.CreatedDate);

    return date2.getTime() - date1.getTime();
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={styles.heading}>
        <h3>Recent Activities</h3>
      </section>

      <section className={styles.tableContainer}>
        <div className={styles.tableHeading}>
          <img src={points} alt="points" />
          <div>
            <div className={styles.amount}>{profile?.UpgradeScore}</div>
            <label>TOTAL POINTS</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={sortedActivities?.slice(0, 10) || []}
          scroll={{ x: true }}
          pagination={false}
          rootClassName={styles.table}
          rowKey={(record) => record.ActivityId}
        />
        <Pagination
          pageNumber={pageNumber}
          itemsPerPage={PAGE_SIZE}
          totalSize={data?.TotalSize || 0}
          setPageNumber={handlePagination}
        />
      </section>
    </div>
  );
};

export default Main;
