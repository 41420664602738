import styles from "./main.module.scss";
import woman from "../../assets/woman.png";
import success from "../../assets/success.png";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import { Modal } from "antd";
import { useState } from "react";
import Textarea from "../../custom/textarea/textarea";
import Checkbox from "../../custom/checkbox/checkbox";
import { useAtomValue } from "jotai";
import { userProfile } from "../../state";

const Main = () => {
  const profile = useAtomValue(userProfile);
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const formik = useFormik<FormikValues>({
    initialValues: {
      organizationName: "",
      comment: "",
      isRecommended: false,
    },
    onSubmit: () => {
      openModal();
    },
  });

  return (
    <div>
      <h1 className={styles.formTitle}>Sponsor Member</h1>
      <section className={styles.sponsorshipForm}>
        <div className={styles.profile}>
          <img src={woman} alt="woman" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.fields}>
              <Field
                as={Input}
                name="organizationName"
                label="Name of organization"
                placeholder="Eg. ABC Company Ltd"
              />
              <Field
                as={Textarea}
                name="comment"
                label="Comment"
                placeholder="Enter comment"
              />
            </div>
            <hr className={styles.divider} />
            <section className={styles.checkGroup}>
              <label>Do you want to recommend this organization?</label>
              <div className={styles.checkboxes}>
                <Field name="isRecommended" as={Checkbox} label="Yes" />
                <Field name="isRecommended" as={Checkbox} label="No" />
              </div>
            </section>
            <div className={styles.ctaGroup}>
              <Button
                className={styles.submitBtn}
                type="submit"
                text="Submit"
              />
            </div>
          </form>
        </FormikProvider>
      </section>
      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Sponsorship success!</h2>
          <p>
            You have successfully sponsored this organisation,{" "}
            <b>Cyberspace Limited</b>
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
