import classNames from "classnames";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { resetPasswordNonMember } from "../../requests";
import { App } from "antd";
import { errorMessage } from "../../utils/errorMessage";

const NonMemberResetPassword = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const [params] = useSearchParams();
  const resetToken = params.get("token");
  const email = params.get("email");

  const resetPasswordMutation = useMutation({
    mutationKey: ["non-member-reset-password"],
    mutationFn: resetPasswordNonMember,
  });
  const resetPasswordHandler = async (values: FormikValues) => {
    const payload: ResetPasswordPayload = {
      Password: values?.newPassword,
      ConfirmPassword: values?.confirmPassword,
      Email: email!,
      Token: resetToken!,
    };
    try {
      await resetPasswordMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: data?.Message,
          });
          navigate("/non-member-events");
        },
      });
    } catch (error: any) {
      notification.error({
        message: errorMessage(error),
      });
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      resetPasswordHandler(values);
    },
  });

  return (
    <div className={styles.formContainer}>
      <h3 className={styles.greenTitle}>Reset Password</h3>
      <br />
      <section>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.fields}>
              <Field
                as={Input}
                type="password"
                name="newPassword"
                label="New Password"
                placeholder="Enter password"
              />
              <Field
                as={Input}
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Enter password"
              />
            </div>
            <br />
            <div className={styles.buttonGroup}>
              <Button
                text="Cancel"
                type="button"
                onClick={() => navigate("/non-member-events")}
                className={classNames("cancelBtn")}
              />
              <Button
                text="Submit"
                type="submit"
                isLoading={resetPasswordMutation.isPending}
                disabled={resetPasswordMutation.isPending}
              />
            </div>
          </form>
        </FormikProvider>
      </section>
    </div>
  );
};

export default NonMemberResetPassword;
