import { useState } from "react";
import { Modal, App } from "antd";
import styles from "./styles.module.scss";
import { FormikValues, useFormik, FormikProvider, FormikErrors } from "formik";
import Button from "../../../../custom/button/button";
import Upload from "../../../../custom/upload/upload";
import { useAtomValue } from "jotai";
import { userData } from "../../../../state";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { verifyMaritalStatus } from "../../../../requests/index";

interface Props {
  open: boolean;
  onClose: () => void;
  maritalStatus: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

const ChangeOfMaritalStatusModal = ({
  open,
  onClose,
  maritalStatus,
  setFieldValue,
}: Props) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const memberId = user?.MemberId as string;
  const [affidavit, setAffidavit] = useState<File | null>(null);
  const [changeOfNameDocument, setChangeOfNameDocument] = useState<File | null>(
    null
  );
  const [marriageCert, setMarriageCert] = useState<File | null>(null);
  const queryClient = useQueryClient();

  const changeMaritalStatusMutation = useMutation({
    mutationKey: ["update-profile-maritalStatus"],
    mutationFn: (formData: FormData) => verifyMaritalStatus(formData),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {},
    onSubmit: () => {
      if (!isSuppliedAllTheRequiredDocument(marriageCert, changeOfNameDocument))
        return;

      const formData = new FormData();

      formData.append("MembershipNo", memberId);
      formData.append("UploadMarriageCert", marriageCert!);
      formData.append("UploadNewspaper", changeOfNameDocument!);
      formData.append("MaritalStatus", maritalStatus);

      if (affidavit) {
        formData.append("UploadAffidavit", affidavit!);
      }

      changeMaritalStatusMutation.mutate(formData, {
        onSuccess: (response) => {
          notification.success({ message: response });
          setFieldValue("maritalStatus", maritalStatus);
          queryClient.refetchQueries({ queryKey: ["get-profile"] });

          setAffidavit(null);
          setChangeOfNameDocument(null);
          setMarriageCert(null);
          onClose();
        },
        onError: (error: any) => {
          const errorMessage = error?.response?.data?.Message
            ? error?.response?.data?.Message
            : error?.response?.data?.errors
            ? error?.response?.data?.errors[
                "MembershipNo" || "Name" || "Reason" || "DocumentTitle"
              ]?.[0]
            : error?.message;

          notification.error({ message: errorMessage });
        },
      });
    },
  });

  const isSuppliedAllTheRequiredDocument = (
    marriageCert: File | null,
    changeOfDocument: File | null
  ): boolean => {
    if (!marriageCert) return false;
    if (!changeOfDocument) return false;

    return true;
  };

  const handleChangeOfAffidavit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setAffidavit(files[0]);
  };

  const handleChangeOfDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setChangeOfNameDocument(files[0]);
  };

  const handleChangeOfMarriageCert = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;
    if (files) setMarriageCert(files[0]);
  };

  return (
    <FormikProvider value={formik}>
      <Modal
        open={open}
        footer={null}
        title="Change of Marital Status"
        onCancel={onClose}
      >
        <section className={styles.section}>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <section className={styles.field}>
              <Upload
                label="Upload Marriage Certificate"
                accept="img, pdf"
                allowedFormats={["img", "pdf"]}
                onChange={handleChangeOfMarriageCert}
                fileName={marriageCert?.name}
                fileSize={marriageCert?.size}
              />
              {!marriageCert && (
                <p className={styles.error}>
                  <span>&#42;</span> marriage certificate is required
                </p>
              )}
            </section>
            <section className={styles.field}>
              <Upload
                label="Change of Name Document *"
                accept="img, pdf"
                allowedFormats={["img", "pdf"]}
                onChange={handleChangeOfDocument}
                fileName={changeOfNameDocument?.name}
                fileSize={changeOfNameDocument?.size}
              />
              {!changeOfNameDocument && (
                <p className={styles.error}>
                  <span>&#42;</span> change of Name Document is required
                </p>
              )}
            </section>

            <section className={styles.field}>
              <Upload
                label="Upload Court Affidavit"
                accept="img, pdf"
                allowedFormats={["img", "pdf"]}
                onChange={handleChangeOfAffidavit}
                fileName={affidavit?.name}
                fileSize={affidavit?.size}
              />
            </section>
          </form>
          <section className={styles.btnWrapper}>
            <Button
              text="Cancel"
              className={styles.btnCancel}
              onClick={onClose}
              type="button"
            />
            <Button
              text="Save"
              onClick={() => formik.handleSubmit()}
              disabled={changeMaritalStatusMutation.isPending}
              isLoading={changeMaritalStatusMutation.isPending}
              type="submit"
            />
          </section>
        </section>
      </Modal>
    </FormikProvider>
  );
};

export default ChangeOfMaritalStatusModal;
