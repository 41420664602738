import Button from "../../custom/button/button";
import certificate from "../../assets/certificate.svg";
import styles from "./main.module.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  MemberType,
  addWorkActivity,
  downloadDocument,
  getDocuments,
  getScoreTypes,
} from "../../requests";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { App, Empty, Modal, Spin } from "antd";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../utils/formatDate";
import classNames from "classnames";
import { useState } from "react";
import { errorMessage } from "../../utils/errorMessage";
import * as Yup from "yup";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import success from "../../assets/success.png";
import Textarea from "../../custom/textarea/textarea";
import Upload from "../../custom/upload/upload";
import Input from "../../custom/input/input";
import Select from "../../custom/select/select";

const Main = () => {
  const queryClient = useQueryClient();
  const { notification } = App.useApp();
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [currentId, setCurrentId] = useState<number>(0);
  const user = useAtomValue(userData);
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-documents"],
    queryFn: () => getDocuments(user?.MemberId!),
  });
  const documents = data?.Data as Document[];

  const scoreTypes = useQuery({
    queryKey: ["get-score-types"],
    queryFn: () => getScoreTypes(user?.MembershipTypeId as number),
  });

  const getDocumentMutation = useMutation({
    mutationKey: ["get-document"],
    mutationFn: downloadDocument,
  });

  const addDocumentMutation = useMutation({
    mutationKey: ["add-document"],
    mutationFn: (formData: FormData) => addWorkActivity(formData),
  });

  const scoreTypeData = scoreTypes?.data?.Data as ScoreType[];
  const scoreTypeOptions = scoreTypeData?.map((score) => (
    <option key={score.ScoreTypeId} value={score.ScoreTypeId}>
      {score.Name}
    </option>
  ));

  const addActivityHandler = async (values: FormikValues) => {
    try {
      const formData = new FormData();
      formData.append("NameOfTraining", values.eventName);
      formData.append("Comments", values.comment);
      if (file) formData.append("UploadDocument", file);
      formData.append("MembershipNo", user?.MemberId as string);
      formData.append("DocActivityId", `${values.eventType}`);

      await addDocumentMutation.mutateAsync(formData, {
        onSuccess: () => {
          //notification.success({ message: "Document upload successfully" });
          setOpenModal(false);
          setOpenSuccessModal(true);
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handleDownload = async (id: number) => {
    try {
      await getDocumentMutation.mutateAsync(id, {
        onSuccess: (data) => {
          const url = window.URL.createObjectURL(data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Document.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
      });
    } catch (error: any) {
      let response = await error?.response?.data.text();
      const actualObj = JSON.parse(response);
      notification.error({
        message: "Error",
        description: actualObj?.Message || errorMessage(error),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string().required("Required"),
    eventType: Yup.number().required("Required"),
    comment: Yup.string().required("Required"),
    file: Yup.mixed().required("Required"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      eventName: "",
      eventType: 0,
      comment: "",
      file: "",
    },
    onSubmit: (values) => {
      addActivityHandler(values);
    },
    validationSchema,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      setFile(file[0]);
      formik.setFieldValue("file", file[0]);
    }
  };

  const cancelAddDocument = () => {
    queryClient.cancelQueries({ queryKey: ["add-document"] });
    formik.resetForm();
    setFile(null);
    setOpenModal(false);
  };

  if (isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <FormikProvider value={formik}>
      <section className={classNames("spaceBetween")}>
        {documents?.length > 0 && <h3>Documents</h3>}
        {!isCorporate && (
          <Button onClick={() => setOpenModal(true)} text="Upload Document" />
        )}
      </section>

      {documents?.length > 0 ? (
        <section className={styles.documentContainer}>
          {documents.map((item) => (
            <div className={styles.document} key={item.Id}>
              <div className={styles.spaceBetween}>
                <img src={certificate} alt="certificate" />
                <Button
                  isLoading={
                    item.Id === currentId
                      ? getDocumentMutation.isPending
                      : false
                  }
                  disabled={
                    item.Id === currentId
                      ? getDocumentMutation.isPending
                      : false
                  }
                  onClick={() => {
                    setCurrentId(item.Id);
                    handleDownload(item.Id);
                  }}
                  text="Download"
                />
              </div>
              <label>{item.Description || "CIPM CERTIFICATION"}</label>
              <h4>{item.CertTitle}</h4>
              {item.Description?.toLowerCase()?.includes("upgrade") ? (
                <p>
                  This is to certify that your membership has been upgraded on{" "}
                  {getDateWithoutTime(item.DateEarned)}
                </p>
              ) : (
                <p>
                  This is to certify that you attended the{" "}
                  {item.CertTitle.toLowerCase()} held on{" "}
                  {getDateWithoutTime(item.DateEarned)}
                </p>
              )}
              <div className={styles.documentDetails}>
                {item.Id}
                <span className={styles.bulletSeparator}></span>
                <span>{item.Score} points earned</span>
              </div>
            </div>
          ))}
        </section>
      ) : (
        <Empty
          description={
            <p
              style={{
                justifySelf: "center",
                alignSelf: "center",
                fontSize: 24,
                fontWeight: 600,
                color: "#f00d0d",
              }}
            >
              No document to download yet
            </p>
          }
        />
      )}
      <Modal
        centered
        open={openSuccessModal}
        onCancel={() => setOpenSuccessModal(false)}
        footer={null}
        styles={{
          body: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            marginBlock: "2rem",
          },
        }}
      >
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Success!</h2>
          <p>
            Your document has been uploaded successfully. Admin will review and
            update your points.
          </p>
        </div>
      </Modal>
      <Modal
        centered
        open={openModal}
        title="Upload Document"
        onCancel={() => setOpenModal(false)}
        footer={null}
        styles={{
          body: {
            maxHeight: "calc(100vh - 100px)",
            overflowY: "auto",
          },
        }}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.fields}>
                <Field
                  name="eventName"
                  as={Input}
                  label="Name of activity"
                  placeholder="Name of activity"
                />
                <Field
                  name="eventType"
                  as={Select}
                  label="Activity Type"
                  placeholder="Select"
                  options={scoreTypeOptions}
                />
                <Field
                  name="comment"
                  as={Textarea}
                  label="Comment"
                  placeholder="Enter comment"
                />
                <Upload
                  label="Upload Document"
                  description={
                    <p>
                      Certificate <b>MUST</b> contain your picture
                    </p>
                  }
                  allowedFormats={["pdf", "img"]}
                  onChange={handleFileChange}
                  fileName={file?.name}
                />
                {formik.touched.file && formik.errors.file ? (
                  <div
                    className={styles.error}
                  >{`*${formik.errors.file.toString()}`}</div>
                ) : null}
              </div>
              <div className={styles.cta}>
                <Button
                  type="button"
                  onClick={cancelAddDocument}
                  text="Cancel"
                  className={classNames("cancelBtn")}
                />
                <Button
                  isLoading={addDocumentMutation.isPending}
                  disabled={addDocumentMutation.isPending}
                  type="submit"
                  text="Save"
                />
              </div>
            </form>
          </FormikProvider>
        </div>
      </Modal>
    </FormikProvider>
  );
};

export default Main;
