import classNames from "classnames";
import styles from "./styles.module.scss";
import Button from "../../custom/button/button";
import { App, Modal, Spin, Table } from "antd";
import {
  combineDateAndTime,
  getDateWithoutTime,
  getTimeWithoutDate,
} from "../../utils/formatDate";
import { ColumnsType } from "antd/es/table";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { userProfile } from "../../state";
import { addChairmanActivity, getChairmanActivities } from "../../requests";
import { AxiosError } from "axios";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import { useState } from "react";
import { errorMessage } from "../../utils/errorMessage";
import * as Yup from "yup";

const columns: ColumnsType<ChairmanActivity> = [
  {
    title: "S/N",
    key: "index",
    render(_value, _record, index) {
      return index + 1;
    },
  },
  {
    title: "Activity",
    dataIndex: "Activity",
    key: "index",
  },
  {
    title: "Event",
    dataIndex: "EventName",
    key: "index",
  },
  {
    title: "Date",
    dataIndex: "EventDate",
    key: "index",
    render: (_, { EventDate }) => getDateWithoutTime(EventDate),
  },
  {
    title: "Time",
    dataIndex: "EventTime",
    key: "index",
    render: (_, { EventTime }) => getTimeWithoutDate(EventTime),
  },
  {
    title: "Venue",
    dataIndex: "Venue",
    key: "index",
  },
  {
    title: "Number of Attendees",
    dataIndex: "NoOfAttendees",
    key: "index",
  },
];

const ChairmanActivities = () => {
  const profile = useAtomValue(userProfile);
  const { notification } = App.useApp();
  const [open, setOpen] = useState(false);

  const addChairmanActivityMutation = useMutation({
    mutationKey: ["add-chairman-activity"],
    mutationFn: (payload: ChairmanActivityPayload) =>
      addChairmanActivity(payload),
  });
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["get-chairman-activities", profile?.BranchId],
    queryFn: () => getChairmanActivities(profile?.BranchId),
    enabled: !!profile?.BranchId,
  });

  const activityData = data?.Data as ChairmanActivity[];

  const addChairmanActivityHandler = async (values: FormikValues) => {
    const dateTime = combineDateAndTime(values.date, values.time);

    const payload: ChairmanActivityPayload = {
      Activity: values.activity,
      EventDate: values.date,
      EventTime: dateTime.toISOString(),
      EventName: values.eventName,
      Venue: values.venue,
      NoOfAttendees: parseInt(values.noOfAttendees),
      MembershipNo: profile?.MembershipNo,
      BranchId: profile?.BranchId,
    };

    try {
      await addChairmanActivityMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({ message: data?.Message });
          refetch();
          setOpen(false);
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    activity: Yup.string().required("required"),
    eventName: Yup.string().required("required"),
    date: Yup.string().required("required"),
    time: Yup.string().required("required"),
    venue: Yup.string().required("required"),
    noOfAttendees: Yup.number().required("required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      activity: "",
      eventName: "",
      date: "",
      time: "",
      venue: "",
      noOfAttendees: 0,
    },
    onSubmit: (values) => {
      addChairmanActivityHandler(values);
    },
    validationSchema,
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("spaceBetween")}>
        <h3>Event Attendance</h3>
        <Button onClick={() => setOpen(true)} text="Add Activity" />
      </section>
      <section>
        <Table
          columns={columns}
          dataSource={activityData ?? []}
          scroll={{ x: true }}
          pagination={false}
          locale={{ emptyText: "No activities yet" }}
          rootClassName={classNames("table")}
        />
      </section>
      <Modal
        title="Add Activity"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        styles={{
          body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
        }}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
              <div className={styles.fieldCol}>
                <Field
                  name="activity"
                  as={Input}
                  label="Activity"
                  placeholder="Activity"
                />
                <Field
                  name="eventName"
                  as={Input}
                  label="Event Name"
                  placeholder="Event name"
                />
                <Field
                  name="date"
                  as={Input}
                  type="date"
                  label="Event Date"
                  placeholder="Event Date"
                />
                <Field
                  name="time"
                  as={Input}
                  type="time"
                  label="Event Time"
                  placeholder="Event Time"
                />
                <Field
                  name="venue"
                  as={Input}
                  label="Venue"
                  placeholder="Venue"
                />
                <Field
                  name="noOfAttendees"
                  as={Input}
                  type="number"
                  label="Number of Attendees"
                  placeholder="Number of Attendees"
                />
              </div>
              <div className={styles.cta}>
                <Button
                  text="Cancel"
                  className={classNames("cancelBtn")}
                  onClick={() => setOpen(false)}
                />
                <Button
                  type="submit"
                  text="Save"
                  isLoading={addChairmanActivityMutation.isPending}
                  disabled={addChairmanActivityMutation.isPending}
                />
              </div>
            </form>
          </FormikProvider>
        </div>
      </Modal>
    </div>
  );
};

export default ChairmanActivities;
