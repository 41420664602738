import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider as AtomProvider } from "jotai";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider, App as AntdApp } from "antd";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = {
  token: { colorPrimary: "#11643C", fontFamily: "BRFirma-Medium" },
  components: {
    Select: {
      optionFontSize: 13,
      fontSize: 13,
    },
  },
};

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AtomProvider>
        <BrowserRouter>
          <ConfigProvider theme={theme}>
            <AntdApp notification={{ placement: "top" }}>
              <App />
            </AntdApp>
          </ConfigProvider>
        </BrowserRouter>
      </AtomProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
