import { useAtomValue, useSetAtom } from "jotai";
import styles from "./styles.module.scss";
import {
  getUserGrade,
  isUpdateCorporateProfile,
  userData,
  userProfile,
} from "../../../state";
import IconText from "../../../custom/iconText/iconText";
import avatar from "../../../assets/user.png";
import { ReactComponent as PaperPine } from "../../../assets/papperPine.svg";
import { ReactComponent as Search } from "../../../assets/search-alt.svg";
import Button from "../../../custom/button/button";
import { useState } from "react";
import { Tabs, TabsProps } from "antd";
import CompanyDetails from "./companyDetails";
import TopManagement from "./topManagement";
import DocumentUpload from "./documentUpload";

const CorporateProfile = () => {
  const profile = useAtomValue(userProfile);
  const user = useAtomValue(userData);
  const [photo, setPhoto] = useState<File | null>(null);
  const setIsProfileEditable = useSetAtom(isUpdateCorporateProfile);
  const grade = getUserGrade(user?.MembershipTypeId!);
  const [tabIndex, setTabIndex] = useState("1");

  const handleTabChange = (key: string) => {
    setTabIndex(key);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      setPhoto(file[0]);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <p className={styles.tabLabel}>Company Details</p>,
      children: <CompanyDetails photo={photo} />,
    },
    {
      key: "2",
      label: <p className={styles.tabLabel}>Top Management</p>,
      children: <TopManagement />,
    },
    {
      key: "3",
      label: <p className={styles.tabLabel}>Document Upload</p>,
      children: <DocumentUpload />,
    },
  ];

  return (
    <section className={styles.section}>
      <section className={styles.profileWrapper}>
        <article className={styles.article}>
          <div className={styles.pictureContainer}>
            <img
              src={profile?.UploadPictureUrl}
              alt=""
              className={styles.profile}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = avatar;
              }}
            />
            <label className={styles.changePhotoBtn}>
              Change Photo
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </label>
          </div>
          <div>
            <h2 className={styles.heading}>{profile?.Name?.toUpperCase()}</h2>
            <p className={styles.para}>{grade} Grade Member at CIPM Nigeria</p>
            <span className={styles.iconHolder}>
              <IconText
                heading={profile?.MembershipNo!}
                para="Number"
                icon={<Search />}
              />
              <IconText
                heading={`${profile?.UpgradeScore}`}
                para="Score"
                icon={<PaperPine />}
              />
              {/* {profile?.CreatedDate && (
                <IconText
                  heading={`${getDateWithoutTime(profile?.CreatedDate)}`}
                  para="Date Inducted"
                  icon={<Search />}
                />
              )} */}
            </span>
          </div>
        </article>
        <Button
          text="Edit Profile"
          className={styles.editButton}
          onClick={() => setIsProfileEditable((prev) => !prev)}
        />
        <Tabs
          defaultActiveKey={tabIndex}
          items={items}
          onChange={handleTabChange}
          className={styles.tab}
        />
      </section>
    </section>
  );
};

export default CorporateProfile;
