import { useAtomValue } from "jotai";
import styles from "./styles.module.scss";
import { getUserGrade, userData, userProfile } from "../../state";
import { Link } from "react-router-dom";
import avatar from "../../assets/user.png";
import { ReactComponent as Tree } from "../../assets/chart-tree.svg";
import { ReactComponent as Search } from "../../assets/search-alt.svg";
import { ReactComponent as Message } from "../../assets/message.svg";
import { ReactComponent as CheckBox } from "../../assets/checkbox.svg";
import { hasDateBeenReached } from "../../utils/formatDate";
import { MemberType } from "../../requests";

const ProfileCard = ({
  addAboutLink,
  showLicenseScore,
  showUpgradeScore,
}: {
  addAboutLink?: boolean;
  showLicenseScore?: boolean;
  showUpgradeScore?: boolean;
}) => {
  const profile = useAtomValue(userProfile);
  const user = useAtomValue(userData);
  const grade = getUserGrade(user?.MembershipTypeId!);
  const hasExpiredLicense = hasDateBeenReached(profile?.LicenseExpire!);
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;

  return (
    <div className={styles.largeGap}>
      <div className={styles.mediumGap}>
        <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
        <div>
          <h2>{profile?.Name}</h2>
          {addAboutLink ? (
            <Link className={styles.extLink} to="/about-hrpl">
              About HRPL
            </Link>
          ) : null}
        </div>
      </div>
      <div className={styles.statsGroup}>
        <div className={styles.stat}>
          <div className={styles.icon1}>
            <Tree />
          </div>
          <section>
            <h4>{grade}</h4>
            <label>Grade</label>
          </section>
        </div>
        <div className={styles.stat}>
          <div className={styles.icon2}>
            <Search />
          </div>
          <section>
            <h4>{profile?.MembershipNo}</h4>
            <label>Number</label>
          </section>
        </div>
        <div className={styles.stat}>
          <div className={styles.icon3}>
            <Message />
          </div>
          {showLicenseScore ? (
            <section>
              <h4>{profile?.LicenseScore}</h4>
              <label>License Score</label>
            </section>
          ) : showUpgradeScore ? (
            <section>
              <h4>{profile?.UpgradeScore}</h4>
              <label>Upgrade Score</label>
            </section>
          ) : (
            <section>
              <h4>{profile?.LicenseScore + profile?.UpgradeScore}</h4>
              <label>Score</label>
            </section>
          )}
          {isCorporate ? null : (
            <div className={styles.stat}>
              <div className={styles.icon1}>
                <CheckBox />
              </div>
              <section>
                <h4>{hasExpiredLicense ? "Expired" : "Active"}</h4>
                <label>License Status</label>
              </section>
            </div>
          )}
          {/* <div className={styles.stat}>
            <div className={styles.icon1}>
              <CheckBox />
            </div>
            <section>
              <h4>{format(parseISO(profile?.LicenseExpire), "do MMM yyyy")}</h4>
              <label>License Expiry Date</label>
            </section>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
