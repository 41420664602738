import { ReactComponent as BackArrow } from "../../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../../assets/clock.svg";
import { ReactComponent as CyberPay } from "../../../assets/cyberpay-logo.svg";
import { ReactComponent as Info } from "../../../assets/info.svg";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import styles from "../styles.module.scss";
import Points from "../../../assets/points.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getEventById,
  getEventScheduleByEventId,
  getMaterials,
  getProfile,
  getRegisteredNonMemberOrgEvents,
  getStateByCountryId,
  registerNonMemberOrgEvent,
} from "../../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { getDateWithoutTime } from "../../../utils/formatDate";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import { useAtom } from "jotai";
import {
  Nominee,
  corporateDataId,
  nonMemberData,
  tempNominees,
} from "../../../state";
import Input from "../../../custom/input/input";
import * as Yup from "yup";
import Select from "../../../custom/select/select";
import Table, { ColumnsType } from "antd/es/table";
import MessageBox from "../../corporateMembers/eventApplication/conference/messageBox";
import { errorMessage } from "../../../utils/errorMessage";

const ApplyForNonMemberOtherAsOrg = () => {
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const [nonMemberId, setNonMemberId] = useAtom(nonMemberData);
  const [nominees, setNominees] = useAtom(tempNominees);
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const [dataId, setDataId] = useAtom(corporateDataId);
  const navigate = useNavigate();
  const learningEventId = params.get("learningEventId");

  useEffect(() => {
    setNominees([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: ColumnsType<Nominee> = [
    {
      title: "Name",
      dataIndex: "ParticipantName",
      key: "ParticipantName",
    },
    {
      title: "Phone Number",
      dataIndex: "ParticipantPhoneNo",
      key: "ParticipantPhoneNo",
    },
    {
      title: "Email Address",
      dataIndex: "ParticipantEmail",
      key: "ParticipantEmail",
    },
    {
      title: "Action",
      render: (_, record) => (
        <button
          onClick={() => removeNominee(record)}
          className={styles.removeBtn}
        >
          Remove
        </button>
      ),
    },
  ];

  const removeNominee = (nominee: Nominee) => {
    setNominees((prev) =>
      prev.filter((x) => x.ParticipantEmail !== nominee.ParticipantEmail)
    );
  };

  const validationSchema = Yup.object().shape({
    nomineeType: Yup.string().required("required"),
    name: Yup.string().when("nomineeType", {
      is: "nonMember",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    phoneNumber: Yup.string().when("nomineeType", {
      is: "nonMember",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    email: Yup.string()
      .email("Invalid email")
      .when("nomineeType", {
        is: "nonMember",
        then: (schema) => schema.required("required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    membershipNo: Yup.string().when("nomineeType", {
      is: "member",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    schedule: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      nomineeType: "",
      name: "",
      phoneNumber: "",
      email: "",
      membershipNo: "",
      schedule: "",
      state: "",
      //branch: "",
    },
    onSubmit: (values) => {
      registerEventHandler(values);
    },
    validationSchema: validationSchema,
  });

  const registerEventMutation = useMutation({
    mutationKey: ["register-event"],
    mutationFn: registerNonMemberOrgEvent,
  });
  const verifyMembershipNo = useQuery({
    queryKey: ["verify-membership-no"],
    queryFn: () => getProfile(formik.values.membershipNo),
    enabled: false,
  });
  const registeredEvents = useQuery({
    queryKey: ["get-registered-events"],
    queryFn: () =>
      getRegisteredNonMemberOrgEvents(nonMemberId?.Email!, "Other"),
  });
  const otherInfo = useQuery({
    queryKey: ["get-other-details"],
    queryFn: () => getEventById(parseInt(learningEventId!)),
    enabled: !!learningEventId,
  });
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-event-schedule"],
    queryFn: () => getEventScheduleByEventId(parseInt(learningEventId!)),
  });
  const materials = useQuery({
    queryKey: ["get-other-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const states = useQuery({
    queryKey: ["get-states"],
    queryFn: () => getStateByCountryId(1),
  });

  const unpaidEvents = registeredEvents?.data?.Data?.filter(
    (x) => x.HasPaid === false
  ) as RegisteredNonMemberEvent[];
  const materialData = materials?.data?.Data as Material[];
  const otherInfoData = otherInfo?.data?.Data as EventById;
  const otherSchedule = data?.Data as EventSchedule[];
  const stateData = states?.data as State[];

  const stateOptions = stateData?.map((state) => (
    <option key={state.StateId} value={state.StateId}>
      {state.StateName}
    </option>
  ));

  const isMembershipNoInvalid =
    (verifyMembershipNo?.error as AxiosError as any)?.response?.data
      ?.Message === "Invalid membership number";

  const handleAddNominee = async () => {
    const isFormValid =
      formik.values.email && formik.values.phoneNumber && formik.values.name;
    if (formik.values.nomineeType === "member") {
      await verifyMembershipNo.refetch().then((responses) => {
        const nomineeData = responses?.data as Profile;

        const row: Nominee = {
          ParticipantName: nomineeData?.Name,
          ParticipantPhoneNo: nomineeData?.PhoneNumber,
          ParticipantEmail: nomineeData?.Email,
          IsAMember: true,
          MembershipNo: nomineeData?.MembershipNo,
        };

        const hasNominee = nominees?.some(
          (x) => x.ParticipantEmail === row.ParticipantEmail
        );
        const isNumInvalid = (
          responses?.error as AxiosError as any
        )?.response?.data?.Message?.includes("Invalid");

        if (!isNumInvalid) {
          if (!hasNominee) {
            setNominees((prev) => [...prev, row]);
          } else {
            notification.warning({
              message: "Attendee already added",
            });
          }
        }
      });
    } else {
      const hasNominee = nominees?.some(
        (x) => x.ParticipantEmail === formik.values.email
      );
      if (hasNominee) {
        notification.warning({
          message: "Attendee already added",
        });
      } else if (!isFormValid) {
        notification.warning({
          message: "Please enter complete attendee details",
        });
      } else {
        setNominees((prev) => [
          ...prev,
          {
            ParticipantName: formik.values.name,
            ParticipantPhoneNo: formik.values.phoneNumber,
            ParticipantEmail: formik.values.email,
            IsAMember: false,
          },
        ]);
      }
    }
  };

  if (isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  const registerEventHandler = async (values: FormikValues) => {
    const payload: RegisterCorporateEventPayload = {
      HasPaid: false,
      HasAttended: false,
      EventId: otherInfoData?.EventNameId,
      EventCategoryId: otherInfoData?.EventCategoryId,
      EventDetailId: otherInfoData?.LearningEventId,
      TrainingscheduleId: parseInt(values.schedule),
      IsCorporateSponsor: true,
      EvenType: "Other",
      Nomminees: [...nominees],
      CorporateMemberId: nonMemberId?.Id!,
      StateId: parseInt(values.state) || 0,
    };

    try {
      await registerEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          setDataId(data.Data);
          setIsEventScheduled(true);
          if (!otherInfoData?.IsPaymentRequired) {
            notification.success({
              message: "Success",
              description: "Event registered successfully.",
            });
          }
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const signOutNonMember = () => {
    setNonMemberId(null);
    window.localStorage.removeItem("non-member-data");
    navigate("/non-member-events/other");
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <p>
          You are registering as <b>{nonMemberId?.Name}</b>. Not this
          organization?{" "}
          <button onClick={signOutNonMember} className={styles.underline}>
            Log out
          </button>
        </p>
        <br />
        <br />
        {unpaidEvents &&
          unpaidEvents.length > 0 &&
          unpaidEvents?.map((event) => (
            <div key={event.Id} className={styles.infoContainer}>
              <div className={classNames("spaceBetween")}>
                <p>
                  <span>
                    <Info />
                  </span>
                  You are yet to complete payment for <b>{event.EventName}</b>
                </p>
                <Link
                  to={`/non-member-events/invoice?eventType=Other&email=${formik.values.email}&id=${event.UniqueId}&eventDetailId=${event.EventDetailId}`}
                  className={styles.underline}
                >
                  View Invoice
                </Link>
              </div>
            </div>
          ))}
        <br />
        <section className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Event Details</h3>
        </section>
        <section className={styles.formContainer}>
          {nominees.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={nominees}
                pagination={false}
                rowKey={(item) => item.ParticipantEmail}
                scroll={{ x: true }}
              />
              <br />
            </>
          ) : null}
          <h4>Add Attendee</h4>
          <div className={styles.radioWrapper}>
            <div className={styles.radioNormal}>
              <Field
                type="radio"
                id="member"
                name="nomineeType"
                value="member"
              />
              <label htmlFor="member">CIPM Member</label>
            </div>
            <div className={styles.radioNormal}>
              <Field
                type="radio"
                id="nonMember"
                name="nomineeType"
                value="nonMember"
              />
              <label htmlFor="nonMember">Non-CIPM Member</label>
            </div>
          </div>
          <ErrorMessage name="nomineeType">
            {(err) => <div className={styles.error}>*{err}</div>}
          </ErrorMessage>
          <br />
          {formik.values.nomineeType === "nonMember" && (
            <>
              <Field as={Input} name="name" label="Name" placeholder="Name" />
              <div className={styles.fields}>
                <Field
                  as={Input}
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone number"
                />
                <Field
                  as={Input}
                  name="email"
                  label="Email Address"
                  placeholder="Email address"
                />
              </div>
            </>
          )}
          {formik.values.nomineeType === "member" && (
            <Field
              as={Input}
              name="membershipNo"
              label="Membership Number"
              placeholder="Membership number"
            />
          )}
          {isMembershipNoInvalid && (
            <MessageBox message="Invalid membership number" />
          )}
          <br />
          {formik.values.nomineeType && (
            <div className={styles.buttonGroup}>
              <Button
                type="button"
                onClick={handleAddNominee}
                className={styles.editButton}
                isLoading={verifyMembershipNo.isFetching}
                disabled={verifyMembershipNo.isFetching}
                text="Add Attendee"
              />
            </div>
          )}
        </section>
        <section className={styles.detailsContainer}>
          <div className={styles.detailsContent}>
            <h2>
              {otherInfoData?.EventName}
              <span>
                <img src={Points} alt="points" />
              </span>
            </h2>
            <br />
            <div className={styles.line}></div>
            <br />
            {/* <h3>Venue</h3>
            <p>{otherInfoData?.Venue}</p>
            <br /> */}
            <h3>Business Case</h3>
            <p>{otherInfoData?.BusinessCase || "N/A"}</p>
            <br />
            <h3>Who to Participate</h3>
            <ul>
              <li>{otherInfoData?.TargetMember || "N/A"}</li>
            </ul>
            <br />
            <h3>Core Event Objectives</h3>
            <p>{otherInfoData?.CoreObjective || "N/A"}</p>
            <br />
            <h3>Talking Key Points</h3>
            <p>{otherInfoData?.DiscussionPoint || "N/A"}</p>
            <br />
            {materialData && materialData?.length > 0 ? (
              <h3>Event Schedule Materials</h3>
            ) : null}
            <br />
            <div className={styles.list}>
              {materialData?.map((item, index) => (
                <div key={index} className={styles.files}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {isEventScheduled ? (
            <div className={styles.list}>
              {otherInfoData?.IsPaymentRequired && (
                <section className={styles.resource}>
                  <div>
                    <h3>Get Payment Breakdown</h3>
                    <p>Cost details of your preferred event listed here.</p>
                    <br />
                    <Button
                      onClick={() =>
                        navigate(
                          `/non-member-events/invoice?eventType=Other&email=${formik.values.email}&id=${dataId}&eventDetailId=${otherInfoData?.LearningEventId}`
                        )
                      }
                      text="Generate Invoice"
                      iconAfter={<BackArrow />}
                    />
                  </div>
                  <CyberPay />
                </section>
              )}
            </div>
          ) : (
            <div className={styles.scheduleContainer}>
              <h3>Select preferred schedule</h3>
              {otherSchedule?.map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                const endDate = getDateWithoutTime(item.EndDate);
                return (
                  <section
                    className={styles.eventSchedule}
                    key={item.EventNameId}
                  >
                    <Clock />
                    <div>
                      <h4>{item?.Venue}</h4>
                      <label>
                        {startDate} - {endDate}
                      </label>
                      <label>{item.Time}</label>
                    </div>
                    <Field
                      type="radio"
                      name="schedule"
                      value={`${item?.TrainingScheduleId}`}
                    />
                  </section>
                );
              })}
              <ErrorMessage name="schedule">
                {(msg) => <div className={styles.error}>{msg}</div>}
              </ErrorMessage>
              <br />
              <div className={styles.list}>
                <Field
                  name="state"
                  as={Select}
                  label="Location of head office"
                  placeholder="Select location"
                  options={stateOptions}
                />
                {/* <Field
                  name="branch"
                  as={Select}
                  label="Branch"
                  placeholder="Select branch"
                  options={branchOptions}
                /> */}
              </div>
              <br />
              <Button
                isLoading={registerEventMutation.isPending}
                disabled={registerEventMutation.isPending}
                type="submit"
                text={
                  otherInfoData?.IsPaymentRequired
                    ? "Proceed to Payment"
                    : "Register"
                }
              />
            </div>
          )}
        </section>
      </form>
    </FormikProvider>
  );
};

export default ApplyForNonMemberOtherAsOrg;
