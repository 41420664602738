import { App, Modal, Select as AntdSelect } from "antd";
import styles from "../activityModal/styles.module.scss";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addWorkActivity, getScoreTypes } from "../../requests";
import { errorMessage } from "../../utils/errorMessage";
import Select from "../../custom/select/select";
import Textarea from "../../custom/textarea/textarea";
import Upload from "../../custom/upload/upload";
import Button from "../../custom/button/button";
import classNames from "classnames";

interface ActivityModalProps {
  open: boolean;
  closeModal: () => void;
}

const AddActivity = ({ open, closeModal }: ActivityModalProps) => {
  const queryClient = useQueryClient();
  const user = useAtomValue(userData);
  const { notification } = App.useApp();
  const [activityDoc, setActivityDoc] = useState<File | null>(null);
  const [activityName, setActivityName] = useState("");

  const addActivityMutation = useMutation({
    mutationKey: ["add-hr-work-activity"],
    mutationFn: (formData: FormData) => addWorkActivity(formData),
  });
  const scoreTypes = useQuery({
    queryKey: ["get-score-types"],
    queryFn: () => getScoreTypes(user?.MembershipTypeId as number),
  });

  const scoreTypeData = scoreTypes?.data?.Data as ScoreType[];

  // const scoreTypeOptions = scoreTypeData?.map((score) => (
  //   <option key={score.ScoreTypeId} value={`${score.ScoreTypeId}`}>
  //     {score.Name}
  //   </option>
  // ));
  const scoreTypeAntOptions = scoreTypeData?.map((score) => ({
    label: score.Name,
    value: score.ScoreTypeId,
  }));

  const addActivityHandler = async (values: FormikValues) => {
    const comment = `Years of experience: ${values.yearsOfExperience} ${values.comment}`;
    try {
      const formData = new FormData();
      formData.append("NameOfTraining", activityName);
      formData.append("Comments", comment);
      if (activityDoc) {
        formData.append("UploadDocument", activityDoc);
      } else formData.append("UploadDocument", "");
      formData.append("MembershipNo", user?.MemberId as string);
      formData.append("DocActivityId", `${values.eventType}`);

      await addActivityMutation.mutateAsync(formData, {
        onSuccess: () => {
          notification.success({ message: "Activity added successfully" });
          queryClient.refetchQueries({ queryKey: ["get-hr-work-activity"] });
          queryClient.refetchQueries({ queryKey: ["get-profile"] });
          closeModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };
  const findCognate = scoreTypeData?.find((score) =>
    score?.Name?.toLowerCase()?.includes("cognate")
  )?.ScoreTypeId;

  const validationSchema = Yup.object().shape({
    eventType: Yup.string().required("Required"),
    yearsOfExperience: Yup.string().when("eventType", {
      is: findCognate,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    comment: Yup.string().required("Required"),
    file: Yup.mixed(),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      eventType: "",
      yearsOfExperience: "",
      comment: "",
      file: "",
    },
    onSubmit: (values) => {
      addActivityHandler(values);
    },
    validationSchema,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      setActivityDoc(file[0]);
      formik.setFieldValue("file", file[0]);
    }
  };

  const cancelAddActivity = () => {
    queryClient.cancelQueries({ queryKey: ["add-hr-work-activity"] });
    formik.resetForm();
    setActivityDoc(null);
    closeModal();
  };

  const yearsOptions = (
    <>
      <option>Less than one year</option>
      <option>1-2 years</option>
      <option>3-5 years</option>
      <option>6-10 years</option>
      <option>10+ years</option>
    </>
  );

  return (
    <Modal
      centered
      open={open}
      title="Add Activity"
      onCancel={closeModal}
      footer={null}
      styles={{
        body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
      }}
    >
      <div className={styles.modalContent}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.formList}>
              <div className={styles.selectWrapper}>
                <label className={styles.selectLabel}>Upgrade Criteria</label>
                <AntdSelect
                  size="large"
                  variant="filled"
                  placeholder="Select"
                  options={scoreTypeAntOptions}
                  onChange={(value) => {
                    const selectedName = scoreTypeData?.find(
                      (x) => x.ScoreTypeId === parseInt(value)
                    )?.Name;
                    formik.setFieldValue("eventType", value);
                    setActivityName(selectedName as string);
                  }}
                />
                <ErrorMessage name="eventType">
                  {(msg) => <div className={styles.error}>{msg}</div>}
                </ErrorMessage>
              </div>
              {/* <Field
                name="eventType"
                as={Select}
                label="Upgrade Criteria"
                placeholder="Select"
                options={scoreTypeOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedName = scoreTypeData?.find(
                    (x) => x.ScoreTypeId === parseInt(e.target.value)
                  )?.Name;
                  formik.setFieldValue("eventType", e.target.value);
                  setActivityName(selectedName as string);
                }}
              /> */}
              {formik.values.eventType === findCognate ? (
                <Field
                  name="yearsOfExperience"
                  as={Select}
                  label="Years of Experience"
                  placeholder="Select"
                  options={yearsOptions}
                />
              ) : null}
              <Field
                name="comment"
                as={Textarea}
                label="Comment"
                placeholder="Enter comment"
              />
              <Upload
                label="Upload Document"
                description={
                  <p>
                    Certificate <b>MUST</b> contain your picture
                  </p>
                }
                allowedFormats={["pdf", "img"]}
                onChange={handleFileChange}
                fileName={activityDoc?.name}
              />
              {formik.touched.file && formik.errors.file ? (
                <div
                  className={styles.error}
                >{`*${formik.errors.file.toString()}`}</div>
              ) : null}
            </div>
            <div className={styles.cta}>
              <Button
                type="button"
                onClick={cancelAddActivity}
                text="Cancel"
                className={classNames("cancelBtn")}
              />
              <Button
                isLoading={addActivityMutation.isPending}
                disabled={addActivityMutation.isPending}
                type="submit"
                text="Save"
              />
            </div>
          </form>
        </FormikProvider>
      </div>
    </Modal>
  );
};

export default AddActivity;
