import { App, Modal } from "antd";
import styles from "./styles.module.scss";
import Upload from "../../../custom/upload/upload";
import classNames from "classnames";
import Button from "../../../custom/button/button";
import { useState } from "react";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { updateCorporateOfficer } from "../../../requests";
import { errorMessage } from "../../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";

interface Props {
  isOpen: boolean;
  phoneNumber: string;
  name: string;
  closeModal: () => void;
  resetForm: () => void;
  refetch: () => any;
}

const ChangeOfOfficerModal = ({
  isOpen,
  closeModal,
  phoneNumber,
  name,
  resetForm,
  refetch,
}: Props) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const queryClient = new QueryClient();
  const [letter, setLetter] = useState<File | null>(null);
  const firstName = name?.split(" ")[0];
  const lastName = name?.split(" ")[1];

  const changeOfficerMutation = useMutation({
    mutationKey: ["change-officer"],
    mutationFn: updateCorporateOfficer,
  });

  const changeOfficer = async () => {
    const formData = new FormData();
    formData.append("MembershipNo", user?.MemberId!);
    formData.append("PhoneNo", phoneNumber);
    formData.append("FirstName", firstName!);
    formData.append("LastName", lastName!);
    if (letter) formData.append("Officer", letter);
    try {
      await changeOfficerMutation.mutateAsync(formData, {
        onSuccess: (res) => {
          notification.success({
            message: "Success",
            description: res?.Message,
          });

          queryClient.refetchQueries({ queryKey: ["company-details"] });
          refetch();
          resetForm();
          setLetter(null);
          closeModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setLetter(files[0]);
  };
  return (
    <Modal
      centered
      open={isOpen}
      onCancel={closeModal}
      footer={null}
      title="Change of Officer"
    >
      <section className={classNames("modalContent")}>
        <Upload
          label="Formal Letter*"
          accept="img, pdf"
          allowedFormats={["img", "pdf"]}
          onChange={handleChange}
          fileName={letter?.name}
          fileSize={letter?.size}
        />
        <br />
        <div className={styles.cta}>
          <Button
            onClick={closeModal}
            className={classNames("cancelBtn")}
            text="Cancel"
          />
          <Button
            onClick={changeOfficer}
            isLoading={changeOfficerMutation.isPending}
            disabled={changeOfficerMutation.isPending}
            text="Save"
          />
        </div>
      </section>
    </Modal>
  );
};

export default ChangeOfOfficerModal;
