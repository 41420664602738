import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../assets/arrow-back.svg";
import styles from "./main.module.scss";
import Points from "../../assets/points.png";
import { ReactComponent as Calendar } from "../../assets/calendar2.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Marker } from "../../assets/marker.svg";
import Stepper from "../../custom/stepper/stepper";
import { useAtomValue } from "jotai";
import { getUserGrade, userData } from "../../state";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  baseUrl,
  getMembershipUpgradeStatus,
  processPayment,
} from "../../requests";
import { Spin, notification } from "antd";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../utils/formatDate";
import Button from "../../custom/button/button";
import { errorMessage } from "../../utils/errorMessage";
import { useEffect } from "react";
import { formatCurrency } from "../../utils/formatCurrency";

interface StepperContent {
  title: string;
  description: string;
  date?: string;
  status?: string;
}

const MembershipDetails = () => {
  const queryClient = useQueryClient();
  const user = useAtomValue(userData);
  const navigate = useNavigate();
  const grade = getUserGrade(user?.MembershipTypeId!);

  useEffect(() => {
    queryClient.refetchQueries({ queryKey: ["get-profile"] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upgradePaymentMutation = useMutation({
    mutationKey: ["pay-for-upgrade"],
    mutationFn: processPayment,
  });
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["check-status"],
    queryFn: () => getMembershipUpgradeStatus(user?.MemberId!),
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  const status = data?.Data as MembershipUpgradeStatus;
  const isInterviewOngoing = status?.HasInvited && !status?.RequiredPayment;
  const hasAnyRejections = status?.UpgradeHistories?.some(
    (x) => x.IsApproved === false
  );
  const timeForPayment = status?.RequiredPayment && !status?.HasPaid;

  const initSteps = Array.from(status?.UpgradeHistories, (x) => {
    return {
      title: x.Title,
      description: x.Comment,
      date: getDateWithoutTime(x.ApprovalDate),
      status:
        x.IsApproved === null
          ? "Rejected"
          : x.IsApproved === true
          ? "Accepted"
          : "Ongoing",
    };
  });
  let steps = [] as StepperContent[];

  if (status?.HasInvited) {
    steps = [
      ...initSteps?.slice(0, 2),
      {
        title: "Upgrade Interview",
        description: "",
        date: "",
        status: isInterviewOngoing ? "Ongoing" : "Successful",
      },
      ...initSteps?.slice(2),
    ];
  } else if (status?.RequiredPayment) {
    steps?.push({
      title: "Pay for Upgrade",
      description: "",
      date: "",
      status: status?.HasPaid ? "Successful" : "Ongoing",
    });
  } else steps = [...initSteps];

  const upgradePaymentHandler = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: status?.PaymentDetail?.RouteId || user?.MembershipTypeId!,
      MembershipNo: status?.PaymentDetail?.MembershipNo || user?.MemberId!,
      Amount: status?.PaymentDetail?.Amount!,
      Description: status?.PaymentDetail?.Description!,
      ReturnURL: `${baseUrl}/membership-upgrade`,
      PaymentForm: status?.PaymentDetail?.PaymentForm!,
      DataId: status?.PaymentDetail?.DataId!,
    };
    try {
      upgradePaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const additionalInfo = () => {
    if (hasAnyRejections) {
      return (
        <div className={styles.detailsMain}>
          <h2>Reasons for rejection</h2>
          <div className={styles.line}></div>
          <ul>
            <li>Documents submitted don't meet CIPM standards</li>
            <li>Criteria is not met</li>
          </ul>
          <br />
          <Button onClick={() => navigate(-1)} text="Reapply" />
        </div>
      );
    }
    if (isInterviewOngoing) {
      return (
        <div className={styles.detailsMain}>
          <h2>
            Interview Details
            <img src={Points} alt="" />
          </h2>
          <div className={styles.line}></div>
          <div className={styles.textWithIcon}>
            <Calendar />
            {getDateWithoutTime(
              status?.InterviewDetail?.InterviewDate || "N/A",
              true
            )}
          </div>
          <div className={styles.textWithIcon}>
            <Clock />
            {status?.InterviewDetail?.Time}
          </div>
          <div className={styles.textWithIcon}>
            <Marker />
            {status?.InterviewDetail?.InterviewVenue}
          </div>
        </div>
      );
    }
    if (timeForPayment && !!status?.PaymentDetail) {
      return (
        <div className={styles.detailsMain}>
          <h2>Pay for Upgrade</h2>
          <div className={styles.line}></div>
          <p>
            Congratulations!!! You are eligible to upgrade your membership. Now,
            please proceed to pay fee of{" "}
            <b>{formatCurrency(status?.PaymentDetail?.Amount)}</b>
          </p>
          <br />
          <Button
            onClick={upgradePaymentHandler}
            isLoading={upgradePaymentMutation.isPending}
            disabled={upgradePaymentMutation.isPending}
            text="Make Payment"
          />
        </div>
      );
    }
    if (status?.HasPaid && !status?.IsReady) {
      return (
        <div className={styles.detailsMain}>
          <h2>Certificate Processing!</h2>
          <div className={styles.line}></div>
          <p>
            Your payment has been received and your certificate is being
            processed. Please check back later.
          </p>
        </div>
      );
    }
    if (status?.HasPaid && status?.IsReady) {
      return (
        <div className={styles.detailsMain}>
          <h2>Certificate Ready!</h2>
          <div className={styles.line}></div>
          <p>
            Congratulations!!! You have been successfully upgraded to a {grade}{" "}
            Member. Your certificate is ready for collection.
          </p>
          <br />
          <Button
            onClick={() => navigate("/my-documents")}
            text="Download Certificate"
          />
        </div>
      );
    }
  };

  return (
    <div>
      <section className={styles.detailsHeading}>
        <button onClick={() => navigate(-1)}>
          <ArrowBack />
        </button>
        Membership Upgrade Details
      </section>
      <section className={styles.detailsContent}>
        <div className={styles.detailsMain}>
          <Stepper items={steps || initSteps} />
        </div>
        {additionalInfo()}
      </section>
    </div>
  );
};

export default MembershipDetails;
