import classNames from "classnames";
import styles from "./main.module.scss";
import points from "../../assets/points.png";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { ReactComponent as Calendar } from "../../assets/calendar2.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Logo } from "../../assets/logo-small.svg";
import Button from "../../custom/button/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  applyForExam,
  baseUrl,
  getAllExamSchedule,
  getExamById,
  processPayment,
} from "../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import {
  convertTo12HourFormat,
  getDateWithoutTime,
} from "../../utils/formatDate";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { errorMessage } from "../../utils/errorMessage";

interface PaymentPayload {
  RouteId: number;
  Amount: number;
  Description: string;
  PaymentForm: string;
  DataId: number | string;
}

const AssessmentDetails = () => {
  const { notification } = App.useApp();
  const [params] = useSearchParams();
  const user = useAtomValue(userData);
  const navigate = useNavigate();
  const examRegistrationId = params.get("examRegistrationId");
  const examCardRef = useRef(null);

  const printExamCard = useReactToPrint({
    content: () => examCardRef.current,
    documentTitle: "Exam Card",
  });

  const applyForExamMutation = useMutation({
    mutationKey: ["apply-for-exam"],
    mutationFn: applyForExam,
  });
  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-assessment-details", examRegistrationId],
    queryFn: () => getExamById(examRegistrationId!),
    enabled: !!examRegistrationId,
  });
  const examSchedules = useQuery({
    queryKey: ["get-exam-schedules"],
    queryFn: getAllExamSchedule,
  });

  const examDetails = data?.Data as Assessment;
  const examScheduleData = examSchedules?.data?.Data?.find(
    (x) => x.ExamId === examDetails?.ExamId
  ) as Exam;

  const applyForExamHandler = async () => {
    const payload: ApplyForExamPayload = {
      ExamId: examDetails?.ExamId,
      MembershipNo: user?.MemberId!,
      ExamScheduleId: examScheduleData?.ExamScheduleId!,
      ExamDate: examDetails?.ExamDate || examScheduleData?.Date,
      ExamTime: examDetails?.ExamTime || examScheduleData?.Time,
    };
    try {
      await applyForExamMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const paymentDetails = {
            RouteId: data?.Data?.RouteId,
            Amount: data?.Data?.Amount,
            Description: data?.Data?.Description,
            PaymentForm: data?.Data?.PaymentForm,
            DataId: `${data?.Data?.DataId}`,
          };
          handlePayment(paymentDetails);
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
      if (error?.response?.data?.Data) {
        const res = error?.response?.data?.Data;
        const paymentDetails = {
          RouteId: res?.RouteId,
          Amount: res?.Amount,
          Description: res?.Description,
          PaymentForm: res?.PaymentForm,
          DataId: res?.DataId,
        };
        handlePayment(paymentDetails);
      }
    }
  };

  const handlePayment = async (paymentDetails: PaymentPayload) => {
    const payload: ProcessPaymentPayload = {
      RouteId: paymentDetails?.RouteId,
      MembershipNo: user?.MemberId!,
      Amount: paymentDetails?.Amount,
      Description: paymentDetails?.Description,
      ReturnURL: `${baseUrl}/competency-assessment`,
      PaymentForm: paymentDetails?.PaymentForm,
      DataId: paymentDetails?.DataId,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("spaceBetween")}>
        <div className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Registered Assessment Details</h3>
        </div>
        <div className={styles.buttonGroup}>
          {examDetails?.HasPaid === false && (
            <Button
              className={classNames("outlineBtn")}
              onClick={applyForExamHandler}
              isLoading={
                applyForExamMutation.isPending ||
                processPaymentMutation.isPending
              }
              disabled={
                applyForExamMutation.isPending ||
                processPaymentMutation.isPending
              }
              text="Make Payment"
            />
          )}
          <Button onClick={printExamCard} text="Download Exam Card" />
        </div>
      </section>
      <br />
      <section className={styles.assessmentDetails}>
        <div className={classNames("card")}>
          <h2>
            {examDetails.ExamName}
            <span>
              <img src={points} alt="points" />
            </span>
          </h2>
          <div className={styles.line}></div>
          <div className={styles.labelWithIcon}>
            <Calendar />
            {getDateWithoutTime(examScheduleData?.Date)}
          </div>
          <div className={styles.labelWithIcon}>
            <Clock />
            {convertTo12HourFormat(examScheduleData?.Time)}
          </div>
          <br />
          <Button
            onClick={() =>
              (window.location.href = "http://cbt.cipmnigeria.org/")
            }
            text="Take Exam"
          />
        </div>
        <div className={classNames("card")}>
          <div ref={examCardRef} className={styles.examCard}>
            <Logo />
            <h2>Exam Card</h2>
            <br />
            <p>
              <b>Exam Name:</b> {examDetails.ExamName}
            </p>
            <p>
              <b>Exam Date:</b> {getDateWithoutTime(examScheduleData?.Date)}
            </p>
            <p>
              <b>Exam Time:</b> {convertTo12HourFormat(examScheduleData?.Time)}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AssessmentDetails;
