import { App, Modal } from "antd";
import classNames from "classnames";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../../custom/input/input";
import styles from "./styles.module.scss";
import Button from "../../../custom/button/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createProfessionalBody } from "../../../requests";
import * as Yup from "yup";
import { errorMessage } from "../../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";
import { useState } from "react";
import Upload from "../../../custom/upload/upload";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const AddProfessionalBodyModal = ({ open, handleClose }: Props) => {
  const queryClient = useQueryClient();
  const { notification } = App.useApp();
  const [doc, setDoc] = useState<File | null>(null);
  const user = useAtomValue(userData);
  const addProfessionalBodyMutation = useMutation({
    mutationKey: ["add-prof-body"],
    mutationFn: createProfessionalBody,
  });
  const validationSchema = Yup.object().shape({
    bodyName: Yup.string().required("Body name is required"),
    membershipGrade: Yup.string(),
    electionDate: Yup.string(),
    file: Yup.mixed().required("Certificate is required"),
  });
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      setDoc(file[0]);
      formik.setFieldValue("file", file[0]);
    }
  };
  const formik = useFormik<FormikValues>({
    initialValues: {
      bodyName: "",
      membershipGrade: "",
      electionDate: "",
      file: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = new FormData();
      payload.append("BodyName", values.bodyName);
      if (values.membershipGrade)
        payload.append("MembershipGrade", values.membershipGrade);
      if (values.electionDate)
        payload.append("ElectionDate", values.electionDate);
      payload.append("MembershipNo", user?.MemberId!);
      payload.append("ApplicantId", user?.MemberId!);
      if (doc) payload.append("DataFile", doc);

      try {
        await addProfessionalBodyMutation.mutateAsync(payload, {
          onSuccess: (data) => {
            notification.success({
              message: "Success",
              description:
                data?.Message || "Professional body added successfully",
            });
            queryClient.refetchQueries({ queryKey: ["professional-body"] });
            handleClose();
            resetForm();
          },
        });
      } catch (error) {
        notification.error({
          message: "Error",
          description: errorMessage(error),
        });
      }
    },
  });
  return (
    <Modal
      open={open}
      centered
      footer={null}
      title="Add Professional Body"
      onCancel={handleClose}
      styles={{
        body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
      }}
    >
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className={classNames("modalContent")}
        >
          <Field
            as={Input}
            type="text"
            name="bodyName"
            label="Body Name"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="membershipGrade"
            name="membershipGrade"
            label="Membership Grade (Optional)"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="date"
            name="electionDate"
            label="Election Date (Optional)"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Upload
            label="Upload Certificate"
            allowedFormats={["pdf", "img"]}
            onChange={handleFileChange}
            fileName={doc?.name}
            fileSize={doc?.size}
          />
          {formik.touched.file && formik.errors.file ? (
            <div
              className={classNames("error")}
            >{`*${formik.errors.file.toString()}`}</div>
          ) : null}
          <br />
          <section className={classNames("buttonGroup")}>
            <Button
              text="Cancel"
              type="button"
              className={classNames("cancelBtn")}
              onClick={handleClose}
            />
            <Button
              text="Save"
              type="submit"
              isLoading={addProfessionalBodyMutation?.isPending}
              disabled={addProfessionalBodyMutation?.isPending}
            />
          </section>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default AddProfessionalBodyModal;
