import { useAtomValue } from "jotai";
import CorporateBadge from "../components/conferences/gateEntry";
import SingleBadge from "../components/conferences/singleBadge";
import { isCorporate } from "../state";

const ConferenceEntryBadge = () => {
  const isCorporateMember = useAtomValue(isCorporate);
  if (isCorporateMember) {
    return <CorporateBadge />;
  }
  return <SingleBadge />;
};

export default ConferenceEntryBadge;
