import styles from "./styles.module.scss";
import Points from "../../assets/points.png";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../assets/file-small.svg";
import { ReactComponent as CyberPay } from "../../assets/cyberpay-logo.svg";
import success from "../../assets/success.png";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  MemberType,
  TransactionStatus,
  baseUrl,
  getEventByEventDetailId,
  getMaterials,
  getRegisteredEventById,
  processPayment,
  queryPayment,
} from "../../requests";
import { App, Modal, Spin } from "antd";
import { AxiosError } from "axios";
import classNames from "classnames";
import Button from "../../custom/button/button";
import { corporateDataId, userData } from "../../state";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/formatCurrency";
import { errorMessage } from "../../utils/errorMessage";

const RegisteredTrainingDetails = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const [open, setOpen] = useState(false);
  const { id, eventId }: { id?: string; eventId?: string } = useParams();
  const ref = params.get("ref");
  const isCorporateMember = user?.MembershipTypeId === MemberType.Corporate;
  const setDataId = useSetAtom(corporateDataId);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });

  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(ref!),
    enabled: !!ref,
  });

  useEffect(() => {
    if (
      !!ref &&
      queryTransaction.data?.transactionStatus === TransactionStatus.Success
    ) {
      openModal();
      eventsByDetail.refetch();
      eventDetails.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, queryTransaction.data]);

  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: eventData?.PayingAmount,
      Description: "Training Payment",
      ReturnURL: `${baseUrl}/event-application/registered-trainings/${id}/${eventId}`,
      PaymentForm: "Training",
      DataId: isCorporateMember ? eventData?.UniqueId : `${eventData?.Id}`,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.title,
      });
    }
  };

  const eventDetails = useQuery({
    queryKey: ["get-event-details"],
    queryFn: () => getRegisteredEventById(id!),
  });

  const materials = useQuery({
    queryKey: ["get-event-materials"],
    queryFn: () => getMaterials(eventId!),
  });

  const eventData = eventDetails.data?.Data as RegisteredEvent;
  const materialData = materials.data?.Data as Material[];

  const eventsByDetail = useQuery({
    queryKey: ["get-event-by-detail"],
    queryFn: () => getEventByEventDetailId(eventData?.EventDetailId!),
    enabled: !!eventData?.EventDetailId,
  });

  const eventDetailData = eventsByDetail?.data?.Data as Event;

  if (eventsByDetail.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (eventsByDetail.isError)
    return <div>{errorMessage(eventsByDetail.error as AxiosError)}</div>;

  return (
    <div>
      <section className={classNames("headingWithBackArrow")}>
        <button onClick={() => navigate(-1)}>
          <BackArrow />
        </button>
        <h3>Event Details</h3>
      </section>
      <section className={styles.detailsContainer}>
        <div className={styles.detailsContent}>
          <h2>
            {eventData?.EventName}
            <span>
              <img src={Points} alt="points" />
            </span>
          </h2>
          {/* <label>Managing Employee Performance in Business</label> */}
          <div className={styles.line}></div>
          {eventData?.RequirePayment && eventData?.HasPaid && (
            <>
              <h3>Venue</h3>
              <p>{eventDetailData?.Venue || "N/A"}</p>
              <br />
            </>
          )}
          <h3>Business Case</h3>
          <p>{eventDetailData?.BusinessCase || "N/A"}</p>
          <br />
          <h3>Who to Participate</h3>
          <ul>
            <li>{eventDetailData?.TargetMember || "N/A"}</li>
          </ul>
          <br />
          <h3>Core Training Objectives</h3>
          <p>{eventDetailData?.CoreObjective || "N/A"}</p>
          <br />
          <h3>Talking Key Points</h3>
          <p>{eventDetailData?.DiscussionPoint || "N/A"}</p>
          <br />
          {materialData && materialData?.length > 0 && <h3>Event Materials</h3>}
          <br />
          <section className={styles.list}>
            {materialData &&
              materialData?.map((item, index) => (
                <div key={index} className={styles.files}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
          </section>
        </div>
        <div className={styles.list}>
          {!isCorporateMember &&
          eventDetailData?.IsPaymentRequired &&
          eventData?.HasPaid === false ? (
            <section className={classNames("card")}>
              <h2>Pay for Training</h2>
              <div className={styles.line}></div>
              <p>
                Proceed to pay <b>{formatCurrency(eventData?.PayingAmount)}</b>{" "}
                before the event date.
              </p>
              <br />
              <Button
                type="button"
                isLoading={processPaymentMutation.isPending}
                disabled={processPaymentMutation.isPending}
                onClick={handlePayment}
                text="Make Payment"
              />
            </section>
          ) : null}
          {eventData?.IsCorporateSponsor || isCorporateMember ? (
            <section className={styles.resource}>
              <div>
                <h3>Get Payment Breakdown</h3>
                <p>Cost details of your preferred event listed here.</p>
                <br />
                <Button
                  onClick={() => {
                    setDataId(eventData?.UniqueId);
                    navigate(
                      `/event-application/training/invoice?id=${eventData?.Id}&eventDetailId=${eventData?.EventDetailId}&uniqueId=${eventData?.UniqueId}`
                    );
                  }}
                  text="Generate Invoice"
                  iconAfter={<BackArrow />}
                />
              </div>
              <CyberPay />
            </section>
          ) : null}
        </div>
      </section>
      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Payment Successful!</h2>
          <p>You have successfully paid for your event.</p>
        </div>
      </Modal>
    </div>
  );
};

export default RegisteredTrainingDetails;
