import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import styles from "./styles.module.scss";
import Details from "../../../custom/details/details";
import Input from "../../../custom/input/input";
import DownloadFile from "../../../custom/downloadFile/downloadFile";
import { useQuery } from "@tanstack/react-query";
import { UploadType, getCorporateUploads } from "../../../requests";
import { useAtomValue } from "jotai";
import { userProfile } from "../../../state";
import { Spin } from "antd";
import { AxiosError } from "axios";

const DocumentUpload = () => {
  const profile = useAtomValue(userProfile);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-corporate-uploads"],
    queryFn: () => getCorporateUploads(profile?.MembershipNo!),
  });

  const uploadData = data?.Data?.CorporateUpload as CorporateUpload[];

  const financial = uploadData?.find(
    (x) => x.UploadType === UploadType.Financial
  );
  const cac = uploadData?.find((x) => x.UploadType === UploadType.Cac);
  const profileUpload = uploadData?.find(
    (x) => x.UploadType === UploadType.Profile
  );

  const formik = useFormik<FormikValues>({
    initialValues: {
      memberId: "",
      refereeFullName: "",
      refereeApprovalStatus: "",
    },
    onSubmit: () => {},
  });

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <FormikProvider value={formik}>
      <Details
        title="REFERENCE FROM A PROFESSIONAL MEMBER OF THE INSTITUTE (Full Member or Fellow)"
        className={styles.details}
      >
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <hr className={styles.hr} />
          <Field
            name="memberId"
            as={Input}
            label="Membership ID/Email of Referee"
          />
          <Field
            name="refereeFullName"
            as={Input}
            label="Referee's Full Name"
          />
          <Field
            name="refereeApprovalStatus"
            as={Input}
            label="Referee Approval Status"
          />
        </form>
      </Details>
      <br />
      <Details title="COMPANY PROFILE" className={styles.details}>
        <hr className={styles.hr} />
        <br />
        <DownloadFile
          className={styles.input}
          title="Company's Profile Management Structure to determine the place of HRM"
          documentName={"Company's Profile Management"}
          documentUrl={profileUpload?.FileUrl}
        />
      </Details>
      <br />
      <Details title="CERTIFICATE OF INCORPORATION" className={styles.details}>
        <hr className={styles.hr} />
        <br />
        <DownloadFile
          className={styles.input}
          title="Certificate of Incorporation"
          documentName={"Incorporation Certificate.pdf"}
          documentUrl={cac?.FileUrl}
        />
      </Details>
      <br />
      <Details
        title="CURRENT ANNUAL REPORT & FINANCIAL STATEMENT (If Applicable)"
        className={styles.details}
      >
        <hr className={styles.hr} />
        <br />
        <DownloadFile
          className={styles.input}
          title="Current Annual Report"
          documentName={"Current Annual Report"}
          documentUrl={financial?.FileUrl}
        />
      </Details>
    </FormikProvider>
  );
};

export default DocumentUpload;
