import request from '../utils/request';

export enum EventIdType {
  Training = 1,
  Conference = 2,
  Other = 3
}

export enum MemberType {
	Fellow = 1,
	Full = 2,
	Associate = 3,
	Student = 4,
	Corporate = 5
}

export enum EndorsementType {
	Branch = "Branch",
	Upgrade = "Upgrade",
	Voting = "Voting"
}

export enum TransactionStatus {
	Cancel = "Cancel",
	Success = "Successful",
	Failed = "Failed"
}

export enum NotificationType {
	EndorsementRequest = "EdorsementRequest",
	EndorsementResponse = "EdorsementResponse",
	LicenseRenewal = "LicenseRenewal",
	ReadyToMarket = "ReadyToMarket",
	Upgrade = "Upgrade",
}

export enum UploadType {
	Financial = "Financial",
	Cac = "CAC",
	Profile = "Profile"
}

export enum MaritalStatus
{
	Married =1,
	Single = 2,
	Widowed =3,
	Divorced = 4,
}

export type EventType = 'Training' | 'Conference' | 'Other';

export const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'http://members.cipmnigeria.org';
export const removeBrackets = (input: string): string => {
	return input.replace(/\s*\(.*?\)\s*/g, ' ').trim().replace(/\s+/g, ' ');
}

// login
export const signIn = async (payload: LoginPayload) => {
	return (await request.post('/Member/login', payload))?.data as LoginResponse;
};

// reset password
export const resetPassword = async (payload: ResetPasswordPayload) => {
	return (await request.post('/Member/resetpassword', payload))?.data as Response;
};

// forgot password
export const forgotPassword = async (payload: ForgotPasswordPayload) => {
	return (await request.post('/Member/ForgotPasswordAsync', payload))?.data as Response;
};

// change password
export const changePassword = async (payload: ChangePasswordPayload) => {
	const formData = new FormData();
    formData.append("CurrentPassword", payload?.CurrentPassword);
    formData.append("NewPassword", payload?.NewPassword);
    formData.append("ConfirmPassword", payload?.ConfirmPassword);
    formData.append("MembershipNo", payload?.MembershipNo);
	return (await request.post(`/Member/ChangePasswordAsync?memberId=${payload.MembershipNo}`, formData))?.data as Response;
};

// need help
export const needHelp = async (payload: NeedHelpPayload) => {
	return (await request.post('/Member/needhelp', payload))?.data as Response;
};

/**
 *
 * @desc profle  udpate api collection
 *
 */

// get profile
export const getProfile = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/getpersonaldetail?membershipNo=${encodedMembershipNo}`))?.data?.Data as Profile;
};

// update profile
export const updateProfile = async (payload: Partial<Profile>) => {
	return (await request.post('/Member/updateprofile', payload))?.data as Response;
};

export const verifyName = async (payload: FormData) => {
	return (await request.post('/Member/updatename', payload, { headers: { 'Content-Type': 'multipart/form-data' } }))
		?.data?.Message as string;
};

export const verifyMaritalStatus = async (payload: FormData) => {
	return (
		await request.post('/Member/updatemaritalstatus', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
	)?.data?.Message as string;
};

export const updateEmail = async (payload: VerifyEmailPayload) => {
	return (await request.post('/Member/updateemail', payload))?.data?.Message as string;
};

export const verifyAlternateEmail = async (payload: VerifyEmailPayload) => {
	return (await request.post('/Member/updatealternateemail', payload))?.data?.Message as string;
};

export const updatePersonalDetails = async (payload: Partial<Profile>) => {
	return (
		await request.post('/Member/updatepersonaldetail', payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
	)?.data?.Message as string;
};

// get educational background
export const getEducationalBackground = async (membershipNo: string, qualificationId: number = 0) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (
		await request.get(
			`/Member/getacademicbymembershipno?membershipNo=${encodedMembershipNo}&qualificationId=${qualificationId}`
		)
	)?.data as GetEducationalBackgroundResponse;
};

// create educational background
export const createEducationalBackground = async (payload: FormData) => {
	return (await request.post('/Member/UpdateAcademic', payload))?.data as Response;
};

// get qualifications
export const getQualifications = async () => {
	return (await request.get('/SetUp/GetAllQualification'))?.data as GetQualificationResponse;
};

// get work experience
export const getWorkExperience = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/getJobHistorybymembershipno?membershipNo=${encodedMembershipNo}`))
		?.data as GetWorkExperienceResponse;
};

// edit work experience
export const updateWorkExperience = async (payload: Partial<WorkExperience>) => {
	return (await request.post(`/Member/CreateUpdateJobHistory`, payload))?.data as Response;
};

// get next of kin
export const getNextOfKin = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/getNextOfKinbymembershipno?membershipNo=${encodedMembershipNo}`))
		?.data as GetNextOfKinResponse;
};

// create next of kin
export const createNextOfKin = async (payload: Partial<NextOfKin>) => {
	return (await request.post(`/Member/member/CreateUpdateNextOfKin`, payload))?.data as Response;
};

// get professional body
export const getProfessionalBody = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/getProfessionalBodybyMembershipNo?membershipNo=${encodedMembershipNo}`))
		?.data as GetProfessionalBodyResponse;
};

// create professional body
export const createProfessionalBody = async (payload: FormData) => {
	return (await request.post(`/Member/CreateUpdateProfBody`, payload))?.data as Response;
};

// get activity
export const getActivity = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/getActivitybyMembershipNo?membershipNo=${encodedMembershipNo}`))
		?.data as GetActivityResponse;
};

// request endorsement
export const requestEndorsement = async (payload: RequestEndorsementPayload) => {
	return (await request.post('/Member/createendorsementrequest', payload))?.data as Response;
};

// get branches
export const getAllBranches = async () => {
	return (await request.get('/MembershipSetUp/member/GetAllBranch'))?.data as GetBranchesResponse;
};

//get chapters
export const getAllChapters = async () => {
	return (await request.get('/MembershipSetUp/member/GetAllChapter'))?.data as GetChaptersResponse;
};

// get notifications
export const getNotifications = async (membershipNo: string, pageNumber?: number, pageSize?: number) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/GetNotication?${pageNumber ? `PageNumber=${pageNumber}` : ""}${pageSize ? `&PageSize=${pageSize}&` : ""}membership=${encodedMembershipNo}`))
		?.data as GetNotificationsResponse;
};

// get endorsement requests
export const getEndorsementRequests = async (branchId: number | string, pageNumber?: number, pageSize?: number) => {
	return (await request.get(`/Member/getendorsementrequestbranchid?${pageNumber ? `PageNumber=${pageNumber}` : ""}${pageSize ? `&PageSize=${pageSize}&` : ""}branchId=${branchId}`))?.data as GetEndorsementRequestsResponse;
};

// endorse member
export const endorseMember = async (payload: EndorseMemberPayload) => {
	return (await request.post('/Member/updateendorsementrequestbychairman', payload))?.data as Response;
};

// payment history
export const getPaymentHistory = async (params: PaymentHistoryParams) => {
	const encodedMembershipNo = encodeURIComponent(params?.membershipNo);
	return (
		await request.get(
			`/Payment/GetAllPaymentMadeByMembershipNo?PageNumber=${params?.pageNumber}&PageSize=${params?.pageSize}&membershipNo=${encodedMembershipNo}&paymentChannel=${params?.channel}&status=${params?.status}&startDate=${params?.startDate}&endDate=${params?.endDate}`
		)
	)?.data as PaymentHistoryResponse;
};

// get events
export const getEvents = async (params: QueryParams = {PageNumber: 1, PageSize: 20}) => {
  return (await request.get(`/MembershipSetUp/member/GetAllEventDetails?PageNumber=${params?.PageNumber}&PageSize=${params?.PageSize}`))?.data as GetAllEventResponse;
}

// get event by id
export const getEventById = async (eventId: number) => {
  return (await request.get(`/MembershipSetUp/member/GetEventDetailsById?learningEventId=${eventId}`))?.data as GetEventResponse;
}

// get event by event detail id
export const getEventByEventDetailId = async (eventDetailId: number) => {
	return (await request.get(`/MembershipSetUp/member/GetEventDetailsById?learningEventId=${eventDetailId}`))?.data as GetEventByEventIdResponse;
}

// get registered events
export const getRegisteredEvents = async (membershipNo: string, eventType: EventType) => {
  const encodedMembershipNo = encodeURIComponent(membershipNo);
  return (await request.get(`/Activities/gettraininigconferencebymembershipno?membershipNo=${encodedMembershipNo}&evenType=${eventType}`))?.data as GetRegisteredEventsResponse;
}

// delete registered event
export const deleteRegisteredEvent = async (id: number) => {
	return (await request.delete(`/Activities/deletetraininigconferencebyid?Id=${id}`))?.data as Response;
}

// get registered corporate events
export const getRegisteredCorporateEvents = async (membershipNo: string, eventType: EventType) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Activities/gettraininigconferencecorporatebymembershipno?membershipNo=${encodedMembershipNo}&evenType=${eventType}`))?.data as GetRegisteredCorporateEventsResponse;
}

// get registered event by id
export const getRegisteredEventById = async (id: string) => {
  return (await request.get(`/Activities/gettraininigconferencebyid?Id=${id}`))?.data as GetRegisteredEventByIdResponse;
}

// get event categories
export const getEventCategories = async () => {
  return (await request.get(`/MembershipSetUp/member/GetAllEventCatergory`))?.data as GetEventCategoriesResponse;
}

// get events by category
export const getEventsByCategory = async (categoryId: number) => {
  return (await request.get(`/MembershipSetUp/member/GetAllEventDetailByCategoryId?eventCategoryId=${categoryId}`))?.data as GetEventsByCategoryResponse;
}

// get event schedule
export const getEventSchedule = async (params: QueryParams = { PageNumber: 1, PageSize: 20 }) => {
	return (
		await request.get(
			`/MembershipSetUp/member/GetAllTrainingSchedule?PageNumber=${params?.PageNumber}&PageSize=${params?.PageSize}`
		)
	)?.data as GetEventScheduleResponse;
};

// get event schedule by (learning) event details id
export const getEventScheduleByEventId = async (eventDetailId: number) => {
	return (
		await request.get(
			`/MembershipSetUp/member/GetTrainingScheduleByEventDetailId?eventDetailId=${eventDetailId}`
		)
	)?.data as GetEventScheduleByEventIdResponse;
};

// register event
export const registerEvent = async (payload: RegisterEventPayload) => {
	return (await request.post(`/Activities/createmembertraininigconference`, payload))?.data as RegisterEventResponse;
};

// register corporate event
export const registerCorporateEvent = async (payload: RegisterCorporateEventPayload) => {
	return (await request.post(`/Activities/createcoporatetraininigconference`, payload))?.data as RegisterCorporateEventResponse;
};

// register non member event
export const registerNonMemberEvent = async (payload: FormData) => {
	return (await request.post(`/Activities/createnonmembertraininigconference`, payload))?.data as RegisterEventResponse;
};

// register non member org event
export const registerNonMemberOrgEvent = async (payload: RegisterCorporateEventPayload) => {
	return (await request.post(`/NonMember/createnoncoporatetraininigconference`, payload))?.data as RegisterCorporateEventResponse;
};

// get registered non member org events
export const getRegisteredNonMemberOrgEvents = async (id: number | string, eventType: EventType) => {
	return (await request.get(`/NonMember/gettraininigconferencenoncorporatebyid?id=${id}&evenType=${eventType}`))?.data as GetRegisteredNonMemberOrgEventsResponse;
}

// get materials
export const getMaterials = async (eventId: string | number) => {
  return (await request.get(`/MembershipSetUp/member/GetMaterialByEventDetailId?eventDetailId=${eventId}`))?.data as GetMaterialsResponse;
}

// get all dues
export const getAllDues = async (payload: GetAllDuesPayload) => {
  return (await request.post(`/Payment/GetCurrentDuePayment`, payload))?.data as GetAllDuesResponse;
}

// get member dues
export const getMemberDues = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Payment/GetEachPendingPaymentByMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetAllDuesResponse;
}

// get outstanding due
export const getOutstandingDue = async (membershipNo: string) => {
  const encodedMembershipNo = encodeURIComponent(membershipNo);
  return (await request.get(`/Payment/GetPendingPayment?membershipNo=${encodedMembershipNo}`))?.data as GetOutstandingDueResponse;
}

// get percentages
export const getPercentages = async () => {
  return (await request.get(`/MembershipSetUp/member/GetAllPercentage`))?.data as GetPercentagesResponse;
}

// process payment
export const processPayment = async (payload: ProcessPaymentPayload) => {
  return (await request.post(`/Payment/ProcessMemberPayment`, payload))?.data as ProcessPaymentResponse;
}

// query processed payment
export const queryPayment = async (reference: string) => {
  return (await request.get(`/Payment/${reference}/QueryProcessedPayment`))?.data as QueryPaymentResponse;
}

// get invoice by id
export const getInvoiceById = async (invoiceId: string | number) => {
  return (await request.get(`/MembershipSetUp/member/GetInvoiceById?invoiceId=${invoiceId}`))?.data as GetInvoiceByIdResponse;
}

// get invoice by event id
export const getInvoiceByTrainingConferenceId = async (trainingConferenceId: string | number) => {
	return (await request.get(`/MembershipSetUp/member/GetInvoiceByTrainingConferenceId?trainingConferenceId=${trainingConferenceId}`))?.data as GetInvoiceByEventIdResponse;
}

// get corporate invoice by event id
export const getCorporateInvoiceByEventId = async (eventId: string | number, membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MembershipSetUp/member/GetInvoiceByCorporateMemeberEventDetailId?eventDetailId=${eventId}&corporateMembershipNo=${encodedMembershipNo}`))?.data as GetInvoiceByEventIdResponse;
}

// get corporate invoice by unique id
export const getCorporateInvoiceByUniqueId = async (uniqueId: string) => {
	return (await request.get(`/MembershipSetUp/member/GetCorporateInvoiceByUniqueId?uniqueId=${uniqueId}`))?.data as GetInvoiceByEventIdResponse;
}

/**get setup  api*/

export const getCountries = async () => {
	return (await request.get(`/Country/GetCountrys`))?.data as CountryResponse;
};

export const getStateByCountryId = async (countryId: number) => {
	return (await request.get(`/State/GetStateByContry/${countryId}`))?.data as StateResponse;
};

export const getLgaStateId = async (stateId: number) => {
	return (await request.get(`/LGA/GetLgaByState/${stateId}`))?.data as LGAResponse;
};

export const getZoneStateId = async (stateId: number) => {
	return (await request.get(`/Zone/GetZoneNameByState/${stateId}`))?.data as string;
};

export const getBranches = async () => {
	return (await request.get(`/Member/member/GetAllBranch`))?.data?.Data as BranchResponse;
};

export const getChapters = async () => {
	return (await request.get(`/Member/member/GetAllChapter`))?.data?.Data as ChapterRespone;
};

export const getChaptersByBranch = async (branch: string | number) => {
	return (await request.get(`/MembershipSetUp/member/GetChapterByBranchId?branchId=${branch}`))?.data as GetChaptersByBranchIdResponse;
}

export const getChairmanById = async (chairmanId: string) => {
	return (await request.get(`/MembershipSetUp/member/GetChairmanById?chairmanId=${chairmanId}`))?.data as GetChairmanResponse;
}

export const getChairmanByBranch = async (branch: string | number) => {
	return (await request.get(`/MembershipSetUp/member/GetActiveChairmanByBranchId?branchId=${branch}`))?.data as GetChairmanResponse;
}

export const updateBasicDetails = async (payload: UpdateBasicDetailsPayload) => {
	return (await request.post(`/Member/updatebasicdetail`, payload))?.data as Response;
}

export const verifyEmail = async (token: string, email: string) => {
	return (await request.get(`/Member/verifyemail?token=${token}&email=${email}`))?.data as Response;
}

export const verifyAltEmail = async (token: string, email: string) => {
	return (await request.get(`/Member/verifyalternativeemail?token=${token}&email=${email}`))?.data as Response;
}
export const getActivities = async () => {
	return (await request.get(`/Member/getAllActivity`))?.data?.Data as ActivitiesResponse;
};

// get conference sessions
export const getConferenceSessions = async (eventDetailId: string | number) => {
	return (await request.get(`/MembershipSetUp/member/GetSessionByEventDetailsId?eventDetailsId=${eventDetailId}`))?.data as GetConferenceSessionsResponse;
}

// get documents
export const getDocuments = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MemberUpgrade/getDocumentbyMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetDocumentsResponse;
}

// download document
export const downloadDocument = async (id: string | number) => {
	return (await request.get(`/MemberUpgrade/getdocumentbyid?id=${id}`, {responseType: 'blob'}))?.data as Blob;
}

// request license
export const requestLicense = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MemberUpgrade/requestlicensebymembershipno?membershipNo=${encodedMembershipNo}`))?.data as RequestLicenseResponse;
}

// get license renewal status
export const getLicenseRenewalStatus = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/GetLicenseRegisterByMembershipNo?membershipno=${encodedMembershipNo}`))?.data as GetLicenseRenewalStatusResponse;
}

// download license
export const downloadLicense = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MemberUpgrade/GetLicenseByMembershipNo?membershipNo=${encodedMembershipNo}`, {responseType: 'blob'}))?.data as Blob;
}

// get all badges
export const getAllBadges = async (payload: GetBadgePayload) => {
	return (await request.post(`/Member/GetBadge`, payload))?.data as GetAllBadgesResponse;
}

// get badge
export const getBadge = async (id: string | number) => {
	return (await request.post(`/Member/GetBadgeByTrainingConferenceId?trainingConferenceId=${id}`))?.data as GetBadgeResponse;
}

// get hr work activity
export const getHRWorkActivity = async (membershipNo: string, pageNumber?: number, pageSize?: number) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MembershipSetUp/member/GetLicenseActivityByMembershipNo?membershipNo=${encodedMembershipNo}${pageNumber ? `&PageNumber=${pageNumber}` : ""}${pageSize ? `&PageSize=${pageSize}` : ""}`))?.data as GetHRWorkActivityResponse;
}

// add work activity
export const addWorkActivity = async (payload: FormData) => {
	return (await request.post(`/MembershipSetUp/member/CreateLicenseActivity`, payload))?.data as Response;
}

// get assessments
export const getAssessments = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MemberUpgrade/getexamlistbymembershipno?membershipNo=${encodedMembershipNo}`))?.data as GetAssessmentsResponse;
}

// get exams
export const getExams = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MembershipSetUp/member/GetAllExamByMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetAllExamsResponse;
}

// get exam by exam id
export const getExamById = async (id: number | string) => {
	return (await request.get(`/MemberUpgrade/getexambyid?id=${id}`))?.data as GetExamByIdResponse;
}

// get exam schedules
export const getAllExamSchedule = async () => {
	return (await request.get(`/MembershipSetUp/member/GetAllExamSchedule`))?.data as GetExamSchedulesResponse;
}

// apply for exam
export const applyForExam = async (payload: ApplyForExamPayload) => {
	return (await request.post(`/MemberUpgrade/ExamRegistrationByMembershipNo?MembershipNo=${payload.MembershipNo}&ExamId=${payload.ExamId}&ExamScheduleId=${payload.ExamScheduleId}&ExamDate=${payload.ExamDate}&ExamTime=${payload.ExamTime}`))?.data as ApplyForExamResponse;
}

// get dashboard notifications
export const getDashboardNotifications = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Activities/member/GetFeatureNotificationByMemebershipno?membershipNo=${encodedMembershipNo}
	`))?.data as GetDashboardNotificationsResponse;
}

// get score types
export const getScoreTypes = async (membershipTypeId?: number) => {
	return (await request.get(`/MembershipSetUp/member/GetAllScoreType${membershipTypeId ? `?membershipType=${membershipTypeId}` : ""}`))?.data as GetScoreTypesResponse;
}

// corporate members
// get corporate profile
export const getCorporateProfile = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/GetCoporateMemberBiodataByMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetCorporateProfileResponse;
}

// update corporate profile
export const updateCorporateProfile = async (payload: FormData) => {
	return (await request.post(`/Member/CreateUpdateCorporateBioData`, payload))?.data as Response;
}

// update corporate officer
export const updateCorporateOfficer = async (payload: FormData) => {
	return (await request.post(`/Member/CreateUpdateUploadOfficer`, payload))?.data as Response;
}

// update corporate address
export const updateCorporateAddress = async (payload: FormData) => {
	return (await request.post(`/Member/CreateUpdateofficeAddress`, payload))?.data as Response;
}

// get top management
export const getTopManagement = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/GetTopManagementByMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetTopManagementResponse;
}

// get corporate uploads
export const getCorporateUploads = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/GetCorporateUploadByMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetCorporateUploadsResponse;
}

// request membership upgrade
export const requestMembershipUpgrade = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MemberUpgrade/requestupgradebymembershipno?membershipNo=${encodedMembershipNo}`))?.data as Response;
}

// get membership upgrade status
export const getMembershipUpgradeStatus = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MemberUpgrade/pendingupgradebymembershipno?membershipNo=${encodedMembershipNo}`))?.data as GetMembershipUpgradeStatusResponse;
}

// get member by membership number
export const getMemberByMembershipNo = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MembershipSetUp/member/GetMemberByMembershipId?memberId=${encodedMembershipNo}`))?.data as GetMemberByMembershipNoResponse;
}

// get cut off scores
export const getCutOffScores = async () => {
	return (await request.get(`/MembershipSetUp/member/GetScoreTypes`))?.data as GetCutOffScoresResponse;
}

// get external activities
export const getExternalActivities = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/MembershipSetUp/member/GetActivityByMembershipNo?membershipNo=${encodedMembershipNo}`))?.data as GetExternalActivitiesResponse;
}

// get chairman activities
export const getChairmanActivities = async (branchId: number) => {
	return (await request.get(`/MembershipSetUp/member/GetChairmanActivityByBranchId?branchId=${branchId}`))?.data as GetChairmanActivitiesResponse;
}

// create chairman activity
export const addChairmanActivity = async (payload: ChairmanActivityPayload) => {
	return (await request.post(`/MembershipSetUp/member/CreateChairmanActivity`, payload))?.data as Response;
}

// get ready to market content
export const getReadyToMarketContent = async () => {
	return (await request.get(`/MembershipSetUp/member/GetEmailTemplateById?Id=25`))?.data as GetEmailTemplateResponse;
}

// register non-member
export const registerNonMember = async (payload: RegisterNonMemberPayload) => {
	return (await request.post(`/NonMember/Registration`, payload))?.data as Response;
}

// signin non-member
export const signInNonMember = async (payload: {Email: string, Password: string}) => {
	return (await request.post(`/NonMember/login`, payload))?.data as NonMemberLoginResponse;
}

// forgot password non-member
export const forgotPasswordNonMember = async (payload: {Email: string, Url: string}) => {
	return (await request.post(`/NonMember/ForgotPasswordAsync`, payload))?.data as Response;
}

// reset password non-member
export const resetPasswordNonMember = async (payload: ResetPasswordPayload) => {
	return (await request.post(`/NonMember/resetpassword`, payload))?.data as Response;
}

// update non-member details
export const updateNonMemberDetails = async (payload: FormData) => {
	return (await request.post(`/NonMember/updatenoncoporatetraininigconference`, payload))?.data as Response;
}
// get reference request by membership no
export const getreferenceRequest = async (membershipNo: string) => {
	const encodedMembershipNo = encodeURIComponent(membershipNo);
	return (await request.get(`/Member/Getreferencerequestbymembership?membershipNo=${encodedMembershipNo}`))?.data as GetReferenceRequestResponse;
}

// Approve Reference Request
export const approveReferenceRequest = async (payload: ApproveReferenceRequestPayload) => {
	return (await request.post('/Member/approvereferencerequestbyid', payload))?.data as Response;
};

// resend badge
export const resendBadge = async (payload: {eventDetailId: number, email: string}) => {
	const { eventDetailId, email } = payload
	return (await request.get(`/NonMember/resendbadgetoemail?eventDatailId=${eventDetailId}&participantEmail=${email}`))?.data as Response;
};