import { Modal } from "antd";
import styles from "./styles.module.scss";
import Warning from "../../../../assets/warning.png";

interface Props {
  open: boolean;
  onClose: () => void;
}

const VerifyModal = ({ open, onClose }: Props) => {
  return (
    <Modal open={open} footer={null} onCancel={onClose}>
      <section className={styles.section}>
        <img src={Warning} alt="warning" className={styles.img} />
        <h4 className={styles.heading}>Verification Needed</h4>
        <p className={styles.para}>
          To change your name, email or marital status, verification is
          required.
        </p>
      </section>
    </Modal>
  );
};

export default VerifyModal;
