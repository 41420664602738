import { FieldHookConfig, useField } from "formik";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  placeholder?: string;
  className?: string;
  count?: number;
}

const Textarea: React.FC<TextareaProps & FieldHookConfig<string> & any> = ({
  label,
  placeholder,
  className,
  count,
  ...rest
}) => {
  const [field, meta] = useField(rest);

  return (
    <div className={classNames(className, styles.wrapper)}>
      <label className={styles.label}>{label}</label>
      <textarea
        className={styles.textarea}
        {...field}
        {...rest}
        placeholder={placeholder}
      />
      <p className={styles.count}>{count}</p>

      {meta.touched && meta.error ? (
        <label className={styles.error}>
          <span>&#42;</span>
          {meta.error}
        </label>
      ) : null}
    </div>
  );
};

export default Textarea;
