import styles from './styles.module.scss';
import { Circles } from 'react-loader-spinner';
import classNames from 'classnames';
import { Style } from 'react-loader-spinner/dist/type';

interface Props {
	className?: string;
	width?: string | number;
	height?: string | number;
	color?: string;
	style?: Style;
}

const Spinner = ({ className, width, height, color, style }: Props) => {
	return (
		<Circles
			wrapperClass={classNames(styles.spinner, className)}
			width={width}
			height={height}
			color={color}
			wrapperStyle={style}
		/>
	);
};

export default Spinner;
