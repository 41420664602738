import classNames from "classnames";
import Button from "../../../custom/button/button";
import styles from "../styles.module.scss";
import { App } from "antd";
import { useMutation } from "@tanstack/react-query";
import { baseUrl, processPayment } from "../../../requests";
import { useAtomValue } from "jotai";
import { conferencePayment } from "../../../state";
import { formatCurrency } from "../../../utils/formatCurrency";

const PayForConference = ({
  amount,
  email,
}: {
  amount: number;
  email: string;
}) => {
  const { notification } = App.useApp();
  const conferencePaymentDetails = useAtomValue(conferencePayment);

  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });

  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: conferencePaymentDetails?.RouteId!,
      Amount: amount || conferencePaymentDetails?.Amount!,
      MembershipNo: email,
      Description: "Conference Payment",
      ReturnURL: `${baseUrl}/non-member-events/conference`,
      PaymentForm: "Conference",
      DataId: conferencePaymentDetails?.DataId!,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.message ||
          error?.response?.data?.title ||
          error?.message,
      });
    }
  };

  return (
    <section className={classNames("card")}>
      <h2>Pay for Conference</h2>
      <div className={styles.line}></div>
      <p>
        You are one step away to complete your conference registration. Proceed
        to pay <b>{formatCurrency(amount)}</b> before the event date.
      </p>
      <br />
      <Button
        isLoading={processPaymentMutation.isPending}
        disabled={processPaymentMutation.isPending}
        onClick={handlePayment}
        type="button"
        text="Make Payment"
      />
    </section>
  );
};

export default PayForConference;
