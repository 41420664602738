import {
  Field,
  FieldArray,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import styles from "./styles.module.scss";
import Input from "../../../custom/input/input";
import Details from "../../../custom/details/details";
import DownloadFile from "../../../custom/downloadFile/downloadFile";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createEducationalBackground,
  getEducationalBackground,
  getQualifications,
} from "../../../requests";
import { isUpdateProfile, userData } from "../../../state";
import { useAtomValue } from "jotai";
import { AxiosError } from "axios";
import { App, Spin } from "antd";
import { format } from "date-fns";
import AddBackgroundModal from "./addBackgroundModal";
import { useState } from "react";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import { errorMessage } from "../../../utils/errorMessage";
import Select from "../../../custom/select/select";

interface Qualification {
  [key: string]: string;
}

export const qualificationDictionary: Qualification = {
  1: "SSCE / WAEC / NECO GCE & EQUIVALENT CERTIFICATE",
  2: "OND DEGREE",
  3: "BSC / HND DEGREE",
  4: "MSC / EQUIVALENT & HIGHER DEGREE",
  10: "SSCE / WAEC / NECO GCE & EQUIVALENT CERTIFICATE",
  19: "MSC / EQUIVALENT & HIGHER DEGREE",
};

const EducationalBackground = () => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const [open, setOpen] = useState(false);
  const isEditProfile = useAtomValue(isUpdateProfile);
  const [currentField, setCurrentField] = useState<EducationModel>(
    {} as EducationModel
  );
  const [file, setFile] = useState<File | null>();

  const updateBackgroundMutation = useMutation({
    mutationFn: createEducationalBackground,
  });
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["get-educational-background"],
    queryFn: () => getEducationalBackground(user?.MemberId!),
    enabled: !!user?.MemberId,
  });
  const qualQuery = useQuery({
    queryKey: ["get-qualifications"],
    queryFn: getQualifications,
  });

  const qualications = data?.Data?.EducationModels;
  const qualData = qualQuery?.data?.Data as UserQualification[];

  const qualOptions =
    qualData &&
    qualData?.map((item) => (
      <option key={item.id} value={item.id}>
        {item.QualificationName}
      </option>
    ));

  const updateBackgroundHandler = async (
    values: FormikValues,
    resetForm: () => void
  ) => {
    const curr = (values.background as EducationModel[]).find(
      (item) => item.Id === currentField.Id
    ) as EducationModel;

    const payload = new FormData();
    payload.append("Id", `${currentField?.Id}`);
    payload.append("MembershipNo", `${user?.MemberId}`);
    payload.append("ApplicantId", `${user?.ApplicantId}`);
    payload.append("QualificationId", `${curr?.QualificationId}`);
    payload.append("InstitutionName", curr?.InstitutionName);
    payload.append("Discipline", curr?.Discipline);
    payload.append(
      "Qualification",
      qualificationDictionary[curr?.QualificationId]
    );
    payload.append("StartDate", curr?.StartDate);
    payload.append("GraduateDate", curr?.GraduateDate);

    if (file) {
      payload.append("Certificate", file);
    } else {
      payload.append("CertificateUrl", currentField?.CertificateUrl);
    }

    try {
      await updateBackgroundMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          refetch();
          resetForm();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const documents = qualications?.map((model, index) => (
    <Details
      key={model.Id}
      isOpen={index === 0}
      title={qualificationDictionary[model.QualificationId] || "OTHER DEGREE"}
      className={styles.details}
    >
      <section className={styles.field}>
        <Field
          as={Input}
          name={`background[${index}.InstitutionName]`}
          label="Name of Institution"
          placeholder={model?.InstitutionName}
          className={styles.input}
          disabled={!isEditProfile}
        />
      </section>
      <section className={styles.field}>
        <Field
          as={Input}
          name={`background[${index}.Discipline]`}
          label="Discipline (Optional)"
          className={styles.input}
          placeholder={model?.Discipline}
          disabled={!isEditProfile}
        />
      </section>
      <section className={styles.field}>
        <Field
          as={Select}
          name={`background[${index}.QualificationId]`}
          label="Qualification"
          placeholder={model?.QualificationId}
          className={styles.input}
          disabled={!isEditProfile}
          options={qualOptions}
        />
      </section>
      <section className={styles.field}>
        <Field
          as={Input}
          type="date"
          name={`background[${index}.StartDate]`}
          label="Start Date"
          placeholder={format(new Date(model?.StartDate ?? null), "yyyy-MM-dd")}
          className={styles.input}
          disabled={!isEditProfile}
        />
      </section>
      <section className={styles.field}>
        <Field
          as={Input}
          type="date"
          name={`background[${index}.GraduateDate]`}
          label="Graduation Date"
          placeholder={format(new Date(model?.StartDate ?? null), "yyyy-MM-dd")}
          className={styles.input}
          disabled={!isEditProfile}
        />
      </section>

      <section className={styles.field}>
        <DownloadFile
          className={styles.input}
          title="Certificate"
          documentName={`Certificate.${model?.CertificateUrl?.slice(-3)}`}
          documentUrl={model?.CertificateUrl}
          //documentSize={10023}
          otherAction={
            isEditProfile ? (
              <label className={styles.changeFile}>
                Change
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    const file = e.target.files;
                    if (file) {
                      setFile(file[0]);
                    }
                  }}
                />
              </label>
            ) : null
          }
        />
      </section>
      <br />
      {isEditProfile && (
        <section className={styles.btnWrapper}>
          {/* <Button text='Cancel' className={styles.cancelBtn} /> */}
          <Button
            text="Save Changes"
            type="button"
            onClick={() => {
              setCurrentField(model);
              formik.handleSubmit();
            }}
            isLoading={updateBackgroundMutation.isPending}
            disabled={updateBackgroundMutation.isPending}
          />
        </section>
      )}
      <br />
    </Details>
  ));

  const updatedData = qualications?.map((x) => ({
    ...x,
    StartDate: x.StartDate
      ? format(new Date(x.StartDate ?? null), "yyyy-MM-dd")
      : "",
    GraduateDate: x.GraduateDate
      ? format(new Date(x.GraduateDate ?? null), "yyyy-MM-dd")
      : "",
  }));

  const formik = useFormik<FormikValues>({
    initialValues: {
      background: updatedData,
    },
    onSubmit: (values, { resetForm }) =>
      updateBackgroundHandler(values, resetForm),
    enableReinitialize: true,
  });

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div className="error">{(error as AxiosError)?.message}</div>;
  }

  return (
    <div>
      <FormikProvider value={formik}>
        <Button
          className={classNames(styles.lightBtn, styles.end)}
          onClick={() => setOpen(true)}
          text="Add Educational Background"
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <FieldArray name="background">{() => <>{documents}</>}</FieldArray>
        </form>
      </FormikProvider>
      <AddBackgroundModal
        open={open}
        handleClose={() => setOpen(false)}
        qualificationOptions={qualData}
      />
    </div>
  );
};

export default EducationalBackground;
