import { ReactComponent as Check } from "../../assets/check.svg";
import styles from "./styles.module.scss";

interface StepperContent {
  title: string;
  description: string;
  date?: string;
  status?: string;
}
interface StepperProps {
  items: StepperContent[];
}

const Stepper: React.FC<StepperProps> = ({ items }) => {
  const reversedItems = [...items]?.reverse();

  return (
    <div className={styles.steps}>
      {reversedItems?.map((item, index) => (
        <section key={index} className={styles.stepGroup}>
          <div className={styles.dateAndStatus}>
            <label>{item.date}</label>
            <label
              className={
                item.status === "Ongoing"
                  ? styles.pending
                  : item.status === "Accepted" || item.status === "Successful"
                  ? styles.success
                  : styles.failed
              }
            >
              {item.status}
            </label>
          </div>
          <div className={styles.stepIndicators}>
            <div className={styles.stepCircle}>
              {item.status === "Ongoing" ? (
                <div className={styles.greenCircle}></div>
              ) : (
                <Check />
              )}
            </div>
            {index === items?.length - 1 ? null : (
              <div className={styles.stepLine}></div>
            )}
          </div>
          <div className={styles.titleAndDescription}>
            <label>{item.title}</label>
            <p>{item.description}</p>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Stepper;
