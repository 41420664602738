import { FormikProvider, FormikValues, useFormik } from "formik";
import Nominees from "./nominees";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { registerCorporateEvent } from "../../../../requests";
import { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import avatar from "../../../../assets/user.png";
import { ReactComponent as BackArrow } from "../../../../assets/arrow-back.svg";
import {
  tempNominees,
  conferenceStep,
  userProfile,
  corporateDataId,
} from "../../../../state";
import { App } from "antd";
import SelectConference from "./selectConference";
import classNames from "classnames";
import ScheduleConference from "./scheduleConference";
import { useSearchParams } from "react-router-dom";
import { errorMessage } from "../../../../utils/errorMessage";
import { joinStrings } from "../../../../utils/joinStrings";

const ApplyForCorporateConference = () => {
  const [params] = useSearchParams();
  const profile = useAtomValue(userProfile);
  const { notification } = App.useApp();
  const [step, setStep] = useAtom(conferenceStep);
  const setDataId = useSetAtom(corporateDataId);
  const [nominees, setNominees] = useAtom(tempNominees);
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const ref = params.get("ref");
  const isFinalStep = step === 3;

  useEffect(() => {
    ref ? setStep(3) : setStep(1);
    setNominees([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerEventMutation = useMutation({
    mutationKey: ["register-corporate-event"],
    mutationFn: registerCorporateEvent,
  });

  const registerEventHandler = async (values: FormikValues) => {
    let payload: RegisterCorporateEventPayload = {
      CorporateMemberId: profile?.MembershipNo,
      HasPaid: false,
      HasAttended: false,
      EventId: parseInt(values.conferenceName),
      EventCategoryId: parseInt(values.conferenceType),
      EventDetailId: parseInt(values.learningEventId),
      TrainingscheduleId: parseInt(values.schedule) || 1,
      IsCorporateSponsor: true,
      EvenType: "Conference",
      Nomminees: [...nominees],
      //BranchId: parseInt(values.branch),
      StateId: parseInt(values.state),
      ConferenceSessionId: joinStrings(values.session),
    };
    try {
      await registerEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          setDataId(data?.Data);
          setIsEventScheduled(true);
          // if (parseInt(values.amount) === 0) {
          //   navigate("/event-application/conferences");
          //   notification.success({
          //     message: "Event registered successfully",
          //   });
          // }
        },
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const validationSchema = [
    Yup.object().shape({
      nomineeType: Yup.string().required("required"),
      name: Yup.string().when("nomineeType", {
        is: "nonMember",
        then: (schema) => schema.required("required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      phoneNumber: Yup.string().when("nomineeType", {
        is: "nonMember",
        then: (schema) => schema.required("required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      email: Yup.string().when("nomineeType", {
        is: "nonMember",
        then: (schema) => schema.required("required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      membershipNo: Yup.string().when("nomineeType", {
        is: "member",
        then: (schema) => schema.required("required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    Yup.object().shape({
      conferenceName: Yup.string().required("required"),
      conferenceType: Yup.string().required("required"),
      learningEventId: Yup.number(),
      amount: Yup.number(),
    }),
    Yup.object().shape({
      session: Yup.array()
        .min(1, "Session is required")
        .max(2)
        .required("Session is required"),
      state: Yup.string().required("Required"),
      //branch: Yup.string().required("Required"),
    }),
  ];

  const formik = useFormik<FormikValues>({
    initialValues: {
      nomineeType: "",
      name: "",
      phoneNumber: "",
      email: "",
      membershipNo: "",
      conferenceType: "",
      conferenceName: "",
      session: [],
      state: "",
      //branch: "",
    },
    onSubmit: (values) => {
      if (isFinalStep) {
        registerEventHandler(values);
      } else setStep((prev) => prev + 1);
    },
    enableReinitialize: true,
    validationSchema: validationSchema[step - 1],
  });

  let componentSteps = null;
  switch (step) {
    case 1:
      componentSteps = <Nominees />;
      break;
    case 2:
      componentSteps = <SelectConference />;
      break;
    case 3:
      componentSteps = (
        <ScheduleConference
          isEventScheduled={isEventScheduled}
          setIsEventScheduled={setIsEventScheduled}
          isScheduling={registerEventMutation.isPending}
        />
      );
      break;
    default:
      componentSteps = <Nominees />;
      break;
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          {step === 1 ? (
            <h3>Attendee Details</h3>
          ) : (
            <div className={classNames("headingWithBackArrow")}>
              <button
                onClick={() => {
                  formik.resetForm({
                    values: { conferenceName: "", conferenceType: "" },
                  });
                  setStep((prev) => prev - 1);
                }}
              >
                <BackArrow />
              </button>
              <h3>
                {isFinalStep ? "Conference Details" : "Apply for Conference"}
              </h3>
            </div>
          )}
          {isFinalStep ? (
            componentSteps
          ) : (
            <section className={styles.formContainer}>
              <div className={styles.profile}>
                <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
                <div>
                  <h2>{profile?.Name}</h2>
                  <label>Number: {profile?.MembershipNo}</label>
                </div>
              </div>
              {componentSteps}
            </section>
          )}
        </div>
      </form>
    </FormikProvider>
  );
};

export default ApplyForCorporateConference;
