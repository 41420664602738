import classNames from "classnames";
import styles from "../styles.module.scss";
import { useSetAtom } from "jotai";
import { trainingStep } from "../../../../state";
import { Field, FormikValues, useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import {
  EventIdType,
  getEventCategories,
  getEventsByCategory,
} from "../../../../requests";
import Select from "../../../../custom/select/select";
import Button from "../../../../custom/button/button";

enum EventType {
  Training = 1,
  Conference = 2,
  Other = 3,
}

const SelectTraining = () => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const setStep = useSetAtom(trainingStep);

  const eventCategories = useQuery({
    queryKey: ["get-event-categories"],
    queryFn: getEventCategories,
  });
  const eventByCategory = useQuery({
    queryKey: ["get-event-by-category", values.trainingType],
    queryFn: () => getEventsByCategory(parseInt(values.trainingType)),
    enabled: !!values.trainingType,
  });

  const categoryData = eventCategories?.data?.Data as EventCategory[];
  const eventData = eventByCategory?.data?.Data as EventByCategory[];

  const eventCategoryOptions = categoryData
    ?.filter((x) => x.EventTypeId === EventType.Training)
    ?.map((item) => (
      <option key={item.Id} value={item.Id}>
        {item.EventCategoryName}
      </option>
    ));
  const eventsByCategoryOptions = eventData
    ?.filter((x) => x.EventTypeId === EventIdType.Training)
    ?.map((item, index) => (
      <option
        key={index}
        value={`{"eventNameId": ${item.EventNameId}, "learningEventId": ${item.LearningEventId}}`}
      >
        {item.EventName}
      </option>
    ));

  const nextStep = () => {
    const details = JSON.parse(values.trainingName);
    setFieldValue("trainingName", details?.eventNameId);
    setFieldValue("learningEventId", parseInt(details?.learningEventId));
    //setFieldValue("amount", parseInt(details?.amount));
    setStep((prev) => prev + 1);
  };

  return (
    <div className={styles.formBody}>
      <section className={styles.grid}>
        <Field
          name="trainingType"
          as={Select}
          label="Training Type"
          placeholder="Select"
          options={eventCategoryOptions}
        />
        <Field
          name="trainingName"
          as={Select}
          label="Training Name"
          placeholder="Select"
          options={eventsByCategoryOptions}
        />
      </section>
      <br />
      <br />
      <div className={styles.buttonGroup}>
        <Button text="Cancel" className={classNames("cancelBtn")} />
        <Button onClick={nextStep} text="Proceed" />
      </div>
    </div>
  );
};

export default SelectTraining;
