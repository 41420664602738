import { App, Modal, Spin, Select as AntdSelect } from "antd";
import Button from "../../custom/button/button";
import styles from "./main.module.scss";
import success from "../../assets/success.png";
import warning from "../../assets/warning.png";
import points from "../../assets/points.png";
import laptop from "../../assets/laptop.png";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table, { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import Textarea from "../../custom/textarea/textarea";
import Upload from "../../custom/upload/upload";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addWorkActivity,
  baseUrl,
  getCutOffScores,
  getHRWorkActivity,
  getLicenseRenewalStatus,
  getScoreTypes,
  processPayment,
  queryPayment,
  requestLicense,
  TransactionStatus,
} from "../../requests";
import { AxiosError } from "axios";
import ProfileCard from "../profileCard/profileCard";
import { errorMessage } from "../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import Input from "../../custom/input/input";
import { formatDate } from "../../utils/formatDate";
import * as Yup from "yup";
import Pagination from "../../custom/pagination/pagination";

const columns: ColumnsType<HRWorkActivity> = [
  {
    title: "Date Added",
    dataIndex: "CreatedDate",
    key: "Id",
    render: (_, { CreatedDate }) => formatDate(CreatedDate) || "N/A",
  },
  {
    title: "Activity Name",
    dataIndex: "NameOfTraining",
    key: "Id",
  },
  {
    title: "Score",
    key: "Id",
    dataIndex: "Score",
  },
  {
    title: "Status",
    key: "Id",
    dataIndex: "HasApproved",
    render: (_, { HasApproved }) =>
      HasApproved ? (
        <span className={styles.successful}>Approved</span>
      ) : (
        <span className={styles.pending}>Pending approval</span>
      ),
  },
];

const Main = () => {
  const [params] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const user = useAtomValue(userData);
  const { notification } = App.useApp();
  const [activityDoc, setActivityDoc] = useState<File | null>(null);
  const [activityName, setActivityName] = useState("");
  const [openActivity, setOpenActivity] = useState(false);
  const [openOutstanding, setOpenOutstanding] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 10;
  const queryParams = `${pageNumber}${PAGE_SIZE}`;
  const ref = params.get("ref");
  const handlePagination = (pageNumber: number) => setPageNumber(pageNumber);
  const [paymentData, setPaymentData] = useState<ProcessPaymentPayload>({
    RouteId: 0,
    MembershipNo: user?.MemberId!,
    Amount: 0,
    Description: "",
    ReturnURL: "",
    PaymentForm: "",
    DataId: 0,
  });

  const openActivityModal = () => setOpenActivity(true);
  const closeActivityModal = () => setOpenActivity(false);
  const openSuccessModal = () => setPaymentSuccess(true);
  const closeSuccessModal = () => setPaymentSuccess(false);
  const handleClosePayment = () => setOpenPayment(false);
  const handleOpenPayment = () => setOpenPayment(true);
  const openModal = () => setOpenOutstanding(true);
  const closeModal = () => setOpenOutstanding(false);

  const queryTransaction = useQuery({
    queryKey: ["query-transaction"],
    queryFn: () => queryPayment(ref!),
    enabled: !!ref,
  });

  useEffect(() => {
    if (
      !!ref &&
      queryTransaction?.data?.transactionStatus === TransactionStatus.Success
    )
      openSuccessModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, queryTransaction.isSuccess]);

  const requestLicenseMutation = useMutation({
    mutationKey: ["request-license"],
    mutationFn: requestLicense,
  });
  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });
  const addActivityMutation = useMutation({
    mutationKey: ["add-hr-work-activity"],
    mutationFn: (formData: FormData) => addWorkActivity(formData),
  });
  const activities = useQuery({
    queryKey: ["get-hr-work-activity", queryParams],
    queryFn: () => getHRWorkActivity(user?.MemberId!, pageNumber, PAGE_SIZE),
  });
  const scoreTypes = useQuery({
    queryKey: ["get-score-types"],
    queryFn: () => getScoreTypes(),
  });
  const cutOffScores = useQuery({
    queryKey: ["get-cut-off-scores"],
    queryFn: getCutOffScores,
  });
  const checkStatus = useQuery({
    queryKey: ["check-license-renewal-status"],
    queryFn: () => getLicenseRenewalStatus(user?.MemberId!),
  });

  const activityData = activities?.data?.Data as HRWorkActivity[];

  const scoreTypeData = scoreTypes?.data?.Data as ScoreType[];

  // const scoreTypeOptions = scoreTypeData?.map((score) => (
  //   <option key={score.ScoreTypeId} value={score.ScoreTypeId}>
  //     {score.Name}
  //   </option>
  // ));
  const scoreTypeAntOptions = scoreTypeData?.map((score) => ({
    label: score.Name,
    value: score.ScoreTypeId,
  }));
  const licenseCutOffScore = cutOffScores?.data?.Data?.LicenseScore as number;
  const licenseRenewalStatus = () => {
    switch (checkStatus?.data?.Data?.HasApproved) {
      case true:
        return <p className={styles.successful}>Approved</p>;
      case false:
        return <p className={styles.failed}>Rejected</p>;
      case null:
        return <p className={styles.pending}>Pending Approval</p>;
      default:
        return <p className={styles.default}>No request yet</p>;
    }
  };

  const addActivityHandler = async (
    values: FormikValues,
    resetForm: () => void
  ) => {
    try {
      const formData = new FormData();
      formData.append("NameOfTraining", values.eventName || activityName);
      formData.append("Comments", values.comment || "N/A");
      if (activityDoc) formData.append("UploadDocument", activityDoc);
      formData.append("MembershipNo", user?.MemberId as string);
      formData.append("DocActivityId", `${values.eventType}`);

      await addActivityMutation.mutateAsync(formData, {
        onSuccess: () => {
          notification.success({ message: "Activity added successfully" });
          resetForm();
          setActivityDoc(null);
          activities.refetch();
          queryClient.refetchQueries({ queryKey: ["get-profile"] });
          closeActivityModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: paymentData?.RouteId || user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: paymentData?.Amount,
      Description: paymentData?.Description,
      ReturnURL: `${baseUrl}/hr-work-activity`,
      PaymentForm: paymentData?.PaymentForm,
      DataId: paymentData?.DataId,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handleRequestLicense = async (memberId: string) => {
    try {
      await requestLicenseMutation.mutateAsync(memberId, {
        onSuccess: (data) => {
          setPaymentData({ ...data.Data });
          handleOpenPayment();
        },
      });
    } catch (error: any) {
      if (error?.response?.data?.Message?.includes("outstanding")) {
        openModal();
      } else {
        notification.error({
          message: "Error",
          description: errorMessage(error),
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    eventName: Yup.string(),
    eventType: Yup.number().required("Required").min(1, "Required"),
    comment: Yup.string(),
    file: Yup.mixed().required("Required"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      eventName: "",
      eventType: 0,
      comment: "",
      file: "",
    },
    onSubmit: (values, { resetForm }) => {
      addActivityHandler(values, resetForm);
    },
    validationSchema,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      setActivityDoc(file[0]);
      formik.setFieldValue("file", file[0]);
    }
  };

  const cancelAddActivity = () => {
    queryClient.cancelQueries({ queryKey: ["add-hr-work-activity"] });
    formik.resetForm();
    setActivityDoc(null);
    closeActivityModal();
  };

  if (activities.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (activities.isError)
    return <div>{(activities.error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={styles.spaceBetween}>
        <h3>HR Work Activity</h3>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.lightBtn}
            onClick={openActivityModal}
            text="Add Activity"
          />
          <Button
            isLoading={requestLicenseMutation.isPending}
            disabled={requestLicenseMutation.isPending}
            onClick={() => handleRequestLicense(user?.MemberId!)}
            text="Apply for HRPL"
          />
        </div>
      </section>

      <div className={styles.status}>
        <div className={styles.stat}>
          <img src={laptop} alt="laptop" />
          <div>
            <h2>License Renewal Status</h2>
            {licenseRenewalStatus()}
          </div>
        </div>
      </div>

      <section className={styles.tableContainer}>
        <ProfileCard addAboutLink showLicenseScore />
        <br />
        <div className={styles.tableHeading}>
          <img src={points} alt="points" />
          <div>
            <div className={styles.amount}>{licenseCutOffScore}</div>
            <label>POINTS NEEDED</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={activityData}
          pagination={false}
          scroll={{ x: true }}
          className={classNames("table")}
          locale={{ emptyText: "No work activities yet" }}
        />
        <Pagination
          pageNumber={pageNumber || 0}
          itemsPerPage={PAGE_SIZE}
          totalSize={activities.data?.TotalSize || 0}
          setPageNumber={handlePagination}
        />
      </section>
      <Modal
        centered
        open={openActivity}
        title="Add Activity"
        onCancel={closeActivityModal}
        footer={null}
        styles={{
          body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
        }}
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className={styles.formList}>
                <Field
                  name="eventName"
                  as={Input}
                  label="Name of activity (optional)"
                  placeholder="Name of activity"
                />
                <div className={styles.selectWrapper}>
                  <label className={styles.selectLabel}>Activity Type</label>
                  <AntdSelect
                    size="large"
                    variant="filled"
                    placeholder="Select"
                    options={scoreTypeAntOptions}
                    onChange={(value) => {
                      const selectedName = scoreTypeData?.find(
                        (x) => x.ScoreTypeId === parseInt(value)
                      )?.Name;
                      formik.setFieldValue("eventType", value);
                      setActivityName(selectedName as string);
                    }}
                  />
                  <ErrorMessage name="eventType">
                    {(msg) => <div className={styles.error}>{msg}</div>}
                  </ErrorMessage>
                </div>
                {/* <Field
                  name="eventType"
                  as={Select}
                  label="Activity Type"
                  placeholder="Select"
                  options={scoreTypeOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedName = scoreTypeData?.find(
                      (x) => x.ScoreTypeId === parseInt(e.target.value)
                    )?.Name;
                    formik.setFieldValue("eventType", e.target.value);
                    setActivityName(selectedName as string);
                  }}
                /> */}
                <Field
                  name="comment"
                  as={Textarea}
                  label="Comment (optional)"
                  placeholder="Enter comment"
                />
                <Upload
                  label="Upload Document"
                  description={
                    <p>
                      Certificate <b>MUST</b> contain your picture
                    </p>
                  }
                  allowedFormats={["pdf", "img"]}
                  accept=".pdf,image/*"
                  onChange={handleFileChange}
                  fileName={activityDoc?.name}
                />
                {formik.touched.file && formik.errors.file ? (
                  <div
                    className={styles.error}
                  >{`*${formik.errors.file.toString()}`}</div>
                ) : null}
              </div>
              <div className={styles.cta}>
                <Button
                  type="button"
                  onClick={cancelAddActivity}
                  text="Cancel"
                  className={classNames("cancelBtn")}
                />
                <Button
                  isLoading={addActivityMutation.isPending}
                  disabled={addActivityMutation.isPending}
                  type="submit"
                  text="Save"
                />
              </div>
            </form>
          </FormikProvider>
        </div>
      </Modal>
      <Modal
        centered
        open={openOutstanding}
        onCancel={closeModal}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Outstanding Payment</h2>
          <p>
            You have an outstanding subscription payment. Kindly make this
            Payment before license renewal payment.
          </p>
          <div className={styles.buttonGroup}>
            <Button className={classNames("outlineBtn")} text="Cancel" />
            <Button onClick={() => navigate("/pay-dues")} text="Make Payment" />
          </div>
        </div>
      </Modal>
      <Modal
        open={openPayment}
        centered
        footer={null}
        onCancel={handleClosePayment}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Congratulations!!!</h2>
          <p>
            You are eligible to get your license renewal. Now, please proceed to
            pay the fee of <b>N{paymentData?.Amount}</b>
          </p>
          <div className={styles.buttonGroup}>
            <Button
              className={classNames("outlineBtn")}
              onClick={handleClosePayment}
              text="Cancel"
            />
            <Button
              onClick={handlePayment}
              isLoading={processPaymentMutation.isPending}
              disabled={processPaymentMutation.isPending}
              text="Make Payment"
            />
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={paymentSuccess}
        onCancel={closeSuccessModal}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={success} alt="success" />
          <h2>Congratulations!!!</h2>
          <p>
            Your request is being processed. You will be notified when your
            certificate is ready.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
