import styles from "./main.module.scss";
import { ReactComponent as Settings } from "../../assets/settings.svg";
import { ReactComponent as CyberPay } from "../../assets/cyberpay-logo.svg";
import Button from "../../custom/button/button";
import Table, { ColumnsType } from "antd/es/table";
import Pagination from "../../custom/pagination/pagination";
import { Drawer, Spin } from "antd";
import { useState } from "react";
import PaymentHistoryFilters from "./filters";
import { useQuery } from "@tanstack/react-query";
import { getOutstandingDue, getPaymentHistory } from "../../requests";
import { useAtom } from "jotai";
import { userData } from "../../state";
import { formatDate } from "../../utils/formatDate";
import { AxiosError } from "axios";
import { formatCurrency } from "../../utils/formatCurrency";

const columns: ColumnsType<Payment> = [
  {
    title: "Date",
    dataIndex: "PaymentDate",
    key: "PaymentHistoryId",
    render: (_, { PaymentDate }) => formatDate(PaymentDate),
  },
  {
    title: "Amount Paid",
    dataIndex: "Amount",
    key: "PaymentHistoryId",
  },
  {
    title: "Purpose",
    dataIndex: "PaymentAction",
    key: "PaymentHistoryId",
  },
  {
    title: "Channel",
    dataIndex: "PaymentChannel",
    key: "PaymentHistoryId",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "tags",
    render: (_, { PaymentStatus: status }) => {
      let color = status === "Successful" ? styles.successful : styles.failed;
      return <p className={color}>{status}</p>;
    },
  },
];

export interface Filters {
  from?: string;
  to?: string;
  purpose?: string;
  channel?: string;
  status?: string;
}

const Main = () => {
  const drawerWidth = window.innerWidth > 912 ? "26%" : "70%";
  const [user] = useAtom(userData);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    from: "",
    to: "",
    purpose: "",
    channel: "",
    status: "",
  });
  const [activeFilters, setActiveFilters] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const PAGE_SIZE = 10;
  const queryParams = `${user?.MemberId}${filters?.from}${filters?.to}${user?.MemberId}${filters?.channel}${filters?.status}${pageNumber}${PAGE_SIZE}`;

  const handleOpenFilters = () => setOpenFilters(true);
  const handleCloseFilters = () => setOpenFilters(false);
  const handlePagination = (pageNumber: number) => setPageNumber(pageNumber);

  const { data: paymentHistoryData, isLoading } = useQuery({
    queryKey: ["payment-history", queryParams],
    queryFn: () =>
      getPaymentHistory({
        membershipNo: user?.MemberId!,
        startDate: filters?.from || "",
        endDate: filters?.to || "",
        channel: filters?.channel || "",
        status: filters?.status || "",
        pageNumber: pageNumber,
        pageSize: PAGE_SIZE,
      }),
  });

  const outstandingDue = useQuery({
    queryKey: ["get-dues"],
    queryFn: () => getOutstandingDue(user?.MemberId as string),
    enabled: !!user?.MemberId,
  });

  const outstandingData = outstandingDue?.data?.Data as Due;

  if (outstandingDue.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (outstandingDue.isError)
    return <div>{(outstandingDue.error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={styles.heading}>
        <h3>All Payments</h3>
        <Button
          text="Filters"
          iconBefore={
            activeFilters ? (
              <div className={styles.filterBadge}>{activeFilters}</div>
            ) : (
              <Settings />
            )
          }
          onClick={handleOpenFilters}
          className={styles.greyBtn}
        />
      </section>

      <section className={styles.tableContainer}>
        <div className={styles.tableHeading}>
          <CyberPay />
          <div>
            <div className={styles.amount}>
              {/* <span>&#8358;</span> */}
              {formatCurrency(outstandingData?.OutstandingAmount)}
            </div>
            <label>Total Outstanding Payment</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={paymentHistoryData?.reportItems}
          scroll={{ x: true }}
          pagination={false}
          loading={isLoading}
          rootClassName={styles.table}
          locale={{ emptyText: "No payment history yet" }}
        />
        <Pagination
          pageNumber={pageNumber}
          itemsPerPage={PAGE_SIZE}
          totalSize={paymentHistoryData?.TotalCount || 0}
          setPageNumber={handlePagination}
        />
        <Drawer
          placement={"right"}
          onClose={handleCloseFilters}
          open={openFilters}
          width={drawerWidth}
          style={{ fontFamily: "BRFirma-Medium, sans-serif" }}
        >
          <PaymentHistoryFilters
            setFilters={setFilters}
            closeDrawer={handleCloseFilters}
            setActiveFilters={setActiveFilters}
          />
        </Drawer>
      </section>
    </div>
  );
};

export default Main;
