import styles from "./main.module.scss";
import success from "../../assets/success.png";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import { App, Modal } from "antd";
import { useState } from "react";
import * as Yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAllBranches,
  getChairmanByBranch,
  getChaptersByBranch,
  requestEndorsement,
} from "../../requests";
import { useAtomValue } from "jotai";
import { userData, userProfile } from "../../state";
import Select from "../../custom/select/select";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const profile = useAtomValue(userProfile);
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const [branch, setBranch] = useState("");

  const { data: branchesData } = useQuery({
    queryKey: ["get-branches"],
    queryFn: getAllBranches,
  });
  const { data: chaptersData } = useQuery({
    queryKey: ["get-chapters-by-branch"],
    queryFn: () => getChaptersByBranch(branch),
    enabled: !!branch,
  });
  const getBranchChairman = useQuery({
    queryKey: ["get-chairman-by-branch", branch],
    queryFn: () => getChairmanByBranch(branch),
    enabled: !!branch,
  });
  const requestEndorsementMutation = useMutation({
    mutationKey: ["request-endorsement"],
    mutationFn: requestEndorsement,
  });

  const chairman = getBranchChairman?.data?.Data as Chairman;
  const branchOptions = branchesData?.Data?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));
  const chapterOptions = chaptersData?.Data?.map((chapter) => (
    <option key={chapter.ChapterId} value={chapter.ChapterId}>
      {chapter.ChapterName}
    </option>
  ));

  const requestEndorsementHandler = async (values: FormikValues) => {
    const payload: RequestEndorsementPayload = {
      MembershipNo: user?.MemberId!,
      BranchId: parseInt(values?.branch),
      ChapterId: parseInt(values?.chapter),
      EndorsementType: "Branch",
      MembershipTypeId: user?.MembershipTypeId!,
      RequesterComment: values?.comment,
    };

    try {
      await requestEndorsementMutation.mutateAsync(payload, {
        onSuccess: () => {
          openModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: error?.Message || error?.response?.data?.Message,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    branch: Yup.number().required("required"),
    chapter: Yup.number().required("required"),
    branchChairman: Yup.string().required("required"),
    comment: Yup.string().required("required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      branch: 0,
      chapter: 0,
      branchChairman: chairman?.Name,
      comment: "",
    },
    onSubmit: (values) => {
      requestEndorsementHandler(values);
    },
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div>
      <h1 className={styles.formTitle}>Request Endorsement</h1>
      <section className={styles.endorsementForm}>
        <div className={styles.profile}>
          <img
            src={profile?.UploadPictureUrl}
            className={styles.profilePic}
            alt="profile"
          />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.fields}>
              <Field
                as={Select}
                name="branch"
                label="Branch"
                placeholder="Select branch"
                options={branchOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  formik.setFieldValue("branch", e.target.value);
                  setBranch(e.target.value);
                }}
              />
              <Field
                as={Select}
                name="chapter"
                label="Chapter"
                placeholder="Select chapter"
                options={chapterOptions}
              />
              <Field
                as={Input}
                name="branchChairman"
                label="Branch Chairman"
                placeholder="Branch Chairman"
                disabled
              />
              <Field
                as={Input}
                name="comment"
                label="Comment"
                placeholder="Comment"
              />
            </div>
            <div className={styles.ctaGroup}>
              <Button
                text="Cancel"
                onClick={() => navigate("/home")}
                className={styles.btnCancel}
              />
              <Button
                type="submit"
                text={
                  requestEndorsementMutation.isPending
                    ? "Requesting..."
                    : "Request"
                }
                className={styles.btnRequest}
                disabled={requestEndorsementMutation.isPending}
              />
            </div>
          </form>
        </FormikProvider>
      </section>

      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Endorsement Sent!</h2>
          <p>
            You have successfully requested for endorsement from your branch
            chairman.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
