import { useMutation } from "@tanstack/react-query";
import { queryPayment } from "../../requests";
import { Field, FormikProvider, useFormik } from "formik";
import Input from "../../custom/input/input";
import { Alert, App, Card } from "antd";
import { errorMessage } from "../../utils/errorMessage";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";

const QueryTransaction = () => {
  const { notification } = App.useApp();
  const [response, setResponse] = useState({
    type: "",
    status: "",
    description: "",
  });

  const queryPaymentMutation = useMutation({
    mutationKey: ["queryPayment"],
    mutationFn: queryPayment,
  });
  const formik = useFormik({
    initialValues: {
      transactionReference: "",
    },
    onSubmit: async (values) => {
      const { transactionReference } = values;
      try {
        await queryPaymentMutation.mutateAsync(transactionReference, {
          onSuccess: (data) => {
            notification.success({
              message: data.message,
              description: `Transaction ID: ${data.transactionReference}`,
            });
            setResponse({
              type: "success",
              status: data.transactionStatus,
              description: `Reference: ${data.transactionReference}`,
            });
          },
        });
      } catch (error: any) {
        notification.error({
          message: errorMessage(error),
        });
        setResponse({
          type: "error",
          status: "Failed",
          description: error?.response?.data?.message,
        });
      }
    },
  });

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setResponse({
        type: "",
        status: "",
        description: "",
      });
    }, 5000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [queryPaymentMutation.data]); // Empty dependency array ensures the effect runs only once

  return (
    <FormikProvider value={formik}>
      <h3>Check transaction status</h3>
      <br />
      <Card>
        <form onSubmit={formik.handleSubmit}>
          <Field
            name="transactionReference"
            as={Input}
            label="Transaction Reference"
            placeholder="ABC112233445566777889"
            className={styles.inputField}
          />
          <br />
          <Button
            type="submit"
            disabled={queryPaymentMutation.isPending}
            isLoading={queryPaymentMutation.isPending}
            text="Check Status"
          />
        </form>
        <br />
        {!!response.status && (
          <Alert
            message={response.status}
            description={response.description}
            type={response.type === "success" ? "success" : "error"}
            showIcon
            closable
          />
        )}
      </Card>
    </FormikProvider>
  );
};

export default QueryTransaction;
