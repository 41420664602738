import Button from "../../../custom/button/button";
import styles from "./styles.module.scss";
// import Woman from "../../../assets/woman.png";
// import { Image } from "antd";
import { useNavigate } from "react-router-dom";

interface NotificationProps {
  title: string;
  description: string;
  cta: string;
  link: string;
}

const Notification = ({ title, description, cta, link }: NotificationProps) => {
  const navigate = useNavigate();
  return (
    <section className={styles.container}>
      <span className={styles.wrapper}>
        {/* <Image
          src={Woman}
          className={styles.image}
          width={"4.5rem"}
          height={"4.5rem"}
        /> */}
        <div>
          <h4 className={styles.heading}>{title}</h4>
          <p className={styles.para}>{description}</p>
        </div>
      </span>
      <Button text={cta} onClick={() => navigate(link)} />
    </section>
  );
};

export default Notification;
