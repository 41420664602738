import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type ExtraRegisterEventDetails = ProcessPaymentPayload & {
  IsCorporateSponsor: boolean;
}
export type EventStep = number;
export type Nominee = {
  IsAMember: boolean;
  MembershipNo?: string;
  ParticipantName: string;
  ParticipantPhoneNo: string;
  ParticipantEmail: string;
}
const defaultUser = localStorage.getItem("cipm-membership-data");
const defaultNonMember = localStorage.getItem("non-member-data");
const defaultProfile = localStorage.getItem("cipm-membership-profile");
export const userData = atomWithStorage<LoginData | undefined>("cipm-membership-data", JSON.parse(defaultUser!) ?? undefined);
export const trainingStep = atom<EventStep>(1);
export const conferenceStep = atom<EventStep>(1);
export const otherEventsStep = atom<EventStep>(1);
export const userProfile = atomWithStorage<Profile>("cipm-membership-profile", JSON.parse(defaultProfile!) ?? undefined);
export const trainingPayment = atomWithStorage<ExtraRegisterEventDetails>("trainingPayment", {
  RouteId: 0,
  MembershipNo: "",
  Amount: 0,
  Description: "",
  ReturnURL: "",
  PaymentForm: "",
  DataId: 0,
  IsCorporateSponsor: false
});
export const conferencePayment = atomWithStorage<ExtraRegisterEventDetails>("conferencePayment", {
  RouteId: 0,
  MembershipNo: "",
  Amount: 0,
  Description: "",
  ReturnURL: "",
  PaymentForm: "",
  DataId: 0,
  IsCorporateSponsor: false
});
export const otherPayment = atomWithStorage<ExtraRegisterEventDetails>("otherPayment", {
  RouteId: 0,
  MembershipNo: "",
  Amount: 0,
  Description: "",
  ReturnURL: "",
  PaymentForm: "",
  DataId: 0,
  IsCorporateSponsor: false
});
export const trainingPaymentReference = atom<string>("");
export const conferencePaymentReference = atom<string>("");
export const otherPaymentReference = atom<string>("");
export const isUpdateProfile = atomWithStorage('cipm-membership-isUpdateProfile', false);
export const isUpdateCorporateProfile = atom<boolean>(false);
export const getUserGrade = (memberTypeId: number) => {
  switch (memberTypeId) {
    case 1:
      return "Fellow";
    case 2:
      return "Full";
    case 3:
      return "Associate";
    case 4:
      return "Student";
    case 5:
      return "Corporate";
    default:
      return "";
  }
}
export const tempNominees = atom<Nominee[]>([])
export const isCorporate = atomWithStorage<boolean>('cipm-membership-isCorporate', false);
export const corporateDataId = atomWithStorage<string>("corporate-data-id", "");
export const examPayment = atom<ProcessPaymentPayload>({
  RouteId: 0,
  MembershipNo: "",
  Amount: 0,
  Description: "",
  ReturnURL: "",
  PaymentForm: "",
  DataId: ""
})
export const nonMemberData = atomWithStorage<NonMemberLoginData | null>("non-member-data", JSON.parse(defaultNonMember!) ?? undefined);