import styles from "./styles.module.scss";
import Logo from "../../assets/emailSent.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../custom/button/button";

const EmailSent = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const email = state?.state?.email;

  return (
    <section className={styles.section}>
      <img className={styles.icon} src={Logo} alt="email" loading="lazy" />
      <h2 className={styles.heading}>Email Sent!</h2>
      <p className={styles.para}>
        We have sent a link to your registered
        <br /> email
        {email ? <span className={styles.email}>, {email}</span> : null}
        <br />
        Click on it to reset your password.
      </p>
      <br />
      <Button onClick={() => navigate("/")} text="Back to Login" />
    </section>
  );
};

export default EmailSent;
