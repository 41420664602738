import styles from "./verifyButton.module.scss";
import classNames from "classnames";
import Spinner from "../../../../custom/spinner/spinner";

interface Props {
  text: string;
  isAwaiting: boolean;
  handleVerify?: () => void;
  isLoading?: boolean;
}

const VerifyButton = ({ text, isAwaiting, handleVerify, isLoading }: Props) => {
  return (
    <article
      className={classNames(styles.article, isAwaiting && styles.awaiting)}
    >
      <div className={styles.wrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <g clip-path="url(#clip0_19014_15635)">
            <path
              d="M7.5 15C8.98336 15 10.4334 14.5601 11.6668 13.736C12.9001 12.9119 13.8614 11.7406 14.4291 10.3701C14.9968 8.99968 15.1453 7.49168 14.8559 6.03683C14.5665 4.58197 13.8522 3.2456 12.8033 2.1967C11.7544 1.14781 10.418 0.433503 8.96318 0.144114C7.50832 -0.145275 6.00032 0.00324965 4.62987 0.570907C3.25943 1.13856 2.08809 2.09986 1.26398 3.33323C0.439867 4.5666 0 6.01664 0 7.5C0.00215068 9.48847 0.793018 11.3949 2.19907 12.8009C3.60513 14.207 5.51154 14.9979 7.5 15ZM7.5 3.125C7.68542 3.125 7.86668 3.17999 8.02085 3.283C8.17502 3.38601 8.29518 3.53243 8.36614 3.70374C8.43709 3.87504 8.45566 4.06354 8.41949 4.2454C8.38331 4.42726 8.29403 4.5943 8.16291 4.72542C8.0318 4.85653 7.86476 4.94582 7.6829 4.98199C7.50104 5.01816 7.31254 4.9996 7.14123 4.92864C6.96993 4.85768 6.82351 4.73752 6.7205 4.58335C6.61748 4.42918 6.5625 4.24792 6.5625 4.0625C6.5625 3.81386 6.66127 3.57541 6.83709 3.39959C7.0129 3.22378 7.25136 3.125 7.5 3.125ZM6.875 6.25H7.5C7.83152 6.25 8.14946 6.3817 8.38388 6.61612C8.6183 6.85054 8.75 7.16848 8.75 7.5V11.25C8.75 11.4158 8.68415 11.5747 8.56694 11.6919C8.44973 11.8092 8.29076 11.875 8.125 11.875C7.95924 11.875 7.80027 11.8092 7.68306 11.6919C7.56585 11.5747 7.5 11.4158 7.5 11.25V7.5H6.875C6.70924 7.5 6.55027 7.43416 6.43306 7.31695C6.31585 7.19974 6.25 7.04076 6.25 6.875C6.25 6.70924 6.31585 6.55027 6.43306 6.43306C6.55027 6.31585 6.70924 6.25 6.875 6.25Z"
              fill={isAwaiting ? "#AF930D" : "#4D8A6C"}
            />
          </g>
          <defs>
            <clipPath id="clip0_19014_15635">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p className={classNames(styles.text, isAwaiting && styles.awaiting)}>
          {isAwaiting ? "Awaiting Approval" : text}
        </p>
      </div>
      {!isAwaiting && isLoading ? (
        <Spinner width={25} height={25} />
      ) : !isAwaiting ? (
        <button className={styles.btn} onClick={handleVerify} type="button">
          Verify
        </button>
      ) : null}
    </article>
  );
};

export default VerifyButton;
