import { useState } from "react";
import {
  Field,
  FieldArray,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import styles from "./styles.module.scss";
import Details from "../../../custom/details/details";
import Input from "../../../custom/input/input";
import { ReactComponent as Info } from "../../../assets/info.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getWorkExperience, updateWorkExperience } from "../../../requests";
import { useAtomValue } from "jotai";
import { isUpdateProfile, userData } from "../../../state";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { format } from "date-fns/esm";
import Button from "../../../custom/button/button";
import { errorMessage } from "../../../utils/errorMessage";
import AddWorkExperienceModal from "./addWorkExperienceModal";
import classNames from "classnames";

const WorkExperience = () => {
  const user = useAtomValue(userData);
  const { notification } = App.useApp();
  const [open, setOpen] = useState(false);
  const isEditProfile = useAtomValue(isUpdateProfile);
  const [currentField, setCurrentField] = useState<WorkExperience>({
    Id: 0,
    MembershipNo: "",
    Organization: "",
    StartDate: "",
    EndDate: "",
    PositionHeld: "",
    CompanyAddress: "",
    IsCurrentJob: false,
  });

  const updateWorkExperienceMutation = useMutation({
    mutationKey: ["updateWorkExperience"],
    mutationFn: updateWorkExperience,
  });
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["work-experience"],
    queryFn: () => getWorkExperience(user?.MemberId!),
  });

  const workExperienceData = data?.Data as WorkExperience[];

  const updateWorkExperienceHandler = async (values: FormikValues) => {
    const curr = (values.workExperience as WorkExperience[]).find(
      (work) => work.Id === currentField.Id
    ) as WorkExperience;

    const payload: WorkExperience = {
      Id: curr?.Id,
      MembershipNo: user?.MemberId!,
      Organization: curr?.Organization,
      StartDate: curr?.StartDate,
      EndDate: curr?.EndDate,
      PositionHeld: curr?.PositionHeld,
      CompanyAddress: curr?.CompanyAddress,
      IsCurrentJob: curr?.EndDate ? false : true,
    };
    try {
      await updateWorkExperienceMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          refetch();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const updatedData = workExperienceData?.map((x) => ({
    ...x,
    StartDate: x.StartDate
      ? format(new Date(x.StartDate ?? null), "yyyy-MM-dd")
      : "",
    EndDate: x.EndDate ? format(new Date(x.EndDate ?? null), "yyyy-MM-dd") : "",
  }));

  const formik = useFormik<FormikValues>({
    initialValues: {
      workExperience: updatedData,
    },
    onSubmit: (values) => {
      updateWorkExperienceHandler(values);
    },
    enableReinitialize: true,
  });

  if (isLoading) return <Spin />;
  if (isError) return <p className="error">{(error as AxiosError)?.message}</p>;

  return (
    <div>
      <FormikProvider value={formik}>
        <Button
          className={classNames(styles.lightBtn, styles.end)}
          onClick={() => setOpen(true)}
          text="Add Work Experience"
        />
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <FieldArray name="workExperience">
            {() => (
              <>
                {workExperienceData &&
                  workExperienceData?.map((workExperience, index) => (
                    <Details
                      title={`WORK EXPERIENCE - ${index + 1}`}
                      className={styles.details}
                      key={workExperience.Id}
                      isOpen={index === 0}
                    >
                      <form className={styles.details}>
                        <section className={styles.field}>
                          <Field
                            as={Input}
                            type="text"
                            name={`workExperience[${index}].Organization`}
                            label="Organisation Name"
                            placeholder={workExperience?.Organization}
                            className={styles.input}
                            disabled={!isEditProfile}
                          />
                          {workExperience?.IsCurrentJob ? (
                            <p className={styles.smallGap}>
                              <span>
                                <Info />
                              </span>
                              <b>You currently work here</b>
                            </p>
                          ) : null}
                        </section>
                        <section className={styles.fields}>
                          <Field
                            as={Input}
                            type="date"
                            name={`workExperience[${index}].StartDate`}
                            label="Start Date (MM/YY)"
                            placeholder={format(
                              new Date(workExperience?.StartDate ?? null),
                              "yyyy-MM-dd"
                            )}
                            className={styles.input}
                            disabled={!isEditProfile}
                          />
                          <Field
                            as={Input}
                            type="date"
                            name={`workExperience[${index}].EndDate`}
                            label="End Date (MM/YY)"
                            placeholder={
                              workExperience?.EndDate
                                ? format(
                                    new Date(workExperience?.StartDate ?? null),
                                    "yyyy-MM-dd"
                                  )
                                : "Present"
                            }
                            className={styles.input}
                            disabled={!isEditProfile}
                          />
                        </section>
                        <section className={styles.field}>
                          <Field
                            as={Input}
                            type="text"
                            name={`workExperience[${index}].PositionHeld`}
                            label="Position Held"
                            placeholder={workExperience?.PositionHeld}
                            className={styles.input}
                            disabled={!isEditProfile}
                          />
                        </section>
                        <section className={styles.field}>
                          <Field
                            as={Input}
                            type="text"
                            name={`workExperience[${index}].CompanyAddress`}
                            label="Organisation Address"
                            placeholder={workExperience?.CompanyAddress}
                            className={styles.input}
                            disabled={!isEditProfile}
                          />
                        </section>
                        <br />
                        {isEditProfile && (
                          <section className={styles.btnWrapper}>
                            {/* <Button text='Cancel' className={styles.cancelBtn} /> */}
                            <Button
                              text="Save Changes"
                              type="button"
                              onClick={() => {
                                setCurrentField(workExperience);
                                formik.handleSubmit();
                              }}
                              isLoading={
                                updateWorkExperienceMutation?.isPending
                              }
                              disabled={updateWorkExperienceMutation?.isPending}
                            />
                          </section>
                        )}
                        <br />
                      </form>
                    </Details>
                  ))}
              </>
            )}
          </FieldArray>
        </form>
      </FormikProvider>
      <AddWorkExperienceModal open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

export default WorkExperience;
