import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import styles from "./styles.module.scss";
import Textarea from "../../../custom/textarea/textarea";
import Input from "../../../custom/input/input";
import Checkbox from "../../../custom/checkbox/checkbox";
import Button from "../../../custom/button/button";
import VerifyButton from "./verifyButton/verifyButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCorporateProfile, updateCorporateProfile } from "../../../requests";
import { useAtom, useAtomValue } from "jotai";
import { isUpdateCorporateProfile, userData } from "../../../state";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import ChangeOfOfficerModal from "./changeOfOfficerModal";
import classNames from "classnames";
import { errorMessage } from "../../../utils/errorMessage";
import ChangeOfAddressModal from "./changeOfAddressModal";

const CompanyDetails = ({ photo }: { photo: File | null }) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const [areFieldsDisabled, setAreFieldsDisabled] = useAtom(
    isUpdateCorporateProfile
  );
  const [openOfficerModal, setOpenOfficerModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const updateProfileMutation = useMutation({
    mutationKey: ["update-corporate-profile"],
    mutationFn: updateCorporateProfile,
  });
  const {
    data,
    isPending,
    isError,
    error,
    refetch: refetchCompanyDetails,
  } = useQuery({
    queryKey: ["company-details"],
    queryFn: () => getCorporateProfile(user?.MemberId!),
  });
  const companyDetails = data?.Data as CorporateProfile;

  const updateProfile = async (values: FormikValues) => {
    const formData = new FormData();
    formData.append("MembershipNo", user?.MemberId!);
    formData.append("OrganizationName", values.organizationName);
    formData.append("ContactAddress", values.contactAddress);
    formData.append("CompanyPhone", values.companyPhoneNumber);
    formData.append("CompanyPhone", values.companyPhoneNumber);
    formData.append("OfficerName", values.officerName);
    formData.append("OtherPhone", values.mobileNumber);
    formData.append("CompanyEmail", values.email);
    formData.append("OtherEmail", values.altEmail);
    formData.append("BusinessNature", values.natureOfBusiness);
    formData.append("OrganizationType", values.hrType);
    formData.append("LogoUrl", companyDetails?.LogoUrl!);
    formData.append("Logo", photo || companyDetails?.Logo!);
    try {
      await updateProfileMutation.mutateAsync(formData, {
        onSuccess: (data) => {
          notification.success({
            message: "Success",
            description: data.Message,
          });
          refetchCompanyDetails();
          setAreFieldsDisabled(true);
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      organizationName: companyDetails?.OrganizationName,
      contactAddress: companyDetails?.ContactAddress,
      officerName: companyDetails?.OfficerName,
      companyPhoneNumber: companyDetails?.CompanyPhone,
      mobileNumber: companyDetails?.OtherPhone,
      email: companyDetails?.CompanyEmail,
      altEmail: companyDetails?.OtherEmail,
      natureOfBusiness: companyDetails?.BusinessNature,
      hrType:
        companyDetails?.OrganizationType === "Dedicated Hr Role"
          ? "role"
          : companyDetails?.OrganizationType === "Dedicated Hr Department"
          ? "department"
          : "none",
      hasInstituteMembers: [],
    },
    onSubmit: (values) => updateProfile(values),
    enableReinitialize: true,
  });

  const isSameValue = (previousVal: string, currVal: string): boolean => {
    return (
      previousVal?.toLocaleLowerCase()?.trim() ===
      currVal?.toLocaleLowerCase()?.trim()
    );
  };

  const handleChangeOfAddress = () => {
    if (
      isSameValue(companyDetails?.ContactAddress, formik.values.contactAddress)
    ) {
      notification.warning({
        message:
          "Value has not changed. Kindly change the value to request for verification.",
      });
      return;
    }
    setOpenAddressModal(true);
  };
  const handleChangeOfOfficer = () => {
    if (
      isSameValue(companyDetails?.OfficerName, formik.values.contactAddress)
    ) {
      notification.warning({
        message: "Warning",
        description:
          "Value has not changed. Kindly change the value to request for verification.",
      });
      return;
    }
    setOpenOfficerModal(true);
  };

  if (isPending)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Field
          name="organizationName"
          as={Input}
          label="Name of organization"
          placeholder="Kaima Technologies"
          disabled={!areFieldsDisabled}
        />
        <div>
          <Field
            name="contactAddress"
            as={Textarea}
            label="Contact Address"
            placeholder="No 1, Kaima Street, Lagos"
            disabled={
              !areFieldsDisabled || companyDetails?.IsAddressApproved === false
            }
          />
          {areFieldsDisabled && (
            <VerifyButton
              text="Verification is needed for updates"
              isAwaiting={companyDetails?.IsAddressApproved === false}
              handleVerify={handleChangeOfAddress}
            />
          )}
        </div>
        <div>
          <Field
            name="officerName"
            as={Input}
            label="Officer Name"
            placeholder="John Doe"
            disabled={
              !areFieldsDisabled || companyDetails?.IsOfficerApproved === false
            }
          />
          {areFieldsDisabled && (
            <VerifyButton
              text="Verification is needed for updates"
              isAwaiting={companyDetails?.IsOfficerApproved === false}
              handleVerify={handleChangeOfOfficer}
            />
          )}
        </div>
        <div className={styles.fields}>
          <Field
            name="companyPhoneNumber"
            as={Input}
            label="Company's Phone Number"
            disabled={!areFieldsDisabled}
          />
          <Field
            name="mobileNumber"
            as={Input}
            label="Mobile Number"
            disabled={!areFieldsDisabled}
          />
        </div>
        <div className={styles.fields}>
          <Field
            name="email"
            as={Input}
            label="Company's Email Address"
            disabled={!areFieldsDisabled}
          />
          <Field
            name="altEmail"
            as={Input}
            label="Alternative Email Address"
            disabled={!areFieldsDisabled}
          />
        </div>

        <Field
          name="natureOfBusiness"
          as={Textarea}
          label="Nature of Business"
          disabled={!areFieldsDisabled}
        />
        <hr className={styles.hr} />
        <section>
          <label className={styles.label}>
            <b>Kindly select which applies to your organization</b>
          </label>
          <div className={styles.checkboxWrapper}>
            <Field
              name="hrType"
              as={Checkbox}
              value="role"
              checked={formik.values.hrType === "role"}
              label="Dedicated HR Role"
              disabled={true}
            />
            <Field
              name="hrType"
              as={Checkbox}
              value="department"
              checked={formik.values.hrType === "department"}
              label="Dedicated HR Department"
              disabled={true}
            />
            <Field
              name="hrType"
              as={Checkbox}
              value="none"
              checked={formik.values.hrType === "none"}
              label="None"
              disabled={true}
            />
          </div>
        </section>
        <hr className={styles.hr} />
        <section>
          <label className={styles.label}>
            <b>
              Do you have members of your staff who are members of the institute
              (CIPM) ?
            </b>
          </label>
          <div className={styles.checkboxWrapper}>
            <Field
              name="hasInstituteMembers"
              as={Checkbox}
              //checked={formik.values.hasInstituteMembers === "yes"}
              label="Yes"
              disabled={true}
            />
            <Field
              name="hasInstituteMembers"
              as={Checkbox}
              checked={true}
              //checked={formik.values.hasInstituteMembers === "no"}
              label="No"
              disabled={true}
            />
          </div>
        </section>
        {areFieldsDisabled && (
          <section className={styles.btnWrapper}>
            {/* <Button
              onClick={() => setAreFieldsDisabled(true)}
              text="Cancel"
              type="button"
              className={classNames("cancelBtn")}
            /> */}
            <Button
              type="submit"
              isLoading={updateProfileMutation.isPending}
              disabled={updateProfileMutation.isPending}
              text="Save Changes"
            />
          </section>
        )}
      </form>
      <ChangeOfOfficerModal
        isOpen={openOfficerModal}
        phoneNumber={companyDetails?.CompanyPhone}
        name={formik.values.officerName}
        resetForm={formik.resetForm}
        refetch={refetchCompanyDetails}
        closeModal={() => setOpenOfficerModal(false)}
      />
      <ChangeOfAddressModal
        isOpen={openAddressModal}
        phoneNumber={companyDetails?.CompanyPhone}
        address={formik.values.contactAddress}
        resetForm={formik.resetForm}
        refetch={refetchCompanyDetails}
        closeModal={() => setOpenAddressModal(false)}
      />
    </FormikProvider>
  );
};

export default CompanyDetails;
