import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import styles from "./main.module.scss";
import Select from "../../custom/select/select";
import Button from "../../custom/button/button";
import Input from "../../custom/input/input";
import warning from "../../assets/warning.png";
import avatar from "../../assets/user.png";
import classNames from "classnames";
import { App, Modal } from "antd";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { baseUrl, getPercentages, processPayment } from "../../requests";
import { useAtomValue } from "jotai";
import { userData, userProfile } from "../../state";
import * as Yup from "yup";

const MakePayment = ({ outstandingAmount }: { outstandingAmount: number }) => {
  const user = useAtomValue(userData);
  const profile = useAtomValue(userProfile);
  const { notification } = App.useApp();
  const [openRestricted, setOpenRestricted] = useState(false);

  const handleOpenRestricted = () => setOpenRestricted(true);
  const handleCloseRestricted = () => setOpenRestricted(false);

  const validationSchema = Yup.object().shape({
    paymentType: Yup.string().required("required"),
    modeOfPayment: Yup.string().required("required"),
    percentagePayment: Yup.string().when("modeOfPayment", {
      is: "Instalment Payment",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    amount: Yup.string().required("required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      paymentType: "Annual Subscription",
      modeOfPayment: "Full Payment",
      percentagePayment: "",
      amount: outstandingAmount,
    },
    onSubmit: (values) => {
      if (values.modeOfPayment === "Instalment Payment") {
        handleOpenRestricted();
      } else payDuesHandler(values);
    },
    enableReinitialize: true,
    validationSchema,
  });

  const payDuesMutation = useMutation({
    mutationKey: ["pay-dues"],
    mutationFn: processPayment,
  });
  const { data } = useQuery({
    queryKey: ["get-percentage"],
    queryFn: getPercentages,
    enabled: formik.values.modeOfPayment === "Instalment Payment",
  });

  const payDuesHandler = (values: FormikValues) => {
    const payload: ProcessPaymentPayload = {
      RouteId: user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: parseInt(values.amount),
      Description: "Subscription Fee payment",
      ReturnURL: `${baseUrl}/pay-dues`,
      PaymentForm: "Due",
      DataId: "",
    };

    try {
      payDuesMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
          //window.open(responseData?.RedirectURL, "_blank");
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.title,
      });
    }
  };

  const paymentModeOptions = (
    <>
      <option>Full Payment</option>
      <option>Instalment Payment</option>
    </>
  );
  const percentagePaymentOptions =
    data &&
    data?.Data.map((item) => (
      <option key={item.Id} value={item.Percentage}>
        {item.PercentageName}
      </option>
    ));

  return (
    <div>
      <br />
      <h3>Make Payment</h3>
      <section className={classNames("tableContainer")}>
        <div className={styles.profile}>
          <img src={profile?.UploadPictureUrl || avatar} alt="woman" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.fields}>
              <Field
                name="paymentType"
                as={Input}
                label="Payment Type"
                placeholder="Select"
                disabled
              />
              <Field
                name="modeOfPayment"
                as={Select}
                label="Mode of Payment"
                placeholder="Select"
                options={paymentModeOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const mode = e.target.value;
                  formik.setFieldValue("modeOfPayment", mode);
                  formik.setFieldValue(
                    "amount",
                    mode === "Full Payment" ? outstandingAmount : ""
                  );
                }}
              />
              {formik.values.modeOfPayment === "Instalment Payment" && (
                <Field
                  name="percentagePayment"
                  as={Select}
                  label="How much do you want to pay?"
                  placeholder="Select"
                  options={percentagePaymentOptions}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const percentage = e.target.value;
                    const amount = percentage
                      ? (outstandingAmount * parseInt(percentage)) / 100
                      : outstandingAmount;
                    formik.setFieldValue("percentagePayment", percentage);
                    formik.setFieldValue("amount", amount);
                  }}
                />
              )}
              <Field
                name="amount"
                as={Input}
                label="Amount"
                placeholder="200,000"
                disabled
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button text="Cancel" className={classNames("cancelBtn")} />
              <Button
                type="submit"
                text={`Pay ${
                  formik.values.amount && `₦${formik.values.amount}`
                }`}
                isLoading={
                  formik.values.modeOfPayment === "Full Payment" &&
                  payDuesMutation.isPending
                }
                disabled={
                  formik.values.modeOfPayment === "Full Payment" &&
                  payDuesMutation.isPending
                }
                color="#FFFFFF"
                className={styles.bgGreen}
              />
            </div>
          </form>
        </FormikProvider>
      </section>
      <Modal
        open={openRestricted}
        centered
        onCancel={handleCloseRestricted}
        footer={null}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Restricted Access</h2>
          <p>Paying instalmentally will restrict membership benefits like:</p>
          <ul className={styles.list}>
            <li>Ability to launch conference on an active member</li>
            <li>MCPE training</li>
            <li>Printing of License</li>
          </ul>
          <br />
          <div className={styles.fullBtnGroup}>
            <Button text="Cancel" className={classNames("outlineBtn")} />
            <Button
              text="Proceed"
              isLoading={payDuesMutation.isPending}
              disabled={payDuesMutation.isPending}
              onClick={() => payDuesHandler(formik.values)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MakePayment;
