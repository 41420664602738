import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { App, Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAtom, useSetAtom } from "jotai";
import * as Yup from "yup";
import { MemberType, signIn } from "../../requests";
import { isCorporate, userData } from "../../state";

const cpKey = process.env.REACT_APP_RECAPTCHA_KEY as string;

const Signin = () => {
  const { notification } = App.useApp();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [user, setUser] = useAtom(userData);
  const setIsCorporate = useSetAtom(isCorporate);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.Token) {
      if (user?.MembershipTypeId === MemberType.Corporate) {
        setIsCorporate(true);
      } else setIsCorporate(false);
      navigate(
        user?.IsDefaultPassword
          ? "/change-password"
          : user?.IsNewUser
          ? "/update-profile"
          : "/home"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.Token]);

  const loginMutation = useMutation({
    mutationKey: ["login"],
    mutationFn: signIn,
  });

  const loginHandler = async (values: FormikValues) => {
    const payload: LoginPayload = {
      email: values?.email.trim(),
      password: values?.password.trim(),
    };
    try {
      await loginMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          setUser(data?.Data);
        },
      });
    } catch (error: any) {
      notification.error({
        message: error?.Message || error?.response?.data?.Message,
      });
    }
  };

  const validationRules = Yup.object().shape({
    email: Yup.string().required("required"),
    password: Yup.string().required("required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      if (recaptchaToken) {
        loginHandler(values);
      } else {
        notification.error({
          message: "Please verify captcha before signing in",
        });
      }
    },
    validationSchema: validationRules,
  });

  const handleRecaptcha = (token: string | null) => {
    setRecaptchaToken(token);
  };

  return (
    <FormikProvider value={formik}>
      <section className={styles.section}>
        <Link to="/non-member-events" className={styles.rememberMeText}>
          Not a member?{" "}
          <span className={styles.green}>Go to non-member portal</span>
        </Link>
        <h2 className={styles.heading}>Welcome Back!</h2>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <section className={styles.fields}>
            <Field
              as={Input}
              type="text"
              name="email"
              label="Membership ID / Email"
              placeholder="Eg (123456789)"
              className={styles.input}
            />
            <Field
              type="password"
              as={Input}
              name="password"
              label="Password"
              placeholder="Enter password"
              className={styles.input}
            />
          </section>
          <section className={styles.rememberMe}>
            <span className={styles.checkboxWrapper}>
              <Checkbox />
              <p className={styles.rememberMeText}>Remember Me</p>
            </span>

            <Link to="/forgot-password" className={styles.rememberMeText}>
              Forgot password?
            </Link>
          </section>
          <ReCAPTCHA
            sitekey={cpKey}
            onChange={handleRecaptcha}
            className={styles.recaptcha}
          />
          <Button
            type="submit"
            disabled={loginMutation.isPending}
            text={loginMutation.isPending ? "Signing in..." : "Sign in"}
            isLoading={loginMutation.isPending}
            className={styles.signin}
          />
        </form>
      </section>
    </FormikProvider>
  );
};

export default Signin;
