import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { useMutation } from "@tanstack/react-query";
import { MemberType, changePassword } from "../../requests";
import * as Yup from "yup";
import { App } from "antd";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/logout";
import classNames from "classnames";
import { errorMessage } from "../../utils/errorMessage";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;

  const changePasswordMutation = useMutation({
    mutationKey: ["change-password"],
    mutationFn: changePassword,
  });

  const updateBasicDetailsHandler = async (values: FormikValues) => {
    const payload: ChangePasswordPayload = {
      CurrentPassword: values.currentPassword,
      NewPassword: values.newPassword,
      ConfirmPassword: values.confirmPassword,
      MembershipNo: user?.MemberId!,
    };
    try {
      await changePasswordMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: data?.Message,
          });
          navigate(isCorporate ? "/home" : "/update-profile");
        },
      });
    } catch (error: any) {
      notification.error({
        message: errorMessage(error),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("required"),
    newPassword: Yup.string()
      .required("required")
      .min(9)
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter"
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter"
      )
      .matches(/(?=.*\d)/, "Password must contain at least one number"),
    confirmPassword: Yup.string()
      .required("required")
      .oneOf([Yup.ref("newPassword")], "Passwords must match"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      updateBasicDetailsHandler(values);
    },
    enableReinitialize: true,
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <section className={styles.section}>
        <h2 className={styles.heading}>Change Password</h2>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <section className={styles.fields}>
            <Field
              as={Input}
              name="currentPassword"
              label="Current Password"
              placeholder="********"
              type="password"
              className={styles.input}
            />
            <Field
              as={Input}
              name="newPassword"
              label="New Password"
              placeholder="********"
              type="password"
              className={styles.input}
            />
            <Field
              as={Input}
              name="confirmPassword"
              label="Confirm Password"
              placeholder="********"
              type="password"
              className={styles.input}
            />
          </section>
          <div className={styles.buttonGroup}>
            <Button
              text="Cancel"
              type="button"
              className={classNames("cancelBtn")}
              onClick={logout}
            />
            <Button
              type="submit"
              text="Update"
              isLoading={changePasswordMutation.isPending}
              disabled={changePasswordMutation.isPending}
            />
          </div>
        </form>
      </section>
    </FormikProvider>
  );
};

export default ChangePassword;
