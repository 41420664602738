import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "../../assets/logo-small.svg";
import Button from "../../custom/button/button";

const NonMemberLayout = () => {
  const navigate = useNavigate();
  return (
    <main>
      <section className={styles.belowNav}>
        <Logo />
        <Button text="Back to member portal" onClick={() => navigate("/")} />
      </section>

      <section className={styles.content}>
        <h1 className={styles.events}>Events & Conferences</h1>
        <h4 className={styles.get}>
          Get the latest information about all our events and Conferences at
          CIPM
        </h4>

        <div className={styles.btnContainer}>
          <NavLink
            to={"/non-member-events/training"}
            className={({ isActive }) =>
              isActive ? styles.activeButton : styles.button
            }
          >
            Trainings
          </NavLink>
          <NavLink
            to={"/non-member-events/conference"}
            className={({ isActive }) =>
              isActive ? styles.activeButton : styles.button
            }
          >
            Conferences
          </NavLink>
          <NavLink
            to={"/non-member-events/other"}
            className={({ isActive }) =>
              isActive ? styles.activeButton : styles.button
            }
          >
            Other Events
          </NavLink>
        </div>

        <Outlet />
      </section>
    </main>
  );
};

export default NonMemberLayout;
