import { ReactComponent as BackArrow } from "../../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../../assets/clock.svg";
import { ReactComponent as Info } from "../../../assets/info.svg";
import Button from "../../../custom/button/button";
import classNames from "classnames";
import styles from "../styles.module.scss";
import Points from "../../../assets/points.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getAllBranches,
  getConferenceSessions,
  getEventById,
  getMaterials,
  registerNonMemberEvent,
} from "../../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import PayForConference from "./payment";
import { getDateWithoutTime } from "../../../utils/formatDate";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import { useAtom } from "jotai";
import { conferencePayment } from "../../../state";
import Input from "../../../custom/input/input";
import * as Yup from "yup";
import Upload from "../../../custom/upload/upload";
import Select from "../../../custom/select/select";
import { joinStrings } from "../../../utils/joinStrings";

const ApplyForNonMemberConference = () => {
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [isEventScheduled, setIsEventScheduled] = useState(false);
  const [conferencePaymentDetails, setConferencePaymentDetails] =
    useAtom(conferencePayment);
  const navigate = useNavigate();
  const learningEventId = params.get("learningEventId");
  const [morningSession, setMorningSession] = useState<string[]>([]);
  const [afternoonSession, setAfternoonSession] = useState<string[]>([]);

  const registerEventMutation = useMutation({
    mutationKey: ["register-event"],
    mutationFn: registerNonMemberEvent,
  });
  const conferenceInfo = useQuery({
    queryKey: ["get-conference-details"],
    queryFn: () => getEventById(parseInt(learningEventId!)),
    enabled: !!learningEventId,
  });
  const materials = useQuery({
    queryKey: ["get-conference-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const sessions = useQuery({
    queryKey: ["get-conference-sessions"],
    queryFn: () => getConferenceSessions(learningEventId!),
  });
  const branches = useQuery({
    queryKey: ["get-branches"],
    queryFn: getAllBranches,
  });

  const validationSchema = Yup.object().shape({
    session: Yup.array().min(1).max(2).required("required"),
    name: Yup.string().required("required"),
    phoneNumber: Yup.string().required("required"),
    email: Yup.string().email("Invalid email").required("required"),
    branch: Yup.number().min(1).required("required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      session: [],
      name: "",
      phoneNumber: "",
      email: "",
      branch: 0,
    },
    onSubmit: (values) => {
      if (!profilePic) {
        notification.error({
          message: "Please upload a profile picture",
        });
        return;
      } else {
        registerEventHandler(values);
      }
    },
    validationSchema,
  });

  const materialData = materials?.data?.Data as Material[];
  const conferenceInfoData = conferenceInfo?.data?.Data as EventById;
  const sessionData = sessions?.data?.Data as Session[];
  const branchData = branches?.data?.Data;

  const branchOptions = branchData?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));

  const hasMorningSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "A"
  );
  const hasAfternoonSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "B"
  );

  const hasBothSessions = hasMorningSessions && hasAfternoonSessions;

  const handleSubmit = () => {
    if (hasBothSessions) {
      if (formik.values?.session?.length !== 2) {
        notification.warning({
          message: "Please select both session groups",
        });
      } else {
        formik.submitForm();
      }
    } else {
      formik.submitForm();
    }
  };

  if (conferenceInfo.isLoading) return <Spin />;
  if (conferenceInfo.isError)
    return <div>{(conferenceInfo.error as AxiosError)?.message}</div>;

  const registerEventHandler = async (values: FormikValues) => {
    const payload = new FormData();
    payload.append("ParticipantName", values.name);
    payload.append("ParticipantPhoneNo", values.phoneNumber);
    payload.append("ParticipantEmail", values.email);
    payload.append("IsAMember", "false");
    payload.append(
      "RequirePayment",
      `${conferenceInfoData?.IsPaymentRequired}`
    );
    payload.append("HasPaid", "false");
    payload.append("HasAttended", "false");
    payload.append("EventId", `${conferenceInfoData?.EventNameId}`);
    payload.append("ConferenceSessionId", joinStrings(values.session));
    payload.append("IsCorporateSponsor", "false");
    payload.append("EvenType", "Conference");
    payload.append("EventCategoryId", `${conferenceInfoData?.EventCategoryId}`);
    payload.append("EventDetailId", `${conferenceInfoData?.LearningEventId}`);
    payload.append("BranchId", `${parseInt(values.branch)}`);
    //payload.append("ConferenceSessionId", "")
    if (profilePic) {
      payload.append("Picture", profilePic);
    }

    try {
      await registerEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const dataAsObj = data?.Data as ProcessPaymentPayload;
          setConferencePaymentDetails({
            RouteId: dataAsObj?.RouteId,
            MembershipNo: dataAsObj?.MembershipNo,
            Amount: dataAsObj?.Amount,
            Description: dataAsObj?.Description,
            ReturnURL: dataAsObj?.ReturnURL,
            PaymentForm: dataAsObj?.PaymentForm,
            DataId: dataAsObj?.DataId,
            IsCorporateSponsor: formik.values.memberType === "corporate",
          });
          setIsEventScheduled(true);
          if (dataAsObj?.Amount === 0) {
            navigate("/non-member-events/conference");
            notification.success({
              message: "Success",
              description:
                "Event registered successfully. Please check your mail for your entry badge.",
            });
          }
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.message ||
          error?.message,
      });
      if (
        error?.response?.data?.Message?.includes(
          "The member has registered for the event"
        )
      ) {
        const dataAsObj = error?.response?.data?.Data as ProcessPaymentPayload;
        const dataAsNumber = error?.response?.data?.Data as number;
        setConferencePaymentDetails({
          RouteId: dataAsObj?.RouteId,
          MembershipNo: dataAsObj?.MembershipNo,
          Amount: dataAsObj?.Amount,
          Description: dataAsObj?.Description,
          ReturnURL: dataAsObj?.ReturnURL,
          PaymentForm: dataAsObj?.PaymentForm,
          DataId:
            formik.values.memberType === "corporate"
              ? dataAsNumber
              : dataAsObj?.DataId,
          IsCorporateSponsor: formik.values.memberType === "corporate",
        });
        //setIsEventScheduled(true);
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setProfilePic(files[0]);
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <section className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Conference Details</h3>
        </section>
        <section className={styles.formContainer}>
          <Upload
            label="Upload Profile Picture"
            accept="img"
            allowedFormats={["img"]}
            onChange={handleFileChange}
            fileName={profilePic?.name}
            fileSize={profilePic?.size}
          />
          <Field as={Input} name="name" label="Name" placeholder="Name" />
          <div className={styles.fields}>
            <Field
              as={Input}
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone number"
            />
            <Field
              as={Input}
              name="email"
              label="Email Address"
              placeholder="Email address"
            />
          </div>
        </section>
        <section className={styles.detailsContainer2}>
          <div className={styles.detailsContent2}>
            <h2>
              {conferenceInfoData?.EventName}
              <span>
                <img src={Points} alt="points" />
              </span>
            </h2>
            <br />
            <div className={styles.line}></div>
            <br />
            {/* <h3>Venue</h3>
            <p>{conferenceInfoData?.Venue}</p>
            <br /> */}
            <h3>Business Case</h3>
            <p>{conferenceInfoData?.BusinessCase || "N/A"}</p>
            <br />
            <h3>Who to Participate</h3>
            <ul>
              <li>{conferenceInfoData?.TargetMember || "N/A"}</li>
            </ul>
            <br />
            <h3>Core Conference Objectives</h3>
            <p>{conferenceInfoData?.CoreObjective || "N/A"}</p>
            <br />
            <h3>Talking Key Points</h3>
            <p>{conferenceInfoData?.DiscussionPoint || "N/A"}</p>
            <br />
            {materialData && materialData?.length > 0 ? (
              <h3>Conference Schedule Material</h3>
            ) : null}
            <br />
            <div className={styles.list}>
              {materialData?.map((item, index) => (
                <div key={index} className={styles.files}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
            </div>
            <br />
            <h4>Plenary Session Details</h4>
            <label>
              <Info /> Attendance is compulsory
            </label>
            {sessionData
              ?.filter((x) => x.IsTecnicalSession === false)
              .map((item) => {
                const startDate = getDateWithoutTime(item.StartDate);
                return (
                  <section key={item.Id} className={styles.eventSchedule}>
                    <div className={styles.smallFlexGap}>
                      <Clock />
                      <div className={styles.sessionDetails}>
                        <small>{item.SessionType?.toUpperCase()}</small>
                        <h4>{item.SessionName}</h4>
                        <div className={styles.sessionDescription}>
                          Synopsis: {item.Synopsis || "N/A"}
                        </div>
                        <p>Speaker: {item.Speaker}</p>
                        <div>
                          {startDate} &#8226; {item.SessionStartTime} -{" "}
                          {item.SessionEndTime}
                        </div>
                      </div>
                    </div>
                  </section>
                );
              })}
          </div>
          {isEventScheduled ? (
            <div className={styles.list}>
              {conferenceInfoData?.IsPaymentRequired && (
                <PayForConference
                  amount={conferencePaymentDetails?.Amount}
                  email={formik.values.email}
                />
              )}
            </div>
          ) : (
            <div className={styles.scheduleContainer}>
              {hasMorningSessions && <h3>Select technical session: Group 1</h3>}
              {sessionData
                ?.filter(
                  (x) =>
                    x.IsTecnicalSession &&
                    x.TechnicalGroup?.toUpperCase() === "A"
                )
                ?.map((item) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  return (
                    <section key={item.Id} className={styles.eventSchedule}>
                      <div className={styles.smallGap}>
                        <Clock />
                        <div className={styles.sessionDetails}>
                          <small>{item.SessionType?.toUpperCase()}</small>
                          <h4>{item.SessionName}</h4>
                          <p className={styles.sessionDescription}>
                            Synopsis: {item.Synopsis || "N/A"}
                          </p>
                          <p>Speaker: {item.Speaker}</p>
                          <div>
                            {startDate} &#8226; {item.SessionStartTime} -{" "}
                            {item.SessionEndTime}
                          </div>
                        </div>
                      </div>
                      <input
                        type="radio"
                        name="session1"
                        onChange={() => {
                          setMorningSession((prev) => [...prev, `${item.Id}`]);
                          formik.setFieldValue("session", [
                            `${item.Id}`,
                            ...afternoonSession,
                          ]);
                        }}
                      />
                    </section>
                  );
                })}
              <br />
              {hasAfternoonSessions && (
                <h3>Select technical session: Group 2</h3>
              )}
              {sessionData
                ?.filter(
                  (x) =>
                    x.IsTecnicalSession &&
                    x.TechnicalGroup?.toUpperCase() === "B"
                )
                ?.map((item) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  return (
                    <section key={item.Id} className={styles.eventSchedule}>
                      <div className={styles.smallGap}>
                        <Clock />
                        <div className={styles.sessionDetails}>
                          <small>{item.SessionType?.toUpperCase()}</small>
                          <h4>{item.SessionName}</h4>
                          <p className={styles.sessionDescription}>
                            Synopsis: {item.Synopsis || "N/A"}
                          </p>
                          <p>Speaker: {item.Speaker}</p>
                          <div>
                            {startDate} &#8226; {item.SessionStartTime} -{" "}
                            {item.SessionEndTime}
                          </div>
                        </div>
                      </div>
                      <input
                        type="radio"
                        name="session2"
                        onChange={() => {
                          setAfternoonSession((prev) => [
                            ...prev,
                            `${item.Id}`,
                          ]);
                          formik.setFieldValue("session", [
                            ...morningSession,
                            `${item.Id}`,
                          ]);
                        }}
                      />
                    </section>
                  );
                })}
              <ErrorMessage name="session">
                {(msg) => <div className={styles.error}>{msg}</div>}
              </ErrorMessage>
              <br />
              <div className={styles.fields}>
                {/* <Field
                  name="state"
                  as={Select}
                  label="State"
                  placeholder="State"
                  options={stateOptions}
                /> */}
                <Field
                  name="branch"
                  as={Select}
                  label="Location"
                  placeholder="Select location"
                  options={branchOptions}
                />
              </div>
              <br />
              <Button
                isLoading={registerEventMutation.isPending}
                disabled={registerEventMutation.isPending}
                type="button"
                onClick={handleSubmit}
                text={
                  conferenceInfoData?.IsPaymentRequired
                    ? "Proceed to Payment"
                    : "Register"
                }
              />
            </div>
          )}
        </section>
      </form>
    </FormikProvider>
  );
};

export default ApplyForNonMemberConference;
