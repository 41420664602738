import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import styles from "./styles.module.scss";
import Input from "../../../custom/input/input";
import Checkbox from "../../../custom/checkbox/checkbox";
import { useQuery } from "@tanstack/react-query";
import { getTopManagement } from "../../../requests";
import { useAtomValue } from "jotai";
import { userProfile } from "../../../state";
import { Spin } from "antd";
import { AxiosError } from "axios";

const TopManagement = () => {
  const profile = useAtomValue(userProfile);

  const { data, isPending, isError, error } = useQuery({
    queryKey: ["top-management"],
    queryFn: () => getTopManagement(profile?.MembershipNo!),
  });

  const management = data?.Data as TopManagement;

  const formik = useFormik<FormikValues>({
    initialValues: {
      ceoName: management?.DirectorName,
      ceoEmail: management?.DirectorEmail,
      phoneNumber: management?.Telephone,
      mobileNumber: management?.MobilePhone,
      verifyInfo: "no",
      name: profile?.Name,
      designation: "",
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  if (isPending)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Field
          name="ceoName"
          as={Input}
          label="Name of Chief Executive / Director"
          placeholder="Kaima Technologies"
        />
        <Field
          name="ceoEmail"
          as={Input}
          label="Email of Chief Executive / Director"
          placeholder="Kaima Technologies"
        />
        <div className={styles.fields}>
          <Field name="phoneNumber" as={Input} label="Phone Number" />
          <Field name="mobileNumber" as={Input} label="Mobile Number" />
        </div>
        <hr className={styles.hr} />
        <div className={styles.checkboxWrapper}>
          <Field
            name="verifyInfo"
            as={Checkbox}
            value="yes"
            label="Declaration: I hereby declare that the information provided above are correct"
          />
        </div>
        <Field name="name" as={Input} label="Name" />
        <Field name="designation" as={Input} label="Designation" />
      </form>
    </FormikProvider>
  );
};

export default TopManagement;
