import classNames from "classnames";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { useState } from "react";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { registerNonMember, signInNonMember } from "../../requests";
import { App } from "antd";
import { errorMessage } from "../../utils/errorMessage";
import { useSetAtom } from "jotai";
import { nonMemberData } from "../../state";

const NonMemberAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { notification } = App.useApp();
  const setNonMember = useSetAtom(nonMemberData);
  const [hasAccount, setHasAccount] = useState(true);
  const from =
    (location.state as { from: string })?.from || `/non-member-events`;

  const registerNonMemberMutation = useMutation({
    mutationKey: ["register-non-member"],
    mutationFn: registerNonMember,
  });
  const signInNonMemberMutation = useMutation({
    mutationKey: ["sign-in-non-member"],
    mutationFn: signInNonMember,
  });
  const registerHandler = async (values: FormikValues) => {
    const payload: RegisterNonMemberPayload = {
      Name: values.organizationName,
      Address: values.organizationAddress,
      Email: values.organizationEmail,
      Password: values.organizationPassword,
      ContactName: values.contactName,
      ContactEmail: values.contactEmail,
      ContactPhoneNumber: values.contactPhone,
      IsOrganiztion: true,
    };

    try {
      await registerNonMemberMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: "Success",
            description: data?.Message,
          });
          setHasAccount(true);
          navigate(from, { replace: true });
          // navigate(
          //   `/non-member-events/${eventType}/apply-as-org?learningEventId=${learningEventId}`
          // );
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };
  const loginHandler = async (values: FormikValues) => {
    const payload = {
      Email: values.organizationEmail,
      Password: values.organizationPassword,
    };

    try {
      await signInNonMemberMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: "Success",
            description: data?.Message,
          });
          setNonMember(data?.Data);
          navigate(from, { replace: true });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      organizationEmail: "",
      organizationName: "",
      organizationPhone: "",
      organizationAddress: "",
      organizationPassword: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
    },
    onSubmit: (values) => {
      hasAccount ? loginHandler(values) : registerHandler(values);
    },
  });

  return (
    <div className={styles.formContainer}>
      <section className={classNames("spaceBetween")}>
        <h2 className={styles.green}>
          {hasAccount
            ? "Log into your organization account"
            : "Create an organization account"}
        </h2>
        {hasAccount ? (
          <label>
            Don't have an account?{" "}
            <button
              className={styles.greenTitle}
              onClick={() => setHasAccount(false)}
            >
              Sign up
            </button>
          </label>
        ) : (
          <label>
            Already have an account?{" "}
            <button
              className={styles.greenTitle}
              onClick={() => setHasAccount(true)}
            >
              Sign in
            </button>
          </label>
        )}
      </section>
      <br />
      <section>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            {hasAccount ? (
              <section>
                <div className={styles.fields}>
                  <Field
                    name="organizationEmail"
                    as={Input}
                    label="Organization Email"
                    placeholder="Organization Email"
                  />
                  <Field
                    name="organizationPassword"
                    as={Input}
                    type="password"
                    label="Organization Password"
                    placeholder="Organization Password"
                  />
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => navigate("/non-member-events/forgot-password")}
                  className={styles.greenTitle}
                >
                  Forgot Password?
                </button>
              </section>
            ) : (
              <div className={styles.list}>
                <Field
                  name="organizationName"
                  as={Input}
                  label="Organization Name"
                  placeholder="Organization Name"
                />
                <Field
                  name="organizationAddress"
                  as={Input}
                  label="Organization Address"
                  placeholder="Organization Address"
                />
                <div className={styles.fields}>
                  <Field
                    name="organizationEmail"
                    as={Input}
                    label="Organization Email"
                    placeholder="Organization Email"
                  />
                  <Field
                    name="organizationPassword"
                    as={Input}
                    type="password"
                    label="Organization Password"
                    placeholder="Organization Password"
                  />
                </div>
                <Field
                  name="contactName"
                  as={Input}
                  label="Contact Person Name"
                  placeholder="Contact Person Name"
                />
                <div className={styles.fields}>
                  <Field
                    name="contactPhone"
                    as={Input}
                    label="Contact Person Phone Number"
                    placeholder="Contact Person Phone Number"
                  />
                  <Field
                    name="contactEmail"
                    as={Input}
                    label="Contact Person Email"
                    placeholder="Contact Person Email"
                  />
                </div>
              </div>
            )}
            <br />
            <div className={styles.buttonGroup}>
              <Button
                text="Cancel"
                type="button"
                onClick={() => navigate("/non-member-events")}
                className={classNames("cancelBtn")}
              />
              <Button
                text="Proceed"
                type="submit"
                isLoading={
                  hasAccount
                    ? signInNonMemberMutation.isPending
                    : registerNonMemberMutation.isPending
                }
                disabled={
                  hasAccount
                    ? signInNonMemberMutation.isPending
                    : registerNonMemberMutation.isPending
                }
              />
            </div>
          </form>
        </FormikProvider>
      </section>
    </div>
  );
};

export default NonMemberAuth;
