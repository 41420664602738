import classNames from "classnames";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { ReactComponent as Logo } from "../../assets/logo-small.svg";
import styles from "./styles.module.scss";
import Button from "../../custom/button/button";
import Table, { ColumnsType } from "antd/es/table";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  MemberType,
  baseUrl,
  getCorporateInvoiceByUniqueId,
  getInvoiceByTrainingConferenceId,
  processPayment,
} from "../../requests";
import { App, Empty, Spin } from "antd";
import { AxiosError } from "axios";
import { getDateWithoutTime } from "../../utils/formatDate";
import { todaysDate } from "../../utils/todaysDate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import { corporateDataId, userData } from "../../state";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { formatCurrency } from "../../utils/formatCurrency";

const Invoice = ({
  eventType,
}: {
  eventType: "Training" | "Conference" | "Other";
}) => {
  const invoiceRef = useRef(null);
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const id = params.get("id");
  const eventDetailId = params.get("eventDetailId");
  const user = useAtomValue(userData);
  const dataId = useAtomValue(corporateDataId);
  const uniqueId = params.get("uniqueId");
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;

  const printInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    documentTitle: "Payment Invoice",
  });

  const returnUrl = () => {
    switch (eventType) {
      case "Training":
        return `${baseUrl}/event-application/training`;
      case "Conference":
        return `${baseUrl}/event-application/conferences`;
      case "Other":
        return `${baseUrl}/event-application/other-events`;
      default:
        return `${baseUrl}/event-application/training`;
    }
  };

  const columns: ColumnsType<Invoice> = [
    {
      title: "S/N",
      key: "index",
      render(_value, _record, index) {
        return index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "Nominee",
      key: "Id",
      render: (_, { Nominee }) => Nominee || "N/A",
    },
    {
      title: "Mode of Attendance",
      dataIndex: "VenueType",
      key: "Id",
      render: (_, { VenueType }) => VenueType || "N/A",
    },
    {
      title: "Member Type",
      dataIndex: "MembershipRoute",
      key: "Id",
      render: (_, { MembershipRoute }) =>
        !!MembershipRoute
          ? Object.keys(MemberType)
              ?.filter((x) => !(parseInt(x) >= 0))
              ?.includes(MembershipRoute?.split(" ")?.[0])
            ? "Member"
            : "Non-member"
          : "N/A",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Id",
      render: (_, { Amount }) => {
        let numAmount = parseFloat(Amount);
        return formatCurrency(numAmount);
      },
    },
  ];

  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-invoice"],
    queryFn: () => {
      if (isCorporate) {
        return getCorporateInvoiceByUniqueId(uniqueId!);
      } else return getInvoiceByTrainingConferenceId(id!);
    },
    enabled: !!id || !!uniqueId || !!eventDetailId,
  });
  const invoiceData = data?.Data?.filter(
    (x) => `${x.LearningEventId}` === eventDetailId!
  ) as Invoice[];

  const uniqueInvoiceData = invoiceData?.[0];
  const issuedDate = getDateWithoutTime(invoiceData?.[0]?.CreatedDate);
  const hasOutstanding = invoiceData?.some((x) => x.Haspaid === false);
  const totalAmount = invoiceData
    ?.filter((x) => x.Haspaid === false)
    .reduce((curr, acc) => curr + parseFloat(acc.Amount), 0);
  const eventName = invoiceData?.[0]?.Description?.split(":")[0]?.trim();

  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: totalAmount,
      Description: `${eventType} Payment`,
      ReturnURL: returnUrl(),
      PaymentForm: eventType,
      DataId: isCorporate ? dataId : `${id}`,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.title ||
          error?.response?.data?.message ||
          error?.message,
      });
    }
  };

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={styles.heading}>
        <div className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Generated Invoice</h3>
        </div>
        {invoiceData?.length > 0 && (
          <div className={styles.group}>
            <Button
              className={classNames("outlineBtn")}
              onClick={printInvoice}
              text="Download Invoice"
            />
            {invoiceData?.some((x) => x.Haspaid === false) && (
              <Button
                onClick={handlePayment}
                isLoading={processPaymentMutation.isPending}
                disabled={processPaymentMutation.isPending}
                text="Make Payment"
              />
            )}
          </div>
        )}
      </section>
      {invoiceData ? (
        <div>
          <br />
          <br />
          <section ref={invoiceRef} className={styles.invoiceContainer}>
            <div className={styles.letterHead}>
              <div className="spaceBetween">
                <h2 className={styles.eventHeader}>{eventName}</h2>
                <Logo className={styles.logo} />
              </div>
              <h1>Invoice</h1>
            </div>
            <br />
            <section>
              <div className={styles.grid}>
                <div className={styles.invoiceDetails}>
                  <label>Issued On</label>
                  <h6>{issuedDate}</h6>
                </div>
                <div className={styles.invoiceDetails}>
                  <label>Date On</label>
                  <h6>{todaysDate()}</h6>
                </div>
                <div className={styles.invoiceDetails}>
                  <label>From</label>
                  <h6>{uniqueInvoiceData?.From}</h6>
                </div>
                <div className={styles.invoiceDetails}>
                  <label>To</label>
                  <h6>{uniqueInvoiceData?.To}</h6>
                </div>
                <div className={styles.invoiceDetails}>
                  <label>Purpose</label>
                  <h6>{uniqueInvoiceData?.Purpose}</h6>
                </div>
                <div className={styles.invoiceDetails}>
                  <label>Status</label>
                  <h6>{hasOutstanding ? "Unpaid" : "Paid"}</h6>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <h4>Items</h4>
              <Table
                columns={columns}
                dataSource={invoiceData}
                pagination={false}
                scroll={{ x: true }}
                className={classNames("table")}
                summary={(data) => {
                  const total = data?.reduce(
                    (curr, val) => curr + parseFloat(val.Amount),
                    0
                  );
                  return (
                    <tr style={{ width: "100%" }}>
                      <td colSpan={4}>
                        <h4>Total</h4>
                      </td>
                      <td>
                        <b>{formatCurrency(total)}</b>
                      </td>
                    </tr>
                  );
                }}
              />
              <br />
              <h2>Payment Info</h2>
              <div className={styles.line}></div>
              <p>
                <b>Account Number:</b> {uniqueInvoiceData?.AccountNumber}
              </p>
              <p>
                <b>Account Name:</b> {uniqueInvoiceData?.AccountName}
              </p>
              <p>
                <b>Bank Name:</b> {uniqueInvoiceData?.Bank}
              </p>
            </section>
            <p>
              <b>Balance: {formatCurrency(totalAmount)}</b>
            </p>
          </section>
        </div>
      ) : (
        <Empty
          description="Invoice unavailable"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
};

export default Invoice;
