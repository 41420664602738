import { App, Modal } from "antd";
import styles from "./styles.module.scss";
import Upload from "../../../custom/upload/upload";
import classNames from "classnames";
import Button from "../../../custom/button/button";
import { useState } from "react";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { updateCorporateAddress } from "../../../requests";
import { errorMessage } from "../../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";

interface Props {
  isOpen: boolean;
  phoneNumber: string;
  address: string;
  closeModal: () => void;
  resetForm: () => void;
  refetch: () => any;
}

const ChangeOfAddressModal = ({
  isOpen,
  closeModal,
  address,
  phoneNumber,
  resetForm,
  refetch,
}: Props) => {
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const queryClient = new QueryClient();
  const [letter, setLetter] = useState<File | null>(null);

  const changeAddressMutation = useMutation({
    mutationKey: ["change-office-address"],
    mutationFn: updateCorporateAddress,
  });

  const changeOfficeAddress = async () => {
    const formData = new FormData();
    formData.append("MembershipNo", user?.MemberId!);
    formData.append("OfficeAddress", address);
    formData.append("PhoneNo", phoneNumber);
    if (letter) formData.append("Address", letter);
    try {
      await changeAddressMutation.mutateAsync(formData, {
        onSuccess: (res) => {
          notification.success({
            message: "Success",
            description: res.Message,
          });
          queryClient.refetchQueries({ queryKey: ["company-details"] });
          refetch();
          resetForm();
          setLetter(null);
          closeModal();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setLetter(files[0]);
  };
  return (
    <Modal
      centered
      open={isOpen}
      onCancel={closeModal}
      footer={null}
      title="Change of Officer"
    >
      <section className={classNames("modalContent")}>
        <Upload
          label="Formal Letter*"
          accept="img, pdf"
          allowedFormats={["img", "pdf"]}
          onChange={handleChange}
          fileName={letter?.name}
          fileSize={letter?.size}
        />
        <br />
        <div className={styles.cta}>
          <Button
            onClick={closeModal}
            className={classNames("cancelBtn")}
            text="Cancel"
          />
          <Button
            onClick={changeOfficeAddress}
            isLoading={changeAddressMutation.isPending}
            disabled={changeAddressMutation.isPending}
            text="Save"
          />
        </div>
      </section>
    </Modal>
  );
};

export default ChangeOfAddressModal;
