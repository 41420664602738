import styles from './styles.module.scss';
import classNames from 'classnames';
import Spinner from '../spinner/spinner';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	text?: React.ReactNode;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, iconBefore, iconAfter, className, isLoading, ...rest }) => {
	return (
		<button {...rest} className={classNames(styles.button, className)}>
			{iconBefore}
			{isLoading ? <Spinner className={styles.spinner} width={20} height={20} color='#fff' /> : text}
			{iconAfter}
		</button>
	);
};

export default Button;
