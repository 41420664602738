import styles from "./styles.module.scss";
import EmptyActivity from "../../assets/empty.png";

const EmptyState = ({ text }: { text?: string }) => {
  return (
    <section className={styles.emptySection}>
      <img src={EmptyActivity} className={styles.icon} alt="empty table" />
      <p className={styles.para}>{text || "No data yet"}</p>
    </section>
  );
};

export default EmptyState;
