import styles from "./styles.module.scss";
import Notification from "../../components/home/notification/notification";
import Card from "../../components/home/card/card";
import { ReactComponent as CheckBox } from "../../assets/checkbox.svg";
import { ReactComponent as Calender } from "../../assets/calender.svg";
import Points from "../../assets/points.png";
import { Image, Spin, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAtomValue } from "jotai";
import { userData, userProfile } from "../../state";
import ProfileCard from "../../components/profileCard/profileCard";
import { useQuery } from "@tanstack/react-query";
import {
  MemberType,
  NotificationType,
  getDashboardNotifications,
  getNotifications,
  getOutstandingDue,
} from "../../requests";
import { AxiosError } from "axios";
import { getDateWithoutTime, hasDateBeenReached } from "../../utils/formatDate";
import { makeArrayUnique } from "../../utils/uniqueArray";
import { format, parseISO } from "date-fns";

const columns: ColumnsType<ActivityHistory> = [
  {
    title: "Date",
    dataIndex: "CreatedDate",
    key: "id",
    render: (_, { CreatedDate }) => getDateWithoutTime(CreatedDate!),
  },
  {
    title: "Purpose",
    dataIndex: "Purpose",
    key: "id",
    render: (_, { Purpose }) => Purpose || "N/A",
  },
  {
    title: "Reviewed Comment",
    dataIndex: "ActivityDescription",
    key: "id",
  },
];

const NotifyBody = (notification: DashboardNotification) => {
  switch (notification.Description) {
    case NotificationType.EndorsementResponse:
      return (
        <Notification
          title="Endorsement"
          description="Endorse member into your branch"
          cta="Endorse Member"
          link="/endorsement-requests"
        />
      );
    case NotificationType.EndorsementRequest:
      return (
        <Notification
          title="Endorsement"
          description="Request endorsement from branch chairman"
          cta="New Request"
          link="/request-endorsement"
        />
      );
    case NotificationType.LicenseRenewal:
      return (
        <Notification
          title="License Renewal"
          description="Expired license"
          cta="Renew License"
          link="/hr-work-activity"
        />
      );
    case NotificationType.Upgrade:
      return (
        <Notification
          title="Membership Upgrade"
          description="Upgrade your membership"
          cta="Upgrade"
          link="/membership-upgrade"
        />
      );
    case NotificationType.ReadyToMarket:
      return (
        <Notification
          title="Ready to Market"
          description="Take aptitude test"
          cta="Take Test"
          link="/ready-to-market"
        />
      );
    default:
      return null;
  }
};

const Home = () => {
  const user = useAtomValue(userData);
  const profile = useAtomValue(userProfile);
  const isCorporate = user?.MembershipTypeId === MemberType.Corporate;
  const hasExpiredLicense = hasDateBeenReached(profile?.LicenseExpire!);

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["get-dashboard-notifications"],
    queryFn: () => getDashboardNotifications(profile?.MembershipNo!),
    enabled: !!profile?.MembershipNo,
  });

  const activityQuery = useQuery({
    queryKey: ["get-activity-history"],
    queryFn: () => getNotifications(profile?.MembershipNo),
    enabled: !!profile?.MembershipNo,
  });

  const outstanding = useQuery({
    queryKey: ["get-outstanding-due"],
    queryFn: () => getOutstandingDue(profile?.MembershipNo),
    enabled: !!profile?.MembershipNo,
  });

  const activity = activityQuery?.data?.Data as ActivityHistory[];
  const outstandingData = outstanding?.data?.Data as Due;

  const sortedActivities = activity?.sort((a, b) => {
    const date1 = new Date(a.CreatedDate);
    const date2 = new Date(b.CreatedDate);

    return date2.getTime() - date1.getTime();
  });

  const notificationData = data?.Data as DashboardNotification[];
  const notifications = makeArrayUnique(
    notificationData || []
  ) as DashboardNotification[];

  if (isPending || isLoading || activityQuery.isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <main className={styles.main}>
      <section className={styles.userInfo}>
        <ProfileCard showUpgradeScore />
      </section>
      {notifications?.length > 0 ? (
        <h2 className={styles.heading}>Recent Notification</h2>
      ) : null}
      <section className={styles.list}>
        {notifications?.map((item, index) => (
          <NotifyBody key={index} {...item} />
        ))}
      </section>
      <h4 className={styles.subheading}>Activity Summary</h4>

      <section className={styles.cardWrapper}>
        <Card
          heading={`₦${outstandingData?.OutstandingAmount}`}
          text="Outstanding Payment"
          icon={CheckBox}
        />
        <Card
          heading={`${profile?.UpgradeScore}`}
          text="Total Points"
          icon={Calender}
        />
        {isCorporate ? null : (
          <>
            <Card
              heading={hasExpiredLicense ? "Expired" : "Active"}
              text="License Status"
              icon={CheckBox}
            />
            <Card
              heading={format(parseISO(profile?.LicenseExpire), "do MMM yyyy")}
              text="License Expiry Date"
              icon={Calender}
            />
          </>
        )}
      </section>
      <h2 className={styles.subheading}>Recent Activities</h2>
      <section className={styles.tableWrapper}>
        <div className={styles.pointsWrapper}>
          <span className={styles.pointsSpan}>
            <Image src={Points} />
          </span>
          <span>
            <h4 className={styles.pointsHeading}>{profile?.UpgradeScore}</h4>
            <p className={styles.para}>TOTAL POINTS</p>
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={sortedActivities?.slice(0, 5) || []}
          scroll={{ x: true }}
          pagination={false}
          rootClassName={styles.table}
        />
      </section>
    </main>
  );
};

export default Home;
