import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { filesize } from "filesize";

interface Props {
  documentName: string;
  documentSize?: number;
  documentUrl?: string;
  title: string;
  className: string;
  otherAction?: React.ReactNode;
}

const DownloadFile = ({
  documentName,
  documentSize,
  documentUrl,
  title,
  className,
  otherAction,
  ...rest
}: Props) => {
  return (
    <section className={classNames(className, styles.section)} {...rest}>
      <h4 className={styles.tilte}>{title}</h4>
      <section className={styles.sectionWrapper}>
        <div className={styles.wrapper}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="61"
            viewBox="0 0 60 61"
            fill="none"
          >
            <g clip-path="url(#clip0_19014_16038)">
              <path
                d="M42.5 35.5001C42.5 36.1631 42.2366 36.799 41.7678 37.2678C41.2989 37.7367 40.663 38.0001 40 38.0001H20C19.337 38.0001 18.7011 37.7367 18.2322 37.2678C17.7634 36.799 17.5 36.1631 17.5 35.5001C17.5 34.837 17.7634 34.2011 18.2322 33.7323C18.7011 33.2635 19.337 33.0001 20 33.0001H40C40.663 33.0001 41.2989 33.2635 41.7678 33.7323C42.2366 34.2011 42.5 34.837 42.5 35.5001ZM32.5 43.0001H20C19.337 43.0001 18.7011 43.2634 18.2322 43.7323C17.7634 44.2011 17.5 44.837 17.5 45.5001C17.5 46.1631 17.7634 46.799 18.2322 47.2678C18.7011 47.7367 19.337 48.0001 20 48.0001H32.5C33.163 48.0001 33.7989 47.7367 34.2678 47.2678C34.7366 46.799 35 46.1631 35 45.5001C35 44.837 34.7366 44.2011 34.2678 43.7323C33.7989 43.2634 33.163 43.0001 32.5 43.0001ZM55 26.7126V48.0001C54.996 51.314 53.6778 54.4912 51.3344 56.8345C48.9911 59.1779 45.814 60.4961 42.5 60.5001H17.5C14.186 60.4961 11.0089 59.1779 8.66555 56.8345C6.3222 54.4912 5.00397 51.314 5 48.0001V13.0001C5.00397 9.68607 6.3222 6.50895 8.66555 4.16561C11.0089 1.82226 14.186 0.504027 17.5 0.500058H28.7875C31.0865 0.49414 33.364 0.944027 35.4881 1.82371C37.6122 2.70338 39.5408 3.9954 41.1625 5.62506L49.8725 14.3401C51.5031 15.9607 52.7959 17.8887 53.6761 20.0125C54.5562 22.1363 55.0062 24.4136 55 26.7126ZM37.6275 9.16006C36.8407 8.39796 35.9573 7.74237 35 7.21006V18.0001C35 18.6631 35.2634 19.299 35.7322 19.7678C36.2011 20.2367 36.837 20.5001 37.5 20.5001H48.29C47.7574 19.543 47.1009 18.6604 46.3375 17.8751L37.6275 9.16006ZM50 26.7126C50 26.3001 49.92 25.9051 49.8825 25.5001H37.5C35.5109 25.5001 33.6032 24.7099 32.1967 23.3034C30.7902 21.8968 30 19.9892 30 18.0001V5.61756C29.595 5.58006 29.1975 5.50006 28.7875 5.50006H17.5C15.5109 5.50006 13.6032 6.29023 12.1967 7.69676C10.7902 9.10328 10 11.0109 10 13.0001V48.0001C10 49.9892 10.7902 51.8968 12.1967 53.3034C13.6032 54.7099 15.5109 55.5001 17.5 55.5001H42.5C44.4891 55.5001 46.3968 54.7099 47.8033 53.3034C49.2098 51.8968 50 49.9892 50 48.0001V26.7126Z"
                fill="#8DC467"
              />
            </g>
            <defs>
              <clipPath id="clip0_19014_16038">
                <rect
                  width="60"
                  height="60"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <div>
            <p className={styles.documentName}>{documentName}</p>
            {documentSize && (
              <p className={styles.documentSize}>{filesize(documentSize)}</p>
            )}
          </div>
        </div>
        <div className="flexWithGap">
          <Link
            to={documentUrl || ""}
            target="_blank"
            download
            className={styles.download}
          >
            View
          </Link>
          {otherAction && otherAction}
        </div>
      </section>
    </section>
  );
};

export default DownloadFile;
