import Button from "../../custom/button/button";
import styles from "./main.module.scss";
import avatar from "../../assets/user.png";
import { ReactComponent as Tree } from "../../assets/chart-tree.svg";
import { ReactComponent as Search } from "../../assets/search-alt.svg";
import { ReactComponent as Message } from "../../assets/message.svg";
import { useAtomValue } from "jotai";
import { getUserGrade, userData, userProfile } from "../../state";
import { useMutation } from "@tanstack/react-query";
import { downloadLicense } from "../../requests";
import { App } from "antd";
import { errorMessage } from "../../utils/errorMessage";

const Main = () => {
  const { notification } = App.useApp();
  const profile = useAtomValue(userProfile);
  const user = useAtomValue(userData);
  const grade = getUserGrade(user?.MembershipTypeId!);

  const downloadLicenseMutation = useMutation({
    mutationKey: ["download-license"],
    mutationFn: downloadLicense,
  });

  const handleDownload = async (membershipNo: string) => {
    try {
      await downloadLicenseMutation.mutateAsync(membershipNo, {
        onSuccess: (data) => {
          const url = window.URL.createObjectURL(data);
          const a = document.createElement("a");
          a.href = url;
          a.download = "License.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
      });
    } catch (error: any) {
      let response = await error?.response?.data.text();
      const actualObj = JSON.parse(response);
      notification.error({
        message: "Error",
        description: actualObj?.Message || errorMessage(error),
      });
    }
  };

  return (
    <div>
      <h3>Obtain License</h3>

      <section className={styles.container}>
        <div className={styles.userInfo}>
          <div className={styles.nameGroup}>
            <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
          </div>
          <section>
            <div>
              <h2>{profile?.Name}</h2>
              <p className={styles.para}>
                <b>{`${grade} Grade Member`}</b> at <b>CIPM Nigeria</b>
              </p>
            </div>
            <div className={styles.statsGroup}>
              <div className={styles.stat}>
                <div className={styles.icon1}>
                  <Tree />
                </div>
                <section>
                  <h4>{grade}</h4>
                  <label>Grade</label>
                </section>
              </div>
              <div className={styles.stat}>
                <div className={styles.icon2}>
                  <Search />
                </div>
                <section>
                  <h4>{user?.MemberId}</h4>
                  <label>Number</label>
                </section>
              </div>
              <div className={styles.stat}>
                <div className={styles.icon3}>
                  <Message />
                </div>
                <section>
                  <h4>{profile?.LicenseScore}</h4>
                  <label>Score</label>
                </section>
              </div>
            </div>
          </section>
          <Button
            onClick={() => handleDownload(user?.MemberId!)}
            isLoading={downloadLicenseMutation.isPending}
            disabled={downloadLicenseMutation.isPending}
            className={styles.end}
            text="Download License"
          />
        </div>
      </section>
    </div>
  );
};

export default Main;
