import { nthNumber } from "./todaysDate";

// days of the week array
const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function addLeadingZero(number: number) {
  if (number < 10) {
      return number.toString().padStart(2, '0');
  } else {
      return number.toString();
  }
}

export const formatDate = (date: string, showDay?: boolean) => {
  if (!date) return "";
  const timestamp = new Date(date);

  const year = timestamp.getFullYear()
const month = timestamp.toLocaleString('default', { month: 'long' })
const day = timestamp.getDate()
const hours = timestamp.getHours();
const minutes = timestamp.getMinutes();
const dayOfTheWeek = days[timestamp.getDay()];

  return `${showDay ? dayOfTheWeek : ""} ${day}${nthNumber(day)} ${month} ${year}, ${(hours % 12) || 12}:${addLeadingZero(minutes)}${hours >= 12 ? 'pm' : 'am'}`
}

export const getDateWithoutTime = (date: string, showDay?: boolean) => {
  return date ? formatDate(date, showDay)?.split(",")[0]?.trim() : "";
}

export const getTimeWithoutDate = (date: string) => {
  return date ? formatDate(date)?.split(",")[1] : "";
}

export const hasDateBeenReached = (date: string) => {
  return new Date(date).getTime() <= new Date().getTime();
}

export const combineDateAndTime = (dateInput: string, timeInput: string) => {
  const combinedDateTimeString = dateInput + 'T' + timeInput;
  const combinedDateTime = new Date(combinedDateTimeString);
  return combinedDateTime;
}

export const convertTo12HourFormat = (time24: string) => {
  if (!time24) return "";
  let [h, m] = time24.split(':');
  let hours = parseInt(h, 10);
  let minutes = parseInt(m, 10);

  // Convert hours to 12-hour format
  let period = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Format the output time
  let time12 = hours + ':' + (minutes < 10 ? '0' : '') + minutes + period;

  return time12;
}

export const isMorning = (dateString: string): boolean  => dateString?.toLowerCase()?.includes('am');
export const isAfternoon = (dateString: string): boolean => dateString?.toLowerCase()?.includes('pm');



// export const isMorning = (dateString: string): boolean  => {
//   if (dateString?.toLowerCase()?.includes('am')) {
//     return true;
//   }
//   let date: Date;

//   // Check if the input is a full ISO date string
//   if (dateString.includes('T')) {
//     date = parseISO(dateString);
//   } else {
//     // Parse the input time string assuming it's in "h:mm a" format
//     date = parse(dateString, 'h:mm a', new Date());
//   }

//   // Format the date to get the hours and minutes
//   const hours = date.getHours();
//   const minutes = date.getMinutes();

//   // Create a date object representing 12:00 PM
//   const noon = new Date(date);
//   noon.setHours(12, 0, 0, 0);

//   // Check if the time is before noon
//   return isBefore(date, noon) || dateString?.toLowerCase()?.includes('am');
// }

// export const isAfternoon = (dateString: string): boolean  => {
//   if (dateString?.toLowerCase()?.includes('pm')) {
//     return true;
//   }
//   let date: Date;

//   // Check if the input is a full ISO date string
//   if (dateString.includes('T')) {
//     date = parseISO(dateString);
//   } else {
//     // Parse the input time string assuming it's in "h:mm a" format
//     date = parse(dateString, 'h:mm a', new Date());
//   }

//   // Format the date to get the hours and minutes
//   const hours = date.getHours();
//   const minutes = date.getMinutes();

//   // Create a date object representing 12:00 PM
//   const noon = new Date(date);
//   noon.setHours(12, 0, 0, 0);

//   // Check if the time is before noon
//   return !isBefore(date, noon);
// }