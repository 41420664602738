import { App, Modal } from "antd";
import classNames from "classnames";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../../custom/input/input";
import styles from "./styles.module.scss";
import Button from "../../../custom/button/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNextOfKin } from "../../../requests";
import * as Yup from "yup";
import { errorMessage } from "../../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const AddNextOfKinModal = ({ open, handleClose }: Props) => {
  const queryClient = useQueryClient();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const addNextOfKinMutation = useMutation({
    mutationKey: ["add-next-of-kin"],
    mutationFn: createNextOfKin,
  });
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    relationship: Yup.string().required("Relationship is required"),
    designation: Yup.string().required("Occupation is required"),
    contactAddressLine1: Yup.string().required("Contact address is required"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      relationship: "",
      designation: "",
      contactAddressLine1: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload: Partial<NextOfKin> = {
        FullName: values.fullName,
        Email: values.email,
        PhoneNumber: values.phoneNumber,
        Relationship: values.relationship,
        Address: values.contactAddressLine1,
        Designation: values.designation,
        MembershipNo: user?.MemberId!,
      };

      try {
        await addNextOfKinMutation.mutateAsync(payload, {
          onSuccess: (data) => {
            notification.success({
              message: "Success",
              description: data?.Message || "Next of kin added successfully",
            });
            queryClient.refetchQueries({ queryKey: ["next-of-kin"] });
            handleClose();
            resetForm();
          },
        });
      } catch (error) {
        notification.error({
          message: "Error",
          description: errorMessage(error),
        });
      }
    },
  });
  return (
    <Modal
      open={open}
      centered
      footer={null}
      title="Add Next of Kin"
      onCancel={handleClose}
      styles={{
        body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
      }}
    >
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className={classNames("modalContent")}
        >
          <Field
            as={Input}
            type="text"
            name="fullName"
            label="Full Name"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="email"
            name="email"
            label="Email Address"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="text"
            name="phoneNumber"
            label="Phone Number"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="text"
            name="relationship"
            label="Relationship"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="text"
            name="designation"
            label="Occupation"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="text"
            name="contactAddressLine1"
            label="Contact Address"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <br />
          <section className={classNames("buttonGroup")}>
            <Button
              text="Cancel"
              type="button"
              className={classNames("cancelBtn")}
              onClick={handleClose}
            />
            <Button
              text="Save"
              type="submit"
              isLoading={addNextOfKinMutation?.isPending}
              disabled={addNextOfKinMutation?.isPending}
            />
          </section>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default AddNextOfKinModal;
