import { App, Modal } from "antd";
import classNames from "classnames";
import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../../custom/input/input";
import styles from "./styles.module.scss";
import Button from "../../../custom/button/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateWorkExperience } from "../../../requests";
import * as Yup from "yup";
import { errorMessage } from "../../../utils/errorMessage";
import { useAtomValue } from "jotai";
import { userData } from "../../../state";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const AddWorkExperienceModal = ({ open, handleClose }: Props) => {
  const queryClient = useQueryClient();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const addWorkExperienceMutation = useMutation({
    mutationKey: ["create-work-experience"],
    mutationFn: updateWorkExperience,
  });
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required("Organization name is required"),
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string(),
    positionHeld: Yup.string().required("Position held is required"),
    organizationAddress: Yup.string().required(
      "Organization address is required"
    ),
    isCurrentJob: Yup.string().required("Current job is required"),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      organizationName: "",
      startDate: "",
      endDate: "",
      positionHeld: "",
      organizationAddress: "",
      isCurrentJob: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let payload: Partial<WorkExperience> = {
        Id: 0,
        MembershipNo: user?.MemberId!,
        Organization: values.organizationName,
        StartDate: values.startDate,
        PositionHeld: values.positionHeld,
        CompanyAddress: values.organizationAddress,
        IsCurrentJob: values.isCurrentJob === "yes",
      };
      if (values.endDate) {
        payload.EndDate = values.endDate;
      }

      try {
        await addWorkExperienceMutation.mutateAsync(payload, {
          onSuccess: (data) => {
            notification.success({
              message: "Success",
              description:
                data?.Message || "Work experience added successfully",
            });
            queryClient.refetchQueries({ queryKey: ["work-experience"] });
            handleClose();
            resetForm();
          },
        });
      } catch (error) {
        notification.error({
          message: "Error",
          description: errorMessage(error),
        });
      }
    },
  });
  return (
    <Modal
      open={open}
      centered
      footer={null}
      title="Add Work Experience"
      onCancel={handleClose}
      styles={{
        body: { maxHeight: "calc(100vh - 100px)", overflowY: "auto" },
      }}
    >
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          className={classNames("modalContent")}
        >
          <Field
            as={Input}
            type="text"
            name="organizationName"
            label="Organization Name"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <div>Do you currently work here?</div>
          <section className={styles.radioWrapper}>
            <div className={styles.radioWrapper}>
              <Field name="isCurrentJob" type="radio" value={"yes"} />
              <label htmlFor="yes">Yes</label>
            </div>
            <div className={styles.radioWrapper}>
              <Field name="isCurrentJob" type="radio" value={"no"} />
              <label htmlFor="no">No</label>
            </div>
          </section>
          <Field
            as={Input}
            type="date"
            name="startDate"
            label="Start Date"
            className={classNames(styles.input, styles.fullWidth)}
          />
          {formik.values.isCurrentJob === "no" ? (
            <Field
              as={Input}
              type="date"
              name="endDate"
              label="End Date"
              className={classNames(styles.input, styles.fullWidth)}
            />
          ) : null}
          <Field
            as={Input}
            type="text"
            name="positionHeld"
            label="Position Held"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <Field
            as={Input}
            type="text"
            name="organizationAddress"
            label="Organization Address"
            className={classNames(styles.input, styles.fullWidth)}
          />
          <br />
          <section className={classNames("buttonGroup")}>
            <Button
              text="Cancel"
              type="button"
              className={classNames("cancelBtn")}
              onClick={handleClose}
            />
            <Button
              text="Save"
              type="submit"
              isLoading={addWorkExperienceMutation?.isPending}
              disabled={addWorkExperienceMutation?.isPending}
            />
          </section>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default AddWorkExperienceModal;
