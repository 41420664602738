import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import Button from "../../custom/button/button";
import { useNavigate } from "react-router-dom";
import { getReadyToMarketContent } from "../../requests";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import { AxiosError } from "axios";

const ReadyToMarket = () => {
  const navigate = useNavigate();

  const { data, isLoading, error, isError } = useQuery({
    queryKey: ["get-ready-to-market"],
    queryFn: getReadyToMarketContent,
  });

  const content = data?.Data?.Body as string;

  if (isLoading)
    return <Spin style={{ justifySelf: "center", alignSelf: "center" }} />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <div>
      <section className={classNames("spaceBetween")}>
        <div className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>Aptitude Test Instructions</h3>
        </div>
        <Button
          onClick={() => window.open("http://cbt.cipmnigeria.org/", "_blank")}
          text="Take Test"
        />
      </section>
      <section className={styles.container}>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </section>
    </div>
  );
};

export default ReadyToMarket;
