import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import Select from "../../custom/select/select";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getBranches,
  getChairmanByBranch,
  getChaptersByBranch,
  updateBasicDetails,
} from "../../requests";
import { useState } from "react";
import * as Yup from "yup";
import { App, Spin } from "antd";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { useNavigate } from "react-router-dom";
import { logout } from "../../utils/logout";
import classNames from "classnames";
import { AxiosError } from "axios";

const UpdateProfile = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const user = useAtomValue(userData);
  const [branch, setBranch] = useState("");

  const updateBasicDetailsMutation = useMutation({
    mutationKey: ["update-basic-details"],
    mutationFn: updateBasicDetails,
  });
  const getAllBranches = useQuery({
    queryKey: ["get-branches"],
    queryFn: getBranches,
    retry: 0,
  });
  const getBranchChairman = useQuery({
    queryKey: ["get-chairman-by-branch", branch],
    queryFn: () => getChairmanByBranch(branch),
    enabled: !!branch,
  });
  const getChapters = useQuery({
    queryKey: ["get-chapters-by-branch", branch],
    queryFn: () => getChaptersByBranch(branch),
    enabled: !!branch,
  });

  const branches = getAllBranches?.data as BranchResponse;
  const chairman = getBranchChairman?.data?.Data as Chairman;
  const chapters = getChapters?.data?.Data as ChapterByBranch[];

  const updateBasicDetailsHandler = async (values: FormikValues) => {
    const payload: UpdateBasicDetailsPayload = {
      MembershipNo: user?.MemberId!,
      BranchId: parseInt(values?.branch),
      ChapterId: values?.chapter || 0,
      AlternateEmail: values?.altEmail || "",
    };
    try {
      await updateBasicDetailsMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: data?.Message || "Profile updated successfully",
          });
          if (Boolean(formik.values?.altEmail)) {
            navigate("/verify-email");
          } else {
            navigate("/home");
          }
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.title,
      });
    }
  };

  const branchOptions = branches?.map((branch, index) => (
    <option value={`${branch.BranchId}`} key={index}>
      {branch.BranchName}
    </option>
  ));
  const chapterOptions = chapters?.map((chapter, index) => (
    <option value={chapter.ChapterId} key={index}>
      {chapter.ChapterName}
    </option>
  ));

  const validationSchema = Yup.object().shape({
    branch: Yup.string().required("required"),
    chapter: Yup.string(),
    branchChairman: Yup.string(),
    altEmail: Yup.string().email(),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      branch: branch || "",
      chapter: "",
      branchChairman: chairman?.Name || "",
      altEmail: "",
    },
    onSubmit: (values) => {
      updateBasicDetailsHandler(values);
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <section className={styles.section}>
        <h2 className={styles.heading}>Update Profile Details</h2>
        {getAllBranches.isLoading ? (
          <Spin />
        ) : getAllBranches.isError ? (
          <div>{(getAllBranches.error as AxiosError)?.message}</div>
        ) : (
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <section className={styles.fields}>
              <Field
                as={Select}
                options={branchOptions}
                name="branch"
                label="Branch *"
                placeholder="Select"
                className={styles.input}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setBranch(e.target.value);
                  formik.setFieldValue("branch", e.target.value);
                }}
              />
              <Field
                as={Input}
                name="branchChairman"
                label="Branch Chairman"
                placeholder="Engr Peter Edward"
                className={styles.input}
                disabled
              />
              <Field
                as={Select}
                name="chapter"
                label="Chapter (optional)"
                placeholder="Select"
                className={styles.input}
                options={chapterOptions}
              />
              <Field
                as={Input}
                name="altEmail"
                label="Alternative Email (optional)"
                placeholder="Eg 123456789"
                className={styles.input}
              />
            </section>
            <div className={styles.buttonGroup}>
              <Button
                text="Cancel"
                type="button"
                className={classNames("cancelBtn")}
                onClick={logout}
              />
              <Button
                type="submit"
                text="Update"
                isLoading={updateBasicDetailsMutation.isPending}
                disabled={updateBasicDetailsMutation.isPending}
              />
            </div>
          </form>
        )}
      </section>
    </FormikProvider>
  );
};

export default UpdateProfile;
