import { FieldHookConfig, useField } from "formik";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useState } from "react";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  type?: string;
}

const Input: React.FC<InputProps & FieldHookConfig<string> & any> = ({
  label,
  placeholder,
  className,
  type,
  ...rest
}) => {
  const [field, meta] = useField(rest);
  const [show, setShow] = useState(false);
  const [inputType, setInputType] = useState(type);
  const handleShow = () => {
    setShow((prev) => !prev);
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  return (
    <div className={classNames(styles.wrapper, className)} {...rest}>
      {type === "password" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          className={styles.showIcon}
          onClick={handleShow}
        >
          <g clip-path="url(#clip0_19014_21799)">
            <path
              d="M14.4899 7.3125C14.4723 7.26562 13.9742 6.16406 12.8727 5.05664C11.402 3.5918 9.54456 2.8125 7.49964 2.8125C5.45471 2.8125 3.59729 3.5918 2.12659 5.05664C1.02503 6.16406 0.52698 7.26562 0.509402 7.3125C0.483241 7.37155 0.469727 7.43542 0.469727 7.5C0.469727 7.56458 0.483241 7.62845 0.509402 7.6875C0.52698 7.73438 1.02503 8.83594 2.12659 9.94336C3.59729 11.4082 5.45471 12.1875 7.49964 12.1875C9.54456 12.1875 11.402 11.4082 12.8727 9.94336C13.9742 8.83594 14.4723 7.73438 14.4899 7.6875C14.516 7.62845 14.5295 7.56458 14.5295 7.5C14.5295 7.43542 14.516 7.37155 14.4899 7.3125ZM7.49964 5.39062C7.91683 5.39062 8.32466 5.51434 8.67154 5.74612C9.01843 5.9779 9.28879 6.30734 9.44845 6.69278C9.6081 7.07822 9.64987 7.50234 9.56848 7.91152C9.48709 8.3207 9.28619 8.69655 8.99119 8.99155C8.69619 9.28655 8.32033 9.48745 7.91116 9.56884C7.50198 9.65023 7.07785 9.60846 6.69241 9.44881C6.30698 9.28915 5.97754 9.01879 5.74576 8.67191C5.51397 8.32502 5.39026 7.91719 5.39026 7.5C5.39026 6.94056 5.6125 6.40403 6.00808 6.00845C6.40367 5.61286 6.9402 5.39062 7.49964 5.39062Z"
              fill="#5F6063"
            />
          </g>
          <defs>
            <clipPath id="clip0_19014_21799">
              <rect width="15" height="15" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
      <label className={styles.label}>{label}</label>
      <input
        className={styles.input}
        {...field}
        {...rest}
        type={inputType ? inputType : "text"}
        placeholder={placeholder}
      />

      {meta.touched && meta.error ? (
        <label className={styles.error}>
          <span>&#42;</span>
          {meta.error}
        </label>
      ) : null}
    </div>
  );
};

export default Input;
