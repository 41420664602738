import { Navigate, Outlet, useLocation } from "react-router-dom";

const NonMemberProtect = () => {
  const location = useLocation();
  //const nonMember = useAtomValue(nonMemberData);
  const nonMember = localStorage.getItem("non-member-data");

  if (!!nonMember) {
    return <Outlet />;
  }

  return (
    <Navigate
      to="/non-member-events/auth"
      state={{ from: location.pathname + location.search }}
      replace
    />
  );
};

export default NonMemberProtect;
