import { Modal } from "antd";
import styles from "./styles.module.scss";
import Warning from "../../../../assets/warning.png";
import Mail from "../../../../assets/mail.png";

interface Props {
  open: boolean;
  isMailSent?: boolean;
  onClose: () => void;
}

const ChangeOfEmailModal = ({ open, isMailSent, onClose }: Props) => {
  return (
    <Modal open={open} footer={null} onCancel={onClose}>
      {isMailSent ? (
        <section className={styles.section}>
          <img src={Mail} alt="mail" className={styles.img} />
          <h4 className={styles.heading}>Mail Sent</h4>
          <p className={styles.para}>
            We have sent a link to your alternative email. <br />
            Click on it to change your email.
          </p>
        </section>
      ) : (
        <section className={styles.section}>
          <img src={Warning} alt="warning" className={styles.img} />
          <h4 className={styles.heading}>No Alternative Email</h4>
          <p className={styles.para}>
            To change the email linked to your <br />
            account, you must first provide your
            <br /> alternative email.
          </p>
        </section>
      )}
    </Modal>
  );
};

export default ChangeOfEmailModal;
