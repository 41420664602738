import styles from "../styles.module.scss";
import { useAtomValue } from "jotai";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { trainingPayment, userData } from "../../../../state";
import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getEventById,
  getEventScheduleByEventId,
  getMaterials,
  getRegisteredEvents,
  getStateByCountryId,
} from "../../../../requests";
import { Spin } from "antd";
import { AxiosError } from "axios";
import classNames from "classnames";
import { ReactComponent as BackArrow } from "../../../../assets/arrow-back.svg";
import { ReactComponent as File } from "../../../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../../../assets/clock.svg";
import { ReactComponent as CyberPay } from "../../../../assets/cyberpay-logo.svg";
import Points from "../../../../assets/points.png";
import Button from "../../../../custom/button/button";
import { getDateWithoutTime } from "../../../../utils/formatDate";
import Select from "../../../../custom/select/select";

const ScheduleTraining = ({
  isEventScheduled,
  setIsEventScheduled,
  isScheduling,
}: {
  isEventScheduled: boolean;
  setIsEventScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  isScheduling: boolean;
}) => {
  const user = useAtomValue(userData);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { values } = useFormikContext<FormikValues>();
  const learningEventId: number = parseInt(values.learningEventId);
  const trainingDetails = useAtomValue(trainingPayment);
  const ref = params.get("ref");

  useEffect(() => {
    if (!!ref && trainingDetails?.IsCorporateSponsor) setIsEventScheduled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const trainingInfo = useQuery({
    queryKey: ["get-training-details"],
    queryFn: () => getEventById(learningEventId!),
    enabled: !!learningEventId,
  });
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["get-event-schedule"],
    queryFn: () => getEventScheduleByEventId(learningEventId!),
  });
  const materials = useQuery({
    queryKey: ["get-training-materials"],
    queryFn: () => getMaterials(learningEventId!),
  });
  const states = useQuery({
    queryKey: ["get-states"],
    queryFn: () => getStateByCountryId(1),
  });
  const registeredEvents = useQuery({
    queryKey: ["get-user-event-info"],
    queryFn: () => getRegisteredEvents(user?.MemberId!, "Training"),
  });

  const materialData = materials?.data?.Data as Material[];
  const trainingInfoData = trainingInfo?.data?.Data as EventById;
  const trainingSchedule = data?.Data as EventSchedule[];
  const stateData = states?.data as State[];
  const registeredEventsData = registeredEvents?.data
    ?.Data as RegisteredEvent[];

  const userRegister = registeredEventsData?.find(
    (event) => event.EventId === trainingInfoData?.EventNameId
  );
  const hasUserRegistered = !!userRegister;
  const hasUserPaid = hasUserRegistered && userRegister?.HasPaid;

  const stateOptions = stateData?.map((state) => (
    <option key={state.StateId} value={state.StateId}>
      {state.StateName}
    </option>
  ));

  if (isLoading) return <Spin />;
  if (isError) return <div>{(error as AxiosError)?.message}</div>;

  return (
    <section className={styles.detailsContainer}>
      <div className={styles.detailsContent}>
        <h2>
          {trainingInfoData?.EventName}
          <span>
            <img src={Points} alt="points" />
          </span>
        </h2>
        <br />
        <div className={styles.line}></div>
        <br />
        {hasUserPaid && (
          <>
            <h3>Venue</h3>
            <p>{trainingInfoData?.Venue}</p>
            <br />
          </>
        )}
        <h3>Business Case</h3>
        <p>{trainingInfoData?.BusinessCase || "N/A"}</p>
        <br />
        <h3>Who to Participate</h3>
        <ul>
          <li>{trainingInfoData?.TargetMember || "N/A"}</li>
        </ul>
        <br />
        <h3>Core Training Objectives</h3>
        <p>{trainingInfoData?.CoreObjective || "N/A"}</p>
        <br />
        <h3>Talking Key Points</h3>
        <p>{trainingInfoData?.DiscussionPoint || "N/A"}</p>
        <br />
        {materialData && materialData?.length > 0 ? (
          <h3>Training Schedule Material</h3>
        ) : null}
        <br />
        <div className={styles.list}>
          {materialData?.map((item, index) => (
            <div key={index} className={styles.files}>
              <div>
                <File />
                <label>{item.MaterialName}</label>
              </div>

              <Link
                to={item.MaterialUrl}
                target="_blank"
                className={classNames("linkAsBtn")}
              >
                Download
              </Link>
            </div>
          ))}
        </div>
      </div>

      <section className={styles.list}>
        {isEventScheduled || (hasUserRegistered && !hasUserPaid) ? (
          <>
            {trainingInfoData?.IsPaymentRequired ? (
              <section className={styles.resource}>
                <div>
                  <h3>Get Payment Breakdown</h3>
                  <p>Cost details of your preferred event listed here.</p>
                  <br />
                  <Button
                    className={styles.mainBtn}
                    onClick={() =>
                      navigate(
                        `/event-application/training/invoice?eventDetailId=${trainingInfoData?.LearningEventId}&uniqueId=${userRegister?.UniqueId}`
                      )
                    }
                    text="Generate Invoice"
                    iconAfter={<BackArrow />}
                  />
                </div>
                <CyberPay />
              </section>
            ) : null}
          </>
        ) : (
          <div className={styles.scheduleContainer}>
            <h3>Select preferred schedule</h3>
            {trainingSchedule?.map((item, index) => {
              const startDate = getDateWithoutTime(item.StartDate);
              const endDate = getDateWithoutTime(item.EndDate);
              return (
                <section key={index} className={styles.eventSchedule}>
                  <div className={styles.smallGap}>
                    <div>
                      <Clock />
                    </div>
                    <div>
                      <h4>{item?.Venue}</h4>
                      <p>
                        {startDate} - {endDate}
                      </p>
                      <p>{item.Time}</p>
                    </div>
                  </div>
                  <Field
                    type="radio"
                    name="schedule"
                    value={`${item?.TrainingScheduleId}`}
                  />
                </section>
              );
            })}
            <ErrorMessage name="schedule">
              {(msg) => <div className={styles.error}>{msg}</div>}
            </ErrorMessage>
            <br />
            <div className={styles.fields}>
              <Field
                name="state"
                as={Select}
                label="Location of head office"
                placeholder="Location of head office"
                options={stateOptions}
              />
            </div>
            <Button
              isLoading={isScheduling}
              disabled={isScheduling}
              type="submit"
              text="Proceed to Payment"
            />
          </div>
        )}
      </section>
    </section>
  );
};

export default ScheduleTraining;
