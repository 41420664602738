import styles from "./main.module.scss";
import { ReactComponent as CyberPay } from "../../assets/cyberpay-logo.svg";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import Table, { ColumnsType } from "antd/es/table";
import laptop from "../../assets/laptop.png";
import success from "../../assets/success.png";
import Button from "../../custom/button/button";
import classNames from "classnames";
import { useState } from "react";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

interface TableData {
  id: number;
  year: number;
  totalAmount: number;
  amountPaid: number;
  balance: number;
}

const data: TableData[] = [
  {
    id: 1,
    year: 2020,
    totalAmount: 900000,
    amountPaid: 620000,
    balance: 280000,
  },
  {
    id: 2,
    year: 2021,
    totalAmount: 900000,
    amountPaid: 620000,
    balance: 280000,
  },
  {
    id: 3,
    year: 2022,
    totalAmount: 900000,
    amountPaid: 620000,
    balance: 280000,
  },
  {
    id: 4,
    year: 2023,
    totalAmount: 900000,
    amountPaid: 620000,
    balance: 280000,
  },
];

const columns: ColumnsType<TableData> = [
  {
    title: "Year",
    dataIndex: "year",
    key: "id",
    render: (_, { year }) => {
      let isCurrentYear =
        data?.reduce((acc, curr) => Math.max(acc, curr.year), data[0]?.year) ===
        year;
      return (
        <span className={styles.yearCell}>
          <b>{year}</b>
          {isCurrentYear && (
            <span className={styles.currentBadge}>Current</span>
          )}
        </span>
      );
    },
  },
  {
    title: "Total amount",
    dataIndex: "totalAmount",
    key: "id",
  },
  {
    title: "Amount Paid",
    dataIndex: "amountPaid",
    key: "id",
  },
  {
    title: "Balance",
    key: "id",
    dataIndex: "balance",
    render: (_, { balance }) => {
      return (
        <p>
          <b>{balance}</b>
        </p>
      );
    },
  },
];

const OutstandingPayment = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <div>
      <section className={classNames("headingWithBackArrow")}>
        <BackArrow onClick={() => navigate(-1)} />
        <h3>Outstanding Payments</h3>
      </section>

      <section className={classNames("tableContainer")}>
        <div className={classNames("tableHeading")}>
          <CyberPay />
          <div>
            <div className={classNames("tableAmount")}>
              <span>&#8358;</span>485,000
            </div>
            <label>Total Outstanding Payment</label>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: true }}
          pagination={false}
          rootClassName={classNames("table")}
        />
        <br />
      </section>
      <br />
      <br />
      <div className={classNames("card", "spaceBetween")}>
        <div className={classNames("flexWithGap")}>
          <img src={laptop} alt="laptop" />
          <div>
            <h3>Annual Subscription</h3>
            <p>Pay first to register for conference</p>
          </div>
        </div>
        <Button onClick={openModal} text="Make Payment" />
      </div>

      <Modal centered open={open} onCancel={closeModal} footer={null}>
        <div className={styles.modalContent}>
          <img src={success} alt="success" />
          <h2>Payment Successful!</h2>
          <p>
            You have successfully paid all outstanding annual Subscription.
            Proceed to pay ₦20,000 for your registered conference.
          </p>
          <div className={styles.buttonGroup}>
            <Button text="Cancel" className={styles.outlineBtn} />
            <Button text="Make Payment" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OutstandingPayment;
