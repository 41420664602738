import { ReactComponent as File } from "../../assets/file-small.svg";
import { ReactComponent as Clock } from "../../assets/clock.svg";
import { ReactComponent as Info } from "../../assets/info.svg";
import Button from "../../custom/button/button";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Points from "../../assets/points.png";
import { Link, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  EventIdType,
  getAllBranches,
  getConferenceSessions,
  getEventById,
  getEventScheduleByEventId,
  getMaterials,
  getRegisteredEventById,
  getStateByCountryId,
  resendBadge,
  updateNonMemberDetails,
} from "../../requests";
import { App, Spin } from "antd";
import { AxiosError } from "axios";
import { useState } from "react";
import { getDateWithoutTime } from "../../utils/formatDate";
import {
  ErrorMessage,
  Field,
  FormikProvider,
  FormikValues,
  useFormik,
} from "formik";
import Input from "../../custom/input/input";
import Upload from "../../custom/upload/upload";
import * as Yup from "yup";
import Select from "../../custom/select/select";

import { errorMessage } from "../../utils/errorMessage";
import { joinStrings } from "../../utils/joinStrings";

const UpdateDetails = () => {
  const [params] = useSearchParams();
  const { notification } = App.useApp();
  const [profilePic, setProfilePic] = useState<File | null>(null);
  const [showResend, setShowResend] = useState(false);
  const trainingConferenceId = params.get("traningconferenceid");
  const [morningSession, setMorningSession] = useState<string[]>([]);
  const [afternoonSession, setAfternoonSession] = useState<string[]>([]);

  const resendBadgeMutation = useMutation({
    mutationKey: ["resend-badge"],
    mutationFn: resendBadge,
  });
  const updateEventMutation = useMutation({
    mutationKey: ["update-non-member-details"],
    mutationFn: updateNonMemberDetails,
  });
  const event = useQuery({
    queryKey: ["get-registered-event"],
    queryFn: () => getRegisteredEventById(trainingConferenceId!),
    enabled: !!trainingConferenceId,
  });
  const eventData = event?.data?.Data as RegisteredEvent;

  const trainingInfo = useQuery({
    queryKey: ["get-training-details"],
    queryFn: () => getEventById(eventData?.EventDetailId),
    enabled: !!eventData?.EventDetailId,
  });
  const { data } = useQuery({
    queryKey: ["get-event-schedule"],
    queryFn: () => getEventScheduleByEventId(eventData?.EventDetailId),
    enabled: !!eventData?.EventDetailId,
  });
  const materials = useQuery({
    queryKey: ["get-training-materials"],
    queryFn: () => getMaterials(eventData?.EventDetailId),
    enabled: !!eventData?.EventDetailId,
  });
  const states = useQuery({
    queryKey: ["get-states"],
    queryFn: () => getStateByCountryId(1),
  });
  const branches = useQuery({
    queryKey: ["get-branches"],
    queryFn: getAllBranches,
  });
  const sessions = useQuery({
    queryKey: ["get-conference-sessions"],
    queryFn: () => getConferenceSessions(eventData?.EventDetailId),
    enabled: eventData?.EvenType === "Conference" && !!eventData?.EventDetailId,
  });

  const validationSchema = Yup.object().shape({
    schedule: Yup.string(),
    name: Yup.string(),
    phoneNumber: Yup.string(),
    state: Yup.string(),
    branch: Yup.string(),
    session: Yup.array().min(1).max(2),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      schedule: "",
      name: "",
      phoneNumber: "",
      email: "",
      state: "",
      branch: "",
      session: [],
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (!profilePic) {
        notification.error({
          message: "Please upload a profile picture",
        });
        return;
      } else updateEvent(values, resetForm);
    },
    validationSchema: validationSchema,
  });

  const materialData = materials?.data?.Data as Material[];
  const trainingInfoData = trainingInfo?.data?.Data as EventById;
  const trainingSchedule = data?.Data as EventSchedule[];
  const branchData = branches?.data?.Data;
  const stateData = states?.data as State[];
  const sessionData = sessions?.data?.Data as Session[];
  const isConference = trainingInfoData?.EventTypeId === EventIdType.Conference;

  const branchOptions = branchData?.map((branch) => (
    <option key={branch.BranchId} value={branch.BranchId}>
      {branch.BranchName}
    </option>
  ));
  const stateOptions = stateData?.map((state) => (
    <option key={state.StateId} value={state.StateId}>
      {state.StateName}
    </option>
  ));

  const hasMorningSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "A"
  );
  const hasAfternoonSessions = sessionData?.some(
    (x) => x.IsTecnicalSession && x.TechnicalGroup?.toUpperCase() === "B"
  );

  const hasBothSessions = hasMorningSessions && hasAfternoonSessions;

  const handleSubmit = () => {
    if (isConference) {
      if (hasBothSessions) {
        if (formik.values?.session?.length !== 2) {
          notification.warning({
            message: "Please select both session groups",
          });
        } else {
          formik.submitForm();
        }
      } else {
        formik.submitForm();
      }
    } else {
      formik.submitForm();
    }
  };

  const updateEvent = async (values: FormikValues, resetForm: () => void) => {
    try {
      const payload = new FormData();
      if (isConference) {
        payload.append(
          "ConferenceSessionId",
          joinStrings(values.session) || eventData?.ConferenceSessionId
        );
      }
      payload.append("IrainingConferenceId", trainingConferenceId!);
      if (!isConference) {
        payload.append(
          "TrainingscheduleId",
          values.schedule || `${eventData.TrainingscheduleId}`
        );
      }
      payload.append(
        "ParticipantName",
        values.name || eventData.ParticipantName
      );
      payload.append(
        "ParticipantPhoneNo",
        values.phoneNumber || `${eventData?.ParticipantPhoneNo}`
      );
      if (values.branch) {
        payload.append("BranchId", values.branch || eventData?.BranchId);
      }
      if (values.state) {
        payload.append("StateId", values.state || eventData?.StateId);
      }

      if (profilePic) payload.append("Picture", profilePic);

      await updateEventMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          resetForm();
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error.message,
      });
    }
  };

  const handleResendBadge = async () => {
    const payload = {
      eventDetailId: trainingInfoData?.LearningEventId!,
      email: formik.values.email,
    };

    try {
      await resendBadgeMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          notification.success({
            message: "Success",
            description: data?.Message,
          });
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  if (event.isLoading) return <Spin />;
  if (event.isError) return <div>{(event.error as AxiosError)?.message}</div>;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) setProfilePic(files[0]);
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <section className={classNames("spaceBetween")}>
          <h3>Update Event Details</h3>
          {isConference && (
            <label>
              Didn't receive your badge?{" "}
              <button
                className={styles.greenTitle}
                type="button"
                onClick={() => setShowResend(true)}
              >
                Resend badge
              </button>
            </label>
          )}
        </section>
        {showResend && (
          <section className={styles.formContainer}>
            <Field as={Input} name="email" label="Email" placeholder="Email" />
            <br />
            <Button
              text="Resend badge"
              type="button"
              onClick={handleResendBadge}
              isLoading={resendBadgeMutation.isPending}
              disabled={resendBadgeMutation.isPending}
            />
          </section>
        )}
        <section className={styles.formContainer}>
          <Upload
            label="Upload Profile Picture"
            accept="img"
            allowedFormats={["img"]}
            onChange={handleFileChange}
            fileName={profilePic?.name}
            fileSize={profilePic?.size}
          />
          <div className={styles.fields}>
            <Field as={Input} name="name" label="Name" placeholder="Name" />
            <Field
              as={Input}
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone number"
            />
          </div>
        </section>
        <section className={styles.detailsContainer}>
          <div className={styles.detailsContent}>
            <h2>
              {trainingInfoData?.EventName}
              <span>
                <img src={Points} alt="points" />
              </span>
            </h2>
            <br />
            <div className={styles.line}></div>
            <br />
            <h3>Venue</h3>
            <p>{trainingInfoData?.Venue}</p>
            <br />
            <h3>Business Case</h3>
            <p>{trainingInfoData?.BusinessCase || "N/A"}</p>
            <br />
            <h3>Who to Participate</h3>
            <ul>
              <li>{trainingInfoData?.TargetMember || "N/A"}</li>
            </ul>
            <br />
            <h3>Core Training Objectives</h3>
            <p>{trainingInfoData?.CoreObjective || "N/A"}</p>
            <br />
            <h3>Talking Key Points</h3>
            <p>{trainingInfoData?.DiscussionPoint || "N/A"}</p>
            <br />
            {materialData && materialData?.length > 0 ? (
              <h3>Training Schedule Material</h3>
            ) : null}
            <br />
            <div className={styles.list}>
              {materialData?.map((item, index) => (
                <div key={index} className={styles.files}>
                  <div>
                    <File />
                    <label>{item.MaterialName}</label>
                  </div>

                  <Link
                    to={item.MaterialUrl}
                    target="_blank"
                    className={classNames("linkAsBtn")}
                  >
                    Download
                  </Link>
                </div>
              ))}
            </div>
            <br />
            {isConference ? (
              <>
                <h4>Plenary Session Details</h4>
                <label>
                  <Info /> Attendance is compulsory
                </label>
                {sessionData
                  ?.filter((x) => x.IsTecnicalSession === false)
                  .map((item) => {
                    const startDate = getDateWithoutTime(item.StartDate);
                    return (
                      <section className={styles.eventSchedule}>
                        <div className={styles.smallGap}>
                          <Clock />
                          <div className={styles.sessionDetails}>
                            <small>{item.SessionType?.toUpperCase()}</small>
                            <h4>{item.SessionName}</h4>
                            <div className={styles.sessionDescription}>
                              Synopsis: {item.Synopsis || "N/A"}
                            </div>
                            <p>Speaker: {item.Speaker}</p>
                            <div>
                              {startDate} &#8226; {item.SessionStartTime} -{" "}
                              {item.SessionEndTime}
                            </div>
                          </div>
                        </div>
                      </section>
                    );
                  })}
              </>
            ) : null}
          </div>
          <div className={styles.scheduleContainer}>
            {isConference ? (
              <>
                {hasMorningSessions && (
                  <h3>Select technical session: Group 1</h3>
                )}
                {sessionData
                  ?.filter(
                    (x) =>
                      x.IsTecnicalSession &&
                      x.TechnicalGroup?.toUpperCase() === "A"
                  )
                  ?.map((item) => {
                    const startDate = getDateWithoutTime(item.StartDate);
                    return (
                      <section key={item.Id} className={styles.eventSchedule}>
                        <div className={styles.smallGap}>
                          <Clock />
                          <div className={styles.sessionDetails}>
                            <small>{item.SessionType?.toUpperCase()}</small>
                            <h4>{item.SessionName}</h4>
                            <p className={styles.sessionDescription}>
                              Synopsis: {item.Synopsis || "N/A"}
                            </p>
                            <p>Speaker: {item.Speaker}</p>
                            <div>
                              {startDate} &#8226; {item.SessionStartTime} -{" "}
                              {item.SessionEndTime}
                            </div>
                          </div>
                        </div>
                        <input
                          type="radio"
                          name="session1"
                          onChange={() => {
                            setMorningSession((prev) => [
                              ...prev,
                              `${item.Id}`,
                            ]);
                            formik.setFieldValue("session", [
                              `${item.Id}`,
                              ...afternoonSession,
                            ]);
                          }}
                        />
                      </section>
                    );
                  })}
                <br />
                {hasAfternoonSessions && (
                  <h3>Select technical session: Group 2</h3>
                )}
                {sessionData
                  ?.filter(
                    (x) =>
                      x.IsTecnicalSession &&
                      x.TechnicalGroup?.toUpperCase() === "B"
                  )
                  ?.map((item) => {
                    const startDate = getDateWithoutTime(item.StartDate);
                    return (
                      <section key={item.Id} className={styles.eventSchedule}>
                        <div className={styles.smallGap}>
                          <Clock />
                          <div className={styles.sessionDetails}>
                            <small>{item.SessionType?.toUpperCase()}</small>
                            <h4>{item.SessionName}</h4>
                            <p className={styles.sessionDescription}>
                              Synopsis: {item.Synopsis || "N/A"}
                            </p>
                            <p>Speaker: {item.Speaker}</p>
                            <div>
                              {startDate} &#8226; {item.SessionStartTime} -{" "}
                              {item.SessionEndTime}
                            </div>
                          </div>
                        </div>
                        <input
                          type="radio"
                          name="session2"
                          onChange={() => {
                            setAfternoonSession((prev) => [
                              ...prev,
                              `${item.Id}`,
                            ]);
                            formik.setFieldValue("session", [
                              ...morningSession,
                              `${item.Id}`,
                            ]);
                          }}
                        />
                      </section>
                    );
                  })}
              </>
            ) : (
              <>
                <h3>Select preferred schedule</h3>
                {trainingSchedule?.map((item, index) => {
                  const startDate = getDateWithoutTime(item.StartDate);
                  const endDate = getDateWithoutTime(item.EndDate);
                  return (
                    <section key={index} className={styles.eventSchedule}>
                      <Clock />
                      <div>
                        <h4>{item?.Venue}</h4>
                        <label>
                          {startDate} - {endDate}
                        </label>
                        <label>{item.Time}</label>
                      </div>
                      <Field
                        type="radio"
                        name="schedule"
                        value={`${item?.TrainingScheduleId}`}
                      />
                    </section>
                  );
                })}
                <ErrorMessage name="schedule">
                  {(msg) => <div className={styles.error}>{msg}</div>}
                </ErrorMessage>
              </>
            )}
            <br />
            <div className={styles.list}>
              <Field
                name="state"
                as={Select}
                label="State"
                placeholder="Select state"
                options={stateOptions}
              />
              <Field
                name="branch"
                as={Select}
                label="Branch"
                placeholder="Select branch"
                options={branchOptions}
              />
            </div>
            <br />
            <Button
              isLoading={updateEventMutation.isPending}
              disabled={updateEventMutation.isPending}
              type="button"
              onClick={handleSubmit}
              text="Save Changes"
            />
          </div>
        </section>
      </form>
    </FormikProvider>
  );
};

export default UpdateDetails;
