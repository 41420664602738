import { Field, FormikProvider, FormikValues, useFormik } from "formik";
import Input from "../../custom/input/input";
import Button from "../../custom/button/button";
import styles from "./styles.module.scss";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { forgotPassword } from "../../requests";
import { App } from "antd";

const ForgotPassword = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const forgotPasswordMutation = useMutation({
    mutationKey: ["forgotPassword"],
    mutationFn: forgotPassword,
  });
  const forgotPasswordHandler = async (values: FormikValues) => {
    const payload: ForgotPasswordPayload = {
      Email: values.email,
      IsAdmin: false,
    };

    try {
      await forgotPasswordMutation.mutateAsync(payload, {
        onSuccess: () => {
          navigate("/email-sent", { state: { email: values.email } });
        },
      });
    } catch (error: any) {
      notification.error({
        message:
          error?.Message ||
          error?.response?.data?.Message ||
          error?.response?.data?.title,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Invalid email"),
  });
  const formik = useFormik<FormikValues>({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      forgotPasswordHandler(values);
    },
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <section className={styles.section}>
        <h2 className={styles.heading}>Forgot password?</h2>
        <form onSubmit={formik.handleSubmit}>
          <section className={styles.fields}>
            <Field
              as={Input}
              name="email"
              label="Membership ID / Email"
              placeholder="Eg (123456789)"
              className={styles.input}
            />
          </section>
          <Button
            type="submit"
            isLoading={forgotPasswordMutation.isPending}
            text={"Submit"}
            className={styles.forgotPassword}
            disabled={forgotPasswordMutation.isPending}
          />
          <Link to="/" className={styles.login}>
            Back to login
          </Link>
        </form>
      </section>
    </FormikProvider>
  );
};

export default ForgotPassword;
