import classNames from "classnames";
import styles from "./styles.module.scss";
import warning from "../../assets/warning.png";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../../custom/button/button";
import { useMutation } from "@tanstack/react-query";
import { baseUrl, processPayment, requestLicense } from "../../requests";
import { useAtomValue } from "jotai";
import { userData } from "../../state";
import { App, Modal } from "antd";
import { errorMessage } from "../../utils/errorMessage";
import { useState } from "react";

const AboutHRPL = () => {
  const user = useAtomValue(userData);
  const [openPayment, setOpenPayment] = useState(false);
  const [paymentData, setPaymentData] = useState<ProcessPaymentPayload>({
    RouteId: 0,
    MembershipNo: user?.MemberId!,
    Amount: 0,
    Description: "",
    ReturnURL: "",
    PaymentForm: "",
    DataId: 0,
  });
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const handleClosePayment = () => setOpenPayment(false);
  const handleOpenPayment = () => setOpenPayment(true);

  const requestLicenseMutation = useMutation({
    mutationKey: ["request-license"],
    mutationFn: requestLicense,
  });
  const processPaymentMutation = useMutation({
    mutationKey: ["process-payment"],
    mutationFn: processPayment,
  });
  const handlePayment = async () => {
    const payload: ProcessPaymentPayload = {
      RouteId: paymentData?.RouteId || user?.MembershipTypeId!,
      MembershipNo: user?.MemberId!,
      Amount: paymentData?.Amount,
      Description: paymentData?.Description,
      ReturnURL: `${baseUrl}/hr-work-activity`,
      PaymentForm: paymentData?.PaymentForm,
      DataId: paymentData?.DataId,
    };
    try {
      await processPaymentMutation.mutateAsync(payload, {
        onSuccess: (data) => {
          const responseData = JSON.parse(
            data?.ResponsePayLoad
          ) as PaymentDetails;
          window.location.href = responseData?.RedirectURL;
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const handleRequestLicense = async (memberId: string) => {
    try {
      await requestLicenseMutation.mutateAsync(memberId, {
        onSuccess: (data) => {
          setPaymentData({ ...data.Data });
          handleOpenPayment();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };
  return (
    <div>
      <section className={classNames("spaceBetween")}>
        <div className={classNames("headingWithBackArrow")}>
          <button onClick={() => navigate(-1)}>
            <BackArrow />
          </button>
          <h3>About HRPL</h3>
        </div>
        <Button
          isLoading={requestLicenseMutation.isPending}
          disabled={requestLicenseMutation.isPending}
          onClick={() => handleRequestLicense(user?.MemberId!)}
          text="Request for License"
        />
      </section>
      <section className={styles.container}>
        <h3>What is HRPL?</h3>
        <p>
          Human Resource Practitioners’ Licence is a permit to operate or
          practice and an endorsement/certification by the Chartered Institute
          of Personnel Management of Nigeria to its holder of his/her worthiness
          to practice the profession within and according to the ethos of the
          profession over a specified validity period.
        </p>

        <h3 className={styles.headingMovedDown}>Why HRPL?</h3>
        <ul>
          <li>
            A Licensed HR practitioner has a distinguishing factor in market
            differentiation and preference in that:
          </li>
          <li>
            It sets the holder apart as an active practitioner who is
            knowledgeable in current principles and core practices of HR
            management
          </li>
          <li>
            It connects you to an elite network of motivated HR professionals
          </li>
          <li>
            It confers on the holder a sense of accountability, confidence,
            loyalty and continuous strive for commitment to the ethos of the
            profession
          </li>
          <li>
            Establishes and confers the confidence of stakeholders and other
            recipients of the holder’s service, of his/her currency, business
            relevance and a measure of quality assurance of his services.
          </li>
          <li>
            It provides an audit-able and credible medium and platform for
            validating the quality of the service of the holder benchmarked
            against similar/identical body both locally and internationally
          </li>
          <li>
            It demonstrates and recognises diligence and commitment to the
            ethics and professional standards required of practitioners and
            provides a credible medium for enforcing such standards
          </li>
          <li>
            It establishes a recurrent link with the requisite knowledge, skills
            and competences of licence holders to the core body of knowledge
            that underlie the value chain of the profession
          </li>
        </ul>

        <h3 className={styles.headingMovedDown}>Eligibility</h3>
        <p>
          You must be a financially active CIPM member on a minimum grade of
          Associate.
        </p>

        <h3 className={styles.headingMovedDown}>License Validity</h3>
        <p>
          All licences will have a validity of 3 years after which the holders
          will be required to revalidate/renew the licences. Holders of licences
          exceeding their validity cycle will no longer be authorized to
          practise the profession and all Human Resource roles, activities or
          contract entered into during such period will not have professionally
          certified backing and be null and void. It will be illegal for holders
          of such expired licence to make any professional HR representations.
        </p>

        <h3 className={styles.headingMovedDown}>Recertification Process</h3>
        <p>
          Applicants for revalidation of licences have two (2) major options:
        </p>
        <ul>
          <li>Via Scenario examination</li>
          <li>
            Via accumulation of credit points from work activities and relevant
            training programs
          </li>
        </ul>
        <p>
          For more details, please visit our website{" "}
          <NavLink
            to={"https://www.cipmnigeria.org"}
            className={styles.inlineLink}
          >
            www.cipmnigeria.org
          </NavLink>{" "}
          for Frequently Asked Questions and Answers (FAQ/A) or call
          08037167899; funmionasanya@cipmnigeira.org.
        </p>
      </section>
      <Modal
        open={openPayment}
        centered
        footer={null}
        onCancel={handleClosePayment}
      >
        <div className={classNames("modalContent")}>
          <img src={warning} alt="warning" />
          <h2>Congratulations!!!</h2>
          <p>
            You are eligible to get your license renewal. Now, please proceed to
            pay the fee of <b>N{paymentData?.Amount}</b>
          </p>
          <br />
          <div className={styles.buttonGroup}>
            <Button
              className={classNames("outlineBtn")}
              onClick={handleClosePayment}
              text="Cancel"
            />
            <Button
              onClick={handlePayment}
              isLoading={processPaymentMutation.isPending}
              disabled={processPaymentMutation.isPending}
              text="Make Payment"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AboutHRPL;
