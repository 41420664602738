import styles from "../styles.module.scss";
import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import Input from "../../../../custom/input/input";
import Button from "../../../../custom/button/button";
import classNames from "classnames";
import { useAtom } from "jotai";
import { Nominee, tempNominees } from "../../../../state";
import { App } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../../../requests";
import { AxiosError } from "axios";
import MessageBox from "../conference/messageBox";

const Nominees = () => {
  const [nominees, setNominees] = useAtom(tempNominees);
  const { notification } = App.useApp();
  const { values } = useFormikContext<FormikValues>();

  const verifyMembershipNo = useQuery({
    queryKey: ["verify-membership-no"],
    queryFn: () => getProfile(values.membershipNo),
    enabled: false,
  });

  const columns: ColumnsType<Nominee> = [
    {
      title: "Name",
      dataIndex: "ParticipantName",
      key: "ParticipantName",
    },
    {
      title: "Phone Number",
      dataIndex: "ParticipantPhoneNo",
      key: "ParticipantPhoneNo",
    },
    {
      title: "Email Address",
      dataIndex: "ParticipantEmail",
      key: "ParticipantEmail",
    },
    {
      title: "Action",
      render: (_, record) => (
        <button
          onClick={() => removeNominee(record)}
          className={styles.removeBtn}
        >
          Remove
        </button>
      ),
    },
  ];

  const isMembershipNoInvalid =
    (verifyMembershipNo?.error as AxiosError as any)?.response?.data
      ?.Message === "Invalid membership number";

  const handleAddNominee = async () => {
    const isFormValid = values.email && values.phoneNumber && values.name;
    if (values.nomineeType === "member") {
      await verifyMembershipNo.refetch().then((responses) => {
        const nomineeData = responses?.data as Profile;

        const row: Nominee = {
          ParticipantName: nomineeData?.Name,
          ParticipantPhoneNo: nomineeData?.PhoneNumber,
          ParticipantEmail: nomineeData?.Email,
          IsAMember: true,
          MembershipNo: nomineeData?.MembershipNo,
        };

        const hasNominee = nominees?.some(
          (x) => x.ParticipantEmail === row.ParticipantEmail
        );
        const isNumInvalid = (
          responses?.error as AxiosError as any
        )?.response?.data?.Message?.includes("Invalid");

        if (!isNumInvalid) {
          if (!hasNominee) {
            setNominees((prev) => [...prev, row]);
          } else {
            notification.warning({
              message: "Attendee already added",
            });
          }
        }
      });
    } else {
      const hasNominee = nominees?.some(
        (x) => x.ParticipantEmail === values.email
      );
      if (hasNominee) {
        notification.warning({
          message: "Attendee already added",
        });
      } else if (!isFormValid) {
        notification.warning({
          message: "Please enter complete attendee details",
        });
      } else {
        setNominees((prev) => [
          ...prev,
          {
            ParticipantName: values.name,
            ParticipantPhoneNo: values.phoneNumber,
            ParticipantEmail: values.email,
            IsAMember: false,
          },
        ]);
      }
    }
  };

  const removeNominee = (nominee: Nominee) => {
    setNominees((prev) =>
      prev.filter((x) => x.ParticipantEmail !== nominee.ParticipantEmail)
    );
  };

  return (
    <div>
      {nominees.length > 0 ? (
        <Table
          columns={columns}
          dataSource={nominees}
          pagination={false}
          rowKey={(item) => item.ParticipantEmail}
          scroll={{ x: true }}
        />
      ) : null}
      <hr className={styles.line} />
      <h4>Add Attendee</h4>
      <div className={styles.radioWrapper}>
        <div className={styles.radioNormal}>
          <Field type="radio" id="member" name="nomineeType" value="member" />
          <label htmlFor="member">CIPM Member</label>
        </div>
        <div className={styles.radioNormal}>
          <Field
            type="radio"
            id="nonMember"
            name="nomineeType"
            value="nonMember"
          />
          <label htmlFor="nonMember">Non-CIPM Member</label>
        </div>
      </div>
      <ErrorMessage name="nomineeType">
        {(err) => <div className={styles.error}>*{err}</div>}
      </ErrorMessage>
      <br />
      {values.nomineeType === "nonMember" && (
        <>
          <Field as={Input} name="name" label="Name" placeholder="Name" />
          <div className={styles.fields}>
            <Field
              as={Input}
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone number"
            />
            <Field
              as={Input}
              name="email"
              label="Email Address"
              placeholder="Email address"
            />
          </div>
        </>
      )}
      {values.nomineeType === "member" && (
        <Field
          as={Input}
          name="membershipNo"
          label="Membership Number"
          placeholder="Membership number"
        />
      )}
      {isMembershipNoInvalid && (
        <MessageBox message="Invalid membership number" />
      )}
      <br />
      {values.nomineeType && (
        <div className={styles.buttonGroup}>
          <Button
            type="button"
            onClick={handleAddNominee}
            className={styles.editButton}
            isLoading={verifyMembershipNo.isFetching}
            disabled={verifyMembershipNo.isFetching}
            text="Add Attendee"
          />
        </div>
      )}
      <br />
      <hr className={styles.line} />
      <div className={styles.buttonGroup}>
        <Button className={classNames("cancelBtn")} text="Cancel" />
        <Button disabled={nominees.length === 0} type="submit" text="Proceed" />
      </div>
    </div>
  );
};

export default Nominees;
