import ReactPaginate from "react-paginate";
import { ReactComponent as DoubleArrow } from "../../assets/double-arrow.svg";
import styles from "./styles.module.scss";

interface PaginationProps {
  pageNumber: number;
  itemsPerPage: number;
  totalSize: number;
  setPageNumber: (page: number) => void;
}

const Paginate: React.FC<PaginationProps> = ({
  pageNumber,
  itemsPerPage,
  totalSize,
  setPageNumber,
}) => {
  const hasRemainingPageItem = (): boolean =>
    Number.isInteger(totalSize / itemsPerPage) ? false : true;

  const derivePageNumber = (): number => {
    if (hasRemainingPageItem()) {
      return Math.trunc(Math.floor(totalSize / itemsPerPage)) + 1;
    }

    return Math.floor(totalSize / itemsPerPage);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setPageNumber(selectedPage.selected + 1);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <div className={styles.nextIndicator}>
          Next <DoubleArrow />
        </div>
      }
      onPageChange={handlePageChange}
      pageRangeDisplayed={3}
      pageCount={derivePageNumber()}
      previousLabel={
        <div className={styles.previousIndicator}>
          <DoubleArrow /> Previous
        </div>
      }
      renderOnZeroPageCount={null}
      containerClassName={styles.paginateContainer}
      pageClassName={styles.page}
      breakClassName={styles.ellipsis}
      // breakLinkClassName={styles.activePageLink}
      activeClassName={styles.activePage}
      activeLinkClassName={styles.activePageLink}
      previousClassName={styles.indicator}
      nextClassName={styles.indicator}
      forcePage={pageNumber - 1}
    />
  );
};

export default Paginate;
