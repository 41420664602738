import { Routes, Route } from "react-router-dom";
import SignIn from "./screens/signin/signin";
import Layout from "./screens/layout/layout";
import DashboardLayout from "./screens/dashboardLayout/dashboardLayout";
import Home from "./screens/home/home";
import RequestEndorsement from "./screens/requestEndorsement";
import EndorseMember from "./screens/endorseMember";
import MembershipUpgrade from "./screens/MembershipUpgrade";
import SponsorMember from "./screens/sponsorMember";
import Notifications from "./screens/notifications";
import MembershipUpgradeDetails from "./screens/membershipUpgradeDetails";
import AboutHRPL from "./screens/aboutHRPL/aboutHRPL";
import HRWorkActivity from "./screens/hrWorkActivity";
import PaymentHistory from "./screens/paymentHistory";
import Training from "./screens/training";
import ApplyForTraining from "./screens/applyForTraining";
import PayDues from "./screens/payDues";
import ForgotPassword from "./screens/forgotPassword/forgotPassword";
import ResetPassword from "./screens/resetPassword/resetPassword";
import SingleLayout from "./screens/singleLayout/singleLayout";
import EmailSent from "./screens/emailSent/emailSent";
import MessageReceived from "./screens/messageReceived/messageReceived";
import NeedHelp from "./screens/needHelp/needHelp";
import VerifyEmail from "./screens/verifyEmail/verifyEmail";
import UpdateProfile from "./screens/updateProfile/updateProfile";
import ProtectedRoute from "./utils/protectedRoute";
import ActivityHistory from "./screens/activityHistory";
import Documents from "./screens/myDocuments";
import CompetencyAssessment from "./screens/competencyAssessment";
import Conference from "./screens/conferences";
import ApplyForConference from "./screens/applyForConference";
import ConferenceOutstandingPayment from "./screens/conferenceOutstandingPayment";
import ConferenceEntryBadge from "./screens/badge";
import OtherEvents from "./screens/otherEvents";
import ApplyForOtherEvents from "./screens/applyForOtherEvent";
import Profile from "./screens/profile/profile";
import ApplyForAssessment from "./screens/applyForAssessment";
import RegisteredTrainingDetails from "./screens/registeredEventDetails/registeredTrainingDetails";
import RegisteredConferenceDetails from "./screens/registeredEventDetails/registeredConferenceDetails";
import RegisteredOtherDetails from "./screens/registeredEventDetails/registeredOtherDetails";
import TrainingInvoice from "./screens/trainingInvoice";
import ConferenceInvoice from "./screens/conferenceInvoice";
import OtherInvoice from "./screens/otherInvoice";
import ObtainLicense from "./screens/obtainLicense";
import AssessmentDetails from "./screens/assessmentDetails";
import ReadyToMarket from "./screens/readyToMarket/main";
import CorporateProfile from "./screens/corporateMembers/profile/profile";
import ApplyForCorporateTraining from "./screens/corporateMembers/eventApplication/training/applyForTraining";
import ApplyForCorporateConference from "./screens/corporateMembers/eventApplication/conference/applyForConference";
import ApplyForCorporateOther from "./screens/corporateMembers/eventApplication/other/applyForOther";
import { useAtomValue } from "jotai";
import { isCorporate, userData } from "./state";
import NonMemberLayout from "./screens/nonMembers/layout";
import NonMemberTraining from "./screens/nonMembers/training/training";
import ApplyForNonMemberTraining from "./screens/nonMembers/training/apply";
import NonMemberConference from "./screens/nonMembers/conference/conference";
import NonMemberOther from "./screens/nonMembers/other/other";
import ApplyForNonMemberOther from "./screens/nonMembers/other/apply";
import ApplyForNonMemberConference from "./screens/nonMembers/conference/apply";
import ChangePassword from "./screens/changePassword/changePassword";
import ChairmanActivities from "./screens/chairmanActivities/chairmanActivities";
import EndorsementRequests from "./screens/endorsementRequests/endorsementRequests";
import NonMemberAuth from "./screens/nonMembers/auth";
import ApplyForNonMemberTrainingAsOrg from "./screens/nonMembers/training/orgApply";
import NonMemberInvoice from "./screens/nonMembers/invoice";
import UpdateDetails from "./screens/nonMembers/updateDetails";
import ApplyForNonMemberOtherAsOrg from "./screens/nonMembers/other/orgApply";
import ApplyForNonMemberConferenceAsOrg from "./screens/nonMembers/conference/orgApply";
import Referral from "./screens/referral";
import NonMemberForgotPassword from "./screens/nonMembers/forgotPassword";
import NonMemberResetPassword from "./screens/nonMembers/resetPassword";
import { MemberType } from "./requests";
import ErrorPage from "./screens/errorPage/errorPage";
import NonMemberProtect from "./utils/protectNonMember";
import QueryTransaction from "./screens/queryTransaction/queryTransaction";

function App() {
  const isCorporateMember = useAtomValue(isCorporate);
  const user = useAtomValue(userData);
  const isFellowOrFullMember =
    user?.MembershipTypeId === MemberType.Fellow ||
    user?.MembershipTypeId === MemberType.Full;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<ErrorPage />} path="*" />
        <Route element={<SignIn />} path="/" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<ResetPassword />} path="/reset-password" />
      </Route>
      <Route element={<SingleLayout />}>
        <Route element={<ChangePassword />} path="/change-password" />
        <Route element={<NeedHelp />} path="/need-help" />
        <Route element={<EmailSent />} path="/email-sent" />
        <Route element={<MessageReceived />} path="/message-received" />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<SingleLayout />}>
          <Route element={<VerifyEmail />} path="/verify-email" />
          <Route element={<UpdateProfile />} path="/update-profile" />
        </Route>
        <Route element={<DashboardLayout />}>
          <Route element={<Home />} path="/home" />
          <Route
            element={isCorporateMember ? <CorporateProfile /> : <Profile />}
            path="/profile"
          />
          {isFellowOrFullMember && (
            <Route element={<Referral />} path="/referral" />
          )}
          <Route element={<RequestEndorsement />} path="/request-endorsement" />
          <Route element={<SponsorMember />} path="/sponsor-member" />
          <Route element={<Notifications />} path="/notifications" />
          {!isCorporateMember && (
            <>
              <Route
                element={<MembershipUpgrade />}
                path="/membership-upgrade"
              />
              <Route
                element={<MembershipUpgradeDetails />}
                path="/membership-upgrade/details"
              />
              <Route element={<ObtainLicense />} path="/obtain-license" />
              <Route element={<AboutHRPL />} path="/about-hrpl" />
              <Route element={<HRWorkActivity />} path="/hr-work-activity" />
            </>
          )}
          <Route element={<PaymentHistory />} path="/payment-history" />
          <Route element={<QueryTransaction />} path="/query-transaction" />
          <Route element={<Training />} path="/event-application/training" />
          <Route
            element={
              isCorporateMember ? (
                <ApplyForCorporateTraining />
              ) : (
                <ApplyForTraining />
              )
            }
            path="/event-application/training/apply"
          />
          <Route
            element={<Conference />}
            path="/event-application/conferences"
          />
          <Route
            element={<OtherEvents />}
            path="/event-application/other-events"
          />
          <Route
            element={
              isCorporateMember ? (
                <ApplyForCorporateConference />
              ) : (
                <ApplyForConference />
              )
            }
            path="/event-application/conferences/apply"
          />
          <Route element={<PayDues />} path="/pay-dues" />
          <Route
            element={<ConferenceOutstandingPayment />}
            path="/event-application/conferences/outstanding-payment"
          />
          <Route element={<ActivityHistory />} path="/activity-history" />
          <Route element={<Documents />} path="/my-documents" />
          <Route element={<AboutHRPL />} path="/about-hrpl" />
          <Route
            element={<CompetencyAssessment />}
            path="/competency-assessment"
          />
          <Route
            element={<ApplyForAssessment />}
            path="/competency-assessment/apply"
          />
          <Route
            element={<AssessmentDetails />}
            path="/competency-assessment/details"
          />
          <Route
            element={<TrainingInvoice />}
            path="/event-application/training/invoice"
          />
          <Route
            element={<ConferenceInvoice />}
            path="/event-application/conferences/invoice"
          />
          <Route
            element={<OtherInvoice />}
            path="/event-application/other-events/invoice"
          />
          <Route
            element={<ConferenceEntryBadge />}
            path="/event-application/conferences/entry-badge"
          />
          <Route
            element={<OtherEvents />}
            path="/event-application/other-events"
          />
          <Route
            element={
              isCorporateMember ? (
                <ApplyForCorporateOther />
              ) : (
                <ApplyForOtherEvents />
              )
            }
            path="/event-application/other-events/apply"
          />
          <Route
            element={<RegisteredTrainingDetails />}
            path="/event-application/registered-trainings/:id/:eventId"
          />
          <Route
            element={<RegisteredConferenceDetails />}
            path="/event-application/registered-conferences/:id/:eventId"
          />
          <Route
            element={<RegisteredOtherDetails />}
            path="/event-application/registered-others/:id/:eventId"
          />
          <Route
            element={<ConferenceEntryBadge />}
            path="/event-application/badge"
          />
          <Route element={<ReadyToMarket />} path="/ready-to-market" />
          {user?.IsChairman && (
            <>
              <Route
                element={<ChairmanActivities />}
                path="/external-activities"
              />
              <Route
                element={<EndorsementRequests />}
                path="/endorsement-requests"
              />
              <Route element={<EndorseMember />} path="/endorse-member" />
            </>
          )}
        </Route>
      </Route>
      <Route element={<NonMemberLayout />}>
        <Route path="/non-member-events">
          <Route element={<NonMemberAuth />} path="auth" />
          <Route element={<NonMemberForgotPassword />} path="forgot-password" />
          <Route element={<NonMemberResetPassword />} path="reset-password" />
          <Route element={<NonMemberTraining />} path="training" />
          <Route
            element={<ApplyForNonMemberTraining />}
            path="training/apply"
          />
          <Route element={<NonMemberProtect />}>
            <Route
              element={<ApplyForNonMemberTrainingAsOrg />}
              path="training/apply-as-org"
            />
            <Route
              element={<ApplyForNonMemberOtherAsOrg />}
              path="other/apply-as-org"
            />
            <Route
              element={<ApplyForNonMemberConferenceAsOrg />}
              path="conference/apply-as-org"
            />
            <Route element={<NonMemberInvoice />} path="invoice" />
          </Route>
          <Route element={<NonMemberConference />} path="conference" />
          <Route
            element={<ApplyForNonMemberConference />}
            path="conference/apply"
          />
          <Route element={<NonMemberOther />} path="other" />
          <Route element={<ApplyForNonMemberOther />} path="other/apply" />
        </Route>
        <Route element={<UpdateDetails />} path="/update-details" />
      </Route>
    </Routes>
  );
}

export default App;
