import Button from "../../custom/button/button";
import scroll from "../../assets/scroll.png";
import avatar from "../../assets/user.png";
import styles from "./main.module.scss";
import { Field, FormikValues, useFormikContext } from "formik";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import Select from "../../custom/select/select";
import { useAtomValue, useSetAtom } from "jotai";
import { conferenceStep, userProfile } from "../../state";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Input from "../../custom/input/input";
import {
  EventIdType,
  getEventCategories,
  getEventsByCategory,
} from "../../requests";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

export const ConferenceApplication1 = () => {
  const { values } = useFormikContext<FormikValues>();
  const profile = useAtomValue(userProfile);

  return (
    <div>
      <h3>Apply for Conference</h3>
      <section className={styles.formContainer}>
        <div className={styles.profile}>
          <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>
        <div className={styles.formBody}>
          <label className={styles.formTitle}>Mode of sponsorship</label>
          <div className={styles.radioWrapper}>
            <div
              className={classNames(
                styles.radioOption,
                values?.memberType === "individual" ? styles.outlineGreen : ""
              )}
            >
              <Field type="radio" name="memberType" value="individual" />
              <label htmlFor="individual">Individual</label>
            </div>
            <div
              className={classNames(
                styles.radioOption,
                values?.memberType === "corporate" ? styles.outlineGreen : ""
              )}
            >
              <Field type="radio" name="memberType" value="corporate" />
              <label htmlFor="corporate">Corporate</label>
            </div>
          </div>
        </div>
        <br />
        <hr className={styles.divider} />
        {values?.memberType === "corporate" && (
          <>
            <div className={styles.fields}>
              <Field
                as={Input}
                name="organizationName"
                label="Organization Name"
              />
              <Field
                as={Input}
                name="contactPersonName"
                label="Contact Person Name"
              />
              <Field as={Input} name="email" label="Email Address" />
              <Field as={Input} name="phoneNumber" label="Phone Number" />
            </div>
            <br />
            <Field
              as={Input}
              name="organizationAddress"
              label="Organization Address"
            />
          </>
        )}
        <br />
        <br />
        <div className={styles.buttonGroup}>
          <Button text="Cancel" className={classNames("cancelBtn")} />
          <Button type="submit" text="Proceed" />
        </div>
      </section>
    </div>
  );
};

export const ConferenceApplication2 = () => {
  const { values, setFieldValue, submitForm } =
    useFormikContext<FormikValues>();
  const profile = useAtomValue(userProfile);
  const setStep = useSetAtom(conferenceStep);
  const [categoryId, setCategoryId] = useState("");

  const eventCategories = useQuery({
    queryKey: ["get-event-categories"],
    queryFn: getEventCategories,
  });
  const eventByCategory = useQuery({
    queryKey: ["get-event-by-category", categoryId],
    queryFn: () => getEventsByCategory(parseInt(categoryId)),
    enabled: !!categoryId,
  });

  const eventCategoryOptions = eventCategories?.data?.Data?.filter(
    (x) => x.EventTypeId === EventIdType.Conference
  ).map((item) => (
    <option key={item.Id} value={item.Id}>
      {item.EventCategoryName}
    </option>
  ));
  const eventsByCategoryOptions = eventByCategory?.data?.Data?.filter(
    (x) => x.EventTypeId === EventIdType.Conference
  ).map((item, index) => (
    <option
      key={index}
      value={`{"eventNameId": ${item.EventNameId}, "learningEventId": ${item.LearningEventId}}`}
      //value={`${item.EventNameId} ${item.LearningEventId} ${item.Amount}`}
    >
      {item.EventName}
    </option>
  ));

  const nextStep = () => {
    const details = JSON.parse(values.conferenceName);
    setFieldValue("conferenceName", details?.eventNameId);
    setFieldValue("learningEventId", details?.learningEventId);
    //setFieldValue("amount", details?.amount);
    submitForm();
  };

  return (
    <div>
      <h3 className={classNames("headingWithBackArrow")}>
        <button onClick={() => setStep((prev) => prev - 1)}>
          <BackArrow />
        </button>
        Apply for Conference
      </h3>
      <section className={styles.formContainer}>
        <div className={styles.profile}>
          <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>
        <div className={styles.formBody}>
          <section className={styles.grid}>
            <Field
              name="conferenceType"
              as={Select}
              label="Conference Theme"
              placeholder="Select"
              options={eventCategoryOptions}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setCategoryId(e.target.value);
                setFieldValue("conferenceType", e.target.value);
              }}
            />
            <Field
              name="conferenceName"
              as={Select}
              label="Conference Type"
              placeholder="Select"
              options={eventsByCategoryOptions}
            />
          </section>
          <br />
          <br />
          <section className={styles.spaceBetween}>
            <Link
              target="_blank"
              to="https://www.cipmnigeria.org/conference/faqs"
            >
              <span>
                <img src={scroll} alt="scroll" />
              </span>
              <span>Learn more about Conference process</span>
            </Link>
            <div className={styles.buttonGroup}>
              <Button text="Cancel" className={classNames("cancelBtn")} />
              <Button onClick={nextStep} text="Proceed" />
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};
