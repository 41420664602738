import styles from "./main.module.scss";
import Button from "../../custom/button/button";
import avatar from "../../assets/user.png";
import { ReactComponent as BackArrow } from "../../assets/arrow-back.svg";
import { useAtomValue, useSetAtom } from "jotai";
import { trainingStep, userProfile } from "../../state";
import { ErrorMessage, Field, FormikValues, useFormikContext } from "formik";
import Input from "../../custom/input/input";
import Select from "../../custom/select/select";
import { useQuery } from "@tanstack/react-query";
import {
  EventIdType,
  getEventCategories,
  getEventsByCategory,
} from "../../requests";
import classNames from "classnames";
import { useState } from "react";

enum EventType {
  Training = 1,
  Conference = 2,
  Other = 3,
}

export const ApplyForTraining1 = () => {
  const { values } = useFormikContext<FormikValues>();
  const profile = useAtomValue(userProfile);

  return (
    <div>
      <h3>Apply for Training</h3>
      <section className={styles.formContainer}>
        <div className={styles.profile}>
          <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>
        <div className={styles.formBody}>
          <label className={styles.formTitle}>Mode of Sponsorship</label>
          <div className={styles.radioWrapper}>
            <div
              className={classNames(
                styles.radioOption,
                values?.memberType === "individual" ? styles.outlineGreen : ""
              )}
            >
              <Field type="radio" name="memberType" value="individual" />
              <label htmlFor="individual">Individual</label>
            </div>
            <div
              className={classNames(
                styles.radioOption,
                values?.memberType === "corporate" ? styles.outlineGreen : ""
              )}
            >
              <Field type="radio" name="memberType" value="corporate" />
              <label htmlFor="corporate">Corporate</label>
            </div>
          </div>
          <ErrorMessage name="memberType">
            {(msg) => <div className={styles.error}>{msg}</div>}
          </ErrorMessage>
          <hr className={classNames("divider")} />
          {values.memberType === "corporate" && (
            <>
              <div className={styles.grid}>
                <Field
                  as={Input}
                  name="organizationName"
                  label="Organization Name"
                />
                <Field
                  as={Input}
                  name="contactPersonName"
                  label="Contact Person Name"
                />
                <Field as={Input} name="email" label="Email Address" />
                <Field as={Input} name="phoneNumber" label="Phone Number" />
              </div>
              <br />
              <Field
                as={Input}
                name="organizationAddress"
                label="Organization Address"
              />
            </>
          )}
          <div className={styles.buttonGroup}>
            <Button className={classNames("cancelBtn")} text="Cancel" />
            <Button type="submit" text="Proceed" />
          </div>
        </div>
      </section>
    </div>
  );
};

export const ApplyForTraining2 = () => {
  const { values, setFieldValue, submitForm } =
    useFormikContext<FormikValues>();
  const profile = useAtomValue(userProfile);
  const setStep = useSetAtom(trainingStep);
  const [categoryId, setCategoryId] = useState("");

  const eventCategories = useQuery({
    queryKey: ["get-event-categories"],
    queryFn: getEventCategories,
  });
  const eventByCategory = useQuery({
    queryKey: ["get-event-by-category", categoryId],
    queryFn: () => getEventsByCategory(parseInt(categoryId)),
    enabled: !!categoryId,
  });

  const eventCategoryOptions = eventCategories?.data?.Data?.filter(
    (x) => x.EventTypeId === EventType.Training
  ).map((item) => (
    <option key={item.Id} value={item.Id}>
      {item.EventCategoryName}
    </option>
  ));
  const eventsByCategoryOptions = eventByCategory?.data?.Data?.filter(
    (x) => x.EventTypeId === EventIdType.Training
  ).map((item) => (
    <option
      key={item.EventNameId}
      value={`{"eventNameId": ${item.EventNameId}, "learningEventId": ${item.LearningEventId}}`}
    >
      {item.EventName}
    </option>
  ));

  const nextStep = () => {
    const details = JSON.parse(values.trainingName);
    setFieldValue("trainingName", details?.eventNameId);
    setFieldValue("learningEventId", details?.learningEventId);
    //setFieldValue("amount", details?.amount);
    submitForm();
  };

  return (
    <div>
      <h3 className={classNames("headingWithBackArrow")}>
        <button onClick={() => setStep((prev) => prev - 1)}>
          <BackArrow />
        </button>
        Apply for Training
      </h3>
      <section className={styles.formContainer}>
        <div className={styles.profile}>
          <img src={profile?.UploadPictureUrl || avatar} alt="profile" />
          <div>
            <h2>{profile?.Name}</h2>
            <label>Number: {profile?.MembershipNo}</label>
          </div>
        </div>
        <div className={styles.formBody}>
          <section className={styles.grid}>
            <Field
              name="trainingType"
              as={Select}
              label="Training Type"
              placeholder="Select"
              options={eventCategoryOptions}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setCategoryId(e.target.value);
                setFieldValue("trainingType", e.target.value);
              }}
            />
            <Field
              name="trainingName"
              as={Select}
              label="Training Name"
              placeholder="Select"
              options={eventsByCategoryOptions}
            />
          </section>
          <br />
          <br />
          <div className={styles.buttonGroup}>
            <Button text="Cancel" className={classNames("cancelBtn")} />
            <Button onClick={nextStep} text="Proceed" />
          </div>
        </div>
      </section>
    </div>
  );
};
