import styles from './styles.module.scss';

interface Props {
	icon: React.ReactNode;
	heading: string;
	para: string;
}

const IconText = ({ icon, heading, para }: Props) => {
	return (
		<section className={styles.section}>
			{icon}
			<div>
				<h4 className={styles.heading}>{heading}</h4>
				<p className={styles.para}>{para}</p>
			</div>
		</section>
	);
};

export default IconText;
