import styles from "./styles.module.scss";
import IconText from "../../custom/iconText/iconText";
import Button from "../../custom/button/button";
import avatar from "../../assets/user.png";
import { ReactComponent as PaperPine } from "../../assets/papperPine.svg";
import { ReactComponent as Search } from "../../assets/search-alt.svg";
import { App, Tabs } from "antd";
import type { TabsProps } from "antd";
import { useState } from "react";
import PersonalDetails from "../../components/profile/personalDetails/personalDetails";
import EducationalBackground from "../../components/profile/educationalBackground/educationalBackground";
import WorkExperience from "../../components/profile/workExperience/workExperience";
import NextOfKin from "../../components/profile/nextOfKin/nextOfKin";
import ProfessionalBody from "../../components/profile/professionalBody/professionalBody";
import Activity from "../../components/profile/activity/activity";
import { useAtomValue, useSetAtom } from "jotai";
import {
  userData,
  isUpdateProfile,
  getUserGrade,
  userProfile,
} from "../../state";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getProfile, updatePersonalDetails } from "../../requests";
import Spinner from "../../custom/spinner/spinner";
import { AxiosError } from "axios";
import { errorMessage } from "../../utils/errorMessage";

const Profile = () => {
  const user = useAtomValue(userData);
  const profile = useAtomValue(userProfile);
  const { notification } = App.useApp();
  const grade = getUserGrade(user?.MembershipTypeId!);
  const setIsUpdateProfileButton = useSetAtom(isUpdateProfile);
  const [tabIndex, setTabIndex] = useState("1");

  const handleTabChange = (key: string) => {
    setTabIndex(key);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file) {
      uploadPhoto(file[0]);
    }
  };

  const updateProfilePhotoMutation = useMutation({
    mutationKey: ["update-profile-photo"],
    mutationFn: (payload: Partial<Profile>) => updatePersonalDetails(payload),
  });
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchProfile,
  } = useQuery({
    queryKey: ["get-profile"],
    queryFn: () => getProfile(user?.MemberId!),
    enabled: !!user?.MemberId,
  });

  const uploadPhoto = async (photo: File) => {
    const payload: Partial<Profile> = {
      UploadPicture: photo,
      //TitleId: profile?.TitleId,
      Email: profile?.Email,
      PhoneNumber: profile?.PhoneNumber,
      DateBirth: profile?.DateBirth,
      ContactAddress: profile?.ContactAddress,
      ZoneId: profile?.ZoneId,
      MembershipNo: profile?.MembershipNo,
      MembershipType: profile?.MembershipType,
      Name: profile?.Name,
    };
    try {
      await updateProfilePhotoMutation.mutateAsync(payload, {
        onSuccess: () => {
          notification.success({
            message: "Success",
            description: "Profile photo updated successfully",
          });
          refetchProfile();
        },
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: errorMessage(error),
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <p className={styles.tabLabel}>Personal Details</p>,
      children: <PersonalDetails personalDetails={data} />,
    },
    {
      key: "2",
      label: <p className={styles.tabLabel}>Educational Background</p>,
      children: <EducationalBackground />,
    },
    {
      key: "3",
      label: <p className={styles.tabLabel}>Work Experience</p>,
      children: <WorkExperience />,
    },
    {
      key: "4",
      label: <p className={styles.tabLabel}>Next of Kin</p>,
      children: <NextOfKin />,
    },
    {
      key: "5",
      label: <p className={styles.tabLabel}>Professional Bodies</p>,
      children: <ProfessionalBody />,
    },
    {
      key: "6",
      label: <p className={styles.tabLabel}>Activity</p>,
      children: <Activity />,
    },
  ];

  if (isLoading) {
    return <Spinner width={80} height={80} style={{ marginInline: "auto" }} />;
  }

  if (isError) {
    return <p className="error">{(error as AxiosError)?.message}</p>;
  }

  return (
    <section className={styles.section}>
      <section className={styles.profileWrapper}>
        <article className={styles.article}>
          <div className={styles.pictureContainer}>
            <img
              src={data?.UploadPictureUrl}
              alt=""
              className={styles.profile}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = avatar;
              }}
            />
            <label className={styles.changePhotoBtn}>
              {updateProfilePhotoMutation.isPending
                ? "Changing Photo..."
                : "Change Photo"}
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </label>
          </div>
          <div className={styles.description}>
            <h2 className={styles.heading}>
              {data?.Name?.toUpperCase() ||
                data?.FirstName?.toUpperCase() +
                  " " +
                  data?.LastName?.toUpperCase()}
            </h2>
            <p className={styles.para}>{grade} Grade Member at CIPM Nigeria</p>
            <span className={styles.iconHolder}>
              <IconText
                heading={data?.MembershipNo!}
                para="Number"
                icon={<Search />}
              />
              <IconText
                heading={`${data?.UpgradeScore!}`}
                para="Score"
                icon={<PaperPine />}
              />
              {/* {data?.CreatedDate && (
                <IconText
                  heading={`${getDateWithoutTime(data?.CreatedDate!)}`}
                  para="Date Inducted"
                  icon={<Search />}
                />
              )} */}
            </span>
          </div>
        </article>
        <Button
          text="Edit Profile"
          className={styles.editButton}
          onClick={() => setIsUpdateProfileButton((prev) => !prev)}
        />
        <Tabs
          defaultActiveKey={tabIndex}
          items={items}
          onChange={handleTabChange}
          className={styles.tab}
        />
      </section>
    </section>
  );
};

export default Profile;
