import { FieldHookConfig, useField } from "formik";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Spinner from "../spinner/spinner";

interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  options?: React.ReactNode;
  label?: string;
  placeholder?: string;
  className: string;
  isLoading?: boolean;
  isError?: boolean;
  error?: string;
}

const Select: React.FC<SelectProps & FieldHookConfig<string> & any> = ({
  options,
  label,
  placeholder,
  className,
  isLoading,
  isError,
  error,
  ...rest
}) => {
  const [field, meta] = useField(rest);

  return (
    <section className={classNames(className, styles.wrapper)}>
      <label className={styles.label}>
        {isLoading ? (
          <Spinner width={25} height={25} />
        ) : isError ? (
          error
        ) : (
          label
        )}
      </label>
      <select className={styles.select} {...field} {...rest}>
        {/* {!meta.value && <option value="">{placeholder || label}</option>} */}
        <option value="">{placeholder || label}</option>
        {options}
      </select>
      {meta.touched && meta.error ? (
        <label className={styles.error}>
          <span>&#42;</span>
          {meta.error}
        </label>
      ) : null}
    </section>
  );
};

export default Select;
